import React, { useMemo } from 'react'

import { InputNumber } from 'antd'

const LabelInputNumber = ({ className, label, max = 100, min = 0, value, onChange }) => {
  const finalClassname = useMemo(() => `label-input-number flex-row ${className ?? ''}`, [])

  return (
    <div className={finalClassname}>
      {label} : &nbsp;
      <InputNumber max={max} min={min} value={value} onChange={onChange} />
    </div>
  )
}

export default LabelInputNumber
