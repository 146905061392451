import React, { createContext, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { getStudentById, getStudentFreeFields } from '../utils/api/student'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

export const StudentContext = createContext()

const StudentProvider = ({ children, user }) => {
  const [freefields, setFreefields] = useState({})
  const [student, setStudent] = useState(null)

  useEffect(() => {
    if (user.student) {
      getStudentFreeFields(user.student, user, { contexts: ['institution'] }).then(json => {
        if (json?.data) {
          const freefields = {}

          json.data.forEach(sff => {
            if (!freefields[sff.fieldType.institution.id]) {
              freefields[sff.fieldType.institution.id] = []
            }

            freefields[sff.fieldType.institution.id].push(sff)
          })

          setFreefields(freefields)
        }
      })
      getStudentById(user, user.student).then(json => {
        if (json?.data) {
          setStudent(json.data)
        }
      })
    } else {
      setFreefields({})
    }
  }, [user.student])

  return (
    <StudentContext.Provider
      value={{ freefields, student, setStudent }}
    >
      {children}
    </StudentContext.Provider>
  )
}

export default connect(mapStateToProps)(StudentProvider)
