import { requestWithPromise } from '..'

export const getJobResult = (jobId, user) => {
  return requestWithPromise(
    `/job-result/${jobId}`,
    'GET',
    null,
    user,
    true
  )
}
