import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

class AppBanner extends React.Component {
  render () {
    const appBanner = this.props.getAppBanner

    if (appBanner === null) return null

    const message = appBanner.message
    const type = appBanner.type
    const maintenance = appBanner.maintenance

    if (message.length === 0 && maintenance === false) return null

    let icon = null
    if (type === 'info') icon = 'question-circle'
    if (type === 'warning') icon = 'exclamation-circle'
    if (type === 'error') icon = 'exclamation-triangle'

    let color = null
    if (type === 'info') color = '#3C8DBC'
    if (type === 'warning') color = '#FF9800'
    if (type === 'error') color = '#F44336'

    return (
      <div style={{ backgroundColor: color, color: 'white', padding: '10px' }} className='flex-row'>
        <div className='flex-column'>
          <div className='flex-fill' />
          <FontAwesomeIcon icon={icon} style={{ fontSize: '30px', marginRight: '10px' }} />
          <div className='flex-fill' />
        </div>
        <div>
          {
            !maintenance ? null
              : <div>
                {this.props.t('Interneo is in maintenance mode.')}<br />
              </div>
          }
          <div>{appBanner.message}</div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBanner)
