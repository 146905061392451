import React from 'react'

import { Switch } from 'antd'

const StandardSwitch = props => {
  const handleChange = () => {
    props.onChange(!props.data[props.dataKey], props.dataKey)
  }

  return (
    <Switch
      checked={props.data[props.dataKey]}
      onChange={handleChange}
      disabled={props.disabled}
    />
  )
}

export default StandardSwitch
