import React, { useEffect, useState } from 'react'

import { Timeline } from 'antd'
import { DATE_WITHOUT_TIME, EUROPEAN_DATE } from '../../utils/constants'
import moment from 'moment'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { isArray } from 'lodash'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const History = ({ data, period, strictMode, t }) => {
  const [localData, setLocalData] = useState({})
  const [periodDays, setPeriodDays] = useState([])
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())

  useEffect(() => {
    if (isArray(data)) {
      const localData = {}
      let startDate = period.startDate
      let endDate = period.endDate

      data.forEach(d => {
        if (d.date < startDate) {
          startDate = d.date
        } else if (d.date > endDate) {
          endDate = d.date
        }

        localData[d.date.format(DATE_WITHOUT_TIME)] = d
      })

      setStartDate(startDate)
      setEndDate(endDate)
      setLocalData(localData)
    }
  }, [data, period])

  useEffect(() => {
    if (startDate < endDate) {
      const periodDays = []
      let current = startDate.clone()

      while (current <= endDate) {
        const key = current.format(DATE_WITHOUT_TIME)

        periodDays.push(localData[key] ?? { date: current.clone(), body: t('No schedule.') })

        current = current.clone().add(1, 'days')
      }

      setPeriodDays(periodDays)
    }
  }, [startDate, endDate, localData, t])

  return (
    <div className='internship-schedule'>
      <Timeline>
        {!(strictMode && data?.length === 0) && periodDays.map((p, index) => {
          return (
            <Timeline.Item
              color={p.date.isoWeekday() === 6 || p.date.isoWeekday() === 7 ? '#E8E8E8' : '#3C8DBC'}
              key={'date_' + index}
            >
              <div className='flex-row'>
                <b>{p.date.format(EUROPEAN_DATE)}</b> &nbsp;:&nbsp;
                {p.body}
              </div>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </div>
  )
}

export default connect(mapStateToProps)(History)
