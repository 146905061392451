import React, { createContext, useEffect, useMemo, useState } from 'react'

import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import InternshipFilesManager from '../../Components/shared/FilesManager/InternshipFilesManager/InternshipFilesManager'
import InternshipFileModal from '../Forms/InternshipFileModal'
import { ROLE_OBSERVER } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'

export const InternshipFilesDrawerContext = createContext()

export const DEFAULT_STATE = { data: null }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipFilesDrawer = ({ children, user, t }) => {
  const [selectedInternship, setSelectedInternship] = useState(DEFAULT_STATE)

  const isReadOnly = useMemo(() => user.roles.includes(ROLE_OBSERVER), [user.roles])

  return (
    <>
      <InternshipFilesDrawerContext.Provider value={{ setSelectedInternship }}>
        {children}
      </InternshipFilesDrawerContext.Provider>
      <Drawer
        title={t('internship_files_drawer.title')}
        width='650px'
        onClose={() => setSelectedInternship(DEFAULT_STATE)}
        visible={!!selectedInternship.data}
        zIndex={5000}
        destroyOnClose
      >
        <InternshipFileModal>
          <InternshipFilesManager internship={selectedInternship.data} readOnly={isReadOnly} />
        </InternshipFileModal>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(InternshipFilesDrawer)
