
import React, { useState, useEffect, useRef } from 'react'

import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import IconTooltip from '../../../institution/Quotas/definition/IconTooltip'
import { getIconByName } from '../../../../utils/fontAwesomeHelper'
import { Col, Input, Row } from 'antd'
import TimeInput from '../Other/TimeInput'
import { getLuminanceFromColorCode } from '../../../../utils/colors'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { toLower } from 'lodash'
import EventCodesTooltip from '../ShiftTableParts/Tooltips/EventCodesTooltip'
import PresetsTooltip from '../ShiftTableParts/Tooltips/PresetsTooltip'
import { CELL_TRESHOLDS, FILLED_COLOR, OVERFILLED_COLOR_TRANSPARENCY } from '../constants'

const ICON_COLOR = 'rgb(55, 135, 191)'
const WARNING_ICON = 'warning'
const WARNING_ICON_COLOR = FILLED_COLOR
const WARNING_TEXT = 'You have exceeded the number of places available'
const ERROR_BACKGROUND_COLOR = 'red'
const NULL_SHIFT_PAUSE_NAME = 'Without pause'
const NULL_SHIFT_PAUSE_ICON = faBan
const CELL_STATES = {
  DISABLE: 0,
  IN_MULTI_SELECTION_MODE: 1,
  EVENT_EDITABLE_ONLY: 2,
  FULLY_EDITABLE: 3
}
const { DISABLE, EVENT_EDITABLE_ONLY, FULLY_EDITABLE, IN_MULTI_SELECTION_MODE } = CELL_STATES
const { OVERFILLED } = CELL_TRESHOLDS

const PauseQuotaCell = (props) => {
  const [shift, setShift] = useState({})
  const [shiftPause, setShiftPause] = useState(null)
  const [state, setState] = useState(DISABLE)
  const [hasFocus, setHasFocus] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [displayWarning, setDisplayWarning] = useState(false)

  const luminance = props.color ? getLuminanceFromColorCode(props.color) : 'black'
  const cellHasShift = shift.id && shift.id > 0
  const eventCodeOnlyMode = state === EVENT_EDITABLE_ONLY || state === DISABLE

  const periodRef = useRef(null)
  const eventRef = useRef(null)

  useEffect(() => {
    if (props.shift && !isDirty) {
      setShift({
        ...props.shift,
        id: props.shift.id ?? -1,
        periodCode: props.shift.periodCode ?? '',
        eventCodeType: props.shift.eventCodeType?.id ? props.shift.eventCodeType : '',
        startTime: getTimeAsObject(props.shift.startTime),
        endTime: getTimeAsObject(props.shift.endTime)
      })
    }
  }, [props.shift, isDirty])

  useEffect(() => {
    if (props.preset) {
      setShiftPause(formatShiftPause(props.preset.shiftPause))
    } else {
      setShiftPause(null)
    }
  }, [props.preset])

  useEffect(() => {
    if ((props.multiSelectionMode && props.eventCodeOnly) || !props.contentEditable || props.inWidgetMode) {
      setState(DISABLE)
    } else if (props.multiSelectionMode) {
      setState(IN_MULTI_SELECTION_MODE)
    } else if (props.eventCodeOnly) {
      setState(EVENT_EDITABLE_ONLY)
    } else {
      setState(FULLY_EDITABLE)
    }
  }, [props.contentEditable, props.eventCodeOnly, props.multiSelectionMode])

  useEffect(() => {
    if (hasFocus && (!shift.id || shift.id <= 0)) {
      if (props.eventCodeOnly && eventRef.current) {
        eventRef.current.focus()
      } else if (periodRef.current) {
        periodRef.current.focus()
      }
    }
  }, [hasFocus, shift, props.eventCodeOnly])

  const formatShiftPause = shiftPause => {
    if (shiftPause) {
      const oldName = shiftPause.name
      const firstLetter = oldName[0]
      const endOfName = toLower(oldName.slice(1, oldName.length))

      return { ...shiftPause, name: firstLetter + endOfName }
    }

    return shiftPause
  }

  const handleCellBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setHasFocus(false)
    }
  }

  const handleCellFocus = () => {
    setHasFocus(true)
  }

  const handleOnClick = () => {
    if (props.errorMessage && typeof props.resetErrorMessage === 'function') {
      props.resetErrorMessage()
    }
  }

  const handleCellSave = key => {
    if (typeof props.onCellChange === 'function') {
      props.onCellChange(shift, key)
      setIsDirty(false)
    }
  }

  const handlePeriodChange = e => {
    const newShift = { ...shift }

    newShift.periodCode = e.target.value.trim().toUpperCase()

    setShift(newShift)
    setIsDirty(true)
  }

  const handleTimeChange = (time, key) => {
    const newShift = { ...shift }

    newShift[key] = { ...time }

    setShift(newShift)

    if (typeof props.onCellChange === 'function') {
      props.onCellChange(newShift, key)
      setIsDirty(false)
    }
  }

  const handleEventChange = e => {
    const newShift = { ...shift }

    newShift.eventCodeType = e.target.value.trim().toUpperCase()

    setShift(newShift)
    setIsDirty(true)
  }

  const handleMouseEnter = () => {
    if (props.cellTreshold === OVERFILLED) {
      setDisplayWarning(true)
    }
  }

  const handleMouseLeave = () => {
    if (displayWarning) {
      setDisplayWarning(false)
    }
  }

  const renderPeriodAndPause = () => {
    const displayState = cellHasShift || periodHasToBeDisplayed()

    if (displayState) {
      return (
        <div
          className='pause-quota-period-row'
          style={getPartStyleByDisplay(displayState && (shift.periodCode !== '' || state === FULLY_EDITABLE))}
        >
          <Row>
            <Col span={18}>
              <Input
                ref={periodRef}
                value={shift.periodCode}
                onChange={handlePeriodChange}
                onBlur={() => handleCellSave('periodCode')}
                disabled={state === EVENT_EDITABLE_ONLY || state === DISABLE}
              />
            </Col>
            <Col span={6}>
              {getIconTooltipByShiftPause()}
            </Col>
          </Row>
        </div>
      )
    }

    return <div />
  }

  const getIconTooltipByShiftPause = () => {
    if (props.errorMessage) {
      if (eventCodeOnlyMode) {
        return <div />
      }

      return getTooltipByState()
    }

    if (displayWarning) {
      return (
        <IconTooltip
          title={props.t(WARNING_TEXT)}
          icon={getIconByName(WARNING_ICON)}
          iconColor={WARNING_ICON_COLOR}
        />
      )
    }

    if (shiftPause) {
      return (
        <IconTooltip
          title={props.t(shiftPause.name)}
          icon={getIconByName(shiftPause.icon)}
          iconColor={ICON_COLOR}
        />
      )
    }

    return (
      <IconTooltip
        title={props.t(NULL_SHIFT_PAUSE_NAME)}
        icon={NULL_SHIFT_PAUSE_ICON}
        iconColor={ICON_COLOR}
      />
    )
  }

  const getTooltipByState = () => {
    if (eventCodeOnlyMode) {
      return (
        <EventCodesTooltip
          title={props.errorMessage}
          placement='top'
          icon='exclamation-circle'
          iconColor={ERROR_BACKGROUND_COLOR}
        />
      )
    }

    return (
      <PresetsTooltip
        title={props.errorMessage}
        presets={props.internship.presets}
        placement='top'
        icon='exclamation-circle'
        iconColor={ERROR_BACKGROUND_COLOR}
      />
    )
  }

  const renderTimes = () => {
    return (
      <div
        className='pause-quota-times-row'
        style={getPartStyleByDisplay(cellHasShift && timesAreDifferent())}
      >
        <TimeInput
          color={props.color}
          time={shift.startTime}
          onTimeChange={time => handleTimeChange(time, 'startTime')}
          errorMode={!!props.errorMessage}
          disabled={state === EVENT_EDITABLE_ONLY || state === DISABLE}
        />
        <span style={{ color: luminance }}>-</span>
        <TimeInput
          color={props.color}
          time={shift.endTime}
          onTimeChange={time => handleTimeChange(time, 'endTime')}
          errorMode={!!props.errorMessage}
          disabled={state === EVENT_EDITABLE_ONLY || state === DISABLE}
        />
      </div>
    )
  }

  const timesAreDifferent = () => {
    return (
      shift.endTime.hours !== shift.startTime.hours ||
      shift.endTime.minutes !== shift.startTime.minutes ||
      shift.endTime.seconds !== shift.startTime.seconds
    )
  }

  const renderEventCodePart = () => {
    const displayState = cellHasShift || eventHasToBeDisplayed()

    if (displayState) {
      return (
        <div className='pause-quota-event-row' style={getPartStyleByDisplay(displayState)}>
          <Input
            value={shift.eventCodeType?.type ? props.t('event.code.' + shift.eventCodeType.type) : (shift.eventCodeType ?? '')}
            ref={eventRef}
            style={{ color: luminance }}
            tabIndex={state === EVENT_EDITABLE_ONLY ? 0 : -1}
            onChange={handleEventChange}
            onBlur={() => handleCellSave('eventCode')}
            disabled={state === DISABLE}
          />
        </div>
      )
    }

    return <div />
  }

  const periodHasToBeDisplayed = () => {
    return (hasFocus && state === FULLY_EDITABLE) || (props.errorMessage && !eventCodeOnlyMode)
  }

  const eventHasToBeDisplayed = () => {
    return (hasFocus && state === EVENT_EDITABLE_ONLY)
  }

  const getTimeAsObject = stringTime => {
    const time = { hours: 0, minutes: 0, seconds: 0 }

    if (stringTime) {
      const timeParts = stringTime.split(':')

      time.hours = parseInt(timeParts[0])
      time.minutes = parseInt(timeParts[1])
      time.seconds = timeParts[2] ? parseInt(timeParts[2]) : 0
    }

    return time
  }

  const getPartStyleByDisplay = display => {
    if (display) {
      return { visibility: 'visible' }
    }

    return { visibility: 'hidden' }
  }

  const getClassNames = () => {
    let classeNames = 'pause-quota-cell'

    if (props.errorMessage) {
      classeNames += eventCodeOnlyMode
        ? ' pause-shift-error-event-only'
        : ' pause-shift-error'
    }

    return classeNames
  }

  const getStyle = () => {
    if (props.errorMessage) {
      return { backgroundColor: ERROR_BACKGROUND_COLOR }
    }

    if (props.color) {
      return { backgroundColor: props.color }
    }

    return {}
  }

  const getCellStyle = () => {
    const style = {}

    if (props.cellTreshold === OVERFILLED) {
      style.backgroundColor = OVERFILLED_COLOR_TRANSPARENCY
    }

    return style
  }

  return (
    <div
      className='pause-quota-cell-container'
      tabIndex={shift.id && shift.id > 0 ? -1 : 0}
      onBlur={handleCellBlur}
      onFocus={handleCellFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={getCellStyle()}
    >
      <div
        className={getClassNames()}
        style={getStyle()}
        onClick={handleOnClick}
      >
        {renderPeriodAndPause()}
        {renderTimes()}
        {renderEventCodePart()}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PauseQuotaCell)
