const SET_THEME = 'SET_THEME'

export const getTheme = (state) => {
  return state
}

export const setTheme = (theme) => {
  return {
    type: SET_THEME,
    theme: theme
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case SET_THEME:
      return action.theme
    default:
      return state
  }
}
