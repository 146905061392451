import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Input, Dropdown, Menu } from 'antd'
import { InstitutionContext } from '../../../Providers/InstitutionProvider'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getBadgesAssignations, getPendingAssignation, setBadgesAssignations, setDirtyBadgeAssignationId, setPendingAssignationData } from '../../../reducers/BadgesReducer'
import { bindActionCreators } from 'redux'
import { isFunction } from 'lodash'

const mapStateToProps = state => {
  return {
    getPendingAssignation: getPendingAssignation(state),
    getBadgesAssignations: getBadgesAssignations(state),
    t: getTranslate(state.locale)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPendingAssignationData: bindActionCreators(setPendingAssignationData, dispatch),
    setBadgesAssignations: bindActionCreators(setBadgesAssignations, dispatch),
    setDirtyBadgeAssignationId: bindActionCreators(setDirtyBadgeAssignationId, dispatch)
  }
}

const CustomInputDropdown = (props) => {
  const {
    allDataKeys, currentData, currentDataKeys, dataId, getter, idIdentifier, maxDataDisplay, cleanTimeOut, formatCurrentData, setDirtyBadgeAssignationId, setter, t
  } = props
  const { institutionStudents } = useContext(InstitutionContext)

  const [inputValue, setInputValue] = useState('')
  const [localDirty, setLocalDirty] = useState(false)

  useEffect(() => {
    if (props[getter] && (dataId === null || props[getter][dataId])) {
      const value = dataId === null ? props[getter] : props[getter][dataId]

      setInputValue(formatCurrentData ? formatCurrentData(value, currentDataKeys) : value[idIdentifier])
    }
  }, [dataId === null ? props[getter] : props[getter][dataId], idIdentifier, currentDataKeys])

  useEffect(() => {
    if (!localDirty) {
      setLocalDirty(true)
    }
  }, [inputValue])

  const formater = useCallback(data => {
    return isFunction(formatCurrentData) && allDataKeys ? formatCurrentData(data, allDataKeys) : data
  }, [formatCurrentData, allDataKeys])

  const students = useMemo(() => institutionStudents.data, [institutionStudents.data])
  const data = useMemo(() => {
    const localData = {}

    if (students && maxDataDisplay) {
      const normalizeFilter = inputValue.toLowerCase()

      let displayed = 0

      for (let i = 0; i < students.length; i++) {
        if (displayed === maxDataDisplay - 1) {
          break
        }

        const dataFormatted = formater(students[i], allDataKeys)

        if (dataFormatted.toLowerCase().includes(normalizeFilter)) {
          localData[students[i].id] = { value: dataFormatted, studentData: students[i] }
          displayed++
        }
      }
    }

    return localData
  }, [students, maxDataDisplay, inputValue, formater])

  const setNewSlectedStudent = useCallback(newId => {
    const newValue = dataId === null ? { ...props[getter] } : { ...props[getter][dataId] }

    newValue.studentId = data[newId].studentData.id
    newValue.studentLastName = data[newId].studentData.lastname
    newValue.studentFirstName = data[newId].studentData.firstname

    if (dataId === null) {
      props[setter](newValue)
    } else {
      const badgesAssignations = { ...props[getter] }

      badgesAssignations[dataId] = newValue

      props[setter](badgesAssignations)
      setDirtyBadgeAssignationId(dataId)
    }
  }, [props[getter], props[setter], dataId, data, setDirtyBadgeAssignationId])

  const handleVisibleChange = useCallback(status => {
    if (status) {
      cleanTimeOut()
    } else if (localDirty) {
      setInputValue(formater(currentData))
      setLocalDirty(false)

      const newValue = dataId === null ? { ...props[getter] } : { ...props[getter][dataId] }

      if (dataId === null) {
        props[setter](newValue)
      } else {
        const badgesAssignations = { ...props[getter] }

        badgesAssignations[dataId] = newValue

        props[setter](badgesAssignations)
        setDirtyBadgeAssignationId(dataId)
      }
    }
  }, [cleanTimeOut, currentData, dataId, localDirty, props[getter], props[setter], setDirtyBadgeAssignationId, setInputValue, formater])

  const menu = useMemo(() => (
    <Menu>
      {Object.keys(data).map(key => {
        return (
          <Menu.Item key={key} onClick={() => setNewSlectedStudent(key)}>
            {data[key].value}
          </Menu.Item>
        )
      })}
    </Menu>
  ), [data, setNewSlectedStudent])

  return (
    <Dropdown onVisibleChange={handleVisibleChange} overlay={menu} trigger={['click']}>
      <Input
        value={inputValue}
        placeholder={t('Empty')}
        onChange={e => setInputValue(e.target.value)}
        onClick={() => setInputValue('')}
      />
    </Dropdown>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomInputDropdown)
