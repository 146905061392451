const formatStudentBirthdateInBackendForm = student => {
  if (student.birthdate === null || student.birthdate.trim().length === 0) {
    return null
  }

  const birthdateParts = student.birthdate.split('/')

  return `${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`
}

const getStudentFullName = student => {
  if (student === null || !student.lastname || !student.firstname) {
    return null
  }

  return `${student.lastname} ${student.firstname}`
}

export {
  formatStudentBirthdateInBackendForm,
  getStudentFullName
}
