import { VALIDATION_FIELDS } from '../validators'

export const FILE_TYPES = {
  CONVENTION: 'convention',
  DOCUMENT: 'document'
}
export const TYPE_COLORS = {
  convention: 'volcano',
  document: 'orange'
}

export const INTERNSHIP_FILE_VALIDATORS = [
  { key: VALIDATION_FIELDS.TYPE, validator: s => s.type !== null },
  { key: VALIDATION_FIELDS.ORIGINAL_NAME, validator: s => s.originalName !== null }
]

export const getOriginalName = file => file.originalName
export const getExtension = file => file.extension
export const getType = file => file.type
export const getTypeColor = file => TYPE_COLORS[getType(file)]

export class InternshipFile {
  constructor ({ id = -1, type = FILE_TYPES.DOCUMENT, originalName = null, mimeType = null, extension = null, size = null, internship = null } = {}) {
    this.id = id
    this.type = type
    this.originalName = originalName
    this.mimeType = mimeType
    this.extension = extension
    this.size = size
    this.internship = internship
  }

  toRequestBody = () => {
    const body = { ...this }

    body.internship = body.internship === null ? null : body.internship.id

    return body
  }
}
