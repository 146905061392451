import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

import '../../assets/loading.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const Loading = ({ size, style, t }) => {
  return (
    <div className='loading-container' style={style ?? {}}>
      <FontAwesomeIcon icon={faCog} spin size={size} />
      <b>
        {t('Loading ...')}
      </b>
    </div>
  )
}

export default connect(mapStateToProps)(Loading)
