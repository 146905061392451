import React, { createContext, useCallback, useState } from 'react'

import { Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { onError, onSuccess } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import { addInternshipRequestFile } from '../../utils/api/internshipRequest'
import InternshipRequestFileForm from '../../Components/shared/Forms/InternshipRequestFileForm'

export const InternshipRequestFileModalContext = createContext()

const DEFAULT_FILE = { data: null, callback: null }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipRequestFileModal = ({ children, user, t }) => {
  const [selectedFile, setSelectedFile] = useState(DEFAULT_FILE)

  const handleSubmit = useCallback(({ data, file }) => {
    addInternshipRequestFile(user, selectedFile.data.individualInternshipRequest, { file, type: data.type }).then(json => {
      if (json?.data) {
        onSuccess(t('internship_request_file.create.success'))

        if (selectedFile.callback) {
          selectedFile.callback(json.data)
        }
      }
    }).catch(() => {
      onError(t('internship_request_file.create.error'))
    }).finally(() => {
      setSelectedFile(DEFAULT_FILE)
    })
  }, [selectedFile, setSelectedFile, user, t])

  return (
    <>
      <InternshipRequestFileModalContext.Provider value={{ setSelectedFile }}>
        {children}
      </InternshipRequestFileModalContext.Provider>
      <Modal
        width={500}
        title={t('internship_request_file.title')}
        visible={!!selectedFile.data}
        onCancel={() => setSelectedFile(DEFAULT_FILE)}
        footer={null}
        destroyOnClose
      >
        <InternshipRequestFileForm
          item={selectedFile.data}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(InternshipRequestFileModal)
