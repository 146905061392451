import React, { useEffect, useState } from 'react'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_BOOLEAN_TAG,
  DATA_TYPE_DATE
} from '../../Components/shared/SmartTable'
import { request, generalErrorHandler, decodeFrenchCharacters } from '../../utils'

const MailsListView = props => {
  const [mailsList, setMailsList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // get the list of mails sent
    request('/api/UserMail/none/0/100', 'GET', null, props.getUser).then(({ data }) => {
      setLoading(false)
      setMailsList(data.map(item => ({ ...item, subject: decodeFrenchCharacters(item.subject) })))
      // TODO: Implement loading of new pages
    }).catch(error => {
      generalErrorHandler(error)
    })
  }, [])

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_DATE,
      name: props.t('Sent on'),
      key: 'createdAt',
      format: 'DD/MM/YYYY @ HH:mm:ss'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Recipient'),
      key: 'recipient'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Subject'),
      key: 'subject'
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Template'),
      key: 'template'
    },
    {
      type: DATA_TYPE_BOOLEAN_TAG,
      name: props.t('Sent'),
      key: 'sent'
    }
  ]

  return (
    <div>
      <SmartTable
        columns={columns}
        data={mailsList}
        loading={loading}
        noDataText={props.t('No emails')}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MailsListView)
