import { SCHOOLSECTIONS } from './actionsType'

const { REMOVE_SCHOOL_SECTION_BY_INDEX, REMOVE_SCHOOL_SECTION_BY_ID, SET_SCHOOL_SECTIONS_BY_SCHOOL, SET_SCHOOL_SECTION_BY_INDEX, SET_SCHOOL_SECTION_BY_ID, SET_NEW_SCHOOL_SECTION } = SCHOOLSECTIONS

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SCHOOL_SECTIONS_BY_SCHOOL: {
      const newState = { ...state }
      const { schoolId, sections } = action.payload

      newState[schoolId] = sections[schoolId] ?? []

      return newState
    }
    case SET_SCHOOL_SECTION_BY_INDEX: {
      const newState = { ...state }
      const { schoolId, sectionIndex, schoolSection } = action.payload

      newState[schoolId][sectionIndex] = schoolSection

      return newState
    }
    case SET_SCHOOL_SECTION_BY_ID: {
      const newState = { ...state }

      newState[action.payload.schoolId] = newState[action.payload.schoolId]
        .map(section => {
          if (section.id === action.payload.sectionId) {
            return action.payload.schoolSection
          }

          return section
        })

      return newState
    }
    case SET_NEW_SCHOOL_SECTION: {
      const newState = { ...state }

      if (!newState[action.payload.schoolId]) {
        newState[action.payload.schoolId] = []
      }

      newState[action.payload.schoolId].push(action.payload.schoolSection)

      return newState
    }
    case REMOVE_SCHOOL_SECTION_BY_INDEX: {
      const newState = { ...state }

      newState[action.payload.schoolId].splice(action.payload.sectionIndex, 1)

      return newState
    }
    case REMOVE_SCHOOL_SECTION_BY_ID: {
      const newState = { ...state }

      newState[action.payload.schoolId] = newState[action.payload.schoolId]
        .filter(section => section.id !== action.payload.sectionId)

      return newState
    }
    default:
      return state
  }
}
