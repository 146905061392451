import React from 'react'

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Popover } from 'antd'
import { COLORS_PALETTE } from '../../../config/colors'

const { Search } = Input

const SearchPopover = ({ color, onSearch }) => {
  return (
    <Popover content={<Search onSearch={onSearch} />} trigger='click'>
      <FontAwesomeIcon icon={faSearch} color={color ?? COLORS_PALETTE.FIRST} />
    </Popover>
  )
}

export default SearchPopover
