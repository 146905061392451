import React, { createContext, useCallback, useState } from 'react'

import { Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { onError, onSuccess } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import { isFunction } from 'lodash'
import '../../assets/sectorsModal.scss'
import { createHospitalDepartment, updateHospitalDepartment } from '../../utils/api/hospitalDepartment'
import HospitalDepartmentForm from '../../Components/shared/Forms/HospitalDepartmentForm'
import { HospitalDepartment } from '../../utils/entities/hospitalDepartment'

export const HospitalDepartmentModalContext = createContext()

export const DISPLAY_MODE = 'display'
export const EDITION_MODE = 'edit'
export const EDITION_ONLY_MODE = 'edit_only'
export const DEFAULT_STATE = { data: null, callback: null }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const HospitalDepartmentModal = ({ children, user, users = [], t }) => {
  const [selectedHospitalDepartment, setSelectedHospitalDepartment] = useState(DEFAULT_STATE)

  const handleSubmit = useCallback((department) => {
    const promise = department.id < 0 ? { call: createHospitalDepartment, action: 'create' } : { call: updateHospitalDepartment, action: 'update' }

    department = new HospitalDepartment(department)

    promise.call(user, department.toRequestBody()).then(json => {
      if (json?.data) {
        onSuccess(t(`hospital_department.${promise.action}.success`))

        if (isFunction(selectedHospitalDepartment.callback)) {
          selectedHospitalDepartment.callback()
        }

        setSelectedHospitalDepartment({ ...DEFAULT_STATE })
      }
    }).catch(() => {
      onError(t('hospital_department.' + promise.action + '.error'))
      setSelectedHospitalDepartment({ ...DEFAULT_STATE })
    })
  }, [selectedHospitalDepartment, user, t])

  return (
    <>
      <HospitalDepartmentModalContext.Provider value={{ setSelectedHospitalDepartment }}>
        {children}
      </HospitalDepartmentModalContext.Provider>
      <Modal
        title={selectedHospitalDepartment?.data?.name ? selectedHospitalDepartment.data.name : t('hospital_department_modal.title')}
        visible={!!selectedHospitalDepartment.data}
        onCancel={() => setSelectedHospitalDepartment({ ...DEFAULT_STATE })}
        footer={null}
        destroyOnClose
      >
        {selectedHospitalDepartment.data && (
          <HospitalDepartmentForm
            item={selectedHospitalDepartment.data}
            users={users}
            onSubmit={handleSubmit}
          />
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentModal)
