import React, { useState, useEffect, useContext } from 'react'

import PlaceAllocationForm from './forms/PlaceAllocationForm'
import PopoverIconButton from '../shared/PopoverIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import TooltipIconButton from '../shared/TooltipIconButton'
import { BACKEND_DATE_FORMAT } from '../../../../utils/constants'
import moment from 'moment'
import { Card } from 'antd'
import { faBan, faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

export const POPOVER_CONTENT = 'Are you sure you want to delete this quota?'

const QuotaCard = props => {
  const { sections, sectionsObject } = useContext(GlobalContext)

  const [disabled, setDisabled] = useState(true)
  const [editionMode, setEditionMode] = useState(false)
  const [quota, setQuota] = useState(null)
  const [oldQuota, setOldQuota] = useState(null)

  useEffect(() => {
    if (props.data) {
      setQuota(props.data)
    }
  }, [props.data])

  const handleEdition = () => {
    setDisabled(false)
    setEditionMode(true)
    setOldQuota({ ...quota })
  }

  const handleSuppression = () => {
    if (typeof props.onDelete === 'function') {
      props.onDelete(props.data)
    }
  }

  const handleEditionCancel = () => {
    if (typeof props.onPlacesChanged === 'function') {
      props.onPlacesChanged(quota.total - oldQuota.total)
    }

    setDisabled(true)
    setEditionMode(false)
    setQuota({ ...oldQuota })
    setOldQuota(null)
  }

  const handleSave = () => {
    setDisabled(true)
    setEditionMode(false)
    setOldQuota(null)

    if (typeof props.onSave === 'function') {
      props.onSave(quota)
    }
  }

  const updateQuotaPlaces = newPlaces => {
    if (typeof props.onPlacesChanged === 'function') {
      props.onPlacesChanged(quota.total - newPlaces)
    }

    setQuota({ ...quota, total: newPlaces })
  }

  const updateQuotaDates = dates => {
    const newQuota = { ...quota }

    newQuota.startDate = dates[0].format(BACKEND_DATE_FORMAT)
    newQuota.endDate = dates[1].format(BACKEND_DATE_FORMAT)

    setQuota(newQuota)
  }

  const updateQuotaSection = section => {
    setQuota({ ...quota, section: { id: section.id } })
  }

  const renderCardActions = () => {
    if (editionMode) {
      return (
        [
          <TooltipIconButton
            key='card-save-button'
            title='Save'
            onClick={handleSave}
            disabled={!quota.section}
            icon={faSave}
          />,
          <TooltipIconButton
            key='card-cancel-button'
            title='Cancel'
            onClick={handleEditionCancel}
            icon={faBan}
          />
        ]
      )
    }

    return ([
      <TooltipIconButton
        key='card-edit-button'
        title='Edit'
        onClick={handleEdition}
        icon={faEdit}
      />,
      <PopoverIconButton
        key='card-trash-button'
        title='Delete'
        tooltipTitle='Delete'
        content={POPOVER_CONTENT}
        onConfirm={handleSuppression}
        icon={faTrash}
      />
    ])
  }

  return (
    <Card
      style={{ width: '100%', marginBottom: '10px' }}
      actions={renderCardActions()}
    >
      <QuickDualDatePickers
        startDate={quota ? moment(quota.startDate) : moment()}
        endDate={quota ? moment(quota.endDate) : moment()}
        onDatesChange={updateQuotaDates}
        disabled={disabled}
      />
      <PlaceAllocationForm
        places={quota ? quota.total : 0}
        disabled={disabled}
        section={quota && quota.section ? sectionsObject[quota.section.id] : null}
        sections={sections}
        onPlacesChanged={updateQuotaPlaces}
        onSectionSelected={updateQuotaSection}
      />
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotaCard)
