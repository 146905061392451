import React, { useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../routes'
import { getUser } from '../reducers/UserReducer'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { connect } from 'react-redux'
import SectorsView from '../Views/school/validator/SectorsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionProvider from '../Providers/InstitutionProvider'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const ValidatorRouter = ({ history, moduleOption, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  return (
    <InstitutionProvider>
      <Switch>
        <Route path={routes.INTERNSHIPS}>
          <InternshipsView loadShiftsManager={setCurrentDate} />
        </Route>
        <Route path={routes.SHIFTS_MANAGER}>
          <ShiftsManagerView
            currentDate={currentDate}
            displayManagePresets={() => history.push(routes.PRESETS_MANAGER)}
          />
        </Route>
        <Route path={routes.INSTITUTION_SECTORS}>
          <SectorsView />
        </Route>
        <Route path={routes.TAGS_MANAGEMENT}>
          <TagsManagementView />
        </Route>
        <Route path={routes.CHANGELOG}>
          <ChangeLogView />
        </Route>
      </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(connect(mapStateToProps)(ValidatorRouter))
