import { requestWithPromise } from '..'
import { GET, POST } from '../apiHelper'

export const invalidateTutorial = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/invalidate`,
    POST,
    {},
    user,
    { catchError: false }
  )
}

export const getTutorialSteps = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/steps`,
    GET,
    null,
    user,
    { catchError: false }
  )
}

export const validateTutorial = (user, tutorial) => {
  return requestWithPromise(
    `/api/tutorials/${tutorial.id}/validate`,
    POST,
    {},
    user,
    { catchError: false }
  )
}
