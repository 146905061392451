import { SECTORS } from './actionsType'

const { SET_SECTORS, SET_FILES_COUNT } = SECTORS

const INITIAL_STATE = {
  list: [],
  filesCount: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SECTORS: {
      return { ...state, list: action.payload }
    }
    case SET_FILES_COUNT: {
      return { ...state, filesCount: action.payload }
    }
    default:
      return state
  }
}
