import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getAllEvaluations = user => {
  return requestWithPromise(
    '/api/evaluation-internships',
    GET,
    null,
    user
  )
}

export const getInternshipStudentEvaluations = (user, internship) => {
  return requestWithPromise(
    `/api/internships/${internship.id}/student/evaluations`,
    GET,
    null,
    user
  )
}
