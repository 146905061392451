import { requestWithPromise } from '..'
import { DELETE, PATCH, POST } from '../apiHelper'

export const createActTypeCategory = (user, body) => {
  return requestWithPromise(
    '/api/act-type-categories',
    POST,
    body,
    user
  )
}

export const updateActTypeCategory = (user, actTypeCategory, body) => {
  return requestWithPromise(
    `/api/act-type-categories/${actTypeCategory.id}`,
    PATCH,
    body,
    user
  )
}

export const deleteActTypeCategory = (user, actTypeCategory) => {
  return requestWithPromise(
    `/api/act-type-categories/${actTypeCategory.id}`,
    DELETE,
    null,
    user
  )
}
