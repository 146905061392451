import React, { useState, useEffect, useRef } from 'react'
import { Drawer, notification } from 'antd'
import { request, requestMultipart, downloadFile, generalErrorHandler } from '../../utils'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import InstitutionSectorFileSectionsAssignment from './InstitutionSectorFilesSectionAssignment'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { getSectorDocuments } from '../../utils/api/sector'

const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InstitutionSectorFilesManagement = ({ preventChanges, sector, user, width, t, onClose }) => {
  const [generalFiles, setGeneralFiles] = useState([])
  const [specificFiles, setSpecificFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadingGeneralFile, setUploadingGeneralFile] = useState(false)
  const [uploadingSpecificFile, setUploadingSpecificFile] = useState(false)
  const [fileToAssignSections, setFileToAssignSections] = useState(null)
  const generalFileInputRef = useRef(null)
  const specificFileInputRef = useRef(null)

  useEffect(() => {
    if (sector) {
      setLoading(true)
      getSectorDocuments(user, sector).then(json => {
        if (json?.data) {
          const rawGeneralFiles = json.data.filter(item => item.isSectionFile === false)
          const rawSpecificFiles = json.data.filter(item => item.isSectionFile === true)

          setGeneralFiles(rawGeneralFiles.map(file => ({
            id: file.id,
            name: file.originalName
          })))

          setSpecificFiles(rawSpecificFiles.map(file => ({
            id: file.id,
            name: file.originalName,
            sections: file.sections,
            numberOfSections: file.sections.length
          })))
        }

        setLoading(false)
      })
    }
  }, [sector])

  const handleFileUpload = (e, uploadingSpecificFile = false) => {
    if (uploadingSpecificFile) {
      setUploadingSpecificFile(true)
    } else {
      setUploadingGeneralFile(true)
    }
    const file = e.target.files[0]

    if (file && ACCEPTED_DOCUMENT_TYPES.indexOf(file.type) > -1) {
      const body = {
        file
      }
      body['document[sector]'] = sector.id
      if (uploadingSpecificFile) {
        body['document[isSectionFile]'] = true
      }

      requestMultipart('/sector/document/upload', 'POST', body, user)
        .then(json => {
          if (json.data) {
            const { id, originalName } = json.data
            if (uploadingSpecificFile) {
              const updatedSpecificFiles = specificFiles.slice()
              updatedSpecificFiles.push({ id, name: originalName, sections: [], numberOfSections: 0 })
              setSpecificFiles(updatedSpecificFiles)
              setUploadingSpecificFile(false)
            } else {
              const updatedGeneralFiles = generalFiles.slice()
              updatedGeneralFiles.push({ id, name: originalName })
              setGeneralFiles(updatedGeneralFiles)
              setUploadingGeneralFile(false)
            }
          }
        })
    } else {
      notification.error({ message: t('Only PDF, Word, Excel and CSV file types are supported.'), placement: 'bottomRight' })
    }
  }

  const handleFileDownload = (file, environnementFiles) => {
    const fileObject = environnementFiles.filter(f => f.id === file.id)[0]

    try {
      downloadFile(`/sector/document/download/${fileObject.id}`, fileObject.name, user)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const deleteFile = (file) => {
    let files = generalFiles
    files = files.filter(f => f.id !== file.id)
    setGeneralFiles(files)

    request(`/api/SectorDocument/${file.id}`, 'DELETE', null, user)
  }

  const selectSections = file => {
    setFileToAssignSections(file)
  }

  const handleSectionsAssignmentSave = sections => {
    const updatedSpecificFiles = specificFiles.map(item => ({
      ...item,
      sections: sections,
      numberOfSections: sections.length
    }))
    setSpecificFiles(updatedSpecificFiles)
    setFileToAssignSections(null)
  }

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    { type: DATA_TYPE_STRING, name: t('Name'), key: 'name' }
  ]

  const specificFilesColumns = [...columns, { type: DATA_TYPE_STRING, name: t('Sections'), key: 'numberOfSections' }]

  const canManageSpecificFiles = user.roles.includes('ROLE_ENCODER')

  const handleDrawerClose = () => {
    setGeneralFiles([])
    setSpecificFiles([])
    onClose()
  }

  return (
    <>
      <Drawer
        title={t('Sector files manager')}
        width={typeof width === 'undefined' ? '80%' : width}
        onClose={handleDrawerClose}
        visible={sector !== null}
      >
        <h2>{t('General sector files')}</h2>
        <SmartTable
          columns={columns}
          data={generalFiles}
          addLineOnAddData={false}
          addDataText={t('Upload file')}
          loading={loading || uploadingGeneralFile}
          noDataText={t('There is no attached file.')}
          onDataAdd={!preventChanges ? () => generalFileInputRef.current.click() : undefined}
          onDataDelete={!preventChanges ? deleteFile : undefined}
          additionalActions={[
            { iconName: 'download', type: 'primary', title: t('Download file'), onClick: (i) => handleFileDownload(i, generalFiles) }
          ]}
        />
        <input type='file' ref={generalFileInputRef} onChange={handleFileUpload} accept={ACCEPTED_DOCUMENT_TYPES.join(',')} style={{ display: 'none' }} />
        <div style={{ width: '100%', height: '1px', background: '#e8e8e8', margin: '25px 0 20px 0' }} />
        <h2>{t('Files specific to your school')}</h2>
        <SmartTable
          columns={specificFilesColumns}
          data={specificFiles}
          addLineOnAddData={false}
          addDataText={t('Upload file')}
          loading={loading || uploadingSpecificFile}
          noDataText={t('There is no attached file.')}
          onDataAdd={canManageSpecificFiles ? () => specificFileInputRef.current.click() : undefined}
          onDataDelete={canManageSpecificFiles ? deleteFile : undefined}
          additionalActions={[
            { iconName: 'graduation-cap', type: 'primary', title: t('Assign sections'), onClick: (i) => selectSections(i), disabled: !canManageSpecificFiles },
            { iconName: 'download', type: 'primary', title: t('Download file'), onClick: (i) => handleFileDownload(i, specificFiles) }
          ]}
        />
        <input type='file' ref={specificFileInputRef} onChange={(e) => { handleFileUpload(e, true) }} accept={ACCEPTED_DOCUMENT_TYPES.join(',')} style={{ display: 'none' }} />
      </Drawer>
      <InstitutionSectorFileSectionsAssignment
        file={fileToAssignSections}
        onClose={() => setFileToAssignSections(null)}
        onSave={handleSectionsAssignmentSave}
      />
    </>
  )
}

export default connect(mapStateToProps)(InstitutionSectorFilesManagement)
