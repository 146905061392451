import { Button, InputNumber, Select, Switch, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import FormLine from './FormLine'
import { ScheduleComplement } from '../../../utils/entities/ScheduleComplement'
import TextArea from 'antd/lib/input/TextArea'
import { getUser } from '../../../reducers/UserReducer'
import { isArray } from 'lodash'
import { FULL_TIME_FORMAT, HOURS_MINUTES_FORMAT } from '../../../utils/momentjs'
import moment from 'moment'

import '../../../assets/school-permission-form.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ScheduleComplementForm = ({ actTypes, data, internship, user, onSubmit, t }) => {
  const [item, setItem] = useState(new ScheduleComplement({}))

  useEffect(() => {
    setItem(new ScheduleComplement({ ...data }))
  }, [data])

  const handleSubmit = () => {
    onSubmit(new ScheduleComplement({ ...item, internship }))
    setItem(new ScheduleComplement({}))
  }

  const handleTimeChange = (value, type) => {
    const newValue = '00:00:00'
    const timeParts = item.value.split(':')

    if (type === 'hours') {
      timeParts[0] = value < 10 ? '0' + value : value + ''
    } else if (type === 'minutes') {
      timeParts[1] = value < 10 ? '0' + value : value + ''
    }

    setItem({ ...item, value: timeParts.join(':') })
  }

  return (
    <div className='school-permission-form'>
      {item !== null && (
        <>
          <FormLine label={t('Act type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Select
              value={item.actType ? item.actType.id : null}
              style={{ minWidth: '200px' }}
              onSelect={(value, option) => setItem({
                ...item,
                actType: option.props.data,
                value: !option.props.data || option.props.data.hoursFormat ? '00:00:00' : '0'
              })}
            >
              <Select.Option value={null} data={null}>
                /
              </Select.Option>
              {isArray(actTypes) && actTypes.map((a, index) => {
                return (
                  <Select.Option key={'act_' + index} value={a.id} data={a}>
                    {a.name}
                  </Select.Option>
                )
              })}
            </Select>
          </FormLine>
          <FormLine label={t('Value')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {(!item.actType || item.actType.hoursFormat) && (
              <div>
                <InputNumber value={item.value.split(':')[0]} min={0} max={1000} onChange={value => handleTimeChange(value, 'hours')} /> :
                <InputNumber value={item.value.split(':')[1]} min={0} max={60} step={5} onChange={value => handleTimeChange(value, 'minutes')} />
              </div>
            )}
            {item.actType && !item.actType.hoursFormat && (
              <InputNumber
                value={item.value}
                onChange={value => setItem({ ...item, value: '' + value })}
                min={0}
                max={1000}
              />
            )}
          </FormLine>
          <FormLine label={t('Commentary')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <TextArea
              style={{ resize: 'none' }}
              maxLength={255}
              rows={4}
              value={item.commentary}
              onChange={e => setItem({ ...item, commentary: e.target.value })}
            />
          </FormLine>
          <FormLine label={t('State')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <span className={item.positive ? '' : 'red-checked'}> {t('Remove')} </span>
            <Switch checked={item.positive} onChange={positive => setItem({ ...item, positive })} />
            <span className={item.positive ? 'green-checked' : ''}> {t('Add')} </span>
          </FormLine>
          <div className='form-footer'>
            <Button type='primary' onClick={handleSubmit} disabled={item.value === '00:00:00' || item.value === '0'}>{t('Validate')}</Button>
          </div>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ScheduleComplementForm)
