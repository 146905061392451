import { Button, Modal } from 'antd'
import React from 'react'
import { connect, mapDispatchToProps, mapStateToProps } from '../../reducers/Dispatchers'

const MODAL_TITLE = 'De-validate this internship?'
const MODAL_BODY_PART_1 = 'The schedule’s validation on Interneo is equivalent to a signature and has a definitive and unalterable legal meaning. De-validation should only be used exceptionally in case of encoding error or accidental validation.'
const MODAL_BODY_BOLD = 'Are you sure you want to de-validate the internship?'
const CANCEL_BUTTON_STYLE = {
  backgroundColor: '#FF7F7F',
  color: 'white'
}

const InvalidateInternshipModal = props => {
  const handleOk = () => {
    props.onOk()
  }

  const handleCancel = () => {
    props.onCancel()
  }

  const renderModalBody = () => {
    return (
      <div>
        {props.t(MODAL_BODY_PART_1)}
        <br />
        <br />
        <b>
          {props.t(MODAL_BODY_BOLD)}
        </b>
      </div>
    )
  }

  const renderModalFooter = () => {
    return (
      <div className='flex-row'>
        <div className='flex-fill' />
        <Button onClick={handleOk} type='primary'>
          {props.t('Yes')}
        </Button>
        <div className='flex-fill' />
        <Button
          onClick={handleCancel}
          style={CANCEL_BUTTON_STYLE}
        >
          {props.t('Cancel')}
        </Button>
        <div className='flex-fill' />
      </div>
    )
  }

  return (
    <Modal
      visible={props.visible ?? false}
      title={props.t(MODAL_TITLE)}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={renderModalFooter()}
      centered
    >
      {renderModalBody()}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvalidateInternshipModal)
