import { SHIFT_PRESETS } from './actionsType'

const { ADD_SHIFT_PRESET, SET_SHIFT_PRESETS, SET_SHIFT_PRESETS_LOADING, REMOVE_SHIFT_PRESET, UPDATE_SHIFT_PRESET } = SHIFT_PRESETS

const INITIAL_STATE = {
  isLoading: true,
  presets: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SHIFT_PRESETS: {
      const newState = { ...state }

      Object.keys(action.payload).forEach(institutionId => {
        newState.presets[institutionId] = action.payload[institutionId]
      })

      return newState
    }
    case SET_SHIFT_PRESETS_LOADING: {
      return { ...state, isLoading: action.payload }
    }
    case ADD_SHIFT_PRESET: {
      const { preset, institutionId, sectorId } = action.payload

      if (!state.presets[institutionId]) {
        state.presets[institutionId] = {}
      }

      if (!state.presets[institutionId][sectorId]) {
        state.presets[institutionId][sectorId] = {}
      }

      state.presets[institutionId][sectorId] = { ...state.presets[institutionId][sectorId] }
      state.presets[institutionId][sectorId][preset.periodCode] = preset

      return state
    }
    case REMOVE_SHIFT_PRESET: {
      const { preset, institutionId, sectorId } = action.payload
      const newSectorPresets = { ...state.presets[institutionId][sectorId] }

      delete (newSectorPresets[preset.periodCode])

      state.presets[institutionId][sectorId] = newSectorPresets

      return state
    }
    case UPDATE_SHIFT_PRESET: {
      const { preset, institutionId, sectorId } = action.payload
      const newSectorPresets = { ...state.presets[institutionId][sectorId] }

      newSectorPresets[preset.periodCode] = preset

      state.presets[institutionId][sectorId] = newSectorPresets

      return state
    }
    default:
      return state
  }
}
