import React, { useMemo, useCallback } from 'react'

import { notification, Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { callFunctionIfDefined } from '../../../utils/functionHelper'

const { Option } = Select

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const StudentsSelector = ({
  disabled, students, schoolSections, value, onBodyRendered, onSearch, onSelect, t
}) => {
  const getSectionName = useCallback((section, s = null) => {
    if (section.abbreviation && section.schoolYearName) {
      return `${section.abbreviation} (${section.schoolYearName})`
    }

    if (section.sectionToYear && section.sectionToYear.schoolYearName && section.sectionToYear.sectionAbbreviation) {
      return `${section.sectionToYear.sectionAbbreviation} (${section.sectionToYear.schoolYearName})`
    }

    if (s) {
      const year = s.oldSchoolYearName ? ` (${s.oldSchoolYearName})` : ''

      return section.name + year
    }

    return section.abbreviation ?? section.name ?? 'Unknown section'
  }, [])

  const getSection = useCallback(s => {
    if (s.sections.length) {
      if (s.sectionAbbreviation && s.schoolYearName) {
        return `${s.sectionAbbreviation} (${s.schoolYearName})`
      }

      if (s.sections && s.sections.length > 0 && s.sections[0].parent) {
        return getSectionName(s.sections[0], s)
      }
    }

    return t('Without section')
  }, [getSectionName, t])

  const sectionOptions = useMemo(() => {
    const options = [
      <Option
        key='empty'
        value={0}
        data={{ student: null, section: null }}
        filter='/'
      >
        {t('Without student')}
      </Option>
    ]

    if (schoolSections) {
      schoolSections.forEach((ss, index) => {
        const sectionName = `${getSectionName(ss)} - ${t('Without student')}`

        options.push(
          <Option
            key={`-${ss.id}`}
            value={-ss.id}
            data={{ section: ss, student: null }}
            filter={sectionName}
          >
            {sectionName}
          </Option>
        )
      })
    }

    return options
  }, [schoolSections, getSectionName])
  const studentOptions = useMemo(() => {
    const options = []

    if (students) {
      students.forEach((s, index) => {
        const fullName = `${s.lastname} ${s.firstname}`
        const sectionName = getSection(s)

        options.push(
          <Option
            key={`-${s.id}`}
            value={s.id}
            data={{ section: null, student: s }}
            filter={`${sectionName} - ${fullName}`}
          >
            {sectionName} - <b>{fullName}</b>
          </Option>
        )
      })
    }

    return options
  }, [students, getSection])

  const handleSelect = useCallback((value, option) => {
    if (option.props.data.student && getSection(option.props.data.student) === t('Without section')) {
      notification.info({
        message: t('The student has no section.'),
        placement: 'bottomRight'
      })
    }

    callFunctionIfDefined(onSelect, [value, { ...option.props.data }])
  }, [getSection, onSelect])

  return (
    <Select
      disabled={disabled}
      filterOption={onSearch}
      onSelect={handleSelect}
      showArrow={false}
      showSearch
      className='narrow'
      value={value}
    >
      {sectionOptions}
      {studentOptions}
    </Select>
  )
}

export default connect(mapStateToProps)(StudentsSelector)
