import React, { useState, useEffect, useRef } from 'react'

import { EUROPEAN_DATE } from '../../../../utils/constants'
import { DEFAULT_TIME_PERIODS } from '../definition/PlacesDefiner'
import { getEndDateByPeriod } from '../utils/DateTools'
import { DatePicker } from 'antd'
import moment from 'moment'

import '../assets/places-definition.scss'

export const CustomRangePicker = props => {
  const [dates, setDates] = useState([moment(), moment()])
  const endDateRef = useRef(null)

  useEffect(() => {
    if (props.startDate && props.endDate) {
      updateDates(props.startDate, props.endDate)
    }
  }, [props.startDate, props.endDate])

  const updateDates = (startDate, endDate) => {
    const dirty = dates[0] !== startDate || dates[1] !== endDate

    if (dirty) {
      setDates([startDate, endDate])
    }
  }

  const handleStartDateChange = (date, dateString) => {
    const newDates = date > dates[1]
      ? [date, getEndDateByPeriod(date, props.period ?? DEFAULT_TIME_PERIODS[1])]
      : [date, dates[1]]

    setDates(newDates)
    props.onDatesChange(newDates)
    endDateRef.current.picker.state.open = true
  }

  const handleEndDateChange = (date, dateString) => {
    const newDates = date < dates[0]
      ? [date, dates[0]]
      : [dates[0], date]

    setDates(newDates)
    props.onDatesChange(newDates)
  }

  return (
    <div
      className='vertical-center'
    >
      <DatePicker
        value={dates[0]}
        onChange={handleStartDateChange}
        format={props.dateFormat ?? EUROPEAN_DATE}
        disabled={props.disabled}
        allowClear={false}
      />
      <div className='horizontal-margin-30' />
      <DatePicker
        value={dates[1]}
        onChange={handleEndDateChange}
        format={props.dateFormat ?? EUROPEAN_DATE}
        ref={endDateRef}
        disabled={props.disabled}
        allowClear={false}
      />
    </div>
  )
}
