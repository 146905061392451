import React, { useState } from 'react'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_IMAGE
} from '../../../Components/shared/SmartTable'
import './style.scss'
import AddTagDrawer from './AddTagDrawer'

const TagsManagementView = props => {
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(props.getUser.context === 'ADMIN') // eslint-disable-line
  const [showAddDrawer, setShowAddDrawer] = useState(false)
  const [tagToEdit, setTagToEdit] = useState(null)

  const loadTags = async () => {
    if (!props.getTagsFetched) {
      props.fetchTags()
    }
  }

  const handleDrawerClose = () => {
    setShowAddDrawer(false)
    setTagToEdit(null)
  }

  const handleActiveToggle = async (tag, smartTableStopLoading) => {
    const tagToUpdate = props.getTags.find(item => item.id === tag.id)
    if (tagToUpdate) {
      const { id } = tagToUpdate
      await props.toggleActiveTag(id)
    }
    smartTableStopLoading()
  }

  const handleTagDelete = tag => {
    props.deleteTag(tag)
  }

  const builtInColumns = [
    { type: DATA_TYPE_ID, key: 'id' },
    { type: DATA_TYPE_STRING, key: 'translatedName', name: props.t('Name'), disabled: true },
    { type: DATA_TYPE_IMAGE, key: 'icon', name: props.t('Icon'), disabled: true },
    { type: DATA_TYPE_IMAGE, key: 'inactiveIcon', name: props.t('Inactive icon'), disabled: true },
    { type: DATA_TYPE_STRING, key: 'visibility', name: props.t('Visibility'), disabled: true },
    { type: DATA_TYPE_STRING, key: 'entityName', name: props.t('Entity name'), disabled: true }
  ]

  if (!isSuperAdminUser) {
    builtInColumns.push({ type: DATA_TYPE_BOOLEAN, key: 'active', name: props.t('Active') })
  }

  return (
    <div className='tags-management'>
      <h2>{props.t('Built-in tags')}</h2>
      {isSuperAdminUser && (
        <SmartTable
          columns={builtInColumns}
          data={props.getTags.filter(item => item.type === 'builtin')}
          loading={props.getTagsLoading}
          hideSavedStatusIcon
          addLineOnAddData={false}
          onDataAdd={() => { setShowAddDrawer(true) }}
          onDataDelete={handleTagDelete}
          addDataText={props.t('Add built-in tag')}
          additionalActions={[
            {
              iconName: faEdit,
              type: 'primary',
              title: props.t('Edit tag'),
              onClick: tag => setTagToEdit(tag)
            }
          ]}
        />
      )}
      {!isSuperAdminUser && (
        <div>
          <SmartTable
            columns={builtInColumns}
            data={[...props.getTags.slice()].filter(item => item.type === 'builtin')}
            loading={props.getTagsLoading}
            onDataEdit={handleActiveToggle}
          />
          <br />
          <hr />
          <br />
          <h2>{props.t('Personal tags')}</h2>
          <SmartTable
            columns={builtInColumns}
            data={[...props.getTags.slice()].filter(item => item.type === 'personal')}
            loading={props.getTagsLoading}
            hideSavedStatusIcon
            addLineOnAddData={false}
            onDataAdd={() => { setShowAddDrawer(true) }}
            onDataEdit={handleActiveToggle}
            onDataDelete={handleTagDelete}
            addDataText={props.t('Add personal tag')}
            additionalActions={[
              {
                iconName: faEdit,
                type: 'primary',
                title: props.t('Edit tag'),
                onClick: tag => setTagToEdit(tag)
              }
            ]}
          />
        </div>
      )}
      <AddTagDrawer
        visible={tagToEdit !== null || showAddDrawer}
        tagToEdit={tagToEdit}
        onClose={handleDrawerClose}
      />
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsManagementView)
