import { Modal } from 'antd'
import React from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { getShiftsManagerState } from '../../../reducers/ShiftsManagerReducer/actions'
import { SHIFT_MANAGER } from '../../../reducers/ShiftsManagerReducer/actionsType'

const { SET_DISPLAY_EXCEEDING_MODAL } = SHIFT_MANAGER

const TITLE = 'WARNING: quota per pause exceeded!'
const BODY = 'You have exceeded the quota set by the institution. Please check with the institution to make sure that you are allowed to send more students than requested.'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    displayModal: getShiftsManagerState(state).displayExceedingModal
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const ExceedingQuotaModal = props => {
  const dispatch = useDispatch()

  return (
    <Modal
      title={props.t(TITLE)}
      onCancel={() => dispatch({ type: SET_DISPLAY_EXCEEDING_MODAL, payload: false })}
      visible={props.displayModal}
      footer={[]}
      centered
    >
      {props.t(BODY)}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ExceedingQuotaModal)
