import React, { useCallback, useMemo, useState } from 'react'

import { Button, Checkbox, Dropdown, Menu } from 'antd'
import { internshipStates } from '../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import GenericStatesPellet from '../StatesPellet/GenericStatesPellet'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StatesSelector = ({ user, onCheck, values, t }) => {
  const [visible, setVisible] = useState(false)

  const states = useMemo(() => {
    return Object.entries(internshipStates).map(([key, value]) => {
      return { label: key, value: value }
    })
  }, [])

  const handleCheckboxChange = useCallback((event, value) => {
    event.nativeEvent.stopPropagation()

    const isChecked = event.target.checked

    onCheck(isChecked ? [...values, value] : values.filter(item => item !== value))
  }, [onCheck, values])

  const menu = useMemo(() => (
    <Menu>
      {states.map(item => (
        <Menu.Item key={item.value}>
          <div className='states-selector-option'>
            <Checkbox
              onChange={event => handleCheckboxChange(event, item.value)}
              checked={values.includes(item.value)}
            >
              {t(item.label)}
            </Checkbox>
            <GenericStatesPellet state={item.value} />
          </div>
        </Menu.Item>
      ))}
    </Menu>
  ), [states, handleCheckboxChange, values])

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={setVisible} visible={visible}>
        <Button
          onClick={() => setVisible(!visible)}
          style={{ width: '210px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {t('States')}
          </div>
        </Button>
      </Dropdown>
    </div>
  )
}

export default connect(mapStateToProps)(StatesSelector)
