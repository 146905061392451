import React, { useContext, useState } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../routes'
import ChangeLogView from '../Views/shared/ChangeLogView'
import SectorsView from '../Views/institution/SectorsView/SectorsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionProvider from '../Providers/InstitutionProvider'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import InstitutionSettingsView from '../Views/institution/Settings/InstitutionSettingsView'
import StudentsView from '../Views/institution/StudentsView'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'

const ObserverRouter = ({ history }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const [selectedSector, setSelectedSector] = useState(null)

  const goToShiftPresets = sector => {
    setSelectedSector(sector || null)
    history.push(routes.PRESETS_MANAGER)
  }

  return (
    <InstitutionProvider>
      <Switch>
        <Route path={routes.INTERNSHIPS}>
          <InternshipsView loadShiftsManager={setCurrentDate} />
        </Route>
        <Route path={routes.SHIFTS_MANAGER}>
          <ShiftsManagerView currentDate={currentDate} isReadOnly />
        </Route>
        <Route path={routes.STUDENTS}>
          <StudentsView />
        </Route>
        <Route path={routes.INSTITUTION_SECTORS}>
          <SectorsView displayManagePresets={goToShiftPresets} />
        </Route>
        <Route path={routes.PRESETS_MANAGER}>
          <ShiftPresetsManager sector={selectedSector} />
        </Route>
        <Route path={routes.SETTINGS}>
          <InstitutionSettingsView isObserver />
        </Route>
        <Route path={routes.CHANGELOG}>
          <ChangeLogView />
        </Route>
      </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(ObserverRouter)
