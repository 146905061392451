import { GLOBAL_PLACES_KEY } from './Constants'
import { hasSpecificQuotas } from './tools'
import { store } from '../../../..'

export const getQuotaSectionKeyByInternship = internship => {
  const internshipSection = internship.section

  if (!hasSpecificQuotas(store.getState().quotas, internship.sector, internshipSection)) {
    return GLOBAL_PLACES_KEY
  }

  return internshipSection
}

export const getQuotasTotalByInternship = (quotas, internship, accessors, nullValue = 0) => {
  let currentNode = quotas

  if (currentNode === undefined) {
    return nullValue
  }

  for (const key of accessors) {
    if (!key || !currentNode[key]) {
      return nullValue
    }

    currentNode = currentNode[key]
  }

  return formatQuotasTotal(currentNode, nullValue)
}

const formatQuotasTotal = (node, nullValue = 0) => {
  return node.total ?? nullValue
}

export const getMaxPlacesByPeriod = (placesArray, startDate, endDate, period, section = null) => {
  let places
  let startIndex = startDate.diff(period.startDate, period.type)
  let lastIndex = endDate.diff(period.startDate, period.type)

  if (startIndex < 0) {
    startIndex = 0
  }

  if (startIndex >= placesArray.length) {
    startIndex = placesArray.length - 1
  }

  if (lastIndex < startIndex) {
    lastIndex = startIndex
  }

  if (lastIndex >= placesArray.length) {
    lastIndex = placesArray.length - 1
  }

  places = getPlaces(placesArray[startIndex], section)

  for (let i = startIndex; i < lastIndex; i++) {
    const tempPlaces = getPlaces(placesArray[i], section)

    if (tempPlaces.remaining > places.remaining) {
      places = tempPlaces
    }
  }

  return places.remaining
}

export const getPlaces = (placesTree, section = null) => {
  const places = {
    remaining: null,
    total: null
  }

  if (section !== null && placesTree[section.id] && placesTree[section.id].total !== undefined && placesTree[section.id].remaining !== undefined) {
    places.total = placesTree[section.id].total
    places.remaining = placesTree[section.id].remaining
  } else if (placesTree.total !== undefined && placesTree.remaining !== undefined) {
    places.total = placesTree.total
    places.remaining = placesTree.remaining
  }

  return places
}
