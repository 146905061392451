import React, { useContext, useEffect, useState } from 'react'

import { Card } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'
import { makeNewInstitutionConstraint } from '../../../utils/entitiesFactory/InstitutionConstraintFactory'
import ListTable from './ListTable'
import { translationStringSorting } from '../../../utils/sorting'
import { toFirstUpperCase } from '../../../utils'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const CARD_TITLE = "Student's data required to have receive internship"
const ENTITY_NAME = 'Student'
const NO_CONSTRAINT_MESSAGE = 'Your institution has not yet created any constraints on the reception of internships.'
const COLUMNS = [
  {
    type: 'StudentPropertiesSelect',
    dataType: 'string',
    name: 'Properties',
    key: 'propertyName',
    check: data => {
      return !!data.propertyName
    }
  },
  {
    type: 'StandardSwitch',
    dataType: 'boolean',
    name: 'Enable',
    key: 'enable',
    check: data => {
      return !(typeof data.enable === 'undefined' || data.enable === null)
    }
  }
]

const InternshipConstraints = props => {
  const { studentMetadata } = useContext(GlobalContext)

  const [constraints, setConstraints] = useState([])

  useEffect(() => {
    if (props.getInstitutionConstraints.constraints) {
      setConstraints(translationStringSorting(
        props.getInstitutionConstraints.constraints,
        'propertyName',
        value => toFirstUpperCase(props.t(value))
      ))
    }
  }, [props.getInstitutionConstraints.constraints, props.t])

  useEffect(() => {
    if (props.getUser && typeof props.fetchInstitutionConstraints === 'function') {
      props.fetchInstitutionConstraints(props.getUser)
    }
  }, [props.getUser, props.fetchInstitutionConstraints])

  const renderNoDataMessage = () => {
    return (
      <div>
        {props.t("Your institution has not yet created constraints onto student's data")}
      </div>
    )
  }

  const handleDataDelete = institutionConstraint => {
    setConstraints(constraints.filter(c => c.id !== institutionConstraint.id))
    props.removeInstitutionConstraint(
      institutionConstraint,
      props.getUser,
      () => setConstraints(constraints)
    )
  }

  const handleDataSave = (institutionConstraint, callback = null) => {
    if (institutionConstraint.id) {
      props.updateInstitutionConstraint(institutionConstraint, props.getUser)

      return
    }

    props.addInstitutionConstraint(institutionConstraint, props.getUser, callback)
  }

  const makeNewData = () => {
    return makeNewInstitutionConstraint(
      props.getUser.institutions[0].id,
      { id: 1, type: 'INTERNSHIPS_BLOCKING' },
      ENTITY_NAME
    )
  }

  return (
    <div className='internship-constraints'>
      <Card
        title={props.t(CARD_TITLE)}
      >
        <ListTable
          data={constraints}
          columns={COLUMNS}
          disableAddButton={constraints.length === studentMetadata.length}
          noDataMessage={renderNoDataMessage()}
          onDataDelete={handleDataDelete}
          onDataSave={handleDataSave}
          dataFactory={makeNewData}
          loading={props.getInstitutionConstraints.loading}
          noDataText={NO_CONSTRAINT_MESSAGE}
        />
      </Card>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipConstraints)
