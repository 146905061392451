import { Input, Modal, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { createOrUpdate, getSectorNote } from '../../utils/api/sectorNote'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SectorNoteModal = ({ institution, sector, user, onClose, t }) => {
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(true)

  const focus = useCallback(node => {
    if (node) {
      node.focus()
    }
  })

  useEffect(() => {
    if (sector && user) {
      setLoading(true)

      getSectorNote(sector, user).then(data => {
        setNote(data[0] ? data[0].note : '')
        setLoading(false)
      })
    }
  }, [sector, user])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleOk()
    }
  }

  const handleOk = () => {
    createOrUpdate(note, sector, user.school, user).then(() => {
      notification.success({
        message: t('The note has been successfully created'),
        placement: 'bottomRight'
      })
    })
    onClose()
  }

  const renderTitle = () => {
    return (
      t('Note for care unit ') +
      (sector?.name ?? t('Unknown')) +
      t(' of institution ') +
      (institution?.name ?? t('Unknown'))
    )
  }

  return (
    <Modal
      title={renderTitle()}
      visible={!!sector}
      onCancel={onClose}
      onOk={handleOk}
      afterClose={() => setNote('')}
    >
      {!loading && (
        <Input
          ref={focus}
          value={note}
          onChange={e => setNote(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      )}
      {loading && (
        <div className='center'>
          <div className='loading-icon black' />
        </div>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(SectorNoteModal)
