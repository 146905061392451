import React from 'react'

import { Input } from 'antd'

export const TableInput = props => {
  const handleChange = ({ target: { value } }) => {
    const data = { ...props.data }

    data[props.valueKey] = value

    props.onChange(data)
  }

  return (
    <Input
      value={props.data[props.valueKey]}
      onChange={handleChange}
      disabled={props.disabled}
      onFocus={() => props.onFocus()}
      onBlur={() => props.onBlur()}
    />
  )
}
