import { Button, Popconfirm, Select, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import TableFooter from '../shared/Tables/TableFooter'
import TableHeader from '../shared/Tables/TableHeader'
import removeAccents from 'remove-accents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckboxUserSectionTable from '../shared/CheckboxUserSectionTable'

const Option = Select.Option

const DEFAULT_LINE_SHOWN = 20
const SEARCH_DEFAULT_TIMER = 500
const UNSELECT_TEXT = 'Unselect all'
const SELECT_TEXT = 'Select all'
const DATA_TYPE_SELECT = 0
const DATA_TYPE_CHECK_CONTACT_PERSON = 1

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const UserSectionTable = props => {
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [linesCount, setLinesCount] = useState(0)
  const [checkboxList, setCheckboxList] = useState([])
  const [numberCheckedBox, setNumberCheckedBox] = useState(0)
  const [checkAllCheckboxText, setCheckAllCheckboxText] = useState(SELECT_TEXT)
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const timer = useRef(null)

  useEffect(() => {
    const { data } = props

    setMaxPageIndex(data.length % DEFAULT_LINE_SHOWN === 0
      ? Math.floor(data.length / DEFAULT_LINE_SHOWN)
      : Math.floor(data.length / DEFAULT_LINE_SHOWN) + 1
    )

    setLinesCount(data.length)

    if (pageIndex !== 1) {
      setPageIndex(1)
    }
  }, [props.data])

  useEffect(() => {
    if (props.data.length > 0) {
      const checkboxList = []
      let countContactPerson = 0

      props.data.forEach(section => {
        const isCheckedContactPerson = props.userIsContactPerson(section.id)

        if (isCheckedContactPerson) {
          countContactPerson++
        }

        checkboxList.push({
          ...section,
          isCheckedContactPerson: isCheckedContactPerson
        })
      })

      checkboxList.sort((a, b) => {
        return b.isCheckedAdmin - a.isCheckedAdmin || b.isCheckedContactPerson - a.isCheckedContactPerson
      })

      setCheckboxList(checkboxList)
      setNumberCheckedBox(countContactPerson)
    } else {
      setCheckboxList([])
    }
  }, [props.data])

  useEffect(() => {
    setIsDataAvailable(checkboxList.length > 0 || props.data.length > 0)
  }, [props.data, checkboxList])

  useEffect(() => {
    setCheckAllCheckboxText(checkboxList.length === numberCheckedBox ? UNSELECT_TEXT : SELECT_TEXT)
  }, [checkboxList.length, numberCheckedBox])

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const handleSearchFieldChange = value => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      props.searchWithFilter(value)
    }, SEARCH_DEFAULT_TIMER)
  }

  const renderContainer = () => {
    if (isDataAvailable) {
      return (
        <table>
          <thead>
            {renderHead()}
          </thead>
          <tbody>
            {renderBodyAllCheck()}
            {renderBody()}
          </tbody>
        </table>
      )
    }

    return <span> {props.t('No data are actually available for this page.')} </span>
  }

  const renderHead = () => {
    return (
      <tr>
        {props.columns && renderHeadDetails()}
        {props.additionalActions && <th style={{ width: '100px' }} />}
      </tr>
    )
  }

  const renderHeadDetails = () => {
    return props.columns.map((column, index) => {
      return (
        <th key={index} style={{ cursor: 'default', textAlign: 'center' }}>
          {props.t(column.name)}
        </th>
      )
    })
  }

  const renderBodyAllCheck = () => {
    if (props.columns) {
      return (
        <>
          <tr key='row_all' className='bordered' style={{ height: props.density ?? '32px' }}>
            <td key='col_all_title' id='col_all_title'>
              {props.t('Select all')}
            </td>
            <td key='col_all_contact_person' id='col_all_contact_person'>
              <input className='checkbox' type='checkbox' onChange={checkAllCheckboxContactPerson} checked={checkAllCheckboxText === UNSELECT_TEXT} />
            </td>
          </tr>
        </>
      )
    }
  }

  const checkAllCheckboxContactPerson = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText) {
      case SELECT_TEXT:
        setCheckAllCheckboxText(UNSELECT_TEXT)

        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox(checkboxList.length)
        setCheckboxList(newCheckboxList)
        props.setMultipleUserContactPersons(newChangesIndex)
        break
      case UNSELECT_TEXT:
        setCheckAllCheckboxText(SELECT_TEXT)

        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox(0)
        setCheckboxList(newCheckboxList)
        props.setMultipleUserContactPersons(newChangesIndex)
        break
      default:
        break
    }
  }

  const renderBody = () => {
    if (props.columns) {
      const minIndex = DEFAULT_LINE_SHOWN * (pageIndex - 1)
      const maxIndex = minIndex + DEFAULT_LINE_SHOWN

      return checkboxList.slice(minIndex, maxIndex).map((data, index) => {
        return (
          <tr key={'row_' + index} style={{ height: props.density ?? '32px' }}>
            {props.columns.map((column, index) => {
              if (column.type === DATA_TYPE_SELECT) return renderSelect(data, index, column.options)
              if (column.type === DATA_TYPE_CHECK_CONTACT_PERSON) return renderCheckbox(data, index)
            })}
            {props.additionalActions ? renderAdditionalActions(data) : undefined}
          </tr>
        )
      })
    }

    return <tr> {props.t('No columns have been founded')} </tr>
  }

  const renderSelect = (data, index, options) => {
    const noDataSelected = props.t('Select a data')

    return (
      <td key={`col_${index}`}>
        <Tooltip placement='top' title={data.name}>
          <Select
            showSearch
            value={data.id === null ? noDataSelected : data.id}
            filterOption={(input, option) =>
              canonizeString(option.props.children).indexOf(
                canonizeString(input)
              ) > -1}
            onChange={e => {
              updateDataValue(data, e)
            }}
          >
            {options.map(o => {
              return (
                <Option key={o.id} value={o.id} title={o.name}>
                  {o.name}
                </Option>
              )
            })}
          </Select>
        </Tooltip>
      </td>
    )
  }

  const canonizeString = (string) => {
    return removeAccents(string.toString()).toLowerCase()
  }

  const updateDataValue = (prevData, value) => {
    const section = {
      prevId: prevData.id,
      newId: value
    }

    if (prevData.id === null) {
      props.onDataAdd(section)
    } else {
      props.onDataEdit(section)
    }
  }

  const renderCheckbox = (data, index) => {
    let isChecked = false
    let indexSection = 0

    for (const checkIndex in checkboxList) {
      if (data.section === checkboxList[checkIndex].id) {
        isChecked = checkboxList[checkIndex].isCheckedContactPerson
        indexSection = checkIndex
      }
    }

    return (
      <td key={`col_contact_person${index}`}>
        <CheckboxUserSectionTable
          setUserContactPerson={setUserContactPerson}
          sectionId={data.section}
          isChecked={isChecked}
          handleChangeChecked={handleChangeChecked}
          indexSection={indexSection}
        />
      </td>
    )
  }

  const setUserContactPerson = (sectionId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox(numberCheckedBox - 1)
    } else {
      setNumberCheckedBox(numberCheckedBox + 1)
    }

    props.setUserContactPerson(sectionId)
  }

  const handleChangeChecked = (indexSection) => {
    const newCheckboxList = [...checkboxList]

    newCheckboxList[indexSection].isCheckedContactPerson = !checkboxList[indexSection].isCheckedContactPerson

    setCheckboxList(newCheckboxList)
  }

  const renderAdditionalActions = rowData => {
    const additionalActionsJSX = props.additionalActions.map(action => {
      return (
        <Tooltip
          placement='top'
          title={props.t(action.title)}
          key={'action-' + action.title}
        >
          {typeof action.titlePopconfirm !== 'undefined'
            ? buttonConfirmAction(action, rowData)
            : buttonAction(action, rowData)}
        </Tooltip>
      )
    })

    return (
      <td className='buttons'>
        <div className='flex-row'>
          {additionalActionsJSX}
        </div>
      </td>
    )
  }

  const buttonConfirmAction = (action, rowData) => {
    return (
      <Popconfirm
        placement='top'
        okType={action.type}
        title={action.titlePopconfirm}
        okText={props.t('Yes')}
        cancelText={props.t('Cancel')}
        onConfirm={() => action.handleOnClick(rowData)}
      >
        <Button
          type={action.type}
          size='small'
          className='button'
          disabled={action.disabled ? action.disabled(rowData) : false}
        >
          <FontAwesomeIcon icon={action.icon} />
        </Button>
      </Popconfirm>
    )
  }

  const buttonAction = (action, rowData) => {
    return (
      <Button
        type={action.type}
        size='small'
        className='button'
        onClick={() => action.handleOnClick(rowData)}
        disabled={action.disabled ? action.disabled(rowData) : false}
      >
        <FontAwesomeIcon icon={action.icon} />
      </Button>
    )
  }

  const addNewLine = () => {
    const newCheckboxList = [...checkboxList]
    const newSection = {
      ...checkboxList[0],
      name: '',
      abbreviation: '',
      id: null,
      isCheckedContactPerson: false
    }

    newCheckboxList.unshift(newSection)
    setCheckboxList(newCheckboxList)
  }

  return (
    <div className='interactive-table'>
      <TableHeader
        onDataAdd={addNewLine}
        onDataFilter={handleSearchFieldChange}
        addButtonText='Add a section'
        disableSettings='true'
      />

      {renderContainer()}

      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export {
  DATA_TYPE_SELECT,
  DATA_TYPE_CHECK_CONTACT_PERSON
}
export default connect(mapStateToProps)(UserSectionTable)
