import { useEffect, useState } from 'react'
import moment from 'moment'

const localStorage = window.localStorage

const useLocalStorage = (storageKey, fallbackState, getDate = false) => {
  const [value, setValue] = useState(JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState)

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return !getDate ? [value, setValue] : [moment(value), setValue]
}

export default useLocalStorage
