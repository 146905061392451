import React, { useState, useEffect, useContext } from 'react'
import { ADMIN_CONTEXT } from '../../utils/constants'
import SchoolSections from '../../Components/institution/SchoolSections'
import SchoolUsers from '../../Components/super-admin/SchoolUsers'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_SELECT
} from '../../Components/shared/SmartTable'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import Analytics from '../../utils/analytics'
import { generalErrorHandler, validateFormInput, requestWithPromise } from '../../utils'
import SchoolStudentsDrawer from '../../Components/super-admin/SchoolStudents/StudentsDrawer'
import { GlobalContext } from '../../Providers/GlobalProvider'

const InstitutionSchoolsView = props => {
  const { countries } = useContext(GlobalContext)

  const [selectedSchool, setSelectedSchool] = useState(null)
  const [selectedSchoolForStudents, setSelectedSchoolForStudents] = useState(null)
  const [showStudentsDrawer, setShowStudentsDrawer] = useState(false)
  const [localCountries, setLocalCountries] = useState([])

  useEffect(() => {
    Analytics.pageView('/institution-schools')
  }, [])

  useEffect(() => {
    setLocalCountries(countries.map(c => {
      return { id: c.id, name: props.t(c.name) }
    }))
  }, [countries, props.t])

  const tableColumns = () => {
    let columns = []

    if (props.getUser.context === 'ADMIN') {
      columns = [
        { type: DATA_TYPE_ID, key: 'id' },
        { type: DATA_TYPE_STRING, name: props.t('Name'), key: 'name', width: 350 },
        { type: DATA_TYPE_STRING, name: props.t('Address'), key: 'address', width: 200 },
        { type: DATA_TYPE_STRING, name: props.t('Zipcode'), key: 'zipcode', width: 50 },
        { type: DATA_TYPE_STRING, name: props.t('City'), key: 'city', width: 120 },
        { type: DATA_TYPE_SELECT, name: props.t('Country'), key: 'country', options: localCountries, width: 120 },
        { type: DATA_TYPE_STRING, name: props.t('Phone'), key: 'phone', width: 150 },
        {
          type: DATA_TYPE_STRING,
          name: props.t('Email'),
          key: 'email',
          width: 150,
          validate: data => {
            if (!data || !data.length) {
              return true
            }
            return validateFormInput('email', data)
          }
        }
      ]
    } else {
      columns = [
        { type: DATA_TYPE_ID, key: 'id' },
        { type: DATA_TYPE_STRING, name: props.t('Name'), key: 'name' },
        { type: DATA_TYPE_STRING, name: props.t('Phone'), key: 'phone' },
        { type: DATA_TYPE_STRING, name: props.t('Address'), key: 'address' }
      ]
    }
    return columns
  }

  const tableColumnsAction = () => {
    const additionalActions = []

    if (props.getUser.context === 'ADMIN') {
      additionalActions.push({
        iconName: 'user',
        type: 'primary',
        title: props.t('Show school users'),
        onClick: i => setSelectedSchool(i)
      })

      additionalActions.push({
        iconName: 'graduation-cap',
        type: 'primary',
        title: props.t('Show students'),
        onClick: handlerShowStudentsDrawer
      })

      return additionalActions
    }

    additionalActions.push({
      iconName: 'graduation-cap',
      type: 'primary',
      title: props.t('Show sections'),
      onClick: i => setSelectedSchool(i)
    })

    return additionalActions
  }

  const handleCreateSchool = (newSchool, stopSmartTableLoading) => {
    requestWithPromise('/api/School', 'POST', newSchool, props.getUser)
      .then(json => {
        if (json.status && json.status === 'error') {
          stopSmartTableLoading()
          generalErrorHandler(json.message)
          return
        }

        props.addNewSchool(setCountryAfterCreateEdit(json))
        stopSmartTableLoading()
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handleEditSchool = (editSchool, stopSmartTableLoading) => {
    requestWithPromise(`/api/School/${editSchool.id}/none`, 'PATCH', editSchool, props.getUser)
      .then(json => {
        if (json.status && json.status === 'error') {
          stopSmartTableLoading()
          generalErrorHandler(json.message)
          return
        }

        props.updateSchool(setCountryAfterCreateEdit(json))
        stopSmartTableLoading()
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  /**
   * this is called in handle create and edit, we have to send the country object to reducer
   */
  const setCountryAfterCreateEdit = (json) => {
    const school = { ...json.data }
    // we have to send the country object to reducer
    const country = countries.find(item => item.id === school.country)

    if (country) {
      school.country = { id: country.id, name: country.name }
    }

    return school
  }

  const handleRemoveSchool = (removeSchool, stopSmartTableLoading) => {
    requestWithPromise(`/api/School/${removeSchool.id}`, 'DELETE', null, props.getUser)
      .then(json => {
        stopSmartTableLoading()

        if (json.status && json.status === 'error') {
          generalErrorHandler(json.message)
          return
        }

        props.removeSchool(removeSchool)
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const handlerShowStudentsDrawer = school => {
    setSelectedSchoolForStudents(school)
    setShowStudentsDrawer(true)
  }

  const handleHideStudentsDrawer = () => {
    setShowStudentsDrawer(false)
    setTimeout(() => {
      setSelectedSchoolForStudents(null)
    }, 800)
  }

  const render = () => {
    const noSchool = (
      <span>
        Votre établissement n'a encore aucune école à partir de laquelle
        recevoir des stages.
      </span>
    )

    let schools = []

    if (props.getSchools.length > 0) {
      if (props.getUser.context === ADMIN_CONTEXT) {
        schools = props.getSchools.map(s => {
          return {
            id: s.id,
            name: s.name,
            address: s.address,
            zipcode: s.zipcode,
            city: s.city,
            country: s.country ? s.country.id : null,
            phone: s.phone,
            email: s.email,
            area: s.area
          }
        })
      } else {
        schools = props.getSchools.map(s => {
          let address = s.address === null ? '' : s.address
          if (s.address !== null || s.zipcode !== null || s.city !== null) { address += ' - ' }
          address += s.zipcode === null ? '' : s.zipcode
          if (s.zipcode !== null && s.city !== null) address += ' '
          address += s.city === null ? '' : s.city
          if (
            (s.address !== null || s.zipcode !== null || s.city !== null) &&
          s.country !== null
          ) { address += ' - ' }
          address += s.country === null ? '' : s.country.name

          return {
            id: s.id,
            name: s.name,
            phone: s.phone,
            address: address
          }
        })
      }
    }

    if (props.getUser.context === ADMIN_CONTEXT) {
      return (
        <div>
          <SmartTable
            columns={tableColumns()}
            data={schools}
            loading={!props.getDataReady.schools}
            noDataText={noSchool}
            additionalActions={tableColumnsAction()}
            onDataAdd={handleCreateSchool}
            onDataDelete={handleRemoveSchool}
            onDataEdit={handleEditSchool}
          />
          <SchoolUsers
            school={selectedSchool}
            onClose={() => setSelectedSchool(null)}
          />
          <SchoolStudentsDrawer
            school={selectedSchoolForStudents}
            showDrawer={showStudentsDrawer}
            onClose={handleHideStudentsDrawer}
          />
        </div>
      )
    }

    return (
      <div>
        <SmartTable
          columns={tableColumns()}
          data={schools}
          loading={!props.getDataReady.schools}
          noDataText={noSchool}
          additionalActions={tableColumnsAction()}
        />
        <SchoolSections
          school={selectedSchool}
          onClose={() => setSelectedSchool(null)}
        />
      </div>
    )
  }

  return render()
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionSchoolsView)
