/**
 * Build a standart sortingKey object use in TableGenerator Component in order to 
 *  sort the different table columns
 * 
 * @param {*} columnKey            a valid column key found usually in table manager constants
 * @param {function} sortingMethod a valid sorting function with an array returns
 * @returns a standart sortingKey object
 */
const sortingKeyFactory = (columnKey, sortingMethod) => {

  return {
    columnKey: columnKey, 
    sortingMethod: sortingMethod,
    reverse: false
  }
}

export {
  sortingKeyFactory
}