import React from 'react'

import Cookie from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, Checkbox } from 'antd'
import remove from 'lodash/remove'
import { withRouter } from 'react-router-dom'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput, request, getCurrentUrl, getUrlParameterByName, generalErrorHandler } from '../../utils'
import { setLanguage } from '../../utils/locale'

import '../../assets/login-form.scss'
import routes from '../../routes'
import { LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_TAG_EN, LANGUAGE_TAG_NL } from '../../utils/constants'

const FormItem = Form.Item

const DEFAULT_GENERAL_TERM_OF_USAGE_LINK = {
  href: 'https://opalsolutions.be/wp-content/uploads/FR_Privacy-policy_Opal-Solutions_022023_v4.pdf',
  text: 'General terms of usage'
}
const NL_GENERAL_TERM_OF_USAGE_LINK = {
  href: 'https://opalsolutions.be/wp-content/uploads/NL_Privacy-policy_Opal-Solutions_022023_v4.pdf',
  text: 'General terms of usage'
}
const EN_GENERAL_TERM_OF_USAGE_LINK = {
  href: 'https://opalsolutions.be/wp-content/uploads/EN_Privacy-policy_Opal-Solutions_022023_v4.pdf',
  text: 'General terms of usage'
}

const defaultOptins = [
  {
    id: 1,
    content: 'I have read and accepted the general terms of usage. In particular, I agree to the processing of my personal data by Interneo.',
    link: DEFAULT_GENERAL_TERM_OF_USAGE_LINK,
    required: true,
    accepted: false
  },
  {
    id: 2,
    content: 'I agree that my personal data can be used for recruitment purposes by the institutions using Interneo.',
    link: null,
    required: false,
    accepted: false
  }
]

class NormalStudentSignUpForm extends React.Component {
  constructor (props) {
    super(props)

    if (props.isUserSignup) {
      // remove from defaultOptions the scond item
      remove(defaultOptins, item => item.id === 2)
    }

    this.state = {
      token: null,
      loading: true,
      studentEmail: null,
      confirmDirty: false,
      optins: defaultOptins,
      wrongToken: false
    }
  }

  componentDidMount () {
    const { addTranslationForLanguage, setActiveLanguage, getActiveLanguage, getUser } = this.props

    const token = getUrlParameterByName(this.props.isUserSignup ? 'user-token' : 'student-token')
    const language = getUrlParameterByName('user-language') ?? LANGUAGE_LOCALE_TAG_FR_BE
    const matchingLanguage = this.props.getLanguages.find(l => l.code === language)
    const nextLanguage = matchingLanguage ? language : LANGUAGE_LOCALE_TAG_FR_BE

    this.setState({ token })
    setLanguage({
      language: nextLanguage,
      addTranslationForLanguage,
      setActiveLanguage,
      getActiveLanguage,
      getUser: () => { return getUser },
      getPreviousLanguage: previousLanguage => {
        this.previouslySelectedLanguage = getActiveLanguage
      }
    })

    this.setState({
      optins: this.updateOptinsByLanguage(language)
    })

    if (typeof token !== 'undefined' && token !== null && token !== '') {
      window.history.pushState({ id: 'interneo' }, 'interneo', window.location.origin)
      Cookie.remove('token')
      const confirmEmailUrl = this.props.isUserSignup ? '/user/confirm-email' : '/student/confirm-email'

      request(`${confirmEmailUrl}/${token}`, 'GET', null, null, { catchError: false })
        .then((json) => {
          if (json.status === 'error') {
            this.setState({ loading: false, wrongToken: true })
            this.props.onWrongToken()
            return
          }

          this.setState({
            studentEmail: json.email,
            loading: false
          })
        })
        .catch((error) => {
          generalErrorHandler(error)
          this.setState({ loading: false, wrongToken: true })
        })
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.getActiveLanguage !== prevProps.getActiveLanguage) {
      this.setState({
        optins: this.updateOptinsByLanguage(this.props.getActiveLanguage)
      })
    }
  }

  updateOptinsByLanguage = language => {
    if (language === LANGUAGE_TAG_NL) {
      return this.state.optins.map((o, index) => {
        if (index === 0) {
          return {
            ...o,
            link: NL_GENERAL_TERM_OF_USAGE_LINK
          }
        }

        return o
      })
    }

    if (language === LANGUAGE_TAG_EN) {
      return this.state.optins.map((o, index) => {
        if (index === 0) {
          return {
            ...o,
            link: EN_GENERAL_TERM_OF_USAGE_LINK
          }
        }

        return o
      })
    }

    return this.state.optins.map((o, index) => {
      if (index === 0) {
        return {
          ...o,
          link: DEFAULT_GENERAL_TERM_OF_USAGE_LINK
        }
      }

      return o
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })

        const body = {
          password: values.password,
          password_confirm: values.password_confirm,
          token: this.state.token
        }

        const optins = []
        for (let i = 0; i < this.state.optins.length; i++) {
          let text = this.state.optins[i].content
          if (this.state.optins[i].link !== null) {
            text += '<br/><br/><a href="' + this.state.optins[i].link.href + '" rel="noopener noreferrer" target="_blank">' + this.state.optins[i].link.text + '</a>'
          }
          const currentOptin = {
            text: text,
            accepted: this.props.form.getFieldValue(`${this.state.optins[i].required ? 'checkrequired' : 'check'}_${this.state.optins[i].id}`) || false,
            from: getCurrentUrl(),
            required: this.state.optins[i].required
          }
          optins.push(currentOptin)
        }
        body.optins = optins

        const finishSignupUrl = this.props.isUserSignup ? '/user/finish-registration' : '/student/signup'

        request(finishSignupUrl, 'POST', body)
          .then((json) => {
            // save the token to log in the user
            Cookie.set('token', JSON.stringify(json.token), { expires: 7 })

            if (this.props.isUserSignup) {
              this.props.onSignUp(json.token, this.props.isUserSignup)
            } else {
              this.props.onSignUp(json.token, this.props.isUserSignup).then(() => {
                this.props.history.push(routes.INTERNSHIPS)
              })
            }
          })
          .catch((error) => {
            generalErrorHandler(error)
            this.setState({ loading: false })
          })
      }
    })
  }

  validateForm = (rule, value, callback) => {
    switch (rule.field) {
      case 'password':
        if (value && this.state.confirmDirty) this.props.form.validateFields(['password_confirm'], { force: true })
        if (!validateFormInput('password', value, true)) callback(this.props.t('The password must contain between 8 and 255 characters !'))
        break
      case 'password_confirm':
        if (value !== this.props.form.getFieldValue('password')) callback(this.props.t('The passwords do not match !'))
        break
      default:
        break
    }

    if (rule.field.indexOf('checkrequired_') > -1) {
      if (!value) {
        callback(this.props.t('You need to accept this in order to use Interneo !'))
      }
    }

    callback()
  }

  renderCheckboxes = (getFieldDecorator) => {
    const { optins, loading, wrongToken } = this.state
    return optins.map(o => {
      const fieldId = o.required ? 'checkrequired_' : 'check_'
      return (
        <FormItem key={o.id}>
          {getFieldDecorator(fieldId + o.id, { rules: [{ validator: this.validateForm }] })(
            <Checkbox style={{ lineHeight: 'initial' }} disabled={loading || wrongToken}>
              {this.props.t(o.content)}
              <br />
              <br />
              {o.link === null ? null : <a href={o.link.href} rel='noopener noreferrer' target='_blank'>{this.props.t(o.link.text)}</a>}
            </Checkbox>
          )}
        </FormItem>
      )
    })
  }

  render () {
    const { studentEmail, loading, wrongToken, confirmDirty } = this.state
    const { getFieldDecorator } = this.props.form
    let studentEmailPlaceholder = ''

    if (!studentEmail && loading) {
      studentEmailPlaceholder = this.props.t('Loading')
    }

    if (studentEmail) {
      studentEmailPlaceholder = studentEmail
    } else if (loading) {
      studentEmailPlaceholder = this.props.t('Loading')
    } else if (wrongToken) {
      studentEmailPlaceholder = this.props.t('None')
    }

    return (
      <div>
        <Form onSubmit={this.handleSubmit} className='login-form'>
          <FormItem>
            {getFieldDecorator('email', { rules: [], initialValue: this.props.email })(
              <Input
                prefix={<FontAwesomeIcon icon='user' />}
                placeholder={studentEmailPlaceholder}
                disabled
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', { rules: [{ validator: this.validateForm }] })(
              <Input
                prefix={<FontAwesomeIcon icon='lock' />}
                type='password'
                placeholder={this.props.t('New password')}
                disabled={loading || wrongToken}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password_confirm', { rules: [{ validator: this.validateForm }] })(
              <Input
                prefix={<FontAwesomeIcon icon='lock' />}
                type='password'
                placeholder={this.props.t('Confirm the new password')}
                onBlur={(e) => this.setState({ confirmDirty: confirmDirty || !!e.target.value })}
                disabled={loading || wrongToken}
              />
            )}
          </FormItem>
          {this.renderCheckboxes(getFieldDecorator)}
          <FormItem>
            {!wrongToken &&
              <Button type='primary' htmlType='submit' className='full-width' loading={loading}>
                <FontAwesomeIcon icon='sign-in-alt' />&nbsp;{this.props.t('Confirm')}
              </Button>}
            {wrongToken &&
              <div className='expired-link'>
                <FontAwesomeIcon icon='exclamation-circle' />
                {this.props.t('This link does not exist or has expired.')}
              </div>}
          </FormItem>
        </Form>
      </div>
    )
  }
}

const StudentSignUpForm = Form.create()(NormalStudentSignUpForm)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentSignUpForm))
