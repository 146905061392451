import React, { useState, useEffect, useRef } from 'react'
import { Drawer, Tabs, Input, Button } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { LANGUAGE_TAG_EN, LANGUAGE_TAG_FR, LANGUAGE_TAG_NL } from '../../../utils/constants'

const ChangeLogTranslations = props => {
  const [displayedTabKey, setDisplayedTabKey] = useState('1')
  const [version, setVersion] = useState('')
  const [french, setFrench] = useState('')
  const [dutch, setDutch] = useState('')
  const [english, setEnglish] = useState('')
  const [saving, setSaving] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(true)

  const frenchEditorRef = useRef(null)

  useEffect(() => {
    const versionLength = version.trim().length
    const frenchLength = frenchEditorRef.current ? frenchEditorRef.current.getEditor().getText().length : 0
    if (versionLength > 0 && saveDisabled && frenchLength > 1) {
      setSaveDisabled(frenchEditorRef.current.getEditor().getText().length === 0)
    } else if (
      (versionLength === 0 || frenchLength < 2) && !saveDisabled
    ) {
      setSaveDisabled(true)
    }
  }, [version, french, dutch, english])

  const clearForm = () => {
    setDisplayedTabKey('1')
    setVersion('')
    setFrench('')
    setEnglish('')
    setDutch('')
  }

  useEffect(() => {
    const { changelog } = props
    if (changelog.id) {
      clearForm()
      // we are in edit mode
      setVersion(changelog.name)
      if (changelog.messages) {
        changelog.messages.forEach(item => {
          switch (item.locale) {
            case LANGUAGE_TAG_FR:
              setFrench(item.message)
              break
            case LANGUAGE_TAG_NL:
              setDutch(item.message)
              break
            case LANGUAGE_TAG_EN:
              setEnglish(item.message)
              break
          }
        })
      }
    } else if (changelog === true) {
      clearForm()
    }
  }, [props.changelog])

  const { TabPane } = Tabs

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const save = async () => {
    setSaving(true)
    const messages = [{ locale: LANGUAGE_TAG_FR, message: french }]
    if (dutch.trim().length > 0) {
      messages.push({ locale: LANGUAGE_TAG_NL, message: dutch })
    }
    if (english.trim().length > 0) {
      messages.push({ locale: LANGUAGE_TAG_EN, message: english })
    }
    const dataObject = {
      name: version,
      type: 'changelog',
      messages
    }

    if (props.changelog.id) {
      dataObject.id = props.changelog.id
    }

    const dataResponse = await props.saveChangelog({ changelog: dataObject, getUser: props.getUser })
    if (dataResponse && !dataResponse.status) {
      props.onSave(dataResponse)
    }
    setSaving(false)
  }

  const handleTabChange = tabKey => {
    setDisplayedTabKey(tabKey)
  }

  return (
    <Drawer
      title={props.t('Create new changelog')}
      width='80%'
      onClose={props.onClose}
      visible={props.changelog}
      className='changelog-translations-drawer'
    >
      <div className='changelog-translations-drawer-container'>
        <div className='changelog-version-input-container'>
          <Input
            size='large'
            placeholder={props.t('Changelog version')}
            onChange={(e) => { setVersion(e.target.value) }}
            value={version}
          />
        </div>
        <Tabs defaultActiveKey={displayedTabKey} activeKey={displayedTabKey} animated={false} onChange={handleTabChange}>
          <TabPane tab={`${props.t('French')} *`} key='1'>
            <ReactQuill
              ref={frenchEditorRef}
              className='quill-text-editor'
              theme='snow' value={french} onChange={setFrench} modules={modules}
              formats={formats} height={500}
            />
          </TabPane>
          <TabPane tab={props.t('Dutch')} key='2'>
            <ReactQuill
              className='quill-text-editor'
              theme='snow' value={dutch} onChange={setDutch} modules={modules}
              formats={formats} height={500}
            />
          </TabPane>
          <TabPane tab={props.t('English')} key='3'>
            <ReactQuill
              className='quill-text-editor'
              theme='snow' value={english} onChange={setEnglish} modules={modules}
              formats={formats} height={500}
            />
          </TabPane>
        </Tabs>
        <div className='changelog-button-container'>
          <Button
            type='primary'
            onClick={save}
            disabled={saveDisabled}
            loading={saving}
            size='large'
          >
            {props.t('Save')}
          </Button>
        </div>
      </div>

    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogTranslations)
