import React, { useEffect, useMemo, useState } from 'react'

import { Modal } from 'antd'
import SmartTable, { DATA_TYPE_SELECT } from '../SmartTable'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getInternshipInternshipYearActTypes, getLinkedActs, linkInternshipToAct, unlinkInternshipFromAct } from '../../../utils/api/internship'
import { getUser } from '../../../reducers/UserReducer'
import { onError, onSuccess, resolvePromises } from '../../../utils/apiHelper'
import { HTTP_CONFLICT } from '../../../utils/http'
import Loading from '../Loading'

const RAW_COLUMNS = [
  {
    type: DATA_TYPE_SELECT,
    name: 'Act Type',
    key: 'fieldActType',
    validate: data => data !== null && !(data.fieldActType !== null && data.fieldActType > 0)
  }
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}
const LinkActModal = ({ internship, visible, onCancel, t, user }) => {
  const [data, setData] = useState([])
  const [actTypes, setActTypes] = useState([])
  const [loading, setLoading] = useState(true)

  const columns = useMemo(() => RAW_COLUMNS.map(c => {
    return { ...c, name: t(c.name), options: actTypes }
  }), [t, actTypes])

  useEffect(() => {
    if (internship.id && visible) {
      setLoading(true)

      const linkedActsP = fetchLinkedActs(internship)
      const actTypesP = getInternshipInternshipYearActTypes(internship, user).then(json => {
        if (json && json.data) {
          const actTypes = []

          json.data.forEach(actType => {
            if (actType.hoursFormat) {
              actTypes.push({
                ...actType,
                key: actType.id
              })
            }
          })

          setActTypes(actTypes)
        }
      })

      resolvePromises([linkedActsP, actTypesP]).then(() => setLoading(false))
    }
  }, [internship, visible])

  const fetchLinkedActs = internship => {
    return getLinkedActs(internship.id, user).then(json => {
      if (json && json.data) {
        const linkedActs = Object.values(json.data).map(act => {
          return {
            ...act,
            fieldActType: act.id,
            key: act.id
          }
        })

        setData(linkedActs)
      }
    })
  }

  const handleDelete = act => {
    unlinkInternshipFromAct(internship.id, act.id, user).then(json => {
      if (json.data) {
        onSuccess(t('Act unlinked'))
        fetchLinkedActs(internship)
      }
    })
  }

  const handleAdd = (act, stopSmartTableLoading) => {
    linkInternshipToAct(internship.id, act.fieldActType, user, true).then(json => {
      if (json && json.data) {
        onSuccess(t('Act linked'))
        fetchLinkedActs(internship)
      }
    }).catch(response => {
      if (response.status === HTTP_CONFLICT) {
        onError(t('This actType has been already linked to this internship'))
      } else {
        document.dispatchEvent(
          new window.CustomEvent('api-error-displayer', { detail: response })
        )
      }

      stopSmartTableLoading()
    })
  }

  return (
    <Modal
      title={t('Link to an act')}
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
      cancelText={t('Cancel')}
      destroyOnClose
      width='60%'
    >
      {!loading && (actTypes.length > 0 || data.length > 0) && (
        <SmartTable
          columns={columns}
          data={data}
          removeExportButton
          reverseDefaultBoolean
          onDataAdd={handleAdd}
          onDataDelete={handleDelete}
          addDataText={t('Add an act')}
          deleteBody={
            <div style={{ width: '150px' }}>
              <b>{t('Warning: ')}</b>
              {t('if you delete a teaching unit, you will lost all the acts linked to this for this internship.')}
            </div>
          }
        />
      )}
      {!loading && !(actTypes.length > 0 || data.length > 0) && (
        <h3 style={{ color: 'rgba(0, 0, 0, 0.25)' }}> {t('No available act types for this internship year')} </h3>
      )}
      {loading && (
        <Loading size='2x' />
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(LinkActModal)
