import { isArray } from 'lodash'

const arraySortingByKeyAndType = (array, key, type, reverse = false) => {
  let comparator = null

  if (!isArray(key)) {
    key = [key]
  }

  if (type === 'string') {
    comparator = recursiveStringCompare
  } else if (type === 'boolean') {
    comparator = recursiveBooleanCompare
  }

  return comparator === null ? array : array.sort((a, b) => comparator(a, b, key, reverse))
}

const translationStringSorting = (array, key, translator) => {
  return array.sort((a, b) => {
    return stringComparator(translator(a[key]), translator(b[key]))
  })
}

const recursiveStringCompare = (a, b, key, reverse = false, index = 0) => {
  let compare = 0

  if (key[index]) {
    const aValue = getObjectValueByKey(a, key[index])
    const bValue = getObjectValueByKey(b, key[index])

    if (!aValue && bValue) {
      compare = 1
    } else if (aValue && !bValue) {
      compare = -1
    } else if (aValue && bValue) {
      compare = aValue.localeCompare(bValue)
    }

    if (compare === 0) {
      compare = recursiveStringCompare(a, b, key, reverse, index + 1)
    }
  }

  return reverse ? 0 - compare : compare
}

const recursiveBooleanCompare = (a, b, key, reverse = false, index = 0) => {
  let compare = 0

  if (key[index]) {
    const aValue = getObjectValueByKey(a, key)
    const bValue = getObjectValueByKey(b, key)

    if (aValue && !bValue) {
      compare = -1
    } else if (!aValue && bValue) {
      compare = 1
    }

    if (compare === 0) {
      compare = recursiveBooleanCompare(a, b, key, reverse, index + 1)
    }
  }

  return reverse ? 0 - compare : compare
}

const recursiveColumnsTesting = (item, filterValue, columns = []) => {
  for (const column of columns) {
    let key = column.filterKey ?? column.key

    if (isArray(key)) {
      key = key[0]
    }

    const value = getObjectValueByKey(item, key)

    if (filterByType(value, filterValue)) {
      return true
    }
  }

  return false
}

const getObjectValueByKey = (object, key) => {
  if (typeof key === 'function') {
    return key(object)
  }

  return object[key]
}

const filterByType = (value, filterValue) => {
  const type = typeof value

  switch (type) {
    case 'string':
      return value.toLowerCase().includes(filterValue.toLowerCase())
    case 'number':
      return value.toString().toLowerCase().includes(filterValue.toLowerCase())
    default:
      return false
  }
}

const stringComparator = (a, b) => {
  if (!a && !b) {
    return 0
  }

  if (!a) {
    return -1
  }

  if (!b) {
    return 1
  }

  return a.localeCompare(b)
}

export {
  arraySortingByKeyAndType,
  translationStringSorting,
  recursiveColumnsTesting,
  getObjectValueByKey,
  filterByType,
  stringComparator
}
