import React from 'react'

import TooltipIcon from './TooltipIcon'

const TitleIcon = ({ title, tooltip }) => {
  return (
    <div className='flex-row' style={{ alignItems: 'center' }}>
      <div><span><b>{title}</b></span></div>
           &nbsp;
      <TooltipIcon title={tooltip} />
    </div>
  )
}

export default TitleIcon
