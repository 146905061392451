import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { InternshipContext } from '../../../../Context/InternshipsManager/InternshipProvider'
import { getDateCellClassName } from '../utils/utils'
import QuotaDateCell from './QuotaDateCell'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const InstitutionDateCell = ({ startDate, endDate, index, periodIndexes, onPeriodChange, isHovered, t }) => {
  const { internship, quotas } = useContext(InternshipContext)

  const [className, setClassName] = useState('')
  const [inPeriod, setInPeriod] = useState(false)

  useEffect(() => {
    setClassName(getDateCellClassName(index, periodIndexes))
    setInPeriod(index >= periodIndexes.startIndex && index <= periodIndexes.lastIndex)
  }, [index, periodIndexes])

  return (
    <td className={className}>
      {inPeriod && (
        <QuotaDateCell
          endDate={typeof internship.endDate === 'string' ? moment(internship.endDate.split('+')[0]) : moment()}
          placement='top'
          school={internship.school}
          sector={internship.sector}
          startDate={typeof internship.startDate === 'string' ? moment(internship.startDate.split('+')[0]) : moment()}
          t={t}
          value={quotas && typeof quotas[index] === 'number' ? quotas[index] : null}
        />
      )}
    </td>
  )
}

export default connect(mapStateToProps)(InstitutionDateCell)
