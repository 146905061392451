import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconLabel = ({ padding = 'pdl-8', icon, text }) => {
  return (
    <div className={`flex-row ${padding}`}>
      <div className='color-secundary' style={{ height: '20px', width: '20px' }}> <FontAwesomeIcon icon={icon} /> </div>
      <span className='ft-sm'> {text} </span>
    </div>
  )
}

export default IconLabel
