import React, { useEffect, useRef, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import TableHeader from '../shared/Tables/TableHeader'
import TableFooter from '../shared/Tables/TableFooter'
import { Button, Input, Popconfirm, Select, Tag, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckboxUserSectorTable from '../shared/CheckboxUserSectorTable'
import { canonizeString } from '../../utils'
import { isFunction } from 'lodash'
import { isHospitalAdmin, isObserver } from '../../utils/roles'

import '../../assets/user-sector-table.scss'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const DEFAULT_LINE_SHOWN = 20
const SEARCH_DEFAULT_TIMER = 500
export const DATA_TYPE_SELECT = 0
export const DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR = 1
export const DATA_TYPE_CHECK_CONTACT_PERSON = 2
export const DATA_TYPE_TAG = 3
const UNSELECT_TEXT = 'Unselect all'
const SELECT_TEXT = 'Select all'

const Option = Select.Option

const UserSectorTable = ({
  additionalActions, columns = [], data, density, disableSelectAll, readOnly, searchWithFilter, setUserContactPerson, setMultipleUserContactPersons,
  setMultipleManagedUsers, setManagedUser, user, userIsAdmin, userIsContactPerson, onDataAdd, onDataEdit, onDelete, t
}) => {
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [linesCount, setLinesCount] = useState(0)
  const [checkboxList, setCheckboxList] = useState([])
  const [checkAllCheckboxText, setCheckAllCheckboxText] = useState({
    contactPerson: SELECT_TEXT,
    admin: SELECT_TEXT
  })
  const [numberCheckedBox, setNumberCheckedBox] = useState({
    contactPerson: 0,
    admin: 0
  })
  const [isAdmin, setIsAdmin] = useState(false)
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const timer = useRef(null)

  const minIndex = DEFAULT_LINE_SHOWN * (pageIndex - 1)
  const maxIndex = minIndex + DEFAULT_LINE_SHOWN

  useEffect(() => {
    if (data.length > 0) {
      setMaxPageIndex(data.length % DEFAULT_LINE_SHOWN === 0
        ? Math.floor(data.length / DEFAULT_LINE_SHOWN)
        : Math.floor(data.length / DEFAULT_LINE_SHOWN) + 1
      )
      setLinesCount(data.length)

      if (pageIndex !== 1) {
        setPageIndex(1)
      }

      const checkboxList = []
      let countContactPerson = 0
      let countAdmin = 0

      const userContactPerson = isFunction(userIsContactPerson)
      const checkedAdmin = isFunction(userIsAdmin)

      data.forEach(sector => {
        const isCheckedContactPerson = userContactPerson && userIsContactPerson(sector.id)
        const isCheckedAdmin = checkedAdmin && userIsAdmin(sector.id)

        if (isCheckedContactPerson) {
          countContactPerson++
        }

        if (isCheckedAdmin) {
          countAdmin++
        }

        checkboxList.push({
          ...sector,
          isCheckedContactPerson: isCheckedContactPerson,
          isCheckedAdmin: isCheckedAdmin
        })
      })

      checkboxList.sort((a, b) => {
        return b.isCheckedAdmin - a.isCheckedAdmin || b.isCheckedContactPerson - a.isCheckedContactPerson
      })

      setCheckboxList(checkboxList)
      setNumberCheckedBox({
        contactPerson: countContactPerson,
        admin: countAdmin
      })
    } else {
      setCheckboxList([])
    }
  }, [data])

  useEffect(() => {
    setIsDataAvailable(checkboxList.length > 0 || data.length > 0)
  }, [data, checkboxList])

  useEffect(() => {
    const contactPerson = checkboxList.length === numberCheckedBox.contactPerson ? UNSELECT_TEXT : SELECT_TEXT
    const admin = checkboxList.length === numberCheckedBox.admin ? UNSELECT_TEXT : SELECT_TEXT

    setCheckAllCheckboxText({ admin: admin, contactPerson: contactPerson })
  }, [checkboxList.length, numberCheckedBox])

  useEffect(() => {
    const userRole = user === null ? [] : user.roles

    setIsAdmin(isHospitalAdmin(userRole))
  }, [user])

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const handleSearchFieldChange = value => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      searchWithFilter(value, isAdmin)
    }, SEARCH_DEFAULT_TIMER)
  }

  const checkAllCheckboxContactPerson = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText.contactPerson) {
      case SELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, contactPerson: UNSELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, contactPerson: checkboxList.length })
        setCheckboxList(newCheckboxList)
        setMultipleUserContactPersons(newChangesIndex)
        break
      case UNSELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, contactPerson: SELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, contactPerson: 0 })
        setCheckboxList(newCheckboxList)
        setMultipleUserContactPersons(newChangesIndex)
        break
      default:
        break
    }
  }

  const checkAllCheckboxAdmin = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText.admin) {
      case SELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, admin: UNSELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedAdmin) {
            newCheckboxList[key].isCheckedAdmin = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, admin: checkboxList.length })
        setCheckboxList(newCheckboxList)
        setMultipleManagedUsers(newChangesIndex)
        break
      case UNSELECT_TEXT:
        setCheckAllCheckboxText({ ...checkAllCheckboxText, admin: SELECT_TEXT })

        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedAdmin) {
            newCheckboxList[key].isCheckedAdmin = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox({ ...numberCheckedBox, admin: 0 })
        setCheckboxList(newCheckboxList)
        setMultipleManagedUsers(newChangesIndex)
        break
      default:
        break
    }
  }

  const renderTag = (data, index) => {
    return (
      <td key={`col_${index}`}>
        <div className='flex-row j-ctn-c'>
          <Tag color={!data.archived ? 'green' : 'red'}> {t(data.archived ? 'Archived' : 'Active')} </Tag>
        </div>
      </td>
    )
  }

  const renderSelect = (data, index, options) => {
    const noDataSelected = t('Select a data')

    if (isAdmin) {
      return (
        <td key={`col_${index}`}>
          <Tooltip placement='top' title={data.name}>
            <div>{data.name}</div>
          </Tooltip>
        </td>
      )
    } else {
      return (
        <td key={`col_${index}`}>
          <Tooltip placement='top' title={data.name}>
            {data.archived && (
              <Input value={data.name} disabled />
            )}
            {!data.archived && (
              <Select
                showSearch
                value={data.id === null ? noDataSelected : data.id}
                filterOption={(input, option) =>
                  canonizeString(option.props.children).indexOf(
                    canonizeString(input)
                  ) > -1}
                onChange={e => {
                  updateDataValue(data, e)
                }}
                disabled={readOnly}
              >
                {options.map(o => {
                  return (
                    <Option key={o.id} value={o.id} title={o.name}>
                      {o.name}
                    </Option>
                  )
                })}
              </Select>
            )}
          </Tooltip>
        </td>
      )
    }
  }

  const updateDataValue = (prevData, value) => {
    const sector = {
      prevId: prevData.id,
      newId: value
    }

    if (prevData.id === null) {
      onDataAdd(sector)
    } else {
      onDataEdit(sector)
    }
  }

  const renderCheckbox = (data, index, type) => {
    let isChecked = false
    let indexSector = 0

    for (const index in checkboxList) {
      if (data.sector === checkboxList[index].id) {
        if (type === 0) {
          isChecked = checkboxList[index].isCheckedAdmin
        } else {
          isChecked = checkboxList[index].isCheckedContactPerson
        }
        indexSector = index
      }
    }

    return (
      <td key={`col_contact_person${index}`}>
        <CheckboxUserSectorTable
          setUserContactPerson={setContactPerson}
          setManagedUser={setManaged}
          sectorId={data.sector}
          isChecked={isChecked}
          handleChangeChecked={handleChangeChecked}
          indexSector={indexSector}
          type={type}
        />
      </td>
    )
  }

  const setContactPerson = (sectorId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox({ ...numberCheckedBox, contactPerson: numberCheckedBox.contactPerson - 1 })
    } else {
      setNumberCheckedBox({ ...numberCheckedBox, contactPerson: numberCheckedBox.contactPerson + 1 })
    }

    setUserContactPerson(sectorId)
  }

  const setManaged = (sectorId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox({ ...numberCheckedBox, admin: numberCheckedBox.admin - 1 })
    } else {
      setNumberCheckedBox({ ...numberCheckedBox, admin: numberCheckedBox.admin + 1 })
    }

    setManagedUser(sectorId)
  }

  const handleChangeChecked = (indexSector, type) => {
    const newCheckboxList = [...checkboxList]

    if (type === 0) {
      newCheckboxList[indexSector].isCheckedAdmin = !checkboxList[indexSector].isCheckedAdmin
    } else {
      newCheckboxList[indexSector].isCheckedContactPerson = !checkboxList[indexSector].isCheckedContactPerson
    }

    setCheckboxList(newCheckboxList)
  }

  const renderAdditionalActions = rowData => {
    const additionalActionsJSX = additionalActions.map(action => {
      return (
        <Tooltip
          placement='top'
          title={t(action.title)}
          key={'action-' + action.title}
        >
          {typeof action.titlePopconfirm !== 'undefined'
            ? buttonConfirmAction(action, rowData)
            : buttonAction(action, rowData)}
        </Tooltip>
      )
    })

    return (
      <td className='buttons'>
        <div className='flex-row'>
          {additionalActionsJSX}
        </div>
      </td>
    )
  }

  const buttonAction = (action, rowData) => {
    return (
      <Button
        type={action.type}
        size='small'
        className='button'
        onClick={() => action.handleOnClick(rowData)}
        disabled={action.disabled || readOnly ? action.disabled(rowData) : false}
      >
        <FontAwesomeIcon icon={action.icon} />
      </Button>
    )
  }

  const buttonConfirmAction = (action, rowData) => {
    return (
      <Popconfirm
        placement='top'
        okType={action.type}
        title={action.titlePopconfirm}
        okText={t('Yes')}
        cancelText={t('Cancel')}
        onConfirm={() => action.handleOnClick(rowData)}
      >
        <Button
          type={action.type}
          size='small'
          className='button'
          disabled={action.disabled || readOnly ? action.disabled(rowData) : false}
        >
          <FontAwesomeIcon icon={action.icon} />
        </Button>
      </Popconfirm>
    )
  }

  const addNewLine = () => {
    const newCheckboxList = [...checkboxList]
    const newSector = {
      ...checkboxList[0],
      name: '',
      sector: null,
      id: null,
      isCheckedContactPerson: false,
      archived: false
    }

    newCheckboxList.unshift(newSector)
    setCheckboxList(newCheckboxList)

    const newNumberCheckedBox = numberCheckedBox + 1
    setNumberCheckedBox(newNumberCheckedBox)
  }

  return (
    <div className='interactive-table'>
      {isAdmin && <TableHeader disableAdd disableSetting />}
      {!isAdmin && (
        <TableHeader
          onDataAdd={addNewLine}
          addButtonText='Add a care unit'
          disableSettings='true'
          disableAdd={readOnly}
          disableSearchFields={isObserver(user?.roles ?? [])}
          onDataFilter={handleSearchFieldChange}
        />
      )}
      {isDataAvailable && (
        <>
          <table>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index} style={{ cursor: 'default', textAlign: 'center' }}>
                    {t(column.name)}
                  </th>
                ))}
                {additionalActions && !isAdmin && <th style={{ width: '100px' }} />}
              </tr>
            </thead>
            <tbody>
              {!disableSelectAll && (
                <tr key='row_all' className='bordered' style={{ height: density ?? '32px' }}>
                  <td key='col_all_title' id='col_all_title'>
                    {t('Select all')}
                  </td>
                  {isAdmin && (
                    <td key='col_all_local_administrator' id='col_all_local_administrator'>
                      <input className='checkbox' type='checkbox' onChange={checkAllCheckboxAdmin} checked={checkAllCheckboxText.admin === UNSELECT_TEXT} />
                    </td>
                  )}
                  <td key='col_all_contact_person' id='col_all_contact_person'>
                    <input className='checkbox' type='checkbox' onChange={checkAllCheckboxContactPerson} checked={checkAllCheckboxText.contactPerson === UNSELECT_TEXT} />
                  </td>
                </tr>
              )}
              {checkboxList.slice(minIndex, maxIndex).map((data, index) => {
                return (
                  <tr key={'row_' + index} style={{ height: density ?? '32px' }}>
                    {columns.map((column, index) => {
                      if (column.type === DATA_TYPE_SELECT) return renderSelect(data, index, column.options)
                      if (column.type === DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR && isAdmin) return renderCheckbox(data, index, 0)
                      if (column.type === DATA_TYPE_CHECK_CONTACT_PERSON) return renderCheckbox(data, index, 1)
                      if (column.type === DATA_TYPE_TAG) return renderTag(data, index)
                    })}
                    {additionalActions && !isAdmin && renderAdditionalActions(data)}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <TableFooter
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            linesCount={linesCount}
            onIndexChange={handleIndexChange}
          />
        </>
      )}
      {!isDataAvailable && <span> {t('No data are actually available for this page.')} </span>}
    </div>
  )
}

export default connect(mapStateToProps)(UserSectorTable)
