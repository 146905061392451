import React, { useEffect, useMemo, useState } from 'react'

import { Input, Menu, Dropdown, Popconfirm, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLanguageByLocale } from '../../../utils'
import { getInstititionFieldsTypes, getStudentFreeFields, remove, update } from '../../../reducers/FreeFieldsReducer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'

import '../../../assets/free-field-manager.scss'

const NO_FREE_FIELDS_ERROR_MESSAGE = "Your institution doesn't have Free Fields"
const DELETION_TOOLTIP_MESSAGE = 'Delete this data ?'

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  fieldTypes: getInstititionFieldsTypes(state.getFreeFields),
  studentFreeFields: getStudentFreeFields(state.getFreeFields),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  deleteStudentFreeFields: bindActionCreators(remove, dispatch),
  updateStudentFreeField: bindActionCreators(update, dispatch)
})

const FreeFieldDisplay = ({ activeLanguage, disabled, id, user, fieldTypes, studentFreeFields, deleteStudentFreeFields, updateStudentFreeField, t }) => {
  const [studentFreeField, setStudentFreeField] = useState({})

  useEffect(() => {
    if (id != null) {
      setStudentFreeField(studentFreeFields[id])
    }
  }, [id, studentFreeFields])

  const onFreeFieldDeleted = (id) => {
    deleteStudentFreeFields(id, user, t)
  }

  const unavailableFreeFieldsTypes = useMemo(() => Object.keys(studentFreeFields).map(key => {
    return studentFreeFields[key].freeFieldsTypeId
  }), [studentFreeFields])

  const renderNoAvailableFreeFieldsTypes = useMemo(() => (
    <Menu.Item key={1}>
      {t(NO_FREE_FIELDS_ERROR_MESSAGE)}
    </Menu.Item>
  ), [t])

  const menuItems = useMemo(() => {
    return fieldTypes === undefined
      ? renderNoAvailableFreeFieldsTypes
      : Object.keys(fieldTypes).filter(key => {
        return !unavailableFreeFieldsTypes.includes(fieldTypes[key].id) && fieldTypes[key].isEditable
      }).map(key => {
        return (
          <Menu.Item key={key} onClick={() => onFreeFieldTypeUpdate(fieldTypes[key])}>
            {fieldTypes[key].translations[getLanguageByLocale(activeLanguage)]}
          </Menu.Item>
        )
      })
  }, [unavailableFreeFieldsTypes, fieldTypes, renderNoAvailableFreeFieldsTypes, activeLanguage])

  const onFreeFieldTypeUpdate = (freeFieldsType) => {
    const newStudentFreeField = {
      ...studentFreeField,
      freeFieldsTypeId: freeFieldsType.id,
      freeFieldsLabel: freeFieldsType.translations[getLanguageByLocale(activeLanguage)],
      isEditable: freeFieldsType.isEditable,
      isBadgeDisplayed: freeFieldsType.isBadgeDisplayed,
      isEncryption: freeFieldsType.isEncryption
    }

    setStudentFreeField(newStudentFreeField)
    updateStudentFreeField(newStudentFreeField, user, t)
  }

  const onFieldValueEdit = newValue => {
    const newStudentFreeField = { ...studentFreeField, fieldValue: newValue }

    setStudentFreeField(newStudentFreeField)
  }

  const onBlur = (target) => {
    updateStudentFreeField(studentFreeField, user, t)
  }

  return (
    <tr>
      <td className={!studentFreeField.isEditable ? 'not-allowed' : 'onMouseClickable'}>
        <Dropdown overlay={<Menu> {menuItems} </Menu>} trigger={['click']} disabled={!studentFreeField.isEditable || disabled}>
          <div>
            {studentFreeField.freeFieldsLabel}
          </div>
        </Dropdown>
      </td>
      <td>
        <div className='flex-row'>
          <Input
            className='inputText'
            value={!studentFreeField.isEncryption ? studentFreeField.fieldValue : '********'}
            placeholder={t('No value')}
            onChange={e => onFieldValueEdit(e.target.value)}
            onBlur={e => { onBlur(e.target) }}
            disabled={!studentFreeField.isEditable || disabled}
          />
          <Popconfirm
            placement='top'
            okType='danger'
            title={t(DELETION_TOOLTIP_MESSAGE)}
            okText={t('Yes')}
            cancelText={t('Cancel')}
            onConfirm={() => onFreeFieldDeleted(studentFreeField.id)}
            disabled={!studentFreeField.isEditable || disabled}
          >
            <Button
              type='danger'
              size='small'
              style={{ width: '28px', marginLeft: '2px', marginTop: '3px' }}
              disabled={!studentFreeField.isEditable || disabled}
            >
              <FontAwesomeIcon icon='trash' />
            </Button>
          </Popconfirm>
        </div>
      </td>
    </tr>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeFieldDisplay)
