import React, { createContext, useState } from 'react'
import { getUser } from '../reducers/UserReducer'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

export const ShiftManagerContext = createContext()
const ShiftManagerProvider = ({ children, user }) => {
  const [fontSizes, setFontSizes] = useState({ content: 16, time: 8 })

  return (
    <ShiftManagerContext.Provider value={{fontSizes, setFontSizes}}>
      {children}
    </ShiftManagerContext.Provider>
  )
}

export default connect(mapStateToProps)(ShiftManagerProvider)
