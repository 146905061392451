import { List, Select } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { handleFilter } from './antd/antdHelper'
import { getFullNameOrNull } from '../utils/entities/user'
import TrashButton from './antd/Buttons/TrashButton'

const { Option } = Select
const { Item } = List

const SPAN_STYLE = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', paddingRight: '4px' }

const UsersHeap = ({ height = '200', values, users = [], onSelect }) => {
  const usersMap = useMemo(() => users.reduce((acc, u) => {
    acc[u.id] = u
    return acc
  }, {}), [users])
  const valuesMap = useMemo(() => values.reduce((acc, u) => {
    acc[u.id] = u
    return acc
  }, {}), [values])
  const localUsers = useMemo(() => users.reduce((acc, u) => {
    if (valuesMap[u.id]) {
      acc.list.push(u)
    } else {
      acc.select.push(u)
    }
    return acc
  }, { list: [], select: [] }), [users, valuesMap])

  const renderUsers = useCallback(user => {
    const fullname = getFullNameOrNull(user)

    return (
      <Item>
        <div className='flex-row jtf-ctt-sbw w-100'>
          <span style={SPAN_STYLE}> {fullname && <b>{`${fullname} -`}</b>} {user.email} </span>
          <TrashButton onClick={() => onSelect(values.filter(v => v.id !== user.id))} />
        </div>
      </Item>
    )
  }, [values, onSelect])

  return (
    <div className='users-heap-container'>
      <Select
        filterOption={handleFilter}
        showSearch
        style={{ width: '100%' }}
        value={null}
        onChange={id => onSelect([...values, usersMap[id]])}
      >
        {localUsers.select.map(u => (<Option key={u.id} value={u.id} filter={u.email}> {u.email} </Option>))}
      </Select>
      <div style={{ maxHeight: `${height}px` }} className='ovy-a'>
        <List
          size='small'
          bordered
          dataSource={localUsers.list}
          renderItem={renderUsers}
        />
      </div>
    </div>
  )
}

export default UsersHeap
