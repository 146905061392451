import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import SmartTable, { DATA_TYPE_STRING } from './SmartTable'
import { connect } from '../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'
import { requestWithPromise } from '../../utils'
import { getUser } from '../../reducers/UserReducer'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const ContactPersonSectorsWidget = props => {
  const [contactPersonSectors, setContactPersonSectors] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (props.user === null) {
      setContactPersonSectors([])
    } else {
      requestWithPromise(`/api/users/${props.user.id}/contact-person-sectors?institution=${props.institution}`, 'GET', null, props.getUser)
          .then(jsonData => {
            setContactPersonSectors(jsonData.data)
            setLoading(false)
          })
    }
  }, [props.user])

  const COLUMNS = [
    {
      type: DATA_TYPE_STRING,
      name: props.t('Sectors'),
      key: 'name'
    }
  ]

  return (
    <Drawer
      title={props.user !== null ? props.t('Sectors managed by') + ' ' + props.user.email : '?'}
      width='640px'
      onClose={props.onClose}
      visible={props.user !== null}
    >
      <SmartTable
        loading={loading}
        columns={COLUMNS}
        data={contactPersonSectors}
        noDataText={props.t('Without sector')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSectorsWidget)
