import { getLanguageByLocale, request, requestWithPromise } from '../../utils'
import { createStudentFreeField, deleteStudentFreeFields, updateStudentFreeField } from '../../utils/api/studentFreeField'
import { displayError, onSuccess } from '../../utils/apiHelper'
import { SUPPORTED_LANGUAGES } from '../../utils/constants'

const ADD_STUDENT_FREE_FIELD = 'ADD_STUDENT_FREE_FIELD'
const REMOVE_STUDENT_FREE_FIELD = 'REMOVE_STUDENT_FREE_FIELD'
const REMOVE_FREE_FIELD_TYPE = 'REMOVE_FREE_FIELD_TYPE'
const SET_STUDENT_FREE_FIELDS = 'SET_STUDENT_FREE_FIELDS'
const SET_STUDENTS_FREE_FIELDS = 'SET_STUDENTS_FREE_FIELDS'
const SET_INSTITUTION_FIELDS_TYPES = 'SET_INSTITUTION_FIELDS_TYPES'
const SET_FREE_FIELD_TYPE = 'SET_FREE_FIELD_TYPE'
const UPDATE_STUDENT_FREE_FIELDS = 'UPDATE_STUDENT_FREE_FIELDS'
const LOAD_MESSAGE_FREE_FIELDS_TYPES = 'LOAD_MESSAGE_FREE_FIELDS_TYPES'

export const getFreeFields = (state) => {
  return state
}

export const getStudentFreeFields = (state) => {
  return state.studentFreeFields
}

export const getInstititionFieldsTypes = (state) => {
  return state.freeFieldsTypes
}

export const fetchStudentFreeField = (institutionId, studentId, language, user) => {
  return async function fetchStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/free-fields/' + institutionId +
                              '/' + studentId +
                              '/' + language,
    'GET',
    null,
    user,
    { catchError: false }
    )

    dispatch({ type: SET_STUDENT_FREE_FIELDS, payload: response.data })
  }
}

export const fetchInstitutionFieldsTypes = (user) => {
  return async function (dispatch, getState) {
    const response = await request('/free-fields/all',
      'GET',
      null,
      user,
      { catchError: false }
    )

    dispatch({ type: SET_INSTITUTION_FIELDS_TYPES, payload: response.data })
  }
}

export const addNewStudentFreeField = (studentId, fieldType, fieldValue, user, language, t) => {
  return async function (dispatch, getState) {
    createStudentFreeField(user, { studentId, fieldType: fieldType.id, fieldValue }).then(json => {
      dispatch({
        type: ADD_STUDENT_FREE_FIELD,
        payload: {
          id: json.data.id,
          freeFieldsTypeId: json.data.fieldType,
          fieldValue: json.data.fieldValue,
          language: language,
          isEditable: fieldType.isEditable,
          isBadgeDisplayed: fieldType.isBadgeDisplayed
        }
      })
      onSuccess(t('student_free_field.create.success'))
    }).catch(error => {
      displayError(error, 'student_free_field.create')
    })
  }
}

export const update = (studentFreeField, user, t) => {
  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    updateStudentFreeField(user, studentFreeField).then(json => {
      onSuccess(t('student_free_field.update.success'))
      dispatch({ type: UPDATE_STUDENT_FREE_FIELDS, payload: studentFreeField })
    }).catch(error => {
      displayError(error, 'student_free_field.update')
    })
  }
}

export const remove = (studentFreeFieldId, user, t) => {
  return async function deleteStudentFreeFieldsThunk (dispatch, getState) {
    deleteStudentFreeFields(user, { id: studentFreeFieldId }).then(json => {
      onSuccess(t('student_free_field.delete.success'))
      dispatch({ type: REMOVE_STUDENT_FREE_FIELD, payload: studentFreeFieldId })
    }).catch(error => {
      displayError(error, 'student_free_field.delete')
    })
  }
}

export const deleteFreeFieldType = (freeFieldTypeId, user) => {
  return async function deleteStudentFreeFieldsThunk (dispatch, getState) {
    const response = await request('/free-field-type/' + freeFieldTypeId,
      'DELETE',
      null,
      user
    )

    if (response.status === 'success') {
      dispatch(removeFreeFieldType(freeFieldTypeId))
    } else {
      console.log('error when deleteting StudentFreeField with id : ' + freeFieldTypeId)
    }
  }
}

export const addOrUpdateFreeFieldType = (freeFieldType, institutionId, user) => {
  const formatedFreeField = {
    fieldType: freeFieldType.fieldType,
    institution: institutionId,
    isEditable: freeFieldType.isEditable,
    isBadgeDisplayed: freeFieldType.isBadgeDisplayed,
    isStudentDisplayed: freeFieldType.isStudentDisplayed,
    isEncryption: freeFieldType.isEncryption,
    languages: [],
    labels: {}
  }

  Object.keys(freeFieldType.translations).forEach(translationKey => {
    formatedFreeField.languages.push(translationKey)
    formatedFreeField.labels[translationKey] = freeFieldType.translations[translationKey]
  })

  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    const response = await request('/api/FreeFieldsType/create-or-update', 'POST', formatedFreeField, user)

    if (response.status === 'success') {
      freeFieldType.id = response.data.id
      freeFieldType.institution = institutionId

      dispatch(setFreeFieldType(freeFieldType))
    } else {
      console.log('error when updating StudentFreeField')
    }
  }
}

export const updateFreeFieldType = (freeFieldType, institutionId, user) => {
  const newFreeFieldType = { ...freeFieldType, translations: {} }

  SUPPORTED_LANGUAGES.forEach(language => {
    newFreeFieldType.translations[language] = newFreeFieldType[language]
    delete newFreeFieldType[language]
  })

  Object.keys(newFreeFieldType).forEach(k => {
    if (typeof newFreeFieldType[k + '_disabled'] !== 'undefined') {
      delete newFreeFieldType[k + '_disabled']
    }
  })

  return async function updateStudentFreeFieldThunk (dispatch, getState) {
    const jsonResponse = await requestWithPromise('/free-field-type/' + newFreeFieldType.id, 'PATCH', newFreeFieldType, user)

    if (jsonResponse) {
      dispatch(setFreeFieldType(jsonResponse.data))
    }
  }
}

export const addStudentFreeField = (studentFreeField) => {
  return {
    type: ADD_STUDENT_FREE_FIELD,
    payload: studentFreeField
  }
}

export const setStudentFreeFields = (studentFreeFields) => {
  return {
    type: SET_STUDENT_FREE_FIELDS,
    payload: studentFreeFields
  }
}

export const setStudentsFreeFields = (studentsFreeFields) => {
  return {
    type: SET_STUDENTS_FREE_FIELDS,
    payload: studentsFreeFields
  }
}

export const setInstititionFieldsTypes = (freeFieldsTypes) => {
  return {
    type: SET_INSTITUTION_FIELDS_TYPES,
    payload: freeFieldsTypes
  }
}

export const setFreeFieldType = (freeFieldtype) => {
  return {
    type: SET_FREE_FIELD_TYPE,
    payload: freeFieldtype
  }
}

export const removeStudentFreeField = (studentFreeFieldId) => {
  return {
    type: REMOVE_STUDENT_FREE_FIELD,
    payload: studentFreeFieldId
  }
}

export const removeFreeFieldType = (freeFieldTypeId) => {
  return {
    type: REMOVE_FREE_FIELD_TYPE,
    payload: freeFieldTypeId
  }
}

export const loadMessageFieldsTypes = payload => {
  return {
    type: LOAD_MESSAGE_FREE_FIELDS_TYPES, payload
  }
}

export const getLoadMessageFieldsTypes = state => {
  return state.getFreeFields.loadMessage
}

/**
 * Reducer for the Free Fields
 */
export default (state = [], action) => {
  switch (action.type) {
    case ADD_STUDENT_FREE_FIELD: {
      const newStudentFreeFields = { ...state.studentFreeFields }
      const studentFreeFieldsType = { ...state.freeFieldsTypes[action.payload.freeFieldsTypeId] }

      newStudentFreeFields[action.payload.id] = {
        ...action.payload,
        fieldType: studentFreeFieldsType.type,
        freeFieldsLabel: studentFreeFieldsType.translations[getLanguageByLocale(action.payload.language)]
      }

      return { ...state, studentFreeFields: newStudentFreeFields }
    }
    case REMOVE_STUDENT_FREE_FIELD: {
      const newStudentFreeFields = { ...state.studentFreeFields }

      delete newStudentFreeFields[action.payload]

      return { ...state, studentFreeFields: newStudentFreeFields }
    }
    case REMOVE_FREE_FIELD_TYPE: {
      const newFreeFieldsTypes = { ...state.freeFieldsTypes }

      delete newFreeFieldsTypes[action.payload]

      return { ...state, freeFieldsTypes: newFreeFieldsTypes }
    }
    case SET_STUDENT_FREE_FIELDS: {
      return { ...state, studentFreeFields: { ...action.payload } }
    }
    case SET_STUDENTS_FREE_FIELDS: {
      return { ...state, studentFreeFields: { ...action.payload } }
    }
    case SET_INSTITUTION_FIELDS_TYPES: {
      return { ...state, freeFieldsTypes: { ...action.payload } }
    }
    case SET_FREE_FIELD_TYPE: {
      const newFreeFieldsTypes = { ...state.freeFieldsTypes }

      newFreeFieldsTypes[action.payload.id] = { ...action.payload }

      return { ...state, freeFieldsTypes: newFreeFieldsTypes }
    }
    case UPDATE_STUDENT_FREE_FIELDS: {
      const newState = { ...state }
      const newStudentFreeField = action.payload

      newState.studentFreeFields[newStudentFreeField.id] = newStudentFreeField

      return { ...newState }
    }
    case LOAD_MESSAGE_FREE_FIELDS_TYPES: {
      if (state !== action.payload) {
        return {
          ...state,
          loadMessage: action.payload
        }
      }

      return state
    }
    default:
      return state
  }
}
