import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { PARTIAL_PLACES_STATE } from '../../utils/Constants'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'
import PrecisionModal from '../PrecisionModal'

const MODAL_TITLE = 'Cell details'

/**
 * Manage the case of a cell contains a partial quota. A partial quota for wich only a faction of
 * his total is taken into account for the cell calculation.
 */
const PartialHandlerSubCell = props => {
  const [openModal, setOpenModal] = useState(false)

  const data = props.data

  const renderCellContent = () => {
    if (data.state === PARTIAL_PLACES_STATE) {
      return (
        <Row>
          <Col span={18}>
            {data.total}
          </Col>
          <Col span={6} onClick={() => setOpenModal(true)}>
            <FontAwesomeIcon icon={openModal ? faMinus : faPlus} />
          </Col>
        </Row>
      )
    }

    return data.total
  }

  const render = () => {
    if (data) {
      return renderCellContent()
    }

    return ''
  }

  return (
    <div className='partial-handler-sub-cell'>
      {render()}
      {openModal && (
        <PrecisionModal
          period={props.period}
          date={props.date}
          quotas={data.quotas}
          title={MODAL_TITLE}
          onClose={() => setOpenModal(false)}
          visible={openModal}
          footer={null}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PartialHandlerSubCell)
