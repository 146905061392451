import { isArray } from 'lodash'

export const sortByString = (array, key) => {
  if (!Array.isArray(array)) {
    throw new Error('The first parameter of the function is not an array')
  }

  return array.sort((a, b) => {
    const aValue = a[key]
    const bValue = b[key]

    if (typeof aValue !== 'string') {

    }

    if (typeof bValue !== 'string') {

    }

    return aValue.localeCompare(bValue)
  })
}

export const keyIncludes = (array, value, key) => {
  let include = false

  if (isArray(array) && key) {
    let index = 0

    while (index < array.length && !include) {
      include = array[index][key] === value
      index++
    }
  }

  return include
}
