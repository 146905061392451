import React, { useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { getTranslate } from 'react-localize-redux'
import { getInternshipProvider } from './utils/utils'
import { getInternshipLine } from './utils/componentGetters'

import '../../../assets/internships-manager.scss'
import { COLUMNS_INFO_COUNT, DEFAULT_NUMBER_OF_WEEKS } from './utils/constants'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const NO_INTERNSHIPS_TEXT = 'There is no internship to show for these dates.'

const InternshipsTableBody = props => {
  const {
    user, highlightedIndexes, internships, quotas, refreshInternships,
    refreshQuotas, onEarlyStartDate, onLineHover
  } = useContext(InternshipsManagerContext)
  const { Provider, context } = getInternshipProvider(user?.context)
  const InternshipLine = getInternshipLine(user.context)
  const timer = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleLineHover = indexes => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (highlightedIndexes === null || indexes.startIndex !== highlightedIndexes.startIndex || indexes.lastIndex !== highlightedIndexes.lastIndex) {
      timer.current = setTimeout(() => {
        onLineHover(indexes)
      }, 100)
    }
  }

  return (
    <tbody>
      {!props.loading && internships.length > 0 && Provider &&
        internships.map((i, index) => {
          return (
            <Provider
              key={`internship-${index}`}
              internship={i}
              refreshInternships={refreshInternships}
              refreshQuotas={refreshQuotas}
              onEarlyStartDate={onEarlyStartDate}
              quotas={quotas[i.id]}
            >
              <InternshipLine internshipContext={context} onLineHover={handleLineHover} />
            </Provider>
          )
        })}
      {!props.loading && internships.length === 0 &&
        <tr>
          <td colSpan={COLUMNS_INFO_COUNT + DEFAULT_NUMBER_OF_WEEKS}>
            {props.t(NO_INTERNSHIPS_TEXT)}
          </td>
        </tr>}
      {props.loading && (
        <tr>
          <td id='loading-td'>
            <div>
              <div className='table-loading-ring' />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  )
}

export default connect(mapStateToProps)(InternshipsTableBody)
