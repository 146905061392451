import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'

const AddButton = props => {
  const handleDataAdd = () => {
    props.onDataAdd()
  }
  return (
    <Button
      type='primary'
      onClick={handleDataAdd}
      disabled={props.disabled}
      className='tutoriel-bouton-ajouter'
    >
      <FontAwesomeIcon icon='plus' />
      &nbsp;
      {props.addText
        ? props.t(props.addText)
        : props.t('Add')}
    </Button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton)
