import React from 'react'

import { Button, Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const MODAL_TITLE = 'Do you wish to validate this desiderata?'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const DesiderataValidationModal = ({ shift, visible, onCancel, onDeny, onOk, t }) => {
  const renderModalBody = () => {
    return (
      <div>
        <div>
          <h3>{t('Period code')}</h3>
          <p>{shift.periodCode}</p>
          <h3>{t('Start time')}</h3>
          <p>{shift.startTime}</p>
          <h3>{t('End time')}</h3>
          <p>{shift.endTime}</p>
          <h3>{t('Pause')}</h3>
          <p>{shift.pause} min</p>
          {shift.eventCode && (
            <div>
              <h3>{t('Event code')}</h3>
              <p>{shift.eventCode.code}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderModalFooter = () => {
    return [
      <Button key='cancel' type='secondary' onClick={onCancel}>
        {t('Cancel')}
      </Button>,
      <Button key='deny' type='danger' onClick={onDeny}>
        {t('Deny')}
      </Button>,
      <Button key='submit' type='primary' onClick={onOk}>
        {t('Validate')}
      </Button>
    ]
  }

  return (
    <Modal
      visible={visible ?? false}
      title={t(MODAL_TITLE)}
      onOk={onOk}
      onCancel={onCancel}
      footer={renderModalFooter()}
    >
      {renderModalBody()}
    </Modal>
  )
}

export default connect(mapStateToProps)(DesiderataValidationModal)
