import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import '../../../assets/internship-state-pellet.scss'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const StatesPellet = ({ color, icon, tooltipTitle, secondaryIcon, secondaryIconColor, showLabel, t }) => {
  return (
    <Tooltip placement='top' title={typeof tooltipTitle === 'string' ? t(tooltipTitle) : tooltipTitle}>
      <div className='internship-state-pellet'>
        <div className='icon-container'>
          <div className='primary-icon' style={{ backgroundColor: color }}>
            {
              icon === null
                ? null
                : <FontAwesomeIcon icon={icon} style={{ margin: 'auto' }} />
            }
          </div>
          {secondaryIcon &&
            <div className='secondary-icon' style={secondaryIconColor ? { color: secondaryIconColor } : {}}>
              <FontAwesomeIcon icon={secondaryIcon} />
            </div>}
        </div>
        {showLabel ? <div style={{ marginLeft: secondaryIcon ? '11px' : '5px' }}>{tooltipTitle}</div> : null}
      </div>
    </Tooltip>
  )
}

export default connect(mapStateToProps)(StatesPellet)
