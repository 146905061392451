import React, { useState, useEffect } from 'react'

import { Select } from 'antd'
import { canonizeString } from '../../../../utils'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'

const Option = Select.Option

/**
 * A standart factory to build a Select for table header
 * 
 * Needed props :
 *  {function} updateFilter  the calback function call whe the filterSearch field value is modified
 *  {*}        defaultColumn the default column to select
 *  {array}    data          an array of objects to display in Select Option. Have to contains dataKey property
 *  {function} onChange      callback function call when an option is clicked
 *  {*}        dataKey       the property choose to serve as key
 * 
 * Optionnal props : 
 *  {string}   className     string containing one or more css class used to modify button visual aspect
 *  {function} filterOption a custom function to sort Menu Option
 * 
 * @param {*} props 
 * @returns grappe react
 */
const SelectFactory = props => {
  const [data, setData] = useState([])
  const [id, setId] = useState()

  /**
   * Function call when an option filter is clicked
   * 
   * @param {string} newValue the new value choose as filter
   */
  const updateFilter = (newValue) => {
    props.updateFilter(newValue);
  }

  useEffect(() => {
    if (props.defaultColumn) {
      setId(props.defaultColumn)
    }
  }, [props.defaultColumn])

  useEffect(() => {
    if (props.data) {
      setData(props.data)
    }
  }, [props.data])

  /**
   * Default function to filter the Select Component Options 
   */
  const defaultFilterOption = (input, option) => {
    return canonizeString(option.props.children).indexOf(
      canonizeString(input)
    ) > -1
  }

  /**
   * Default fonction to change the selected Option
   * 
   * @param {*} e the value contains in the Option 
   */
  const onChange = e => {
    setId(e)

    if (props.onChange) {
      props.onChange(e)
    } 
  }

  /**
   * Render the Options contains in the Select Component
   * 
   * @param {array} optionsArray an array of objects. Have to contains a 'key' property
   * @param {*} key              a valid table column property 
   * @returns grappe react containing an array of Select.Option Component
   */
  const renderOptions = (optionsArray, key) => {

    return optionsArray.map((option, index) => {
      
      return (
        <Option key={index} value={option[key]} title={props.t(option[key])}>
          {props.t(option[key])}
        </Option>
      )
    })
  }

  //Component return
  return (
    <Select
      showSearch
      value={id}
      filterOption={props.filterOption ? props.filterOption : defaultFilterOption}
      onChange={onChange}
      className={props.className ? props.className : "default-select"}
    >
      {renderOptions(data, props.dataKey)}
    </Select>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFactory)