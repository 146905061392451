import React, { useContext } from 'react'

import { Col, Row } from 'antd'
import { GlobalContext } from '../../../../../Providers/GlobalProvider'

const SectionsSubCell = ({ dataIds, details, total }) => {
  const { sectionsObject } = useContext(GlobalContext)

  const render = () => {
    const body = []

    if (dataIds) {
      dataIds.forEach(sectionId => {
        const section = sectionsObject[sectionId]

        if (section) {
          const { id, abbreviation } = section

          body.push(
            <Row key={`row-section-${id}`}>
              <Col span={18}>
                {abbreviation}
              </Col>
              <Col span={6}>
                {details[id]}
              </Col>
            </Row>
          )
        }
      })
    }

    const firstColSpan = body.length === 0 ? 24 : 8

    return (
      <Row>
        <Col span={firstColSpan}>
          <div className='sections-sub-cell-total'>
            {total}
          </div>
        </Col>
        <Col span={24 - firstColSpan}>
          <div className='section-sub-cell-details'>
            {body}
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <div className='sections-sub-cell'>
      {render()}
    </div>
  )
}

export default SectionsSubCell
