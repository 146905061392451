import React from 'react'

import '../../../../assets/shifts-manager.scss'
import { getTresholdByPlaces } from '../constants'
import { getCellStyleByTreshold } from '../Other/utils'

const TotalHeaderCell = props => {
  const renderTotalLine = () => {
    const total = props.total ?? 0
    const shiftsTotal = props.shiftsTotal ?? 0

    return (
      <div className='simple-total-header-cell' style={getCellStyleByTreshold(getTresholdByPlaces(total - shiftsTotal))}>
        {`${total - shiftsTotal}/${total}`}
      </div>
    )
  }

  return (
    <th
      onClick={props.onClick}
      className='pause-quotas-th'
    >
      {props.name}
      <br />
      {props.date}
      <br />
      {renderTotalLine()}
    </th>
  )
}

export default TotalHeaderCell
