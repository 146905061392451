import React, { useState, useEffect } from 'react'

import SatisfactionRatio from '../../../Components/shared/SatisfactionRatio'
import EvaluationCard from './EvaluationCard'
import { getStudentEvaluations } from '../../../utils/api/student'

import '../../../assets/evaluation-details.scss'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const EvaluationDetails = ({ institutionEvaluationSatisfactionRatio, school, sectionName, studentName, studentEvaluationSatisfactionRatio, studentId, user, t }) => {
  const [loading, setLoading] = useState(true)
  const [evaluationsList, setEvaluationsList] = useState([])

  useEffect(() => {
    if (studentId) {
      setLoading(true)

      getStudentEvaluations(user, { id: studentId }).then(json => {
        if (json.data) {
          setEvaluationsList(json.data.map(item => {
            return { ...item, sectionName: getSectionName(item), individualDetailsDisplayed: false }
          }))
          setLoading(false)
        }
      })
    }
  }, [studentId, user])

  const getSectionName = section => {
    return section.sectionName ?? section.deprecatedSectionName
  }

  return (
    <div className='evaluation-details-wrapper'>
      <div className='top-details'>
        <div className='student-details'>
          <div>
            <label>{t('Student name')}</label>
            <span>{studentName}</span>
          </div>
          <div>
            <label>{t('School')}</label>
            <span>{school}</span>
          </div>
          <div>
            <label>{t('Section(s)')}</label>
            <span>{sectionName}</span>
          </div>
        </div>
        <div className='general-satisfaction-ratios'>
          <div className='satisfaction-block'>
            <label>{t('evaluation_details.student_results')}</label>
            <SatisfactionRatio ratio={studentEvaluationSatisfactionRatio} />
          </div>
          <div className='satisfaction-block'>
            <label>{t('evaluation_details.care_unit_results')}</label>
            <SatisfactionRatio ratio={institutionEvaluationSatisfactionRatio} />
          </div>
        </div>
      </div>
      <div className='evaluations-list'>
        {loading && <div className='loading-ring' />}
        {!loading && (
          <div>
            <div className='h-spacing' />
            <div className='student-evaluations-list-header'>{t('List of all evaluations')}</div>
          </div>
        )}
        {!loading && evaluationsList.map(item => <EvaluationCard key={item.id} evaluation={item} />)}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EvaluationDetails)
