import { createSelector } from 'reselect'

/**
 * Retrieve badges from global store
 * Build for each student a string containing all actives badges number
 */
export const selectConcatStudentsBadges = createSelector(
    state => state.getBadgesProperties.badges,
    (badges) => {
      if (typeof badges !== 'object'){
        
        return []
      } else {
        let concatPerStudentBadges = {}

        Object.keys(badges).forEach((studentId) => {
          concatPerStudentBadges[studentId] = formatBadges(badges[studentId])
        });

        return concatPerStudentBadges
      }
    }
) 

/**
 * Format an array of badges in order to return the list of badges numbers
 * 
 * @param {array} badgesArray an array pf badges entities
 * 
 * @returns string the list of badges numbers 
 */
const formatBadges = (badgesArray) => {
  let formatedBadgesNumbers = ""

  badgesArray.forEach(badge => {
    formatedBadgesNumbers += formatedBadgesNumbers === "" ? badge.badgeNumber : " - " + badge.badgeNumber
  })

  return formatedBadgesNumbers
}