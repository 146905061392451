import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Card, Drawer, List } from 'antd'
import { getActTypes } from '../../../utils/api/schoolSection'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import Loading from '../../../HOC/Loading'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SectionActTypesDrawer = ({ schoolSection, user, onActEdition, onClose, t }) => {
  const [actTypes, setActTypes] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchActTypes = useCallback(section => {
    setLoading(true)
    getActTypes(user, section).then(json => {
      if (json?.data) {
        setActTypes(json.data)
      }

      setLoading(false)
    })
  }, [user, setActTypes, setLoading])

  const actTypesByCategory = useMemo(() => {
    const categories = {}

    actTypes.forEach(at => {
      const categoryKey = at.actTypeCategory === null ? -1 : at.actTypeCategory.id

      if (!categories[categoryKey]) {
        categories[categoryKey] = {
          name: at.actTypeCategory === null ? t('Without category') : at.actTypeCategory.name,
          actTypes: []
        }
      }

      categories[categoryKey].actTypes.push(at)
    })

    return categories
  }, [actTypes, t])

  const handleActEdition = useCallback(() => {
    onActEdition()
    onClose()
  }, [onActEdition, onClose])

  useEffect(() => {
    if (schoolSection) {
      fetchActTypes(schoolSection)
    } else {
      setLoading(false)
      setActTypes([])
    }
  }, [schoolSection, fetchActTypes, setLoading, setActTypes])

  return (
    <Drawer
      title={schoolSection ? t('section_act_types_drawer.title') + ' ' + schoolSection.name : '?'}
      width={400}
      onClose={onClose}
      visible={!!schoolSection}
    >
      <Loading loading={loading}>
        <Button type='primary' onClick={handleActEdition}> {t('section_act_types_drawer.edit_button')} </Button>
        <List>
          {Object.keys(actTypesByCategory).map((key, index) => {
            return (
              <List.Item key={'list-item_' + index}>
                <div style={{ width: '100%' }}>
                  <Card key={index} title={actTypesByCategory[key].name + ` (${actTypesByCategory[key].actTypes.length})`} size='small'>
                    {actTypesByCategory[key].actTypes.map(at => {
                      return (<p key={at.id}>{at.name}</p>)
                    })}
                  </Card>
                </div>
              </List.Item>
            )
          })}
        </List>
      </Loading>
    </Drawer>
  )
}

export default connect(mapStateToProps)(SectionActTypesDrawer)
