import { requestWithPromise } from '../../utils'
import { SCHOOLSECTIONS } from './actionsType'

const { REMOVE_SCHOOL_SECTION_BY_INDEX, REMOVE_SCHOOL_SECTION_BY_ID, SET_SCHOOL_SECTIONS_BY_SCHOOL, SET_SCHOOL_SECTION_BY_INDEX, SET_SCHOOL_SECTION_BY_ID, SET_NEW_SCHOOL_SECTION } = SCHOOLSECTIONS

const getSchoolSections = state => {
  return state
}

const addSchoolSection = (schoolId, schoolSection, user) => {
  return async function addSchoolSectionThunk (dispatch, getState) {
    dispatch(setNewSchoolSection(schoolId, schoolSection))

    const response = await requestWithPromise('/api/school-sections', 'POST', schoolSection, user)

    if (response && response.data) {
      dispatch(setSchoolSectionById(schoolId, schoolSection.id, response.data))
    } else {
      dispatch(deleteSchoolSectionById(schoolId, schoolSection.id))
    }
  }
}

const fetchSchoolSectionsBySchool = (schoolId, user) => {
  return async function fetchSchoolSectionsThunk (dispatch, getState) {
    const response = await requestWithPromise(`/api/schools/${schoolId}/school-sections`, 'GET', null, user)

    if (response) {
      dispatch(setSchoolSectionsBySchool(schoolId, response.data))
    }
  }
}

const modifySchoolSection = (schoolId, schoolSection, user) => {
  return async function modifySchoolSectionThunk (dispatch, getState) {
    const response = await requestWithPromise(`/api/school-sections/${schoolSection.id}`, 'PATCH', schoolSection, user)

    if (response) {
      dispatch(setSchoolSectionByIndex(schoolId, response.data, schoolSection.arrayIndex))
    }
  }
}

const removeSchoolSection = (schoolId, schoolSection, user) => {
  return async function removeSchoolSectionThunk (dispatch, getState) {
    dispatch(deleteSchoolSectionByIndex(schoolId, schoolSection.arrayIndex))

    const response = await requestWithPromise(`/api/school-sections/${schoolSection.id}`, 'DELETE', null, user)

    if (response === null) {
      // dispatch(deleteSchoolSectionByIndex(schoolId, schoolSection.arrayIndex))
      delete schoolSection.arrayIndex

      dispatch(setNewSchoolSection(schoolId, schoolSection))
    }
  }
}

const setSchoolSectionsBySchool = (schoolId, sections) => {
  return {
    type: SET_SCHOOL_SECTIONS_BY_SCHOOL,
    payload: { schoolId, sections }
  }
}

const setSchoolSectionByIndex = (schoolId, schoolSection, sectionIndex) => {
  return {
    type: SET_SCHOOL_SECTION_BY_INDEX,
    payload: { schoolId, schoolSection, sectionIndex }
  }
}

const setSchoolSectionById = (schoolId, sectionId, schoolSection) => {
  return {
    type: SET_SCHOOL_SECTION_BY_ID,
    payload: { schoolId, sectionId, schoolSection }
  }
}

const setNewSchoolSection = (schoolId, schoolSection) => {
  return {
    type: SET_NEW_SCHOOL_SECTION,
    payload: { schoolId, schoolSection }
  }
}

const deleteSchoolSectionByIndex = (schoolId, sectionIndex) => {
  return {
    type: REMOVE_SCHOOL_SECTION_BY_INDEX,
    payload: { schoolId, sectionIndex }
  }
}

const deleteSchoolSectionById = (schoolId, sectionId) => {
  return {
    type: REMOVE_SCHOOL_SECTION_BY_ID,
    payload: { schoolId, sectionId }
  }
}

export {
  addSchoolSection,
  fetchSchoolSectionsBySchool,
  getSchoolSections,
  modifySchoolSection,
  removeSchoolSection,
  setNewSchoolSection,
  setSchoolSectionByIndex,
  setSchoolSectionsBySchool
}
