import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSchools } from '../../../reducers/SchoolsReducer'
import { getUser } from '../../../reducers/UserReducer'
import { getSupervisorStudents } from '../../../utils/api/user'
import { isNewSection } from '../../../utils/entitiesFormaters/SchoolSection'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING
} from '../../shared/SmartTable'
import StudentInfo from '../../shared/StudentInfo'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser),
    getSchools: getSchools(state.getSchools)
  }
}

const SupervisedStudents = ({ getUser, getSchools, t }) => {
  const [selectedStudentInfo, setSelectedStudentInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [userSupervisedStudents, setUserSupervisedStudents] = useState([])

  useEffect(() => {
    if (getUser) {
      getSupervisorStudents(getUser, getUser)
        .then(data => {
          setUserSupervisedStudents(data)
          setLoading(false)
        })
    }
  }, [getUser])

  const getSectionName = (sections) => {
    for (const section of sections) {
      if (section.school.id === getSchools[0].id) {
        return isNewSection(section)
          ? '[' + section.sectionToYear.schoolYearName + '] ' + section.sectionToYear.sectionName
          : section.name
      }
    }
  }

  const render = () => {
    let students = []

    if (userSupervisedStudents.length > 0) {
      students = userSupervisedStudents.map(s => {
        return {
          id: s.id,
          section: getSectionName(s.sections),
          lastname: s.lastname,
          firstname: s.firstname
        }
      })
    }

    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_STRING,
        name: t('Section'),
        key: 'section',
        disabled: true
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Lastname'),
        key: 'lastname',
        disabled: true
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Firstname'),
        key: 'firstname',
        disabled: true
      }
    ]

    const additionalActions = [
      {
        iconName: 'user-graduate',
        type: 'primary',
        title: t('Student sheet'),
        onClick: s => setSelectedStudentInfo(s)
      }
    ]

    return (
      <div>
        <SmartTable
          columns={columns}
          data={students}
          loading={loading}
          noDataText={t('You do not have any student.')}
          additionalActions={additionalActions}
        />
        <StudentInfo
          student={selectedStudentInfo}
          onClose={() => setSelectedStudentInfo(null)}
        />
      </div>
    )
  }

  return (
    render()
  )
}
export default connect(mapStateToProps)(SupervisedStudents)
