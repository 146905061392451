import React, { useEffect, useRef, useState } from 'react'

import { getInstitutionSectors, getAllInstitutions } from '../../utils/api/institution'
import LazyCheckbox from '../antd/Checkbox/LazyCheckbox'
import LazySelect from '../antd/Selects/LazySelect'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Button, List } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'

import '../../assets/multi-sector-select.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MultiSectorSelect = ({ user, onCancel, onOk, t }) => {
  const [selectedSectors, setSelectedSectors] = useState([])
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [institutionSectors, setInstitutionSectors] = useState([])
  const [loading, setLoading] = useState(false)

  const timer = useRef(null)

  useEffect(() => {
    if (selectedInstitution) {
      fetchSectors(selectedInstitution, user)
    } else {
      setInstitutionSectors([])
    }
  }, [selectedInstitution, user])

  const fetchSectors = (institution, user, parameters = { archived: false }) => {
    setLoading(true)

    getInstitutionSectors(user, institution, { ...parameters, contexts: ['institution'] }).then(json => {
      if (json?.data) {
        setInstitutionSectors(json.data)
      }

      setLoading(false)
    })
  }

  const removeSector = sector => {
    const newSectors = selectedSectors.filter(ss => ss.id !== sector.id)

    setSelectedSectors(newSectors)
  }

  return (
    <div className='supervised-sectors-add-container'>
      <div className='supervised-sectors-body'>
        <div className='select-data-container'>
          <div className='institution-data-container'>
            <b> {t('Institution:')} </b>
            <LazySelect
              dataKey='name'
              getData={getAllInstitutions}
              placeholder={t('Select an institution')}
              placement='top'
              style={{ width: '100%' }}
              value={selectedInstitution}
              onSelect={i => {
                setSelectedInstitution(i)
                setInstitutionSectors([])
              }}
              showSearch
            />
          </div>
          {selectedInstitution && (
            <div className='sectors-data-container'>
              <b> {t('Care units:')} </b>
              <LazyCheckbox
                checked={selectedSectors}
                data={institutionSectors}
                dataKey='name'
                loading={loading}
                onChange={setSelectedSectors}
                onParametersChange={parameters => {
                  if (timer.current) {
                    clearTimeout(timer.current)
                  }

                  timer.current = setTimeout(() => {
                    fetchSectors(selectedInstitution, user, parameters)
                  }, 500)
                }}
                showSearch
              />
            </div>
          )}
        </div>
        <div className='selected-data-container'>
          <b> {t('Selected care units:')} </b>
          <div className='selected-data-list'>
            <List size='small'>
              {selectedSectors.map(ss => {
                return (
                  <List.Item key={'list-item_' + ss.id}>
                    <div className='list-item-value'>
                      <span>
                        <b> {ss.institution.name} </b> - {ss.name}
                      </span>
                      <Button onClick={() => removeSector(ss)}>
                        <div className='svg-div'>
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      </Button>
                    </div>
                  </List.Item>
                )
              })}
            </List>
          </div>
        </div>
      </div>
      <div className='supervised-sectors-footer'>
        <Button disabled={selectedSectors.length === 0} onClick={() => onOk(selectedSectors)} style={selectedSectors.length > 0 ? { color: 'rgb(139, 195, 74)' } : {}}>
          <FontAwesomeIcon icon={faSave} />
          {t('Save')}
        </Button>
        <Button onClick={onCancel}> {t('Cancel')} </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(MultiSectorSelect)
