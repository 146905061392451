import { requestWithPromise } from '..'
import { GET, POST } from '../apiHelper'

export const getChangelog = user => {
  return requestWithPromise(
    '/user-notification/changelog',
    GET,
    null,
    user
  )
}

export const consultAllChangelogs = user => {
  return requestWithPromise(
    '/user-notification/consult/all',
    POST,
    {},
    user
  )
}
