import { requestWithPromise } from '../../utils'
import { SECTORS } from './actionsType'

const { SET_SECTORS, SET_FILES_COUNT } = SECTORS

const getSectors = state => {
  return state.sectors.list
}

const getFilesCount = state => {
  return state.sectors.filesCount
}

const fetchSectorsByInstitution = (institutionId, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/institutions/${institutionId}/sectors`,
      'GET',
      null,
      user
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch(setSectors(jsonResponse.data))
      }
    })
  }
}

const fetchSectorsFilesCount = (institutionId, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(
      `/api/institutions/${institutionId}/sectors/documents`,
      'GET',
      null,
      user
    ).then(json => {
      if (json && json.data) {
        dispatch({
          type: SET_FILES_COUNT,
          payload: json.data
        })
      }
    })
  }
}

const setSectors = (sectors) => {
  return {
    type: SET_SECTORS,
    payload: sectors
  }
}

export {
  getSectors,
  getFilesCount,
  fetchSectorsByInstitution,
  fetchSectorsFilesCount,
  setSectors
}
