import { INSTITUTION_OPTION_TYPES } from './actionsType'

const {
  SET_INSTITUTION_OPTION_TYPES
} = INSTITUTION_OPTION_TYPES

const INITIAL_STATE = {
  optionTypes: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INSTITUTION_OPTION_TYPES: {
      const newState = { ...state }
      const optionTypes = {};

      for (const optionType of action.payload) {
        optionTypes[optionType.id] = optionType.type
      }
      
      newState.optionTypes = optionTypes

      return newState
    }
    default:
      return state
  }
}
