import { INSTITUTION_CONSTRAINTS } from './actionsType'

const {
  ADD_INSTITUTION_CONSTRAINT, DELETE_INSTITUTION_CONSTRAINT, SET_INSTITUTION_CONSTRAINTS,
  SET_INSTITUTION_CONSTRAINTS_LOADING, UPDATE_INSTITUTION_CONSTRAINT
} = INSTITUTION_CONSTRAINTS

const INITIAL_STATE = {
  loading: true,
  constraints: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_INSTITUTION_CONSTRAINT: {
      const newState = { ...state }

      newState.constraints.push(action.payload)

      return newState
    }
    case DELETE_INSTITUTION_CONSTRAINT: {
      const newState = { ...state }

      newState.constraints = newState.constraints.filter(ic => ic.id !== action.payload)

      return newState
    }
    case UPDATE_INSTITUTION_CONSTRAINT: {
      const newState = { ...state }
      const updateInstitutionConstraint = action.payload

      newState.constraints = newState.constraints.map(ic => {
        if (ic.id === updateInstitutionConstraint.id) {
          return updateInstitutionConstraint
        }

        return ic
      })

      return newState
    }
    case SET_INSTITUTION_CONSTRAINTS: {
      const newState = { ...state }

      newState.constraints = action.payload

      return newState
    }
    case SET_INSTITUTION_CONSTRAINTS_LOADING: {
      const newState = { ...state }

      newState.loading = action.payload

      return newState
    }
    default : {
      return state
    }
  }
}
