import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import React, { useContext } from 'react'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'
import { getIconByName } from '../../../../../utils/fontAwesomeHelper'
import { GlobalContext } from '../../../../../Providers/GlobalProvider'

const PausesSubCell = props => {
  const { pauses } = useContext(GlobalContext)
  const details = props.details

  const render = () => {
    const body = []

    if (props.dataIds) {
      props.dataIds.forEach(pauseId => {
        const pause = pauses.find(pause => pause.id === pauseId)

        if (pause) {
          const { id, icon } = pause

          body.push(
            <Row key={`row-section-${id}`}>
              <Col span={18}>
                <FontAwesomeIcon icon={getIconByName(icon)} />
              </Col>
              <Col span={6}>
                {details[id] ?? 0}
              </Col>
            </Row>
          )
        }
      })
    }

    const firstColSpan = body.length === 0 ? 24 : 8

    return (
      <Row>
        <Col span={firstColSpan}>
          <div className='sections-sub-cell-total'>
            {props.total}
          </div>
        </Col>
        <Col span={24 - firstColSpan}>
          <div className='section-sub-cell-details'>
            {body}
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <div className='sections-sub-cell'>
      {render()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PausesSubCell)
