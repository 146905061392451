import React, { useMemo, useState } from 'react'
import { getDetails, getFullSectionToYearAbbreviation } from '../../../utils/entities/studentPathway'
import DynamicList from '../../antd/Lists/DynamicList'
import { GlobalDeleteAction } from '../DataTable/utils/actions'
import ActionsRow from '../../antd/Lists/ActionsRow'

const StudentPathwaysList = ({ bordered = true, data, maxHeight, onDelete }) => {
  const columns = useMemo(() => [
    { title: 'list.title.section_to_year', getter: getFullSectionToYearAbbreviation },
    { title: 'list.title.details', getter: getDetails, tooltip: getDetails }
  ], [])
  const [selectedPathways, setSelectedPathways] = useState([])

  const actions = useMemo(() => [
    new GlobalDeleteAction({
      onDelete: () => {
        onDelete(selectedPathways)
        setSelectedPathways([])
      },
      disabled: selectedPathways.length === 0
    })
  ], [selectedPathways, setSelectedPathways])

  return (
    <div className='relative h-100'>
      <DynamicList bordered={bordered} checked={selectedPathways} columns={columns} data={data} maxHeight={maxHeight} onCheckedChange={setSelectedPathways} />
      {selectedPathways.length > 0 && (
        <ActionsRow actions={actions} />
      )}
    </div>
  )
}

export default StudentPathwaysList
