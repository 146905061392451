import React, { useMemo, useState } from 'react'

import { Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import moment from 'moment'
import { connect } from 'react-redux'

const { Option } = Select

const CURRENT_YEAR = moment().year()
const DATES = Array.from({ length: 31 }, (v, i) => i + 1)
const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
const YEARS = Array.from({ length: 100 }, (v, i) => CURRENT_YEAR - i)

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const BirthdatePicker = ({ value, t, onChange }) => {
  const [dateParts, setDateParts] = useState(value ? value.split('/').map(s => Number(s)) : [null, null, null])

  const handleChange = (value, index) => {
    const newDateParts = dateParts.map((part, i) => i === index ? value : part)

    setDateParts(newDateParts)

    if (newDateParts.every(p => p)) {
      onChange(moment(newDateParts.join('-'), 'DD-MM-YYYY'))
    }
  }

  const dateOptions = useMemo(() => DATES.map(i => <Option key={i} value={i}> {i} </Option>), [])
  const monthOptions = useMemo(() => MONTHS.map((m, index) => <Option key={m} value={index + 1}> {t(m)} </Option>), [t])
  const yearOptions = useMemo(() => YEARS.map(y => <Option key={y} value={y}> {y}</Option>), [])

  return (
    <div className='flex-row'>
      <Select style={{ width: '60px', marginLeft: '4px' }} value={dateParts[0]} onChange={value => handleChange(value, 0)}>{dateOptions}</Select>
      <Select style={{ width: '130px', marginLeft: '4px' }} value={dateParts[1]} onChange={value => handleChange(value, 1)}>{monthOptions}</Select>
      <Select style={{ width: '80px', marginLeft: '4px' }} value={dateParts[2]} onChange={value => handleChange(value, 2)}>{yearOptions}</Select>
    </div>
  )
}

export default connect(mapStateToProps)(BirthdatePicker)
