import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  getTranslate
} from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getUser
} from '../../reducers/UserReducer'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const EyeBan = props => {
  const [value, setValue] = useState('')
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    setHidden(props.hidden)
  }, [props.hidden])

  useEffect(() => {
    displayValue(props.value, hidden)
  }, [hidden, props.value])

  const displayValue = (newValue, h) => {
    if (h) {
      let str = ''

      for (let i = 0; i < newValue.length; i++) {
        str += '*'
      }

      setValue(str)

      return
    }

    setValue(newValue)
  }

  return (
    <>
      <span style={{ paddingRight: '15px' }}>{value}</span>

      <FontAwesomeIcon
        icon={hidden ? faEyeSlash : faEye}
        style={{ cursor: 'pointer' }}
        onClick={() => { setHidden(!hidden) }}
      />
    </>
  )
}

export default connect(mapStateToProps)(EyeBan)
