import React from 'react'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'

const TitleCell = props => {
  const render = () => {
    const data = props.data
    const selector = props.selector

    if (data && selector) {
      return (
        <div>
          {data[selector]}
        </div>
      )
    }

    return ''
  }

  return (
    <td>
      {render()}
    </td>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleCell)
