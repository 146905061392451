import React, { useContext, useEffect, useState } from 'react'

import { Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { consultAllChangelogs } from '../../utils/api/changelog'
import { connect } from 'react-redux'
import { onSuccess } from '../../utils/apiHelper'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ChangeLogNotification = ({ user, t }) => {
  const { unSeenChangelogs, setUnseenChangelogs } = useContext(GlobalContext)

  const [notification, setNotification] = useState(null)

  useEffect(() => {
    if (unSeenChangelogs.length > 0) {
      setNotification(unSeenChangelogs[0].message)
    }
  }, [unSeenChangelogs])

  const dismissNotification = () => {
    setUnseenChangelogs([])
    setNotification(null)
    consultAllChangelogs(user).then(json => {
      if (json?.data === 'success') {
        onSuccess(t('changelogs_notification.success'))
      }
    })
  }

  return (
    <Modal
      closable={false}
      title={t('New features available!')}
      visible={notification !== null}
      wrapClassName='user-notification-modal'
      footer={[
        <Button onClick={dismissNotification} key='save' type='primary'>
          <FontAwesomeIcon icon='check' />&nbsp;{t('OK')}
        </Button>
      ]}
    >
      {notification &&
        <div dangerouslySetInnerHTML={{ __html: notification }} />}
    </Modal>
  )
}

export default connect(mapStateToProps)(ChangeLogNotification)
