import React from 'react'

import { Skeleton } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

class LoadingSkeleton extends React.Component {
  render () {
    return (
      <Skeleton active />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingSkeleton)
