import { fetch } from '..'
import { DELETE, PATCH, POST } from '../apiHelper'

export const createStudentFreeField = (user, studentFreeField) => {
  return fetch(
    '/api/free-fields',
    POST,
    studentFreeField,
    user
  )
}

export const updateStudentFreeField = (user, studentFreeField) => {
  return fetch(
    `/api/free-fields/${studentFreeField.id}`,
    PATCH,
    studentFreeField,
    user
  )
}

export const deleteStudentFreeFields = (user, studentFreeField) => {
  return fetch(
    `/api/studentFreeFields/${studentFreeField.id}`,
    DELETE,
    null,
    user
  )
}
