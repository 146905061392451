import React from 'react'

import StandardTableLine from './StandardTableLine'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'

import './assets/standard-table.scss'

const StandardTableBody = props => {
  const renderBody = () => {
    const body = []

    if (props.newData) {
      body.push(
        <StandardTableLine
          key='row-new-data'
          data={props.newData}
          columns={props.columns}
          onSave={props.onSave}
          onDelete={props.onDelete}
        />
      )
    }

    if (props.data && props.columns) {
      props.data.forEach((d, index) => {
        body.push(
          <StandardTableLine
            key={`row-${index}`}
            data={d}
            columns={props.columns}
            onSave={props.onSave}
            onDelete={props.onDelete}
          />
        )
      })
    }

    return body
  }

  return (
    <tbody className='standard-table-body'>
      {renderBody()}
    </tbody>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardTableBody)
