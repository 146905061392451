import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import SmartTable, {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_SELECT,
  DATA_TYPE_DATE
} from '../../Components/shared/SmartTable'
import UserCoordinatedSections from './UserCoordinatedSections'
import UserManagedSectors from './UserManagedSectors'
import UserValidatorInstitutionSector from './UserValidatorInstitutionSector'
import moment from 'moment'
import remove from 'lodash/remove'
import { faKey, faSitemap } from '@fortawesome/free-solid-svg-icons'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { validateFormInput, generalErrorHandler, requestWithPromise } from '../../utils'
import AdminResetPassword from '../shared/AdminResetPassword'
import { ROLE_COORDINATOR, ROLE_ENCODER, ROLE_SUPERVISOR, ROLE_VALIDATOR } from '../../utils/constants'
import UserSupervisedStudents from './UserSupervisedStudents'
import { getCountSupervisorStudents, restoreUserByEmail } from '../../utils/api/user'
import { DELETED_USER, DUPLICATED_USER, onError, onSuccess, POST } from '../../utils/apiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HTTP_CONFLICT } from '../../utils/http'

const Users = props => {
  const [selectedUserCoordinatedSection, setSelectedUserCoordinatedSection] = useState(null)
  const [selectedUserSupervisedStudent, setSelectedUserSupervisedStudent] = useState(null)
  const [countSupervisedStudents, setCountSupervisedStudents] = useState([])
  const [selectedUserManagedSector, setSelectedUserManagedSector] = useState(null)
  const [selectedUserForPasswordReset, setSelectedUserForPasswordReset] = useState(null)
  const [selectedValidatorUser, setSelectedValidatorUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [institutions, setInstitutions] = useState(null)
  const [userIsCoordinator, setUserIsCoordinator] = useState({})
  const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false)
  const [languages, setLanguages] = useState({})
  const [deletedModal, setDeletedModal] = useState({ user: null, body: null, footer: false, title: '' })
  const [revivalLoading, setRevivalLoading] = useState(false)

  useEffect(() => {
    if (props.getInstitutions) {
      setInstitutions(props.getInstitutions.map(item => ({ id: item.id, name: item.name })))
    }

    if (props.getUser.roles) {
      setUserIsCoordinator(props.getUser.roles.includes(ROLE_COORDINATOR))
    }

    if (props.getUser.context) {
      setUserIsSuperAdmin(props.getUser.context === 'ADMIN')
    }

    getCountSupervisorStudents(props.getUser)
      .then(data => {
        setCountSupervisedStudents(data)
      })

    if (props.getLanguages) {
      setLanguages(
        props.getLanguages.map(({ code, name }) => ({
          id: code,
          name: props.t(name)
        }))
      )
    }

    if (typeof props.getUser !== 'undefined') {
      updateRoles(props.getUser)
    }
  }, [props.getUser])

  // this return sa school when the Component is used by a super admin
  const schoolById = () => {
    let school = null
    const { schoolId, getSchools } = props
    if (schoolId) {
      // also check cache
      if (this._previouslySelectedSchool && this._previouslySelectedSchool.id === schoolId) {
        return this._previouslySelectedSchool
      }
      school = getSchools.find(item => item.id === schoolId)
      this._previouslySelectedSchool = school
    }
    return school
  }

  const canResetPassword = () => {
    return ['ROLE_ENCODER', 'ROLE_ADMIN'].includes(props.getUser.roles[0])
  }

  const updateRoles = (currentUser) => {
    if (currentUser.roles.includes(ROLE_COORDINATOR)) {
      setRoles(
        !currentUser.school.managed
          ? []
          : [
            { id: 4, name: props.t('Validator'), apiName: ROLE_VALIDATOR },
            { id: 3, name: props.t('Supervisor'), apiName: ROLE_SUPERVISOR }

          ]
      )
    } else if (!currentUser.school.managed) {
      setRoles(
        [
          { id: 1, name: props.t('Coordinator'), apiName: ROLE_COORDINATOR },
          { id: 2, name: props.t('Administrator'), apiName: ROLE_ENCODER },
          { id: 3, name: props.t('Supervisor'), apiName: ROLE_SUPERVISOR }
        ]
      )
    } else {
      setRoles(
        [
          { id: 1, name: props.t('Coordinator'), apiName: ROLE_COORDINATOR },
          { id: 2, name: props.t('Administrator'), apiName: ROLE_ENCODER },
          { id: 3, name: props.t('Supervisor'), apiName: ROLE_SUPERVISOR },
          { id: 4, name: props.t('Validator'), apiName: ROLE_VALIDATOR }
        ]
      )
    }
  }

  const handleAddUser = (user) => {
    return new Promise(() => {
      const rawRoleId = user.roles
      user.roles = [roles.filter(r => r.id === user.roles)[0].apiName]
      if (userIsSuperAdmin && props.schoolId) {
        user.school = props.schoolId
      }

      if (userIsCoordinator && user.sector) {
        user.affiliatedSectors = [user.sector]
      }

      user.userType = 'school'
      requestWithPromise('/user/create', POST, user, props.getUser, true, true).then(json => {
        const newUser = json.data
        let school = props.getSchools[0]

        if (props.schoolId) {
          school = schoolById()
        }

        const users = school.users
        users.unshift(newUser)
        school.users = users
        if (props.schoolId) {
          props.updateSchool(school)
        } else {
          props.setSchools([school])
        }
      }).catch(error => {
        if (error.status === HTTP_CONFLICT) {
          error.body.getReader().read().then(({ value }) => {
            const decoded = new TextDecoder('utf-8').decode(value)
            const json = JSON.parse(decoded)

            if (json.message === DELETED_USER) {
              setDeletedModal({
                footer: true,
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {props.t('revive.deleted.user', { email: user.username })}
                  </p>
                ),
                title: props.t('Restore an user ?'),
                user: { ...user, roles: rawRoleId }
              })
            } else if (json.message === DUPLICATED_USER) {
              setDeletedModal({
                footer: false,
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {props.t('There is already an account linked to this email address')}
                  </p>
                ),
                title: props.t('Invalid email/username'),
                user: { ...user, roles: rawRoleId }
              })
            }
          })
        }
      })
    })
  }

  const handleEditUser = (user, stopTableLoading, savingValidator = false, sectors = []) => {
    if (roles.length > 0) {
      user.roles = [roles.filter(r => r.id === user.roles)[0].apiName]

      if (savingValidator) {
        const body = {
          userToAdd: user.id,
          sectors: sectors
        }

        requestWithPromise('/sectors/contact-persons', 'POST', body, props.getUser)
      }

      if ((userIsCoordinator || savingValidator) && user.sector) {
        user.affiliatedSectors = [user.sector]
      } else if (user.roles && user.roles[0] !== 'ROLE_COORDINATOR') {
        user.coordinatedSections = []
      }
      if (userIsSuperAdmin && props.schoolId) {
        user.schoolId = props.schoolId
      }
      requestWithPromise('/user/modify/' + user.id, 'POST', user, props.getUser)
        .then(json => {
          if (json.status && json.status === 'error') {
            stopTableLoading()
            generalErrorHandler(json.message)
            return
          }
          let school = props.getSchools[0]
          if (props.schoolId) {
            school = schoolById()
          }

          let users = school.users
          users = users.map(u => {
            if (u.id === user.id) u = json.data
            return u
          })
          school.users = users
          if (props.schoolId) {
            props.updateSchool(school)
          } else {
            props.setSchools([school])
          }
          if (savingValidator) {
          // this calls the method to close the validator drawer
            stopTableLoading()
          }
        })
        .catch(error => {
          generalErrorHandler(error)
        })
    }
  }

  const handleRemoveUser = user => {
    if (typeof user !== 'undefined') {
      if (userIsSuperAdmin && props.schoolId) {
        requestWithPromise('/api/User/' + user.id, 'DELETE', null, props.getUser)
          .then(json => {
            const school = schoolById()
            remove(school.users, item => item.id === user.id)
            props.updateSchool(school)
          })
          .catch(error => {
            generalErrorHandler(error)
          })
      } else {
        props.removeSchoolUser({ userId: user.id, currentUser: props.getUser })
      }
    }
  }

  /**
   * This returns an array of sectors when an institution is selected
   * It happens only when the user is coordinator and tries to create ROLE_VALIDATION users
   */
  const handleInstitutionSelect = selectedInstitutionId => {
    const selectedInstitution = props.getInstitutions.find(item => item.id === selectedInstitutionId)
    if (selectedInstitution) {
      return new Promise((resolve) => {
        resolve(selectedInstitution.sectors.map(item => ({ id: item.id, name: item.name })))
      })
    } else {
      return new Promise(resolve => { resolve([]) })
    }
  }

  const handleReviveUser = () => {
    setRevivalLoading(true)
    restoreUserByEmail(deletedModal.user, props.getUser)
      .then(json => {
        const newUser = json.data
        let school = props.getSchools[0]

        if (props.schoolId) {
          school = schoolById()
        }

        const users = school.users
        users.unshift(newUser)
        school.users = users

        if (props.schoolId) {
          props.updateSchool(school)
        } else {
          props.setSchools([school])
        }

        onSuccess(props.t('The user was successfully revived'))
      }).catch(error => {
        onError(error.code)
      })

    handleReviveCancel()
  }

  const handleReviveCancel = () => {
    setDeletedModal({ ...deletedModal, user: null })
    setRevivalLoading(false)
  }

  const render = () => {
    let data = []
    if (props.getSchools.length > 0 && roles.length > 0) {
      let users = []
      if (props.schoolId) {
        const selectedSchool = schoolById
        if (selectedSchool) {
          users = selectedSchool.users
        }
      } else {
        users = props.getSchools[0].users
      }

      if (userIsCoordinator) {
        // show only users with ROLE_VALIDATOR and ROLE_SUPERVISOR for coordinators
        users = users.filter(item => item.roles.length === 1 && [ROLE_SUPERVISOR, ROLE_VALIDATOR].includes(item.roles[0]))
      }
      data = users.map(u => {
        let userRoleId = null
        if (u.roles && u.roles.length) {
          const foundRole = roles.find(r => r.apiName === u.roles[0])
          if (foundRole) {
            userRoleId = foundRole.id
          }
        }

        const userItem = {
          id: u.id,
          email: u.email,
          username: u.username,
          lastname: u.lastname,
          firstname: u.firstname,
          language: u.language,
          roles: userRoleId,
          lastLogin:
            u.lastLogin === null
              ? null
              : moment(u.lastLogin.date).format('DD/MM/YYYY')
        }

        if (userIsCoordinator) {
          if (u.institutions.length) {
            /**
             * after creation/update we get back objects in institutions & supervisedSectors arrays
             * When we load the page, the main get returns just the corresponding id in these arrays, not an object
             */
            if (u.institutions[0].id) {
              userItem.institution = u.institutions[0].id
            } else {
              userItem.institution = u.institutions[0]
            }
          } else {
            userItem.institution = -1
          }

          if (u.supervisedSectors.length) {
            if (u.supervisedSectors[0].id) {
              userItem.sector = u.supervisedSectors[0].id
            } else {
              userItem.sector = u.supervisedSectors[0]
            }
          } else {
            userItem.sector = -1
          }

          // set the sector additional props: disabled and options
          userItem.sectorDisabled = userItem.institution === -1
          userItem.sectorOptions = []
          if (userItem.institution > -1) {
            // set the options
            const selectedInstitution = props.getInstitutions.find(item => item.id === userItem.institution)
            if (selectedInstitution) {
              userItem.sectorOptions = selectedInstitution.sectors.map(item => ({ id: item.id, name: item.name }))
            }
          }
        }

        return userItem
      })
    }

    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Email'),
        key: 'email',
        validate: data => validateFormInput('email', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Username'),
        key: 'username',
        validate: data => validateFormInput('email', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Lastname'),
        key: 'lastname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: props.t('Firstname'),
        key: 'firstname',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('Language'),
        key: 'language',
        options: languages,
        preventAutoSelectDefaultValue: true,
        validate: data => {
          return data !== null
        }
      },
      {
        type: DATA_TYPE_SELECT,
        name: props.t('Role'),
        key: 'roles',
        options: userIsCoordinator ? roles : roles.filter(item => item.notAllowedInSelect !== true)
      }
    ]

    if (userIsCoordinator) {
      columns.push({
        type: DATA_TYPE_SELECT,
        name: props.t('Institution'),
        key: 'institution',
        options: institutions,
        parentOf: 'sector',
        loadChildData: handleInstitutionSelect,
        preventAutoSelectDefaultValue: true, // for selects, if this is true we won't autoselect the first option (for new rows only!)
        validate: data => {
          return validateFormInput('positiveInteger', data, true)
        }
      })

      columns.push({
        type: DATA_TYPE_SELECT,
        name: props.t('Sector'),
        key: 'sector',
        options: [],
        validate: data => {
          return validateFormInput('positiveInteger', data, true)
        }
      })
    }

    columns.push({
      type: DATA_TYPE_DATE,
      name: props.t('Last login'),
      key: 'lastLogin',
      format: 'DD/MM/YYYY',
      disabled: true
    })

    const additionalActions = [
      {
        iconName: faSitemap,
        type: 'primary',
        title: props.t('Manage students supervised by this user'),
        onClick: u => setSelectedUserSupervisedStudent(u),
        disabledCallback: u => u.roles !== 3, // Sections edition is disabled for other users than supervisor.
        customClassCallback: u => { return countSupervisedStudents[u.id] > 0 ? 'green-button' : '' }
      },
      {
        iconName: 'graduation-cap',
        type: 'primary',
        title: props.t('Manage sections coordinated by this user'),
        onClick: u => setSelectedUserCoordinatedSection(u),
        disabledCallback: u => u.roles !== 1 && u.roles !== 2 // Sections edition is disabled for other users than coordinator and encoder.
      },
      {
        iconName: 'stethoscope',
        type: 'primary',
        title: props.t('Manage care units supervised by this user'),
        onClick: setSelectedUserManagedSector,
        disabledCallback: u => u.roles !== 3 // Sector edition is disabled for other users than supervisor.
      }
    ]

    if (!userIsCoordinator) {
      additionalActions.push({
        iconName: 'clinic-medical',
        type: 'primary',
        title: props.t('Set institution and care unit validated by this user'),
        onClick: u => setSelectedValidatorUser(u),
        disabledCallback: u => u.roles !== 4
      })
    }

    if (canResetPassword) {
      // add the button to reset the password
      additionalActions.push({
        iconName: faKey,
        type: 'primary',
        title: props.t('Reset password'),
        onClick: u => setSelectedUserForPasswordReset(u)
      })
    }

    return (
      <div>
        <SmartTable
          columns={columns}
          data={data}
          loading={!props.getDataReady.institutions}
          onDataEdit={handleEditUser}
          onDataAdd={handleAddUser}
          onDataDelete={props.enableDelete ? handleRemoveUser : undefined}
          addDataText={props.t('Add user')}
          noDataText={props.t('No user')}
          additionalActions={additionalActions}
        />
        <UserSupervisedStudents
          user={selectedUserSupervisedStudent}
          onClose={() => {
            setSelectedUserSupervisedStudent(null)

            getCountSupervisorStudents(props.getUser)
              .then(data => {
                setCountSupervisedStudents(data)
              })
          }}
          schoolId={props.schoolId}
        />
        <UserCoordinatedSections
          user={selectedUserCoordinatedSection}
          onClose={() => setSelectedUserCoordinatedSection(null)}
          schoolId={props.schoolId}
        />
        <UserManagedSectors
          user={selectedUserManagedSector}
          onClose={() => setSelectedUserManagedSector(null)}
          schoolId={props.schoolId}
        />
        <UserValidatorInstitutionSector
          user={selectedValidatorUser}
          onClose={() => setSelectedValidatorUser(null)}
          schoolId={props.schoolId}
          onSave={handleEditUser}
        />
        {canResetPassword &&
          <AdminResetPassword
            userToResetPassword={selectedUserForPasswordReset}
            onClose={() => setSelectedUserForPasswordReset(null)}
          />}
        <Modal
          title={deletedModal.title}
          visible={deletedModal.user !== null}
          onOk={handleReviveUser}
          onCancel={handleReviveCancel}
          footer={deletedModal.footer
            ? (
              <div>
                <Button onClick={handleReviveCancel}> {props.t('Cancel')} </Button>
                <Button onClick={handleReviveUser} type='primary' loading={revivalLoading}>
                  {props.t('Restore')}
                </Button>
              </div>
            )
            : null}
        >
          {deletedModal.body}
        </Modal>
      </div>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
