
import React, { useState, useEffect, useRef, useContext } from 'react'

import PresetsTooltip from '../ShiftTableParts/Tooltips/PresetsTooltip'
import { internshipStates } from '../../../../utils/constants'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import EventCodesTooltip from '../ShiftTableParts/Tooltips/EventCodesTooltip'
import { EVENT_CODE_ERROR_MESSAGE } from './ShiftCell'
import { ShiftManagerContext } from '../../../../Providers/ShiftManagerProvider'
import { getLuminanceFromColorCode } from '../../../../utils/colors'

const ERROR_COLOR = '#d32f2f'
const DEFAULT_CODE_STYLE = {
  borderRadius: '5px',
  height: '37%',
  minHeight: '20.5px',
  background: 'white',
  borderBottom: '1px dashed lightgrey',
  paddingTop: '2px',
  color: '#000000A6'
}
const DEFAULT_EVENT_STYLE = {
  borderRadius: '0',
  borderBottom: '1px dashed lightgrey'
}
const CELL_STATES = {
  DISABLE: 0,
  IN_MULTI_SELECTION_MODE: 1,
  EVENT_EDITABLE_ONLY: 2,
  FULLY_EDITABLE: 3
}
const { DISABLE, EVENT_EDITABLE_ONLY, FULLY_EDITABLE, IN_MULTI_SELECTION_MODE } = CELL_STATES

const FilledShiftCell = (props) => {
  const [shift, setShift] = useState({})
  const [state, setState] = useState(DISABLE)

  const smContext = useContext(ShiftManagerContext)

  const periodRef = useRef()
  const eventRef = useRef()

  useEffect(() => {
    if (props.shift) {
      setShift({
        ...props.shift,
        id: props.shift.id ?? -1,
        periodCode: props.shift.periodCode ?? '',
        eventCode: props.shift.eventCodeType && props.shift.eventCodeType.type ? props.t('event.code.' + props.shift.eventCodeType.type) : ''
      })
    }
  }, [props.shift])

  useEffect(() => {
    if (props.shift.isDesiderata || (props.multiSelectionMode && props.eventCodeOnly) || !props.contentEditable || props.inWidgetMode) {
      setState(DISABLE)
    } else if (props.multiSelectionMode) {
      setState(IN_MULTI_SELECTION_MODE)
    } else if (props.eventCodeOnly) {
      setState(EVENT_EDITABLE_ONLY)
    } else {
      setState(FULLY_EDITABLE)
    }
  }, [props.shift, props.contentEditable, props.eventCodeOnly, props.multiSelectionMode])

  const renderCodePart = (isTabIndex, isVisible) => {
    return (
      <div
        ref={periodRef}
        key={`shift-${props.internship.id}-${shift.id ?? props.index}`}
        data-dateindex={props.index}
        data-internshipid={props.internship.id}
        data-shiftid={shift.id}
        data-columnindex={props.columnIndex}
        data-rowindex={props.index}
        data-originalperiodcode={shift.periodCode}
        data-originaleventcode={shift.eventCode ? shift.eventCode : ''}
        data-originalstarttime={props.startTime}
        data-originalendtime={props.endTime}
        tabIndex={isTabIndex ? 0 : -1}
        style={isVisible ? { ...DEFAULT_CODE_STYLE, fontSize: smContext?.fontSizes.content ?? '16px' } : { fontSize: smContext?.fontSizes.content ?? '16px' }}
        data-sectorid={props.internship.sector}
        data-institutionid={props.internship.institution}
        contentEditable={state === FULLY_EDITABLE}
        suppressContentEditableWarning
        onBlur={e => {
          if (props.contentEditable && ![DISABLE, IN_MULTI_SELECTION_MODE].includes(state)) {
            props.onSave(e)
          }
        }}
      >
        {shift.periodCode}
      </div>
    )
  }

  const renderTimePart = (isVisible) => {
    return (
      <div className='time-range' style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <span
          style={{ fontSize: smContext?.fontSizes.time ?? '8px' }}
          data-dateindex={props.index}
          data-internshipid={props.internship.id}
          data-shiftid={shift.id}
          data-columnindex={props.columnIndex}
          data-originalperiodcode={shift.periodCode}
          data-originaltime={props.startTime}
          data-originalendtime={props.endTime}
          data-originaleventcode={shift.eventCode ? shift.eventCode : ''}
          tabIndex={-1}
          contentEditable={state === FULLY_EDITABLE}
          suppressContentEditableWarning
          onBlur={e => {
            if (state === FULLY_EDITABLE && state !== IN_MULTI_SELECTION_MODE) {
              props.onTimeSave(e)
            }
          }}
          onKeyUp={e => {
            if (state === FULLY_EDITABLE && state !== IN_MULTI_SELECTION_MODE) {
              props.onTimeChange(e, 'start')
            }
          }}
        >
          {props.startTime}
        </span>
        <span style={{ fontSize: smContext?.fontSizes.time ?? '8px' }}>-</span>
        <span
          style={{ fontSize: smContext?.fontSizes.time ?? '8px' }}
          data-dateindex={props.index}
          data-internshipid={props.internship.id}
          data-shiftid={shift.shiftId}
          data-columnindex={props.columnIndex}
          data-originalperiodcode={shift.periodCode}
          data-originaltime={props.endTime}
          data-originalstarttime={props.startTime}
          data-originaleventcode={shift.eventCode ? shift.eventCode : ''}
          contentEditable={state === FULLY_EDITABLE}
          tabIndex={-1}
          suppressContentEditableWarning
          onBlur={e => {
            if (state === FULLY_EDITABLE && state !== IN_MULTI_SELECTION_MODE) {
              props.onTimeSave(e, true)
            }
          }}
          onKeyUp={e => {
            if (state === FULLY_EDITABLE && state !== IN_MULTI_SELECTION_MODE) {
              props.onTimeChange(e, 'end')
            }
          }}
        >
          {props.endTime}
        </span>
      </div>
    )
  }

  const renderEventCodePart = (isTabIndex, displayStyle) => {
    return (
      <div
        ref={eventRef}
        className='shift-event-code-input'
        tabIndex={isTabIndex ? 0 : -1}
        data-dateindex={props.index}
        data-internshipid={props.internship.id}
        data-shiftid={shift.id}
        data-columnindex={props.columnIndex}
        data-rowindex={props.index}
        data-originalperiodcode={shift.periodCode}
        data-originaleventcode={shift.eventCode ? shift.eventCode : ''}
        data-originalendtime={props.endTime}
        data-originalstarttime={props.startTime}
        style={displayStyle ? { ...DEFAULT_EVENT_STYLE, fontSize: smContext?.fontSizes.content ?? '16px' } : { fontSize: smContext?.fontSizes.content ?? '16px' }}
        data-sectorid={props.internship.sector}
        data-institutionid={props.internship.institution}
        contentEditable={![DISABLE, IN_MULTI_SELECTION_MODE].includes(state)}
        suppressContentEditableWarning
        onBlur={(e) => {
          if (props.internship.state !== internshipStates.SCHEDULE_VALIDATED && ![DISABLE, IN_MULTI_SELECTION_MODE].includes(state)) {
            props.onSaveEventCode(e)
          }
        }}
      >
        {shift.eventCode ? shift.eventCode : ''}
      </div>
    )
  }

  const renderBody = () => {
    const canOnlyViewEventCode = !shift.periodCode && ![FULLY_EDITABLE, IN_MULTI_SELECTION_MODE].includes(state)

    if (shift.id > 0) {
      return (
        <div>
          {props.multiSelectionMode && (
            <div className='shift-selection-mode-cover' />
          )}
          {props.errorMessage && (getTooltipByState())}
          <div className='flex-fill' />
          {renderCodePart(state !== EVENT_EDITABLE_ONLY, !canOnlyViewEventCode)}
          <div style={{ height: '5px' }} />
          {(!canOnlyViewEventCode || isTimeDisplayed()) ? renderTimePart(props.endTime && props.startTime) : (<div className='v-spacing' />)}
          {renderEventCodePart(state === EVENT_EDITABLE_ONLY, true)}
        </div>
      )
    }

    return (
      <div
        className='flex-column'
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            props.setDisplayEvent(false)
          }
        }}
        onFocus={e => {
          props.setDisplayEvent(true)
        }}
      >
        {props.errorMessage && (getTooltipByState())}
        <div className='flex-fill' />
        {props.contentEditable && renderCodePart(state !== EVENT_EDITABLE_ONLY, (props.displayEvent && !canOnlyViewEventCode) || (props.errorMessage && ![EVENT_EDITABLE_ONLY, DISABLE].includes(state)))}
        {!canOnlyViewEventCode && renderTimePart(props.endTime && props.startTime)}
        <div className='v-spacing' />
        {(props.displayEvent || canOnlyViewEventCode || props.errorMessage) &&
          renderEventCodePart(state === EVENT_EDITABLE_ONLY, props.displayEvent || props.errorMessage)}
      </div>
    )
  }

  const getTooltipByState = () => {
    if (state === EVENT_EDITABLE_ONLY || state === DISABLE || props.errorMessage === EVENT_CODE_ERROR_MESSAGE) {
      return (
        <EventCodesTooltip
          title={props.errorMessage}
          placement='top'
          icon='exclamation-circle'
        />
      )
    }

    return (
      <PresetsTooltip
        title={props.errorMessage}
        presets={props.internship.presets}
        placement='top'
        icon='exclamation-circle'
      />
    )
  }

  const isTimeDisplayed = () => {
    return (
      props.startTime &&
      props.endTime &&
      props.startTime !== props.endTime
    )
  }

  const getErrorClass = () => {
    if (state === EVENT_EDITABLE_ONLY || state === DISABLE) {
      return ' shift-error-event-only'
    }

    return ' shift-error'
  }

  let backgroundColor = props.color

  if (shift.isDesiderata) {
    backgroundColor = 'transparent'
  }

  let textColor = getLuminanceFromColorCode(props.color)

  if (textColor === 'black') {
    textColor = '#000000A6'
  }

  return (
    <div
      style={{
        border: shift.isDesiderata ? `3px solid ${props.color}` : '',
        background: props.errorMessage ? ERROR_COLOR : backgroundColor,
        color: textColor
      }}
      className={'flex-column shift-cell' + (props.errorMessage ? getErrorClass() : '')}
      onClick={e => {
        if (props.multiSelectionMode && state !== DISABLE) {
          props.multiSelectionHandler()
        }

        if (shift.id < 0 && !props.displayEvent && ![DISABLE, IN_MULTI_SELECTION_MODE].includes(state)) {
          props.eventCodeOnly
            ? eventRef.current.focus()
            : periodRef.current.focus()
        }

        if (shift.isDesiderata) {
          props.onDesiderataSelect(shift)
        }
      }}
    >
      {renderBody()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FilledShiftCell)
