import React, { useEffect, useState } from 'react'

import { buildModalColumns, buildStandardPeriodDates } from '../utils/DataBuilders'
import { computeQuotaPartialOverlappingWeight, getMonthFirstDay, getWeekFirstDay, isOverlapping } from '../utils/DateTools'
import { computeTotalWithWeight } from '../utils/ComputationTools'
import moment from 'moment'
import { Modal, Table } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const PrecisionModal = ({ date, footer, period, quotas, title, visible, onClose, t }) => {
  const [dataPerRow, setDataPerRow] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    let columns = []

    if (period) {
      columns = buildModalColumns(period.type)

      columns.forEach(column => {
        column.title = t(column.title)
      })
    }

    setColumns(columns)
  }, [period, t])

  useEffect(() => {
    if (date && columns.length > 0 && quotas) {
      setDataPerRow(buildModalData(quotas, columns, date))
    }
  }, [date, columns, quotas])

  const buildModalData = (quotas, columns, date) => {
    const rowsData = []
    const { period, rowNumbers } = columns[0]
    const currentDate = period === 'week'
      ? getWeekFirstDay(getMonthFirstDay(date.clone()))
      : date.clone()

    for (let i = 0; i < rowNumbers; i++) {
      rowsData.push(buildRow(quotas, currentDate, period, `row-${i}`))
    }

    return rowsData
  }

  const buildRow = (quotas, date, period, key) => {
    const columnData = buildStandardPeriodDates(date, period, t)
    const periodStartDate = columnData.startDate
    const periodEndDate = columnData.endDate
    const rowData = {
      key,
      total: 0,
      label: columnData.title
    }

    quotas.forEach(quota => {
      if (!quota.section) {
        const startDate = moment(quota.startDate)
        const endDate = moment(quota.endDate)

        if (isOverlapping(startDate, endDate, periodStartDate, periodEndDate)) {
          rowData.total += computeTotalWithWeight(quota.total, computeQuotaPartialOverlappingWeight(startDate, endDate, periodStartDate, periodEndDate))
        }
      }
    })

    return rowData
  }

  return (
    <Modal
      title={title}
      onCancel={onClose}
      visible={visible}
      footer={footer}
      centered
    >
      {dataPerRow.length > 0 && (
        <Table dataSource={dataPerRow} columns={columns} pagination={false} />
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(PrecisionModal)
