import { isNumber } from 'lodash'
import moment from 'moment'
import { request, requestWithPromise } from '../../utils'
import { arrayToUrlParameters } from '../../utils/apiHelper'
import { updateShiftCountByIndex, updateShiftCountByIndexAndPresets } from '../ShiftsManagerReducer/actions'
import { NOT_IN_INSTITUTION_TYPES } from '../../utils/entities/eventCodeType'

const INITIAL_STATE = {}

const REMOVE_SHIFT_BY_INTERNSHIP_ID = 'REMOVE_SHIFT_BY_INTERNSHIP_ID'
const SET_INTERNSHIP_SHIFTS = 'SET_INTERNSHIP_SHIFTS'
const SET_NEW_SHIFT = 'SET_NEW_SHIFT'
const SET_MULTIPLE_INTERNSHIPS_SHIFTS = 'SET_MULTIPLE_INTERNSHIPS_SHIFTS'
const UPDATE_SHIFT = 'UPDATE_SHIFT'
const ACCEPT_DESIDERATA = 'ACCEPT_DESIDERATA'
const SYNCHRONISE_SHIFTS_BY_SHIFTS = 'SYNCHRONISE_SHIFTS_BY_SHIFTS'
const REMOVE_INTERNSHIPS_SHIFTS = 'REMOVE_INTERNSHIPS_SHIFTS'

const getShifts = (state) => {
  return state
}

const fetchInternshipShifts = (internshipId, user) => {
  return async function fetchInternshipShiftsThunk (dispatch, getState) {
    const { data } = await request(
      '/api/internship/' + internshipId + '/shifts',
      'GET',
      null,
      user
    )

    if (data) {
      dispatch(setInternshipShifts(internshipId, data[internshipId] ?? []))
    }
  }
}

const fetchMultipleInternshipShifts = (internshipIds, user) => {
  return async function fetchMultipleInternshipShiftsThunk (dispatch, getState) {
    const { data } = await request(
      '/api/shifts?' + arrayToUrlParameters(internshipIds, 'ids'),
      'GET',
      null,
      user
    )

    if (data) {
      dispatch(setMultipleInternshipsShifts(data))
    }
  }
}

const addShiftFromPreset = (body, user, index, preset = null) => {
  return async function addShiftFromPresetThunk (dispatch, getState) {
    requestWithPromise(
      '/shift/create-from-preset',
      'POST',
      body,
      user
    ).then(JsonResponse => {
      if (JsonResponse) {
        const shiftData = JsonResponse.data.shift
        const newShift = {
          id: shiftData.id,
          internship: shiftData.internship,
          endDate: shiftData.endDate,
          startDate: shiftData.startDate,
          endTime: shiftData.endTime,
          startTime: shiftData.startTime,
          periodCode: shiftData.periodCode,
          pause: shiftData.pause,
          eventCodeType: shiftData.eventCodeType,
          exactLocation: shiftData.exactLocation
        }

        dispatch(setNewShift(newShift, shiftData.internship))

        if (preset) {
          dispatch(updateShiftCountByIndexAndPresets(index, preset))
        } else if (isNumber(index)) {
          dispatch(updateShiftCountByIndex(index, 1))
        }
      }
    })
  }
}

const updateShiftFromPreset = (body, user, index = 0, preset = null, previousPreset = null, oldShift = null) => {
  return async function addShiftFromPresetThunk (dispatch, getState) {
    requestWithPromise(
      '/shift/update-from-preset',
      'POST',
      body,
      user
    ).then(json => {
      if (json?.data) {
        const newShift = {
          id: json.data.id,
          internship: json.data.internship,
          endDate: json.data.endDate,
          startDate: json.data.startDate,
          endTime: json.data.endTime,
          startTime: json.data.startTime,
          periodCode: json.data.periodCode,
          pause: json.data.pause,
          eventCodeType: json.data.eventCodeType,
          exactLocation: json.data.exactLocation
        }

        dispatch(updateShift(newShift, json.data.internship))

        const presetHasChanged = preset !== previousPreset || (preset !== null && previousPreset !== null && preset.periodCode !== previousPreset.periodCode)

        if (oldShift) {
          const notInInstitutionOld = oldShift.eventCodeType !== null && NOT_IN_INSTITUTION_TYPES.includes(oldShift.eventCodeType.type)
          const notInInstitutionNew = json.data.eventCodeType !== null && NOT_IN_INSTITUTION_TYPES.includes(json.data.eventCodeType.type)

          if (!notInInstitutionNew && notInInstitutionOld) {
            if (preset) {
              dispatch(updateShiftCountByIndexAndPresets(index, preset, null))
            } else {
              dispatch(updateShiftCountByIndex(index, +1))
            }
          } else if (notInInstitutionNew && !notInInstitutionOld) {
            if (previousPreset) {
              dispatch(updateShiftCountByIndexAndPresets(index, null, previousPreset))
            } else {
              dispatch(updateShiftCountByIndex(index, -1))
            }
          } else if (presetHasChanged && !notInInstitutionNew) {
            dispatch(updateShiftCountByIndexAndPresets(index, preset, previousPreset))
          }
        } else if (presetHasChanged) {
          dispatch(updateShiftCountByIndexAndPresets(index, preset, previousPreset))
        }
      }
    })
  }
}

const acceptDesiderataByUser = (shift, user) => {
  return async function acceptDesiderataThunk (dispatch, getState) {
    requestWithPromise(
      '/api/shift/accept-desiderata/' + shift.id,
      'PATCH',
      {},
      user
    ).then(jsonResponse => {
      if (jsonResponse) {
        const newShift = {
          id: jsonResponse.data.id,
          internship: jsonResponse.data.internship,
          endDate: jsonResponse.data.endDate,
          startDate: jsonResponse.data.startDate,
          endTime: jsonResponse.data.endTime,
          startTime: jsonResponse.data.startTime,
          periodCode: jsonResponse.data.periodCode,
          pause: jsonResponse.data.pause,
          eventCodeType: jsonResponse.data.eventCodeType,
          exactLocation: jsonResponse.data.exactLocation
        }
        dispatch(acceptDesiderata(newShift))
      }
    })
  }
}

const removeShift = (shift, internshipId, user, index = 0, previousPreset) => {
  return async function removeShiftThunk (dispatch, getState) {
    requestWithPromise(
      '/api/Shift/' + shift.id,
      'DELETE',
      null,
      user
    ).then(JsonResponse => {
      if (JsonResponse) {
        dispatch(removeShiftByInternshipId(shift, internshipId))
        if (index >= 0) {
          const notInInstitution = shift.eventCodeType !== null && NOT_IN_INSTITUTION_TYPES.includes(shift.eventCodeType.type)

          if (previousPreset && !notInInstitution) {
            dispatch(updateShiftCountByIndexAndPresets(index, null, previousPreset))
          } else if (!notInInstitution) {
            dispatch(updateShiftCountByIndex(index, -1))
          }
        }
      }
    })
  }
}

const setNewShift = (shift, internshipId) => {
  return {
    type: SET_NEW_SHIFT,
    payload: { shift, internshipId }
  }
}

const updateShift = (shift, internshipId) => {
  return {
    type: UPDATE_SHIFT,
    payload: { shift, internshipId }
  }
}

const acceptDesiderata = (shift) => {
  return {
    type: ACCEPT_DESIDERATA,
    payload: { shift }
  }
}

const setInternshipShifts = (internshipId, shifts) => {
  return {
    type: SET_INTERNSHIP_SHIFTS,
    payload: {
      internshipId: internshipId,
      shifts: shifts
    }
  }
}

const setMultipleInternshipsShifts = shiftsByInternshipsIds => {
  return {
    type: SET_MULTIPLE_INTERNSHIPS_SHIFTS,
    payload: shiftsByInternshipsIds
  }
}

const synchroniseStoreShiftsByShifts = shifts => {
  return {
    type: SYNCHRONISE_SHIFTS_BY_SHIFTS,
    payload: shifts
  }
}

const removeShiftByInternshipId = (shift, internshipId) => {
  return {
    type: REMOVE_SHIFT_BY_INTERNSHIP_ID,
    payload: {
      id: shift.id,
      internshipId
    }
  }
}

export const removeInternshipsShifts = internshipsIds => {
  return {
    type: REMOVE_INTERNSHIPS_SHIFTS,
    payload: { ids: internshipsIds }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REMOVE_SHIFT_BY_INTERNSHIP_ID: {
      const newShifts = { ...state }

      newShifts[action.payload.internshipId] = newShifts[action.payload.internshipId].filter(shift => {
        return shift.id !== action.payload.id
      })

      return newShifts
    }
    case REMOVE_INTERNSHIPS_SHIFTS : {
      const shifts = { ...state }

      action.payload.ids.forEach(id => {
        delete shifts[id]
      })

      return shifts
    }
    case SET_INTERNSHIP_SHIFTS: {
      const shifts = { ...state }

      shifts[action.payload.internshipId] = formatBackendShifts(action.payload.shifts)

      return shifts
    }
    case SET_NEW_SHIFT: {
      const newShifts = { ...state }
      const { internshipId } = action.payload

      newShifts[internshipId] = handleInternshipShiftsModification(action.payload.shift, newShifts[internshipId])

      return newShifts
    }
    case SET_MULTIPLE_INTERNSHIPS_SHIFTS: {
      const shifts = { ...state }

      Object.keys(action.payload).forEach(internshipId => {
        shifts[internshipId] = formatBackendShifts(action.payload[internshipId])
      })

      return shifts
    }
    case SYNCHRONISE_SHIFTS_BY_SHIFTS: {
      const shifts = { ...state }
      const payloadShifts = action.payload

      payloadShifts.forEach(shift => {
        shifts[shift.internship] = handleInternshipShiftsModification(shift, shifts[shift.internship])
      })

      return shifts
    }
    case UPDATE_SHIFT : {
      const newShifts = { ...state }
      const { internshipId } = action.payload

      newShifts[internshipId] = handleInternshipShiftsModification(
        action.payload.shift,
        newShifts[internshipId]
      )

      return newShifts
    }
    case ACCEPT_DESIDERATA: {
      const newShifts = { ...state }
      const { shift } = action.payload

      newShifts[shift.internship] = handleInternshipShiftsModification(shift, newShifts[shift.internship])

      return newShifts
    }
    default:
      return state
  }
}

const handleInternshipShiftsModification = (newShift, shifts) => {
  const normalizedShift = normalizeShiftDates(newShift)

  if (!shifts) {
    return [normalizedShift]
  }

  if (newShift.id === -1) {
    return setShiftByDate(normalizedShift, shifts)
  }

  return setShiftById(normalizedShift, shifts)
}

const formatBackendShifts = backendShifts => {
  return backendShifts.map(shift => {
    return normalizeShiftDates(shift)
  })
}

const normalizeShiftDates = shift => {
  return {
    ...shift,
    startDate: shift.startDate.split('+')[0],
    endDate: shift.endDate.split('+')[0]
  }
}

const setShiftByDate = (newShift, shifts) => {
  const startDate = moment(newShift.startDate)
  const endDate = startDate.clone().add(1, 'days')

  shifts = shifts.filter(shift => {
    const shiftStartDate = moment(shift.startDate)

    return shiftStartDate < startDate || shiftStartDate >= endDate
  })

  shifts.push(newShift)

  return shifts
}

const setShiftById = (newShift, shifts) => {
  shifts = shifts.filter(shift => shift.id !== newShift.id)

  shifts.push(newShift)

  return shifts
}

export {
  getShifts,
  fetchInternshipShifts,
  fetchMultipleInternshipShifts,
  addShiftFromPreset,
  updateShiftFromPreset,
  acceptDesiderataByUser,
  removeShift,
  setInternshipShifts,
  setMultipleInternshipsShifts,
  synchroniseStoreShiftsByShifts
}
