import { useCallback, useEffect, useMemo, useState } from 'react'
import { Metadata, retrieveMetadata } from '../utils/http'
import { isArray } from 'lodash'

const useRequest = ({ request, parameters = [], defaultData = [], defaultMetadata = new Metadata() }) => {
  const [data, setData] = useState(defaultData)
  const [metadata, setMetadata] = useState(defaultMetadata)
  const [loading, setLoading] = useState(true)

  const isValid = useMemo(() => {
    let isValid = true

    parameters.forEach(p => {
      if (p === null) {
        isValid = false
      }
    })

    return isValid
  }, [parameters])

  const fetch = useCallback((request, parameters) => {
    if (isValid) {
      setLoading(true)

      request(...parameters).then(json => {
        if (json?.data) {
          setData(json.data)
        }

        if (json?.meta) {
          setMetadata(retrieveMetadata(json.meta))
        }

        setLoading(false)
      })
    }
  }, [isValid, setLoading, setMetadata, setData])

  const reload = useCallback(() => {
    fetch(request, parameters)
  }, [request, parameters, fetch])

  useEffect(() => {
    if (typeof request === 'function' && isArray(parameters)) {
      fetch(request, parameters)
    }
  }, [request, parameters])

  return { data, metadata, loading, reload, setData }
}

export default useRequest
