import moment from 'moment-timezone'
import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryString } from '../apiHelper'
import { LANGUAGE_TAG_FR } from '../constants'

export const GENDERS = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'Other'
}

export const getAttracivityModuleInstitutionsStatesByStudent = (user, student) => {
  return requestWithPromise(
        `/student/${student.id}/institutions/attractivity-module`,
        GET,
        null,
        user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const getStudentInternships = (user, student, parameters = {}) => {
  return requestWithPromise(
    `/api/students/${student.id}/internships${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getStudentById = (user, student) => {
  return requestWithPromise(
    `/api/students/${student.id}`,
    GET,
    null,
    user
  )
}

export const getStudentExtraInformations = (user, student, parameters = {}) => {
  return requestWithPromise(
    `/api/students/${student.id}/extra-contact-informations${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const createStudentExtraInformations = (student, user, studentExtraInformation) => {
  return requestWithPromise(
    `/api/students/${student.id}/extra-contact-informations`,
    POST,
    studentExtraInformation.toRequestBody(),
    user
  )
}

export const updateStudentExtraInformations = (student, user, studentExtraInformation) => {
  return requestWithPromise(
    `/api/students/${student.id}/extra-contact-informations/${studentExtraInformation.id}`,
    PATCH,
    studentExtraInformation.toRequestBody(),
    user
  )
}

export const deleteStudentExtraInformations = (student, user, studentExtraInformation) => {
  return requestWithPromise(
    `/api/students/${student.id}/extra-contact-informations/${studentExtraInformation.id}`,
    DELETE,
    null,
    user
  )
}

export const getStudentOptins = (student, user, parameters = {}) => {
  return requestWithPromise(
    `/api/students/${student.id}/optins${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getAllStudents = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/students${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getStudentBadges = (student, user) => {
  return requestWithPromise(
    `/api/students/${student.id}/badges`,
    GET,
    null,
    user
  )
}

export const getStudentFreeFields = (student, user, parameters = {}) => {
  return requestWithPromise(
    `/api/students/${student.id}/free-fields${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getStudentSchoolOptions = (user, student) => {
  return requestWithPromise(
    `/student/${student.id}/school-options`,
    GET,
    null,
    user
  )
}

export const exportStudentInternshipsHistory = (user, student, body = { language: LANGUAGE_TAG_FR, timezone: moment.tz.guess() }) => {
  return requestWithPromise(
    `/api/students/${student.id}/export-internships-history`,
    POST,
    body,
    user
  )
}

export const updateNiss = (user, student, niss) => {
  return requestWithPromise(
    `/api/student/update-niss/${student.id}`,
    PATCH,
    { niss },
    user
  )
}

export const getStudentEvaluations = (user, student) => {
  return requestWithPromise(
    `/evaluation/student/${student.id}`,
    GET,
    null,
    user
  )
}
