import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { getUser } from '../../../reducers/UserReducer'
import { getDateTitle } from './utils/componentGetters'
import { DAY_AND_MONTH_FORMAT } from './utils/constants'
import moment from 'moment'

const TH_STUDENT_TILE_STYLE = { width: '550px' }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipsTableHead = ({ user, t }) => {
  const { addMode, dateColumns } = useContext(InternshipsManagerContext)
  const DateTitle = getDateTitle(user.context)

  const renderTitleDate = () => {
    const currentDate = moment()
    currentDate.set('hour', 0)
    currentDate.set('minute', 0)
    currentDate.set('second', 0)

    return dateColumns.map((dates, index) => {
      return (
        <DateTitle
          key={dates.startDate.format(DAY_AND_MONTH_FORMAT)}
          startDate={dates.startDate}
          endDate={dates.endDate}
          displayAction={addMode}
          index={index}
          isCurrent={dates.startDate <= currentDate && dates.endDate >= currentDate}
        />
      )
    })
  }

  return (
    <thead>
      <tr>
        <th style={TH_STUDENT_TILE_STYLE}>
          {t('Schools')} - {t('Students')}<br />
          {t('Sections')} - {t('Internship year')} - {t('Care units')}
        </th>
        {DateTitle !== null && renderTitleDate()}
      </tr>
    </thead>
  )
}

export default connect(mapStateToProps)(InternshipsTableHead)
