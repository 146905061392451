export const AGGREGATION_STRING = ' - '

const dataAggregationFactory = (dataArray, aggregationString) => {
  return dataArray.length
    ? dataArray.map((element, index) => {
      return index ? aggregationString + element : element
    })
    : ''
}

/**
   * @param {object} parameters each key have to be an url parameter name and
   *                            the value of the key have to be the value of the parameter
   */
const makeUrlParameters = (object, parametersNames = []) => {
  const parametersArray = objectToStringArray(object, '=', parametersNames)

  return '?' + parametersArray.join('&')
}

const objectToStringArray = (object, keyValueSeparator, selectedKeys = []) => {
  const stringArray = []

  if (selectedKeys.length > 0) {
    selectedKeys.forEach(key => {
      if (object[key]) {
        stringArray.push(key + '=' + object[key])
      }
    })

    return stringArray
  }

  Object.keys(object).forEach(key => {
    if (object[key]) {
      stringArray.push(key + '=' + object[key])
    }
  })

  return stringArray
}

const slugify = (stringToSlugify) => {
  if (typeof stringToSlugify !== 'string') {
    return stringToSlugify
  }

  stringToSlugify = stringToSlugify.toLowerCase()

  return stringToSlugify.replace(/['\s]/g, '_')
}

export {
  dataAggregationFactory,
  makeUrlParameters,
  objectToStringArray,
  slugify
}
