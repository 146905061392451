import React, {
  createContext, useEffect, useState
} from 'react'
import { getAppStates } from '../../reducers/AppStates/actions'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import { getDataReady } from '../../reducers/DataReadyReducer'
import { getTaggedEntities } from '../../reducers/TagsReducer'
import { getTranslate } from 'react-localize-redux'
import { bindActionCreators } from 'redux'
import { setInternshipForChat } from '../../reducers/NewMessagesReducer'
import { getColumnsDates } from '../../Components/shared/InternshipsManager/utils/utils'
import StudentInfo from '../../Components/shared/StudentInfo'
import InternshipInfo from '../../Components/shared/InternshipInfo'

const InternshipsManagerContext = createContext()

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser),
  currentDate: getAppStates(state).date,
  getDataReady: getDataReady(state.getDataReady),
  getTaggedEntities: getTaggedEntities(state.tags)
})

const mapDispatchToProps = dispatch => ({ setChat: bindActionCreators(setInternshipForChat, dispatch) })

let InternshipsManagerProvider = ({
  addMode, children, quotas, t, user, currentDate, getDataReady, onEarlyStartDate, onStudentSelected, onInternshipSelected, onInternshipDelete,
  refreshInternships, refreshQuotas, setChat, setInternshipToValidate, internships, weeksShownCount
}) => {
  const [dateColumns, setDateColumns] = useState([])
  const [highlightedIndexes, setHighlightedIndexes] = useState(null)
  const [student, setStudent] = useState(null)
  const [internship, setInternship] = useState(null)

  useEffect(() => setDateColumns(getColumnsDates(currentDate, weeksShownCount)), [weeksShownCount, currentDate])

  return (
    <>
      <InternshipsManagerContext.Provider value={{
        addMode,
        dateColumns,
        currentDate,
        internships,
        quotas,
        getDataReady,
        highlightedIndexes,
        user,
        onEarlyStartDate,
        onInternshipSelected,
        onInternshipDelete,
        onLineHover: setHighlightedIndexes,
        onTableLeave: () => setHighlightedIndexes(null),
        onStudentSelected,
        refreshInternships,
        refreshQuotas,
        setChat,
        setInternshipToValidate,
        t,
        setStudent,
        setInternship
      }}
      >
        {children}
      </InternshipsManagerContext.Provider>
      <StudentInfo student={student} onClose={() => setStudent(null)} />
      <InternshipInfo internship={internship} onClose={() => setInternship(null)} />
    </>
  )
}

InternshipsManagerProvider = connect(mapStateToProps, mapDispatchToProps)(InternshipsManagerProvider)

export {
  InternshipsManagerProvider as default,
  InternshipsManagerContext
}
