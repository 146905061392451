import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SmileyButton = ({ className, disabled, icon, onClick }) => {
  return (
    <button
      disabled={disabled}
      type='button'
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

export default SmileyButton
