import React, { useCallback, useContext, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import { ACTIONS } from '../Components/shared/DataTable/utils/actions'
import ActCategoriesTable from '../Views/school/SchoolSectionsView/ActsTableView/ActCategoriesTable'
import { SchoolContext } from '../Providers/SchoolProvider'
import { TableParameters } from '../utils/entities/tableParameters'
import { ORDER_BY } from '../utils/constants'
import { SORT_DIRECTION_ASC } from '../Components/shared/InternshipsManager/utils/constants'
import { stringComparator } from '../utils/sorting'
import { getUser } from '../reducers/UserReducer'
import Cookies from 'js-cookie'
import ActTypeCategoryFormModal from './Forms/ActTypeCategoryFormModal'
import { deleteActTypeCategory } from '../utils/api/actTypeCategory'
import { onSuccess } from '../utils/apiHelper'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ActCategoriesModal = ({ children, user, onTriggerAction, onSave, t }) => {
  const sContext = useContext(SchoolContext)

  const [visible, setVisible] = useState(false)
  const [categoriesParameters, setCategoriesParameters] = useState(new TableParameters({ orderBy: ORDER_BY.NAME }))

  const sortHandler = useCallback((a, b) => {
    return categoriesParameters.orderDirection === SORT_DIRECTION_ASC ? stringComparator(a.name, b.name) : 0 - stringComparator(a.name, b.name)
  }, [categoriesParameters.orderDirection])

  const actTypeCategories = useMemo(() => {
    let actTypeCategories = sContext.actTypeCategories.toSorted(sortHandler)

    if (categoriesParameters.search) {
      actTypeCategories = actTypeCategories.filter(atc => atc.name && atc.name.toLowerCase().includes(categoriesParameters.search.toLowerCase()))
    }

    return actTypeCategories
  }, [sContext.actTypeCategories, categoriesParameters.search, sortHandler])
  const tableMaxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '276px' : '235px'})`, [Cookies.get('switch_user_id')])
  const modalMaxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '200px' : '159px'})`, [Cookies.get('switch_user_id')])

  const handleSave = useCallback((actTypeCategory, action) => {
    if (action === ACTIONS.CREATE) {
      sContext.setActTypeCategories([...sContext.actTypeCategories, actTypeCategory].toSorted(sortHandler))
    } else if (action === ACTIONS.EDIT) {
      sContext.setActTypeCategories(sContext.actTypeCategories.map(atc => atc.id === actTypeCategory.id ? actTypeCategory : atc).toSorted(sortHandler))
    }
  }, [sContext.setActTypeCategories, sContext.actTypeCategories, sortHandler])
  const handleCategoryRemove = useCallback(actTypeCategory => {
    deleteActTypeCategory(user, actTypeCategory).then(json => {
      if (json?.data) {
        sContext.setActTypeCategories(sContext.actTypeCategories.filter(atc => atc.id !== json.data.id))
        onSuccess(t('act_type_category.delete.success'))
      }
    })
  }, [user, sContext.setActTypeCategories, sContext.actTypeCategories, t])
  const handleActionTrigger = useCallback((entity, action) => {
    if (action === ACTIONS.DISPLAY_ACT_CATEGORIES) {
      setVisible(true)
    } else if (action === ACTIONS.DELETE_ACT_TYPE_CATEGORY) {
      handleCategoryRemove(entity, action)
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(entity, action)
    }
  }, [setVisible, onTriggerAction, handleCategoryRemove])

  return (
    <>
      {React.cloneElement(children, { onTriggerAction: handleActionTrigger })}
      <Modal
        visible={visible}
        bodyStyle={{ height: modalMaxHeight }}
        footer={null}
        title={t('act_categories_modal.title')}
        onCancel={() => setVisible(false)}
      >
        {visible && (
          <ActTypeCategoryFormModal onTriggerAction={handleActionTrigger} onSave={handleSave}>
            <ActCategoriesTable
              data={actTypeCategories}
              loading={sContext.schoolLoading}
              maxHeight={tableMaxHeight}
              parameters={categoriesParameters}
              school={user.school}
              onParametersChange={setCategoriesParameters}
            />
          </ActTypeCategoryFormModal>
        )}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(ActCategoriesModal)
