import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Select } from 'antd'
import { callFunctionIfDefined } from '../../utils/functionHelper'
import { DIRECTION_ASC, DIRECTION_DESC } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

import '../../assets/selector.scss'

const Option = Select.Option

const UNDEFINED_ON_CHANGE_ERROR_MESSAGE = 'Please define onChange property'
const UNDEFINED_ON_DIRECTION_ERROR_MESSAGE = 'Please define onDirectionChange property'

const OrderBySelector = ({ defaultValue, direction, options, value, onChange, onDirectionChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [localDirection, setLocalDirection] = useState(DIRECTION_DESC)

  useEffect(() => {
    if (direction) {
      setLocalDirection(direction)
    }
  }, [direction])

  const handleChange = useCallback(e => callFunctionIfDefined(onChange, [e], UNDEFINED_ON_CHANGE_ERROR_MESSAGE), [onChange])

  const handleOrderDirectionChange = useCallback(() => {
    const newDirection = localDirection === DIRECTION_DESC ? DIRECTION_ASC : DIRECTION_DESC

    setLocalDirection(newDirection)
    callFunctionIfDefined(onDirectionChange, [newDirection], UNDEFINED_ON_DIRECTION_ERROR_MESSAGE)
  }, [localDirection, setLocalDirection, onDirectionChange])

  const menuOptions = useMemo(() => {
    let renderedOptions = []

    if (options) {
      renderedOptions = options.map(option => (
        <Option key={option.key} value={option.value}>
          {option.text}
        </Option>
      ))
    }

    return renderedOptions
  }, [options])
  const styles = useMemo(() => {
    return {
      sortingDiv: { width: 220, zIndex: isOpen ? 9998 : 'initial' },
      selectorDiv: { width: isOpen ? 250 : 200, zIndex: 1 }
    }
  }, [isOpen])

  return (
    <div className='order-by-selector' style={styles.sortingDiv}>
      <Select
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onDropdownVisibleChange={setIsOpen}
        style={styles.selectorDiv}
      >
        {menuOptions}
      </Select>
      <div style={{ width: '5px' }} />
      <Button type='button' onClick={handleOrderDirectionChange}>
        <FontAwesomeIcon icon={direction === DIRECTION_DESC ? faArrowDown : faArrowUp} />
      </Button>
    </div>
  )
}

export default OrderBySelector
