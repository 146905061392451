import React, { useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from '../../../../../reducers/Dispatchers'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SchoolNote = ({ note, t }) => {
  const localNote = useMemo(() => note || t('No note'), [note, t])

  return (
    <>
      <div
        style={{
          color: 'white',
          fontSize: '12px',
          paddingLeft: '12px',
          paddingBottom: '10px',
          paddingTop: '5px',
          borderBottom: '1px solid #E8E8E8',
          marginBottom: '10px'
        }}
      >
        {t('Unit Rating:')}
      </div>

      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {localNote}
      </p>
    </>
  )
}

export default connect(mapStateToProps)(SchoolNote)
