import React, { useEffect, useState } from 'react'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import '../../../assets/changelog-management.scss'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING, DATA_TYPE_DATE } from '../../../Components/shared/SmartTable'
import ChangeLogTranslations from './ChangelogTranslations'
import { getChangelog } from '../../../utils/api/changelog'

const ChangelogManagementView = ({ getUser, removeChangelog, t }) => {
  const [changelog, setChangelog] = useState([])
  const [changelogDrawerData, setChangelogDrawerData] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    getChangelog(getUser).then(json => {
      if (json?.data) {
        setChangelog(json.data)
      }

      setLoading(false)
    })
  }, [getUser])

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    { type: DATA_TYPE_STRING, name: t('Name'), key: 'name' },
    {
      type: DATA_TYPE_DATE,
      name: t('Created at'),
      key: 'createdAt',
      format: 'DD/MM/YYYY @ HH:mm:ss',
      disabled: true
    }
  ]
  const showDrawer = (changelog) => {
    setChangelogDrawerData(changelog || true)
  }

  const hideDrawer = () => {
    setChangelogDrawerData(false)
  }

  const deleteRow = async (row) => {
    removeChangelog({ changelog: row, getUser: getUser })
  }

  const handleSave = (savedRow) => {
    hideDrawer()
  }

  return (
    <div className='changelog-management-wrapper'>
      <SmartTable
        columns={columns}
        data={changelog}
        loading={loading}
        noDataText={t('You do not have any change log items.')}
        onDataAdd={showDrawer}
        onDataDelete={deleteRow}
        addDataText={t('Add changelog')}
        addLineOnAddData={false}
        disableSorting
        additionalActions={[
          { iconName: 'edit', type: 'primary', title: t('Edit'), onClick: showDrawer }
        ]}
      />
      <ChangeLogTranslations
        changelog={changelogDrawerData}
        onClose={hideDrawer}
        onSave={handleSave}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangelogManagementView)
