import React from 'react'

import { notification } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

const DEFAULT_ERROR_MESSAGE = 'An unknown error has occured.'
const ERRORS = {
  400: 'Access denied.',
  401: 'Wrong credentials.',
  403: 'You do not have the right to access or edit this data.',
  404: 'This ressource cannot be found.',
  405: 'This is not allowed.',
  408: 'The server does not answer.',
  409: 'Conflict saving data.',
  410: 'Entity is deleted.',
  500: 'Internal Server Error'
}

class ApiErrorCatcher extends React.Component {
  state = {
    errors: [],
    defaultErrorMessage: DEFAULT_ERROR_MESSAGE
  }

  componentDidMount () {
    const props = this.props
    this.updateErrors(props)

    /**
     * @deprecated used "api-error-displayer" event instead
     */
    document.addEventListener('api-error', (apiError) => {
      let errorMessage = this.state.defaultErrorMessage
      const error = ERRORS[apiError.detail.code]

      if (error) {
        errorMessage = this.props.t(error)
      }

      const errorObject = {
        message: errorMessage,
        placement: 'bottomRight'
      }

      if (apiError.detail.errorDetails) {
        errorObject.description = this.props.t(apiError.detail.errorDetails)
      }

      notification.error(errorObject)
    }, false)

    document.addEventListener('api-message', (apiMessage) => {
      const messageObject = {
        message: this.props.t(apiMessage.detail.title),
        placement: 'bottomRight',
        description: this.props.t('Data not saved. ')
      }

      if (apiMessage.detail.message.forEach) {
        apiMessage.detail.message.forEach(messagePart => {
          messageObject.description += this.props.t(messagePart)
        })
      } else {
        messageObject.description = apiMessage.detail.message
      }

      notification.warning(messageObject)
    }, false)

    document.addEventListener('api-error-displayer', (apiError) => {
      const apiResponse = apiError.detail
      const messageObject = {
        message: apiResponse.status + ' : ' + this.props.t(apiResponse.statusText),
        placement: 'bottomRight',
        description: this.props.t(ERRORS[apiResponse.status])
      }

      notification.error(messageObject)
    }, false)
  }

  componentDidUpdate (nextProps) {
    if (this.props.t(DEFAULT_ERROR_MESSAGE) !== nextProps.t(DEFAULT_ERROR_MESSAGE)) {
      this.updateErrors(nextProps)
    }
  }

  updateErrors = (props) => {
    // Translations are get with an async load to server, and are missing when this component mounted.
    // This is why this function is used for : reload errors translations.

    const errors = [
      { code: 400, message: props.t('Access denied.') },
      { code: 401, message: props.t('Wrong credentials.') },
      { code: 403, message: props.t('You do not have the right to access or edit this data.') },
      { code: 404, message: props.t('This ressource cannot be found.') },
      { code: 405, message: props.t('This is not allowed.') },
      { code: 408, message: props.t('The server does not answer.') },
      { code: 409, message: props.t('Conflict saving data.') },
      { code: 410, message: props.t('Entity is deleted.') }
    ]

    this.setState({
      errors: errors,
      defaultErrorMessage: props.t(DEFAULT_ERROR_MESSAGE)
    })
  }

  render () { return null }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorCatcher)
