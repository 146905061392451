import React, { useState } from 'react'
import { Drawer, Button, Form, Input, Checkbox, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { validateFormInput, request, generalErrorHandler } from '../../../utils'
import '../../../assets/admin-reset-password.scss'

const AdminResetPassword = props => {
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkPassword = (rule, value, callback) => {
    if (!value || value.length === 0) {
      callback(props.t('The password must contain between 8 and 255 characters !'))
    } else if (value && value.length > 0 && confirmDirty) {
      props.form.validateFields(['passwordConfirm'], { force: true })
    }

    if (!validateFormInput('password', value)) {
      callback(
        props.t('The password must contain between 8 and 255 characters !')
      )
    } else {
      callback()
    }
  }

  const checkPasswordConfirm = (rule, value, callback) => {
    if (!value || value.length === 0) {
      callback(props.t('The password must contain between 8 and 255 characters !'))
    } else if (value && value !== props.form.getFieldValue('password')) {
      callback(props.t('The passwords do not match !'))
    } else {
      callback()
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoading(true)
          const dataToSend = { ...values }

          if (props.userToResetPassword.user !== undefined) {
            // we are changing the password for a student
            // check if the student already has an account
            if (props.userToResetPassword.user !== null && props.userToResetPassword.user.id) {
              dataToSend.userId = props.userToResetPassword.user.id
            } else {
              // we don't have an account for the student so we send a new property studentId to backend
              dataToSend.studentId = props.userToResetPassword.id
            }
          } else {
            // we are changing the password for a normal user
            dataToSend.userId = props.userToResetPassword.id
          }
          const { data } = await request('/user/reset-password', 'POST', dataToSend, props.getUser)
          setLoading(false)
          if (data.status === undefined) {
            handleDrawerClose()
            notification.success({
              message: props.t('Password update successful.'),
              placement: 'bottomRight'
            })
          }
        } catch (err) {
          generalErrorHandler(err)
        }
      }
    })
  }

  const handleDrawerClose = () => {
    if (typeof props.onClose === 'function') {
      props.form.resetFields()
      props.onClose()
    }
  }

  const FormItem = Form.Item
  const { getFieldDecorator } = props.form

  return (
    <Drawer
      title={props.t('Reset password')}
      width='30%'
      onClose={handleDrawerClose}
      visible={props.userToResetPassword !== null}
    >
      <div className='admin-reset-password-wrapper'>
        <div className='reset-password-description'>
          {props.t('Please enter new password for the user')}
        </div>
        <Form
          onSubmit={handleSubmit}
          className='login-form full-height flex-column'
        >
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ validator: checkPassword }]
            })(
              <Input
                prefix={<FontAwesomeIcon icon='lock' />}
                type='password'
                placeholder={props.t('Password')}
                onBlur={e => setConfirmDirty(confirmDirty || !!e.target.value)}
                autoComplete='off'
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('passwordConfirm', {
              rules: [{ validator: checkPasswordConfirm }]
            })(
              <Input
                prefix={<FontAwesomeIcon icon='lock' />}
                type='password'
                placeholder={props.t('Confirm password')}
                autoComplete='off'
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('shouldModifyPassword', {
              valuePropName: 'checked',
              initialValue: false
            })(
              <Checkbox>
                {props.t('Ask the user to change password on next connection')}
              </Checkbox>
            )}
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit' className='full-width' loading={loading}>
              <FontAwesomeIcon icon='sync' />&nbsp;
              {props.t('Reset password')}
            </Button>
          </FormItem>
        </Form>
      </div>
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AdminResetPassword))
