import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { DatePicker, Modal, Checkbox } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faTimes } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const WeekPicker = DatePicker.WeekPicker

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const InternshipsManagerActionsHeaderExportModal = (
  { filterSearch, filterStates, orderBy, orderDirection, startDate, visible, weeksShownCount, onCancel, onExport, t }
) => {
  const [start, setStart] = useState(moment().startOf('isoWeek'))
  const [end, setEnd] = useState(moment().add(6, 'months').endOf('isoWeek'))
  const [checked, setChecked] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    setStart(startDate)
    setEnd(startDate.clone().add(weeksShownCount - 1, 'weeks').endOf('isoWeek'))
    setFilters({ search: filterSearch, states: filterStates, orderBy, orderDirection })
  }, [startDate, filterSearch, filterStates, orderBy, orderDirection])

  const okText = useMemo(() => (<> <FontAwesomeIcon icon={faFileExport} /> &nbsp; {t('Export')} </>), [t])
  const cancelText = useMemo(() => (<> <FontAwesomeIcon icon={faTimes} />&nbsp;{t('Cancel')} </>), [t])

  return (
    <Modal
      destroyOnClose
      onCancel={onCancel}
      cancelText={cancelText}
      onOk={() => onExport(start, end, checked, filters)}
      okText={okText}
      title={t('Export timeline')}
      visible={visible}
    >
      <div className='flex-column'>
        {t('Start date')}
        <WeekPicker value={start} format='DD/MM/YYYY' onChange={setStart} />
        <div className='v-spacing' />
        {t('End date')}
        <WeekPicker value={end} format='DD/MM/YYYY' onChange={setEnd} />
        <div className='v-spacing' />
        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
          {t('Use current filters')}
        </Checkbox>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(InternshipsManagerActionsHeaderExportModal)
