import React, { useContext } from 'react'

import { Select } from 'antd'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import { toFirstUpperCase } from '../../../utils'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const Option = Select.Option

const StudentPropertiesSelect = props => {
  const { studentMetadata } = useContext(GlobalContext)

  const handleSelect = (value, option) => {
    props.onChange(value, props.dataKey)
  }

  const renderOptions = () => {
    const options = []

    if (props.getInstitutionConstraints.constraints) {
      const properties = studentMetadata.filter(sp => {
        return !props.getInstitutionConstraints.constraints.find(ic => ic.propertyName === sp)
      })

      properties.forEach((prop, index) => {
        const translatedProperty = toFirstUpperCase(props.t(prop))

        options.push(
          <Option
            key={`option-${index}`}
            value={prop}
            title={translatedProperty}
          >
            {translatedProperty}
          </Option>
        )
      })
    }

    return options
  }

  const filterOptions = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Select
      showSearch
      onSelect={handleSelect}
      disabled={props.disabled}
      value={props.data[props.dataKey] ? toFirstUpperCase(props.t(props.data[props.dataKey])) : '/'}
      filterOption={filterOptions}
    >
      {renderOptions()}
    </Select>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentPropertiesSelect)
