import React, { useContext, useEffect, useState } from 'react'

import { isArray } from 'lodash'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import AllocationTable from './AllocationTable'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { getColumnsByPeriod } from '../../../../utils/timePeriods'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const AllocationTablesContainer = ({ places, schools, sector, sections, selectedCell, user, onCellClick, onCellDoubleClick, t }) => {
  const { period } = useContext(QuotasContext)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns(getColumnsByPeriod(period))
  }, [period])

  const hasSections = isArray(sections) && sections.length > 0
  const hasSchools = isArray(schools) && schools.length > 0

  return (
    <div className='multi-tables-container'>
      {!hasSections && !hasSchools && (
        <div className='multi-tables-placeholder'>
          <span> {t('Please select a least a sector and a school to display quotas')} </span>
        </div>
      )}
      {hasSchools && (
        <AllocationTable
          columns={columns}
          places={places}
          selectedCell={selectedCell}
          schools={schools}
          section={null}
          sector={sector}
          onCellClick={onCellClick}
          onCellDoubleClick={onCellDoubleClick}
        />
      )}
      {hasSections && hasSchools && sections.map((s, index) => {
        return (
          <AllocationTable
            key={index}
            columns={columns}
            places={places}
            selectedCell={selectedCell}
            schools={schools}
            section={s}
            sector={sector}
            onCellClick={onCellClick}
            onCellDoubleClick={onCellDoubleClick}
          />
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps)(AllocationTablesContainer)
