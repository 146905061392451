import React, { useState, useEffect, useContext } from 'react'

import LinkActModal from './LinkActModal'
import { INSTITUTION_CONTEXT, internshipStates, ROLE_HOSPITAL_ADMIN, SCHOOL_CONTEXT } from '../../../utils/constants'
import { Popconfirm, Button, Dropdown, Menu, Badge } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isObject } from '../../../utils'
import { getTranslate } from 'react-localize-redux'
import { getInternshipsWithMessages } from '../../../reducers/NewMessagesReducer'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { faCheck, faEllipsisH, faExchangeAlt, faSyringe, faTrash, faUserGraduate, faCalendar, faComment, faCopy, faFile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/shared/student-badge-quick-menu.css'
import { SCHEDULE_VALIDATED } from '../../../utils/entities/internship'
import { SchoolContext } from '../../../Providers/SchoolProvider'
import { isObserver } from '../../../utils/roles'

const { PENDING, ACCEPTED, AUTO_ACCEPTED, CANCELED, SCHEDULE_ADDED, PRE_ACCEPTED } = internshipStates
const CAN_MODIFY_CARE_UNIT_STATES = [PENDING, ACCEPTED, AUTO_ACCEPTED, SCHEDULE_ADDED, PRE_ACCEPTED]
const MESSAGES_STATE = {
  NO_MESSAGES: 0,
  HAS_MESSAGES: 1,
  HAS_NEW_MESSAGES: 2
}

const mapStateToProps = state => {
  return {
    internshipsWithMessages: getInternshipsWithMessages(state.newMessages),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StudentQuickMenu = ({
  canValidateDesiderata, inShiftsManager, internshipsWithMessages, inWidgetMode, internship, isReadOnly, noMargin, user, onClickedDraft, onDeleteSchedule,
  onSelectedInternshipChat, onSelectedStudentInfo, onSelectedInternshipInfo, onDuplicateInternship, onInternshipDelete, onSelectedForSectorChange,
  onValidateDesideratas, t
}) => {
  const schoolContext = useContext(SchoolContext)

  const [removePopConfirmDisplayed, setIsDeletePopConfirmDisplayed] = useState(false)
  const [isHospitalAdmin, setIsHospitalAdmin] = useState(false)
  const [messagesState, setMessagesStates] = useState(false)
  const [actModalVisible, setActModalVisible] = useState(false)

  useEffect(() => {
    if (user) {
      setIsHospitalAdmin(user.roles.includes(ROLE_HOSPITAL_ADMIN))
    }
  }, [user])

  useEffect(() => {
    if (internship && internshipsWithMessages) {
      const currentInternshipResume = internshipsWithMessages.find(resume => {
        return resume.internship === internship.id
      })

      setMessagesStates(getMessagesStateFromResume(currentInternshipResume))
    }
  }, [internship, internshipsWithMessages])

  const getMessagesStateFromResume = resume => {
    if (!resume || resume.messages === 0) {
      return MESSAGES_STATE.NO_MESSAGES
    }

    if (resume.hasNewMessages) {
      return MESSAGES_STATE.HAS_NEW_MESSAGES
    }

    return MESSAGES_STATE.HAS_MESSAGES
  }

  const handleDeleteCancel = e => {
    setIsDeletePopConfirmDisplayed(false)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDeleteClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleConfirm = () => {
    setIsDeletePopConfirmDisplayed(false)
    onInternshipDelete(internship)
  }

  const renderMessageNotificationDot = () => {
    return <Badge color={getNotificationColorByInternship(internship)} />
  }

  const getNotificationColorByInternship = () => {
    if (messagesState === MESSAGES_STATE.HAS_MESSAGES) {
      return '#3C8DBC'
    }

    if (messagesState === MESSAGES_STATE.HAS_NEW_MESSAGES) {
      return 'red'
    }

    return 'transparent'
  }

  const renderInternshipMenu = internship => {
    return (
      <Menu>
        <div className='student-badge-internship-id'>{`${t('Id')}: ${internship.id}`}</div>
        <Menu.Item onClick={() => onSelectedInternshipChat(internship)}>
          {renderMessageNotificationDot(internship)}
          <FontAwesomeIcon icon={faComment} />
          {t('Discussion')}
        </Menu.Item>
        {internship.student && (
          <Menu.Item onClick={() => onSelectedStudentInfo(internship.student)}>
            <FontAwesomeIcon icon={faUserGraduate} />
            {t('Student sheet')}
          </Menu.Item>
        )}
        {!(internship.state < PENDING || internship.student === null) && internship.state !== PRE_ACCEPTED && (
          <Menu.Item onClick={() => onSelectedInternshipInfo(internship)}>
            <FontAwesomeIcon icon={faCalendar} />
            {t('Internship sheet')}
          </Menu.Item>
        )}
        {!(internship.state < PENDING || internship.student === null || user.context !== SCHOOL_CONTEXT || !isObject(internship.section) || !internship.section.actsMandatory) && (
          <Menu.Item onClick={() => setActModalVisible(true)}>
            <FontAwesomeIcon icon={faSyringe} />
            {t('Link to an act')}
          </Menu.Item>
        )}
        {!inShiftsManager && user.context !== INSTITUTION_CONTEXT && (
          <Menu.Item onClick={() => onDuplicateInternship(internship)}>
            <FontAwesomeIcon icon={faCopy} />
            {t('Duplicate')}
          </Menu.Item>
        )}
        {shouldDisplayRemoveOption() && (
          <Menu.Item style={{ padding: '0px' }} onClick={(e) => { e.domEvent.preventDefault(); return false }}>
            <Popconfirm
              placement='right'
              okType='danger'
              title={t('Delete this internship ?')}
              okText={t('Yes')}
              cancelText={t('Cancel')}
              visible={removePopConfirmDisplayed}
              onConfirm={handleConfirm}
              onCancel={handleDeleteCancel}
              onClick={handleDeleteClick}
            >
              <div style={{ padding: '5px 12px' }} onClick={() => setIsDeletePopConfirmDisplayed(true)}>
                <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer' }} />
                {t('Delete')}
              </div>
            </Popconfirm>
          </Menu.Item>
        )}
        {isHospitalAdmin && !inShiftsManager && CAN_MODIFY_CARE_UNIT_STATES.includes(internship.state) && (
          <Menu.Item onClick={() => onSelectedForSectorChange(internship)}>
            <FontAwesomeIcon icon={faExchangeAlt} />
            {t('Modify care unit')}
          </Menu.Item>
        )}
        {!isObserver(user.roles) && canValidateDesiderata && (
          <Menu.Item onClick={onValidateDesideratas}>
            <FontAwesomeIcon icon={faCheck} />
            {t('student_quick_menu.desiderata_action')}
          </Menu.Item>
        )}
        {inShiftsManager && schoolContext?.useDraftInternships && !internship.isDraft && internship.state !== SCHEDULE_VALIDATED && (
          <Menu.Item onClick={() => onClickedDraft(internship, true)}>
            <FontAwesomeIcon icon={faFile} />
            {t('student_quick_menu.draft_mode_action')}
          </Menu.Item>
        )}
        {inShiftsManager && schoolContext?.useDraftInternships && internship.isDraft && internship.state !== SCHEDULE_VALIDATED && (
          <Menu.Item onClick={() => onClickedDraft(internship, false)}>
            <FontAwesomeIcon icon={faPaperPlane} />
            {t('student_quick_menu.publish_schedule_action')}
          </Menu.Item>
        )}
        {inShiftsManager && internship.state !== SCHEDULE_VALIDATED && !isReadOnly && (
          <Menu.Item onClick={() => onDeleteSchedule(internship)}>
            <FontAwesomeIcon icon={faTrash} />
            {t('student_quick_menu.delete_schedule_action')}
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const shouldDisplayRemoveOption = () => {
    if (user.context === SCHOOL_CONTEXT && typeof onInternshipDelete === 'function') {
      return ![ACCEPTED, CANCELED].includes(internship.state)
    }

    return false
  }

  return (
    <div>
      {!(inWidgetMode || !internship) && (
        <span
          className={`student-badge-quick-menu ${noMargin ? 'no-margin' : ''}`}
        >
          {user.context === SCHOOL_CONTEXT && (
            <LinkActModal
              visible={actModalVisible}
              internship={internship}
              onCancel={() => setActModalVisible(false)}
            />
          )}

          {renderMessageNotificationDot(internship)}
          <Dropdown
            overlay={renderInternshipMenu(internship)}
            trigger={['click']}
            onVisibleChange={(visible) => {
              if (!visible) {
                setIsDeletePopConfirmDisplayed(false)
              }
            }}
            destroyPopupOnHide
          >
            <Button size='small' type='primary' shape='circle'>
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
          </Dropdown>
        </span>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(StudentQuickMenu)
