import React, { Component } from 'react'

import AppBanner from './AppBanner'
import Changelog from '../../Views/shared/ChangeLogView'
import appConfig from '../../config'
import moment from 'moment'
import { Drawer } from 'antd'
import { getEnvironment } from '../../utils'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import '../../assets/basic-form-wrapper.scss'
import LanguagesMenu from './LanguagesMenu'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'

class BasicFormWrapper extends Component {
  state = { showChangeLog: false }

  render () {
    const formWrapperContainerStyle = {
      backgroundColor: '#d2d6de',
      height: '100vh',
      position: 'fixed',
      width: '100vw',
      zIndex: -1
    }

    const formStyle = {
      width: this.props.isWide ? '70%' : '480px',
      maxWidth: this.props.isWide ? '800px' : '90vw',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      padding: '15px',
      marginBottom: '20px',
      boxShadow: 'box-shadow: 0px 0px 1px rgb(0, 0, 0)'
    }

    const logoStyle = {
      height: '90px'
    }

    const versionStyle = {
      position: 'absolute',
      right: '-10px',
      bottom: '-5px'
    }

    const env = getEnvironment()
    let envText = 'unknown'

    if (env === 'TEST') {
      envText = 'test'
    } else if (env === 'PROD') {
      envText = 'production'
    } else if (env === 'DEV') {
      envText = 'dev'
    }

    const appBanner = <AppBanner />

    return (
      <div className='flex-column' style={{ height: '100vh' }}>
        <div style={formWrapperContainerStyle} />
        <div className='flex-row'>
          <div className='flex-fill' />
          <div className='outer-spacing'>
            <LanguagesMenu icon={faGlobeEurope} title={this.props.t('Change language')} />
          </div>
        </div>
        <div style={{ marginTop: '50px' }} />
        <div className='flex-row'>
          <div className='flex-fill' />
          <div style={{ position: 'relative' }}>
            <img src='/assets/interneo_logo.svg' style={logoStyle} alt='interneo-logo' />
            <div style={versionStyle}>V{appConfig.version}</div>
          </div>
          <div className='flex-fill' />
        </div>
        {
          appBanner === null ? null : <div style={{ marginTop: '30px' }}>{appBanner}</div>
        }
        <div style={{ marginTop: '30px' }} />
        <div className='flex-row'>
          <div className='flex-fill' />
          <div style={formStyle} className='flex-column'>
            {this.props.children}
          </div>
          <div className='flex-fill' />
        </div>
        <div className='flex-fill' />
        <div className='flex-column changelog-link-style'>
          <a href='http://opalsolutions.be' target='_blank' rel='noopener noreferrer'>
            Opal Solutions {moment().format('YYYY')}
          </a>
          <span className='alink' onClick={() => this.setState({ showChangeLog: true })}>
            Version : interneo_{envText}_{appConfig.version}
          </span>
        </div>
        <Drawer
          title={this.props.t('Changelog')}
          width='100%'
          onClose={() => this.setState({ showChangeLog: false })}
          visible={this.state.showChangeLog}
        >
          <Changelog />
        </Drawer>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicFormWrapper)
