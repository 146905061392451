export const QUOTAS = {
  INCREMENT_QUOTA_BY_INDEX: 'INCREMENT_QUOTA_BY_INDEX',
  DECREMENT_QUOTA_BY_INDEX: 'DECREMENT_QUOTA_BY_INDEX',
  INCREMENT_QUOTA_BY_INTERNSHIP: 'INCREMENT_QUOTA_BY_INTERNSHIP',
  DECREMENT_QUOTA_BY_INTERNSHIP: 'DECREMENT_QUOTA_BY_INTERNSHIP',
  MOVE_QUOTA_BY_INTERNSHIPS: 'MOVE_QUOTA_BY_INTERNSHIPS',
  SET_QUOTA: 'SET_QUOTA',
  SET_QUOTAS: 'SET_QUOTAS',
  SET_QUOTAS_TREE: 'SET_QUOTAS_TREE',
  SET_QUOTAS_TREE_LOADING: 'SET_QUOTAS_TREE_LOADING',
  SET_QUOTAS_FOR_MULTIPLES_SECTORS: 'SET_QUOTAS_FOR_MULTIPLES_SECTORS',
  MODIFY_QUOTA_TOTAL: 'MODIFY_QUOTA_TOTAL',
  DELETE_QUOTA: 'DELETE_QUOTA'
}
