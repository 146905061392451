import React from 'react'

import { Switch } from 'antd'

export const TableSwitch = props => {
  const handleChange = () => {
    props.onFocus()

    const data = { ...props.data }

    data[props.valueKey] = !data[props.valueKey]

    props.onChange(data)
    props.onBlur(true, data)
  }

  return (
    <Switch
      checked={props.data[props.valueKey]}
      onClick={handleChange}
      disabled={props.disabled}
    />
  )
}
