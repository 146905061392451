import React, { useState, useEffect } from 'react'

import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'

import './assets/standard-table.scss'

const BUTTON_SIZE = 15

const StandardTableHeader = props => {
  const [actionsColumnSize, setActionsColumnSize] = useState(`${BUTTON_SIZE}px`)

  useEffect(() => {
    if (props.actions) {
      setActionsColumnSize(`${props.actions.length * BUTTON_SIZE}px`)
    }
  }, [props.actions])

  const renderColumns = () => {
    const body = []

    if (props.columns) {
      props.columns.forEach((col, index) => {
        body.push(
          <th key={`col_${index}`}>
            {props.t(col.name)}
          </th>
        )
      })

      if (props.actions) {
        body.push(
          <th
            key='col_actions'
            className='th-actions'
            style={{ width: actionsColumnSize }}
          />
        )
      }
    }

    return (
      <tr>
        {body}
      </tr>
    )
  }

  return (
    <thead className='standard-table-header'>
      {renderColumns()}
    </thead>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardTableHeader)
