import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput } from '../../utils'

const FormItem = Form.Item

class NormalUserSettings extends React.Component {
  state = {
    confirmDirty: false,
    visible: false
  }

  checkOldPassword = (rule, value, callback) => {
    if (!value || !value.length) {
      callback(this.props.t('The password must contain between 8 and 255 characters !'))
    } else if (!validateFormInput('password', value)) {
      callback(this.props.t('The password must contain between 8 and 255 characters !'))
    } else {
      callback()
    }
  }

  checkNewPassword = (rule, value, callback) => {
    if (value && this.state.confirmDirty) {
      this.props.form.validateFields(['newPasswordConfirm'], { force: true })
    }

    if (!value || !value.length) {
      callback(this.props.t('The password must contain between 8 and 255 characters !'))
    } else if (!validateFormInput('password', value)) {
      callback(this.props.t('The password must contain between 8 and 255 characters !'))
    } else {
      callback()
    }
  }

  checkNewPasswordConfirm = (rule, value, callback) => {
    if (!value || !value.length) {
      callback(this.props.t('The password must contain between 8 and 255 characters !'))
    } else if (value && value !== this.props.form.getFieldValue('newPassword')) {
      callback(this.props.t('The passwords do not match !'))
    } else {
      callback()
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { xs: { span: 24 } },
      wrapperCol: { xs: { span: 24 } }
    }

    return (
      <Form>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('oldPassword', { rules: [{ validator: this.checkOldPassword }] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('Old password')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('newPassword', { rules: [{ validator: this.checkNewPassword }] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('New password')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('newPasswordConfirm', { rules: [{ validator: this.checkNewPasswordConfirm }] })(
            <Input prefix={<FontAwesomeIcon icon='lock' />} type='password' placeholder={this.props.t('Confirm the new password')} />
          )}
        </FormItem>
      </Form>
    )
  }
}

const UserSettings = Form.create()(NormalUserSettings)
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
