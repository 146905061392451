import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'

/**
 * A standart factory to build an adding Button Component for table header
 * 
 * Needed props :
 *  
 * Optionnal props :
 *  {string}   addText      the text in the addButton
 *  {function} addFunction  the callback trigger when the button is clicked
 *  {boolean}  disabled     by default false and disable the button if it's true
 */
const AddButtonFactory = props => {

  //Component return
  return (
    <Button
      type='primary'
      onClick={props.addFunction ? props.addFunction : undefined}
      disabled={props.disabled}
    >
      <FontAwesomeIcon icon='plus' />
      &nbsp;
      {props.addText
        ? props.t(props.addText)
        : props.t('Add')
      }
    </Button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButtonFactory)