import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { internshipStates } from '../../../utils/constants'
import '../../../assets/shared/validate-internship-button.scss'

const InternshipValidationButton = ({ validateButtonTooltip, internship, onDisplayValidationDrawer }) => (
  <Tooltip
    placement='right'
    title={validateButtonTooltip}
  >
    <button
      className={`validate-internship-button ${internship.state === internshipStates.SCHEDULE_VALIDATED ? 'validated' : ''}`}
      type='button'
      onClick={() => onDisplayValidationDrawer(internship)}
    >
      <FontAwesomeIcon icon={faCalendarCheck} />
      {internship.state === internshipStates.SCHEDULE_TO_VALIDATE &&
        <div className='to-validate-notification'>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </div>}
    </button>
  </Tooltip>
)

export default InternshipValidationButton
