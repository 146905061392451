import React, { useContext, useEffect, useRef, useState } from 'react'

import { Button, Drawer, List, Modal } from 'antd'
import { getUser } from '../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { EUROPEAN_DATE } from '../utils/constants'
import { connect } from 'react-redux'
import { getTimezoneLessMoment } from '../utils/momentjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import StudentFeedback from '../Components/student/StudentFeedback'
import { EvaluationsContext } from '../Providers/EvaluationsProvider'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import ResizeDrawer from './ResizeDrawer'

import '../assets/internships-to-be-evaluated-modal.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InternshipsToBeEvaluatedModal = ({ children, user, t }) => {
  const { internshipsToEvaluate, setInternshipsToEvaluate } = useContext(EvaluationsContext)

  const [hasBeenOpened, setHasBeenOpened] = useState(false)
  const [visible, setVisible] = useState(false)
  const [selectedInternship, setSelectedInternship] = useState(null)

  const timer = useRef(null)

  useEffect(() => {
    if (internshipsToEvaluate.length > 0 && !hasBeenOpened) {
      setHasBeenOpened(true)
      setVisible(true)
    } else if (internshipsToEvaluate.length === 0 && hasBeenOpened) {
      timer.current = setTimeout(() => setVisible(false), 3000)
    }
  }, [internshipsToEvaluate, hasBeenOpened])

  return (
    <>
      {children}
      <Modal
        title={t('Hello ') + user.firstname + t(', you have internships waiting for a feedback!')}
        visible={visible}
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setVisible(false)

          if (timer.current) {
            clearTimeout(timer.current)
          }
        }}
        destroyOnClose
      >
        {internshipsToEvaluate.length > 0 && (
          <div className='internship-to-evaluate-modal'>
            <List>
              {internshipsToEvaluate.map((i, index) => {
                return (
                  <List.Item key={index}>
                    <div className='internship-container'>
                      <div className='internship-info'>
                        <span><b> {i.institution.name} </b></span>
                        <span>
                          {i.sector.name}
                        </span>
                        <span>
                          {getTimezoneLessMoment(i.startDate).format(EUROPEAN_DATE)} - {getTimezoneLessMoment(i.endDate).format(EUROPEAN_DATE)}
                        </span>
                      </div>
                      <div className='internship-actions'>
                        <Button onClick={() => setSelectedInternship(i)}>
                          <FontAwesomeIcon icon={faSmile} />
                          {t('Give feedback')}
                        </Button>
                      </div>
                    </div>
                  </List.Item>
                )
              })}
            </List>
          </div>
        )}
        {internshipsToEvaluate.length === 0 && (
          <div className='thanks-message-container'>
            <FontAwesomeIcon icon={faCheckCircle} size='4x' />
            <span className='thanks-message'> {t('Thanks for evaluate all your internships!')} </span>
          </div>
        )}
      </Modal>
      <ResizeDrawer visible={!!selectedInternship}>
        <Drawer
          title={t('Internship feedback')}
          onClose={() => setSelectedInternship(null)}
          destroyOnClose
        >
          {selectedInternship !== null && (
            <StudentFeedback
              internship={selectedInternship}
              onSave={() => {
                setSelectedInternship(null)
                setInternshipsToEvaluate(internshipsToEvaluate.filter(i => i.id !== selectedInternship.id))
              }}
            />
          )}
        </Drawer>
      </ResizeDrawer>
    </>
  )
}

export default connect(mapStateToProps)(InternshipsToBeEvaluatedModal)
