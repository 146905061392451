import React, { Component } from 'react'

import '../../assets/easter-egg.scss'
import { Modal } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'

class AlertFreezeModal extends Component {
  render () {
    return (
      <Modal
        visible
        closable={false}
        footer={[
          <div className='flex-row' key='key'>
            <div className='flex-fill' />
            {this.props.actionButton}
          </div>
        ]}
      >
        {this.props.text}
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertFreezeModal)
