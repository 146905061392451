import React, { useState, useEffect, useContext, useRef } from 'react'

import { isArray } from 'lodash'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { getAll } from '../../../../utils/api/quota'
import { getUser } from '../../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getPlaces } from '../utils/DataDerivers'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { getIndexByPeriodAndDate } from '../../../../utils/timePeriods'
import { DATE_FORMAT_API } from '../../../../utils/constants'
import Loading from '../../../shared/Loading'
import { AllocationContext } from '../../../../Providers/Quotas/AllocationProvider'
import { isObject } from '../../../../utils'
import { getFrontDate } from '../../../../utils/apiHelper'

import '../assets/quotas.scss'
import moment from 'moment'

const FIRST_COLUMN_TITLE = 'Period'
const PLACES_ROW_TITLE = 'Places available'

export const DEFAULT_SPAN_STYLE = {
  fontWeight: 'bold',
  fontSize: 'larger'
}
export const DEFAULT_EMPTY_TABLE_STYLE = {
  height: 'calc(100% - 85px)',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const AllocationTable = ({ columns, places, schools, section, sector, selectedCell, user, onCellClick, onCellDoubleClick, t }) => {
  const key = isObject(section) ? section.id : -1

  const { period } = useContext(QuotasContext)
  const { fetchQuotas, placesLoading } = useContext(AllocationContext)

  // Nodes configuration contains the number of lines for each of the schoolYear that has to be displayed
  const [quotas, setQuotas] = useState([])
  const [sectionToYears, setSectionoYears] = useState({})
  const [nodesConfig, setNodesConfig] = useState({})
  const [loading, setLoading] = useState(true)
  const [cell, setCell] = useState(null)

  const promiseId = useRef(null)

  useEffect(() => {
    if (typeof fetchQuotas[key] === 'function') {
      setLoading(true)

      fetchQuotas[key]().then(json => {
        if (json && json.data) {
          const { matrice, sectionToYears } = buildNodesConfig(json.data, period)

          setQuotas(json.data)
          setSectionoYears(sectionToYears)
          setNodesConfig(matrice)
        }

        setLoading(false)
      })
    }
  }, [fetchQuotas[key]])

  useEffect(() => {
    if (sector && schools) {
      setLoading(true)
      const id = moment().valueOf()
      promiseId.current = id

      getAll(
        user,
        {
          'start-date': period.startDate.format(DATE_FORMAT_API),
          'end-date': period.endDate.format(DATE_FORMAT_API),
          sector: sector.id,
          section: section === null ? null : section.id,
          schools: schools.map(s => s.id),
          contexts: ['school', 'sectionToYear']
        }
      ).then(json => {
        if (promiseId.current === id) {
          if (json && json.data) {
            const { matrice, sectionToYears } = buildNodesConfig(json.data, period)

            setQuotas(json.data)
            setSectionoYears(sectionToYears)
            setNodesConfig(matrice)
          }

          setLoading(false)
        }
      })
    }
  }, [sector, section, schools, user, period])

  useEffect(() => {
    const cellKey = selectedCell && isObject(selectedCell.section) ? selectedCell.section.id : -1
    const sectionKey = isObject(section) ? section.id : -1

    if (selectedCell && cellKey === sectionKey) {
      setCell(selectedCell)
    } else {
      setCell(null)
    }
  }, [selectedCell, section])

  const buildNodesConfig = (quotas, period) => {
    const width = period.length
    const config = {}
    const years = {}
    const pivotalIndexes = {}

    if (isArray(quotas)) {
      quotas.forEach((q, index) => {
        const key = isObject(q.sectionToYear) ? q.sectionToYear.id : -1
        const startIndex = getIndexByPeriodAndDate(getFrontDate(q.startDate), period)
        const endIndex = q.endDate !== null
          ? getIndexByPeriodAndDate(getFrontDate(q.endDate), period)
          : width - 1
        const places = q.total && q.total > 0 ? q.total : 1

        if (years[key] === undefined) {
          years[key] = q.sectionToYear
        }

        if (!config[key]) {
          config[key] = []
          pivotalIndexes[key] = Array(width + 1).fill(0)
        }

        for (let i = startIndex; i <= endIndex; i++) {
          let pIndex = pivotalIndexes[key][i]
          let placesTaken = 0

          while (placesTaken < places) {
            if (pIndex >= config[key].length) {
              config[key].push(Array(width).fill(null))
            }

            config[key][pIndex][i] = index

            pIndex++
            placesTaken++
          }

          pivotalIndexes[key][i] = pIndex
        }
      })
    }

    return { matrice: config, sectionToYears: years }
  }

  const renderPlacesRow = () => {
    const totalCells = [<td key='t-0' className='allocation-table-first-cell'> {t(PLACES_ROW_TITLE)} </td>]

    if (isArray(places)) {
      places.forEach((p, index) => {
        const { remaining, total } = getPlaces(p, section)
        totalCells.push((
          <td
            key={`t-${index + 1}`}
            className={getPlacesCellClasses(remaining)}
          >
            {(remaining === null || total === null) && (
              <Tooltip title={t('No places were been found for this period. Please add places on the "Define places" tab for this period.')}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Tooltip>
            )}
            {remaining !== null && total !== null && (
              <>
                {remaining}/{total}
              </>
            )}
          </td>
        ))
      })
    }

    return totalCells
  }

  const getPlacesCellClasses = remainingPlaces => {
    const defaultClass = 'allocation-table-cell'

    if (remainingPlaces > 0) {
      return `${defaultClass} remaining-places-ok`
    }

    if (remainingPlaces === 0) {
      return `${defaultClass} remaining-places-exhausted`
    }

    return `${defaultClass} remaining-places-exceeded`
  }

  const renderTableBody = () => {
    const tableBody = []
    let acc = 0

    Object.keys(nodesConfig).forEach((key, index) => {
      for (let i = 0; i < nodesConfig[key].length; i++) {
        tableBody.push((
          <tr key={'r-' + acc} className='allocation-table-row'>
            <td className='allocation-table-first-cell'>
              {sectionToYears[key] ? sectionToYears[key].schoolYearName : '*'}
            </td>
            {renderRow(nodesConfig[key][i], acc)}
          </tr>
        ))

        acc++
      }
    })

    return tableBody
  }

  const renderRow = (data, rowIndex) => {
    return data.map((d, index) => {
      const cellData = {
        rIndex: rowIndex,
        cIndex: index,
        section,
        school: d === null ? null : quotas[d].school,
        date: columns[index].date
      }
      let className = 'allocation-table-cell'

      if (cell && cell.rIndex === rowIndex && cell.cIndex === index) {
        className += ' allocation-table-selected-cell'
      }

      if (d !== null && quotas[d].total === 0) {
        className += ' empty-quota'
      }

      return (
        <td
          key={'c ' + index}
          className={className}
          onClick={() => onCellClick(cellData)}
          onDoubleClick={() => onCellDoubleClick(cellData)}
        >
          {d === null ? '' : (quotas[d].school.abbreviation ? quotas[d].school.abbreviation : quotas[d].school.name.slice(0, 10))}
        </td>
      )
    })
  }

  return (
    <div className='allocation-quotas-div'>
      <h3 className='allocation-quotas-title'> {section ? section.abbreviation : t('Free')} </h3>
      <table className='allocation-quotas-table'>
        <thead>
          <tr className='allocation-table-header-row'>
            <th className='allocation-table-header-cell'> {t(FIRST_COLUMN_TITLE)} </th>
            {columns.map((column, index) => {
              return (
                <th key={`th-${index}`} className='allocation-table-header-cell'> {t(column.title)} </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {(loading || placesLoading) && (
            <tr>
              <td colSpan={columns.length} style={{ height: '80px' }}>
                <Loading size='2x' />
              </td>
            </tr>
          )}
          {!loading && !placesLoading && (
            <>
              <tr className='allocation-table-places-row'>
                {renderPlacesRow()}
              </tr>
              {renderTableBody()}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(AllocationTable)
