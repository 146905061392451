import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Popconfirm, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Loading from '../../shared/Loading'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ActsTable = ({ acts, loading, t, onDelete }) => {
  const groupedActs = useMemo(() => {
    return acts.reduce((acc, a) => {
      const categoryName = a.actType.actTypeCategory?.name ?? t('Without category')

      if (!acc[categoryName]) {
        acc[categoryName] = []
      }

      acc[categoryName].push(a)

      return acc
    }, {})
  }, [acts, t])

  return (
    <>
      <h3>{t('Acts')}</h3>
      <br />
      <div className='modal-acts-div'>
        {loading && (
          <Loading size='2x' />
        )}
        {!loading && acts.length > 0 && (
          <div style={{ overflowY: 'auto', maxHeight: '200px', paddingRight: '15px' }}>
            {Object.entries(groupedActs).map(([categoryName, items]) => (
              <div key={categoryName}>
                <Card title={categoryName}>
                  {items.map(item => {
                    const name = item.actType ? item.actType.name : 'Unknow'
                    return (
                      <div
                        key={item.actType.id}
                        style={{ display: 'flex', paddingBottom: '8px' }}
                      >
                        <div> {name} </div>
                        <div className='flex-fill' />
                        <div style={{ marginRight: '8px', fontWeight: 'bold' }}> {item.description} </div>
                        <Tooltip placement='top' title={t('Delete this act?')}>
                          <Popconfirm
                            placement='top'
                            okType='danger'
                            title={t('Delete this act?')}
                            okText={t('Yes')}
                            cancelText={t('Cancel')}
                            onConfirm={() => onDelete(item)}
                          >
                            <Button
                              type='danger'
                              size='small'
                              style={{ width: '28px', marginLeft: '2px' }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                      </div>
                    )
                  })}
                </Card>
                <br />
              </div>
            ))}
          </div>
        )}
        {acts.length === 0 && (
          <strong className='flex-row'>
            <div className='flex-fill' />
            {t('No data is available')}
            <div className='flex-fill' />
          </strong>
        )}
      </div>
    </>
  )
}

export default connect(mapStateToProps)(ActsTable)
