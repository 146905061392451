import { Alert } from 'antd'
import React, { useMemo } from 'react'

const ErrorMessage = ({ title, details = {} }) => {
  const detailsKeys = useMemo(() => Object.keys(details), [details])
  return (
    <div className='form-alert'>
      <Alert
        style={{ width: '100%' }}
        message={
          <div>
            {title}
            {detailsKeys.length > 0 && (
              <ul>
                {detailsKeys.map((key, index) => {
                  return (
                    <li key={'li_' + index}>
                      <b> {key} </b>: {details[key]}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        }
        type='error'
        showIcon
      />
    </div>
  )
}

export default ErrorMessage
