import React, { useCallback, useContext, useMemo, useState } from 'react'

import HospitalDepartmentsTable from './HospitalDepartmentsTable'
import useRequest from '../../../../hooks/useRequest'
import { deleteHospitalDepartment, getHospitalDepartments } from '../../../../utils/api/hospitalDepartment'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { ORDER_BY } from '../../../../utils/constants'
import HospitalDepartmentModal from '../../../../Providers/Forms/HospitalDepartmentModal'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import HospitalDepartmentSectorsDrawer from '../../../../Providers/Drawer/HospitalDepartmentSectorsDrawer'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const HospitalDepartmentsView = ({ user }) => {
  const { institution, sectors, users, reloadSectors } = useContext(InstitutionContext)

  const [parameters, setParameters] = useState(new TableParameters({ pageSize: 50, orderBy: ORDER_BY.NAME }))
  const { data, loading, metadata, reload } = useRequest({
    request: getHospitalDepartments,
    parameters: useMemo(() => [user, { ...parameters }], [user?.id, parameters])
  })

  const handleDelete = useCallback(department => {
    deleteHospitalDepartment(user, department).then(() => reload())
  }, [reload, user])

  return (
    <div className='hospital-departments-container'>
      <HospitalDepartmentSectorsDrawer sectors={sectors}>
        <HospitalDepartmentModal users={users}>
          <HospitalDepartmentsTable
            data={data}
            institution={institution}
            loading={loading}
            metadata={metadata}
            parameters={parameters}
            onDelete={handleDelete}
            onLinkChange={reloadSectors}
            onParametersChange={setParameters}
            onReload={reload}
          />
        </HospitalDepartmentModal>
      </HospitalDepartmentSectorsDrawer>
    </div>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentsView)
