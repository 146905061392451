import React, { useContext, useEffect, useState } from 'react'

import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import InternshipValidationButton from '../../ShiftsManager/InternshipValidationButton'
import InstitutionDateCell from '../DateCell/InstitutionDateCell'
import InstitutionInternshipData from '../InternshipData/InstitutionInternshipData'
import StudentQuickMenu from '../StudentQuickMenu'
import { getStatesPellet } from '../utils/componentGetters'
import { getIndexesByColumns, getValidationTooltipText } from '../utils/utils'

const DEFAULT_INDEXES = { startIndex: 0, lastIndex: 0 }

const InstitutionInternshipLine = ({ internshipContext, getQuotasState, onLineHover }) => {
  const { internship, onUpdate, handleStateChange } = useContext(internshipContext)
  const { dateColumns, user, onInternshipSelected, onStudentSelected, setChat, setInternshipToValidate, t } = useContext(InternshipsManagerContext)

  const [isHovered, setIsHovered] = useState(false)
  const [periodIndexes, setPeriodIndexes] = useState(DEFAULT_INDEXES)
  const [displaySectorSelector, setDisplaySectorSelector] = useState(false)

  const StatesPellet = getStatesPellet(user.context)

  useEffect(() => {
    if (internship.startDate && internship.endDate) {
      setPeriodIndexes(getIndexesByColumns(internship, dateColumns))
    }
  }, [internship, dateColumns])

  const handleLineHover = () => {
    setIsHovered(true)
    onLineHover(periodIndexes)
  }

  const handleLineLeave = () => {
    setIsHovered(false)
  }

  const handleUpdate = sector => {
    setDisplaySectorSelector(false)
    onUpdate({ sector: sector.id })
  }

  return (
    <tr
      className='internship-line'
      onMouseOver={handleLineHover}
      onMouseLeave={handleLineLeave}
    >
      <td>
        <div className='internship-card-old'>
          <div className='quick-menu-container'>
            <StudentQuickMenu
              noMargin
              internship={internship}
              onSelectedInternshipInfo={onInternshipSelected}
              onSelectedInternshipChat={setChat}
              onSelectedForSectorChange={() => setDisplaySectorSelector(!displaySectorSelector)}
              onSelectedStudentInfo={onStudentSelected}
            />
          </div>
          <InstitutionInternshipData
            displaySectorSelector={displaySectorSelector}
            internship={internship}
            onUpdate={handleUpdate}
          />
          <div className='status-validation-wrapper'>
            <StatesPellet
              internship={internship}
              onChange={handleStateChange}
            />
            {user.canValidateInternship && internship.shifts && internship.shifts.length > 0 &&
              <InternshipValidationButton
                validateButtonTooltip={t(getValidationTooltipText(internship))}
                internship={internship}
                onDisplayValidationDrawer={setInternshipToValidate}
              />}
          </div>
        </div>
      </td>
      {dateColumns.map((dates, index) => {
        return (
          <InstitutionDateCell
            key={index}
            startDate={dates.startDate}
            endDate={dates.endDate}
            index={index}
            isHovered={isHovered}
            periodIndexes={periodIndexes}
          />
        )
      })}
    </tr>
  )
}

export default InstitutionInternshipLine
