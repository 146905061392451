import React, { useContext, useEffect, useState } from 'react'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Button, Checkbox, Form, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { CARPOOL_LANGUAGES, CARPOOL_TYPE, Optin } from '../../utils/entities/optin'
import { createUserOptin, updateUserOptin } from '../../utils/api/user'
import { onError, onSuccess } from '../../utils/apiHelper'
import { LANGUAGE_TAG_EN } from '../../utils/constants'

const TOOLTIP_TEXT = (
  'To be able to consult the list of students open to carpooling, ' +
  'you must first give your consent for other students to have access to your personal contact details.'
)

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const UserCarpoolModal = ({ currentLanguage, visible, user, t, onCancel, onSubmit }) => {
  const { optins, fetchOptins } = useContext(GlobalContext)

  const [carpoolOptin, setCarpoolOptin] = useState(new Optin({ type: CARPOOL_TYPE, user, text: CARPOOL_LANGUAGES[currentLanguage ?? LANGUAGE_TAG_EN] }))

  useEffect(() => {
    let carpoolOptin = optins.find(o => o.type === CARPOOL_TYPE)

    if (!carpoolOptin) {
      carpoolOptin = new Optin({ type: CARPOOL_TYPE, user, text: CARPOOL_LANGUAGES[currentLanguage] })
    }

    setCarpoolOptin(carpoolOptin)
  }, [optins, user, currentLanguage])

  const handleSubmit = (e) => {
    e.preventDefault()

    let route
    let action

    if (carpoolOptin.id > 0) {
      route = updateUserOptin
      action = { error: 'update', success: 'updated' }
    } else {
      route = createUserOptin
      action = { error: 'creation', success: 'created' }
    }

    route(carpoolOptin, user).then(json => {
      let error = false

      if (json?.data) {
        onSuccess(t('The optin has been successfully ' + action.success))
        fetchOptins(user)
      } else {
        error = true
        onError(t('An error occured during the optin ' + action.error))
      }

      if (typeof onSubmit === 'function') {
        onSubmit(error)
      }
    })
  }

  return (
    <Modal
      title={(<div className='warning-modal-title'> {t('Warning!')} </div>)}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form>
        <div className='flex-column'>
          <p>
            <FontAwesomeIcon icon='info-circle' />
            &nbsp;
            {t(TOOLTIP_TEXT)}
          </p>
          <div className='flex-row'>
            <Checkbox
              checked={carpoolOptin.accepted}
              onClick={() => setCarpoolOptin(carpoolOptin.clone({ accepted: !carpoolOptin.accepted }))}
            />
            &nbsp;
            {t('carpool_optin_text')}
          </div>
        </div>
        <br />
        <Button
          disabled={!carpoolOptin.accepted}
          type='primary'
          htmlType='submit'
          onClick={handleSubmit}
        >
          {t('Confirm')}
        </Button>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(UserCarpoolModal)
