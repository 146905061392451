export const getName = s => {
  return s.name
}

export const getAddress = s => {
  return s.address
}

export const getPhoneNumber = s => {
  return s.phoneNumber
}

export const getEmail = s => {
  return s.email
}

export class School {
  constructor ({ id, address, name, phoneNumber } = {}) {
    this.id = id ?? -1
    this.address = address ?? ''
    this.name = name ?? ''
    this.phoneNumber = phoneNumber ?? ''
  }

  toRequestBody = () => {
    return { ...this }
  }
}
