const buildURIByObject = params => {
  const paramsNames = Object.keys(params)
  let uri = ''

  paramsNames.forEach((name, index) => {
    uri += index === (paramsNames.length - 1)
      ? `${name}=${params[name]}`
      : `${name}=${params[name]}&`
  })

  return uri
}

export {
  buildURIByObject
}
