import React, { useCallback, useMemo, useState, useRef } from 'react'

import { Button, Modal, Upload, Progress, Checkbox, Divider, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import { importQuotas } from '../../../../utils/api/quota'
import QuestionBubble from './QuestionBubble'

import '../../../../assets/students-import-modal.scss'
import { getLanguageByLocale } from '../../../../utils'

const { Dragger } = Upload

const ACCEPTED_DOCUMENT_TYPES = [
  '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'
]

const DISABLE_SECTORS = 'disableSectors'
const DISABLE_SECTIONS = 'disableSections'
const DISABLE_EXCLUSIONS = 'disableExclusions'

const IMPORT_OPTIONS = [
  { label: 'import_quotas_button.sectors_option', value: DISABLE_SECTORS, title: 'import_quotas_button.sectors_option.title' },
  { label: 'import_quotas_button.sections_option', value: DISABLE_SECTIONS, title: 'import_quotas_button.sections_option.title' },
  { label: 'import_quotas_button.exclusions_option', value: DISABLE_EXCLUSIONS, title: 'import_quotas_button.exclusions_option.title' }
]

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale).code,
    user: getUser(state.getUser)
  }
}

const ImportQuotaButton = ({ activeLanguage, institution, user, t, afterUpload }) => {
  const [visible, setVisible] = useState(false)
  const [importing, setImporting] = useState(false)
  const [importPercentage, setImportPercentage] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState([DISABLE_SECTORS, DISABLE_SECTIONS, DISABLE_EXCLUSIONS])
  const [fileList, setFilesList] = useState([])

  const acceptedTypes = useMemo(() => ACCEPTED_DOCUMENT_TYPES.join(','), [])
  const translatedOptions = useMemo(() => IMPORT_OPTIONS.map(option => ({ ...option, label: <div> {t(option.label)} <QuestionBubble title={t(option.title)} /> </div> })), [t])
  const progressTimer = useRef(null)

  const resetStates = useCallback((percentage = 0) => {
    setFilesList([])
    setImporting(false)
    setImportPercentage(percentage)
  }, [setFilesList, setImporting, setImportPercentage])

  const updateProgress = useCallback(() => {
    let percentage = 0

    progressTimer.current = setInterval(() => {
      if (percentage >= 99) {
        return clearInterval(progressTimer.current)
      }

      percentage += percentage >= 90 ? 1 : 10
      setImportPercentage(percentage)
    }, 600)
  }, [setImportPercentage, progressTimer.current])

  const handleFileUpload = async () => {
    if (fileList.length > 0) {
      setImporting(true)

      if (ACCEPTED_DOCUMENT_TYPES.includes(fileList[0].type)) {
        const optionsToDisable = selectedOptions.reduce((acc, option) => ({ ...acc, [option]: true }), {})

        updateProgress()

        importQuotas(user, institution, getLanguageByLocale(activeLanguage), fileList[0], optionsToDisable).then(json => {
          if (progressTimer.current) {
            clearInterval(progressTimer.current)
          }

          resetStates(100)
          afterUpload()
          onSuccess(t('import_quotas_button.import.success'))
        }).catch(error => {
          resetStates()

          onError(t(error.message))
        })
      } else {
        onError(t('import_quotas_button.import.error'))
      }
    }
  }

  const handleClose = useCallback(() => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current)
    }

    resetStates()
    setVisible(false)
  }, [progressTimer.current, resetStates, setVisible])

  return (
    <>
      <Tooltip title={t('import_quotas_button.tooltip_title')}>
        <Button onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faFileImport} />
        </Button>
      </Tooltip>
      <Modal
        title={t('import_quotas_button.modal_title')}
        visible={visible}
        onCancel={handleClose}
        onOk={handleFileUpload}
        okText={t('Import')}
        cancelText={t('Cancel')}
        okButtonProps={{ disabled: fileList.length === 0 }}
        destroyOnClose
      >
        <div className='students-import-modal-body'>
          <div className='upload-type-description'>
            <ul>
              <li> {t('import_quotas_button.modal_body.list_1')} </li>
              <li> {t('import_quotas_button.modal_body.list_2')} </li>
              <li> {t('import_quotas_button.modal_body.list_3')} </li>
            </ul>
          </div>
          <Divider />
          <div className='import-options'>
            <Checkbox.Group options={translatedOptions} value={selectedOptions} onChange={setSelectedOptions} />
          </div>
          <div className='upload-area'>
            {!importing && (
              <Dragger
                fileList={fileList}
                name='file'
                accept={acceptedTypes}
                beforeUpload={(file) => {
                  setFilesList([file])

                  return false
                }}
                onRemove={() => setFilesList([])}
              >
                <p className='ant-upload-text'>{t('import_quotas_button.upload_text')} </p>
                <p className='ant-upload-hint'>{t('import_quotas_button.upload_hint')} </p>
              </Dragger>
            )}
            {importing && (
              <div className='importing-animation'>
                <Progress type='circle' percent={importPercentage} />
              </div>
            )}
          </div>
        </div>

      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(ImportQuotaButton)
