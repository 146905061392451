import { requestWithPromise } from '..'

export const getSectorNote = (sector, user) => {
  return requestWithPromise(
    `/api/sectors/${sector.id}/sector-notes`,
    'GET',
    null,
    user
  ).then(json => {
    return json?.data ?? []
  })
}

export const createOrUpdate = (note, sector, school, user) => {
  const body = {
    note: note,
    school: school.id
  }

  return requestWithPromise(
    `/api/sectors/${sector.id}/sector-notes`,
    'POST',
    body,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}
