import React, { useCallback, useContext, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { VALIDATION_FIELDS } from '../../../utils/validators'
import { Input, TreeSelect } from 'antd'
import { faComment, faGraduationCap, faSave } from '@fortawesome/free-solid-svg-icons'
import FormItem from './FormItem'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import IconButton from '../../antd/Buttons/IconButton'
import { isNumber } from 'lodash'
import { StudentPathway } from '../../../utils/entities/studentPathway'

const { SHOW_PARENT } = TreeSelect
const { TextArea } = Input

const DEFAULT_STATE = { sections: [], details: '' }
const DEFAULT_ERROR = { missingFields: [] }

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const StudentPathwayBulkForm = ({ saveText, onSubmit, t }) => {
  const { newSectionToYears } = useContext(GlobalContext)

  const [data, setData] = useState(DEFAULT_STATE)
  const [error, setError] = useState(DEFAULT_ERROR)

  const yearsBySection = useMemo(() => newSectionToYears.reduce((acc, sty) => {
    if (!acc[sty.sectionAbbreviation]) {
      acc[sty.sectionAbbreviation] = {
        key: 'section_' + sty.section.id,
        title: sty.sectionAbbreviation,
        value: sty.sectionAbbreviation,
        children: []
      }
    }

    acc[sty.sectionAbbreviation].children.push({
      key: sty.id,
      title: `${sty.sectionAbbreviation} (${sty.schoolYearName})`,
      value: sty.id,
      sty
    })

    return acc
  }, {}), [newSectionToYears])
  const sectionsTree = useMemo(() => Object.values(yearsBySection), [yearsBySection])

  const errorClass = useMemo(() => error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  const handleSubmit = useCallback(() => {
    if (data.sections.length > 0) {
      const studentPathways = data.sections.reduce((acc, s) => {
        if (isNumber(s)) {
          acc.push(new StudentPathway({ sectionToYear: newSectionToYears.find(sty => sty.id === s), details: data.details }))
        } else {
          yearsBySection[s].children.forEach(({ sty }) => {
            acc.push(new StudentPathway({ sectionToYear: sty, details: data.details }))
          })
        }

        return acc
      }, [])

      onSubmit(studentPathways)
      setData(DEFAULT_STATE)
      setError(DEFAULT_ERROR)
    } else {
      setError({ title: t('form.errors'), details: '', visible: true, missingFields: [VALIDATION_FIELDS.SECTIONS] })
    }
  }, [data, error, setError, t])

  const handleFilter = useCallback((value, treeNode) => {
    value = value.trim().toLowerCase()

    return treeNode.props.children.length === 0
      ? treeNode.props.title.toLowerCase().includes(value)
      : treeNode.props.value.toLowerCase().includes(value)
  }, [])

  return (
    <div className='flex-col h-100'>
      <FormItem icon={faGraduationCap} label='form.label.section(s)'>
        <TreeSelect
          className={errorClass[VALIDATION_FIELDS.SECTIONS]}
          dropdownStyle={{ maxHeight: '200px' }}
          placement='bottom'
          treeData={sectionsTree}
          value={data.sections}
          onChange={v => setData({ ...data, sections: v })}
          treeCheckable
          showCheckedStrategy={SHOW_PARENT}
          style={{ width: '100%' }}
          filterTreeNode={handleFilter}
        />
      </FormItem>
      <FormItem icon={faComment} label='form.label.details'>
        <TextArea maxLength={500} rows={2} value={data.details} onChange={e => setData({ ...data, details: e.target.value })} />
      </FormItem>
      <div className='h-100' />
      <IconButton className='w-100' type='primary' icon={faSave} onClick={handleSubmit} text={saveText ?? t('actions.save')} />
    </div>
  )
}

export default connect(mapStateToProps)(StudentPathwayBulkForm)
