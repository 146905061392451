export const USER_ACCOUNT_FINALISATION = 'USER_ACCOUNT_FINALISATION'
export const EARLY_YEAR_RECRUITING_MAIL = 'EARLY_YEAR_RECRUITING_MAIL'
export const LAST_YEAR_RECRUITING_MAIL = 'LAST_YEAR_RECRUITING_MAIL'
export const INTERNSHIP_STUDENT_EVALUATION = 'INTERNSHIP_STUDENT_EVALUATION'
export const INTERNSHIP_IS_ACCEPTED = 'INTERNSHIP_IS_ACCEPTED'

export const DESCRIPTIONS = {
  USER_ACCOUNT_FINALISATION: 'user_finalisation_email_description',
  EARLY_YEAR_RECRUITING_MAIL: 'early_year_recruitement_email_description',
  LAST_YEAR_RECRUITING_MAIL: 'last_year_recruitement_email_description',
  INTERNSHIP_STUDENT_EVALUATION: 'student_evaluation_email_description',
  INTERNSHIP_IS_ACCEPTED: 'internship_acceptation_email_description'
}
