const makeSchoolSection = (schoolId = -1) => {
  return {
    abbreviation: '',
    actsMandatory: false,
    comment: null,
    dumpSection: false,
    id: -1,
    name: '',
    school: schoolId,
    schoolYearName: '',
    schoolYearId: -1,
    sectionId: -1,
    sectionToYear: -1
  }
}

const makeRootSchoolSection = () => {
  return { name: '' }
}

export {
  makeSchoolSection,
  makeRootSchoolSection
}
