import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { internshipStates } from '../../utils/constants'
import '../../assets/internship-state-pellet.scss'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { buildMissingPropertiesMessage } from './InternshipsManager/utils/utils'
const {
  PENDING_NO_SECTOR, CREATED, PENDING, ACCEPTED, CANCELED, REFUSED, ACCEPTED_UNMANAGED, AUTO_ACCEPTED,
  SCHEDULE_ADDED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, PENDING_STUDENT_DATA_MISSING
} = internshipStates
class InternshipStatePellet extends Component {
  state = {
    tooltip: '',
    internship: this.props.internship,
    constraints: null
  }

  componentDidMount () {
    this.savePropsToState(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.savePropsToState(nextProps)
  }

  savePropsToState = (props) => {
    const internship = props.internship
    let tooltip = this.state.tooltip
    switch (internship.state) {
      case PENDING_STUDENT_DATA_MISSING: {
        tooltip = buildMissingPropertiesMessage(
          internship,
          props.selectInstitutionConstraintsByInstitution[internship.institution],
          props.t
        )

        break
      }
      case PENDING_NO_SECTOR:
        tooltip = props.t('Without institution/care unit, cannot be approved/refused.')
        break
      case CREATED:
        tooltip = props.t('Without institution/care unit, cannot be approved/refused.')
        break
      case PENDING:
        tooltip = props.t('Waiting for approval/refusal.')
        break
      case ACCEPTED:
        tooltip = props.t('Accepted, cannot be edited.')
        break
      case AUTO_ACCEPTED:
        tooltip = props.t('Accepted automatically.')
        break
      case CANCELED:
        tooltip = props.t('Canceled, cannot be edited.')
        break
      case REFUSED:
        tooltip = props.t('Refused, see the internship discussion thread.')
        break
      case ACCEPTED_UNMANAGED:
        tooltip = props.t('Accepted automatically (the institution do not use Interneo).')
        break
      case SCHEDULE_ADDED:
        tooltip = props.t('Accepted & modified by institution, cannot be edited')
        break
      case SCHEDULE_VALIDATED:
        tooltip = props.t('Internship terminated & validated')
        break
      case SCHEDULE_TO_VALIDATE:
        tooltip = props.t('Internship to be validated')
        break
    }

    if (this.props.getUser.context === 'STUDENT') {
      // The tooltip changes for the student, he doesn't need as much details as the teachers.
      switch (internship.state) {
        case PENDING_STUDENT_DATA_MISSING:
          tooltip = props.t('Waiting for acceptance.')
          break
        case PENDING_NO_SECTOR:
          tooltip = props.t('Waiting for acceptance.')
          break
        case CREATED:
          tooltip = props.t('Waiting for acceptance.')
          break
        case PENDING:
          tooltip = props.t('Waiting for acceptance.')
          break
        case ACCEPTED:
          tooltip = props.t('Confirmed.')
          break
        case AUTO_ACCEPTED:
          tooltip = props.t('Confirmed.')
          break
        case CANCELED:
          tooltip = props.t('Canceled.')
          break
        case REFUSED:
          tooltip = props.t('Refused.')
          break
        case ACCEPTED_UNMANAGED:
          tooltip = props.t('Confirmed.')
          break
        case SCHEDULE_ADDED:
          tooltip = props.t('Accepted & modified by institution, cannot be edited')
          break
        case SCHEDULE_VALIDATED:
          tooltip = props.t('Internship terminated & validated')
          break
        case SCHEDULE_TO_VALIDATE:
          tooltip = props.t('Internship will be validated by institution')
          break
      }
    }

    this.setState({
      internship: internship,
      tooltip: tooltip
    })
  }

  render () {
    const secondaryIconColorCode = '#d32f2f'
    let internshipStatesColor = [
      { state: PENDING_STUDENT_DATA_MISSING, color: '#ff5722', icon: faExclamation, secondaryIcon: null }, // cannot change (missing data)
      { state: PENDING_NO_SECTOR, color: '#B7B7B7', icon: 'question', secondaryIcon: null }, // cannot change (no institution)
      { state: CREATED, color: '#B7B7B7', icon: 'question', secondaryIcon: null }, // created
      { state: PENDING, color: '#03A9F4', icon: 'question', secondaryIcon: null }, // pending
      { state: ACCEPTED, color: '#7EB73E', icon: 'check', secondaryIcon: null }, // accepted
      { state: AUTO_ACCEPTED, color: 'rgba(126, 183, 62, 0.5)', icon: 'check', secondaryIcon: null }, // auto-accepted
      { state: REFUSED, color: '#F44336', icon: 'times', secondaryIcon: null }, // refused
      { state: CANCELED, color: '#FFC107', icon: 'ban', secondaryIcon: null }, // canceled
      { state: ACCEPTED_UNMANAGED, color: '#7EB73E80', icon: 'check', secondaryIcon: null }, // accepted automatically (the instituion has no interneo account)
      { state: SCHEDULE_ADDED, color: '#7EB73E', icon: 'check', secondaryIcon: 'calendar-alt' }, // SCHEDULE_ADDED
      { state: SCHEDULE_VALIDATED, color: '#7EB73E', icon: 'check', secondaryIcon: 'calendar-check' }, // SCHEDULE_VALIDATED
      { state: SCHEDULE_TO_VALIDATE, color: '#7EB73E', icon: 'check', secondaryIcon: 'exclamation-circle', secondaryIconColor: secondaryIconColorCode } // SCHEDULE_TO_VALIDATE
    ]

    if (this.props.getUser.context === 'STUDENT') {
      // The states pellet change for the student, he doesn't need as much details as the teachers.
      internshipStatesColor = [
        { state: PENDING_STUDENT_DATA_MISSING, color: '#03A9F4', icon: 'question', secondaryIcon: null }, // cannot change (no institution)
        { state: PENDING_NO_SECTOR, color: '#03A9F4', icon: 'question', secondaryIcon: null }, // cannot change (no institution)
        { state: CREATED, color: '#03A9F4', icon: 'question', secondaryIcon: null }, // created
        { state: PENDING, color: '#03A9F4', icon: 'question', secondaryIcon: null }, // pending
        { state: ACCEPTED, color: '#7EB73E', icon: 'check', secondaryIcon: null }, // accepted
        { state: AUTO_ACCEPTED, color: '#7EB73E', icon: 'check', secondaryIcon: null }, // auto-accepted
        { state: REFUSED, color: '#F44336', icon: 'times', secondaryIcon: null }, // refused
        { state: CANCELED, color: '#FFC107', icon: 'ban', secondaryIcon: null }, // canceled
        { state: ACCEPTED_UNMANAGED, color: '#7EB73E80', icon: 'check', secondaryIcon: null }, // accepted automatically (the instituion has no interneo account)
        { state: SCHEDULE_ADDED, color: '#7EB73E', icon: 'check', secondaryIcon: 'calendar-alt' }, // SCHEDULE_ADDED
        { state: SCHEDULE_VALIDATED, color: '#7EB73E', icon: 'check', secondaryIcon: 'calendar-check' }, // SCHEDULE_VALIDATED
        { state: SCHEDULE_TO_VALIDATE, color: '#7EB73E', icon: 'check', secondaryIcon: 'exclamation-circle', secondaryIconColor: secondaryIconColorCode } // SCHEDULE_TO_VALIDATE
      ]
    }

    let icon = null
    let secondaryIcon = null
    let secondaryIconColor = null
    let style = {}
    const colorAndIcons = internshipStatesColor.find(item => item.state === this.state.internship.state)

    if (colorAndIcons) {
      style = { backgroundColor: colorAndIcons.color }
      icon = colorAndIcons.icon
      secondaryIcon = colorAndIcons.secondaryIcon

      if (colorAndIcons.secondaryIconColor) {
        secondaryIconColor = colorAndIcons.secondaryIconColor
      }
    }

    return (
      <Tooltip placement='top' title={this.state.tooltip}>
        <div className='internship-state-pellet'>
          <div className='icon-container'>
            <div className='primary-icon' style={style}>
              {
                icon === null
                  ? null
                  : <FontAwesomeIcon icon={icon} style={{ margin: 'auto' }} />
              }
            </div>
            {secondaryIcon &&
              <div className='secondary-icon' style={secondaryIconColor ? { color: secondaryIconColor } : {}}>
                <FontAwesomeIcon icon={secondaryIcon} />
              </div>}
          </div>
          {this.props.showLabel ? <div style={{ marginLeft: secondaryIcon ? '11px' : '5px' }}>{this.state.tooltip}</div> : null}
        </div>
      </Tooltip>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipStatePellet)
