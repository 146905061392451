import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBan,
  faBars,
  faBirthdayCake,
  faBox,
  faBoxOpen,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faChartPie,
  faCheck,
  faChessBoard,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClinicMedical,
  faClock,
  faCog,
  faCogs,
  faComment,
  faCopy,
  faDownload,
  faEdit,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileExport,
  faGlobeEurope,
  faGraduationCap,
  faHourglassEnd,
  faHourglassStart,
  faIdBadge,
  faInfo,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMapMarker,
  faMedkit,
  faMoon,
  faPaperclip,
  faPause,
  faPhone,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSave,
  faSchool,
  faSignInAlt,
  faSignOutAlt,
  faStethoscope,
  faSync,
  faSyncAlt,
  faSyringe,
  faTimes,
  faTrash,
  faUser,
  faUserGraduate,
  faUserLock,
  faUserSecret,
  faUserTie
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendar as farCalendar
} from '@fortawesome/free-regular-svg-icons'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAt,
  faBan,
  faBars,
  faBirthdayCake,
  faBox,
  faBoxOpen,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faChartPie,
  faCheck,
  faChessBoard,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClinicMedical,
  faClock,
  faCog,
  faCogs,
  faComment,
  faCopy,
  faDownload,
  faEdit,
  faEllipsisH,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileExport,
  faGlobeEurope,
  faGraduationCap,
  faHourglassEnd,
  faHourglassStart,
  faIdBadge,
  faInfo,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMapMarker,
  faMedkit,
  faMoon,
  faPaperclip,
  faPause,
  faPhone,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faQuestionCircle,
  faRedo,
  faSave,
  faSchool,
  faSignInAlt,
  faSignOutAlt,
  faStethoscope,
  faSync,
  faSyncAlt,
  faSyringe,
  faTimes,
  faTrash,
  faUser,
  faUserGraduate,
  faUserLock,
  faUserSecret,
  faUserTie,
  farCalendar
)
