import React, { useCallback, useMemo } from 'react'

import { Select } from 'antd'
import { canonizeString } from '../../../utils'
import { callFunctionIfDefined } from '../../../utils/functionHelper'
import { isArray } from 'lodash'

const { Option } = Select

const Selector = ({
  className, data, dataKey, disabled, showArrow, showSearch, showEmpty, style, value, valueKey, onBodyRendered, onFilter, onSearch,
  onSelect
}) => {
  const getValue = useCallback((d, key) => {
    if (typeof key === 'function') {
      return key(d)
    }

    if (typeof key === 'string') {
      return d[key]
    }

    return d.id
  }, [])

  const getOptionBody = useCallback(d => {
    if (typeof onBodyRendered === 'function') {
      return onBodyRendered(d)
    }

    return getValue(d, dataKey ?? valueKey)
  }, [onBodyRendered, getValue])

  const options = useMemo(() => {
    const options = showEmpty ? [<Option key='empty' value={-1} data={null}> / </Option>] : []

    if (isArray(data)) {
      data.forEach((d, index) => {
        const body = getOptionBody(d)
        const value = getValue(d, valueKey)

        options.push(<Option key={`${index} ${value}`} value={value} data={d} title={body}> {body} </Option>)
      })
    }

    return options
  }, [showEmpty, data, getValue, getOptionBody])

  const handleFilter = (input, option) => {
    if (typeof onFilter === 'function') {
      return onFilter(option.props.data)
    }

    if (option.props.data === null) {
      return false
    }

    const data = canonizeString(getValue(option.props.data, dataKey ?? valueKey))

    return data.includes(canonizeString(input))
  }

  const handleOptionSelected = useCallback((value, option) => {
    callFunctionIfDefined(onSelect, [value, option.props.data])
  }, [onSelect])

  return (
    <Select
      className={className ?? 'narrow'}
      disabled={disabled}
      showArrow={showArrow}
      showSearch={showSearch}
      style={style}
      value={value}
      filterOption={handleFilter}
      onSelect={handleOptionSelected}
    >
      {options}
    </Select>
  )
}

export default Selector
