import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import pullAt from 'lodash/pullAt'
import { Form, Input, Button, Card, notification } from 'antd'
import { request, generalErrorHandler, validateFormInput } from '../../../utils'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import '../../../assets/profile-page.scss'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { xs: { span: 24 } },
  wrapperCol: { xs: { span: 24 } }
}

const PersonnalInformationsForm = (props) => {
  const [personnalMails, setPersonnalMails] = useState('')
  const [personnalPhoneNumbers, setPersonnalPhoneNumbers] = useState('')
  const [phonesEditionMode, setPhonesEditionMode] = useState(false)
  const [emailsEditionMode, setEmailsEditionMode] = useState(false)
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([])
  const [newEmails, setNewEmails] = useState([])

  const { getFieldDecorator } = props.form

  /**
   * Set student personnal informations in local state every time props.getStudentExtraInformation changes
   */
  useEffect(() => {
    if (props.getStudentExtraInformations) {
      setPersonnalMails(props.getStudentExtraInformations.emails ? props.getStudentExtraInformations.emails : [])
      setPersonnalPhoneNumbers(props.getStudentExtraInformations.phones ? props.getStudentExtraInformations.phones : [])
    }
  }, [props.getStudentExtraInformations])

  /**
   * Make a bullet list with an array of string
   *
   * @param {array}  dataArray a string array which needs to be tr
   * @returns A string containing all the dataArray strings separate by the aggregationString
   */
  const dataAggregationFactory = dataArray => {
    const ulBody = dataArray.length ? dataArray.map((mail, index) => {
      return (
        <li key={index}> {mail} </li>
      )
    }) : ''

    return ulBody === '' ? ulBody : (
      <ul>
        {ulBody}
      </ul>
    )
  }

  /**
   * Add a new row in one of the student personnal informations
   *
   * @param {string} rowName the section name in wich you want to add a row
   */
  const handleAddRow = (rowName) => {
    if (rowName === 'emails') {
      const newNewEmails = [...newEmails]

      newNewEmails.push('')

      setNewEmails(newNewEmails)
    } else {
      const newNewPhoneNumbers = [...newPhoneNumbers]

      newNewPhoneNumbers.push('')

      setNewPhoneNumbers(newNewPhoneNumbers)
    }
  }

  /**
   * Remove a row at a specific index
   *
   * @param {function} getter a function to get rows data
   * @param {function} setter a function to set rows data
   * @param {*} index  the index of the row that you will remove
   */
  const removeRow = (getter, setter, index) => {
    const newData = [...getter].slice()

    pullAt(newData, [index])

    setter(newData)
  }

  /**
   * Check if an email is correctly formed
   *
   * @param {*}        rule     rule apply
   * @param {string}   value    email value
   * @param {function} callback function call if the email is not correctly formed
   */
  const checkEmail = (rule, value, callback) => {
    if (!validateFormInput('email', value)) callback(props.t('This email address is not correct !'))
    callback()
  }

  /**
   * Check if a phone number is correctly formed
   *
   * @param {*}        rule     rule apply
   * @param {string}   value    phone number value
   * @param {function} callback function call if the email is not correctly formed
   */
  const checkPhone = (rule, value, callback) => {
    if (!validateFormInput('phone', value)) callback(props.t('Only numbers are authorized !'))
    callback()
  }

  /**
   * Manage the change between read-only and edition mode for student personnal informations
   * Try to saved the student personnal informations in backend when these data as changed
   * Display the errors send by the backend as notification
   *
   * @param {string} section determine if we are in emails section or phones section
   */
  const toggleEditionMode = (section) => {
    const sectionHandler = {
      phones: {
        setter: setPhonesEditionMode,
        getter: phonesEditionMode,
        cleaner: setNewPhoneNumbers
      },
      emails: {
        setter: setEmailsEditionMode,
        getter: emailsEditionMode,
        cleaner: setNewEmails
      }
    }

    if (sectionHandler[section].getter) {
      const form = props.form

      form.validateFields((err, values) => {
        if (!err) {
          const body = {
            emails: section === 'emails' ? [] : personnalMails,
            phones: section === 'phones' ? [] : personnalPhoneNumbers
          }
          const cutSection = section.slice(0, -1)

          for (const [key, value] of Object.entries(values)) {
            if (key.includes(cutSection)) {
              body[section].push(value.toLowerCase())
            }
          }

          sectionHandler[section].setter(!sectionHandler[section].getter)
          sectionHandler[section].cleaner([])
          request(
            '/student/save-additional-contact-info',
            'POST',
            body,
            props.getUser
          ).then(json => {
            if (json.status === 'error') {
              notification.error({
                message: json.message,
                placement: 'bottomRight'
              })

              return
            }
            if (typeof json.error !== 'undefined') {
              notification.error({
                message: props.t('Error saving additional details'),
                placement: 'bottomRight'
              })

              return
            }

            // check if we have duplicate emails
            const { notUniqueEmails } = json.data
            if (notUniqueEmails && notUniqueEmails.length) {
              notification.error({
                message: props.t("Couldn't save the additional contact details because the following emails are already used in our system: " + notUniqueEmails.join(', ')),
                placement: 'bottomRight'
              })

              return
            }

            props.setStudentExtraInformations({
              emails: body.emails,
              phones: body.phones
            })
            notification.success({
              message: props.t('Saved additional details'),
              placement: 'bottomRight'
            })
          })
            .catch(error => {
              generalErrorHandler(error)

              notification.error({
                message: props.t('Error saving additional details.'),
                placement: 'bottomRight'
              })
            })
        }
      })
    } else {
      sectionHandler[section].setter(!sectionHandler[section].getter)
    }
  }

  /**
   * Render student personnal informations displayed in read-only mode
   *
   * @returns React grape of the student personnal information section in read-only mode
   */
  const renderPersonnalInformation = (mails, phones) => {
    const emailsData = emailsEditionMode ? '' : dataAggregationFactory(mails)
    const phonesData = phonesEditionMode ? '' : dataAggregationFactory(phones)
    const cardToDisplay = {
      mails: {
        title: [<FontAwesomeIcon key={1} icon='at' />, ' ', props.t('Email')],
        content: emailsEditionMode ? renderMailsForm() : emailsData === '' ? props.t("You don't record yet a personnal email") : emailsData,
        extra: [<Button key='a' onClick={() => setEmailsEditionMode(!emailsEditionMode)}> <FontAwesomeIcon icon={faEdit} /> </Button>]
      },
      phonesNumbers: {
        title: [<FontAwesomeIcon key={2} icon='phone' />, ' ', props.t('Phone number')],
        content: phonesEditionMode ? renderPhonesNumberForm() : phonesData === '' ? props.t("You don't record yet a personnal phone number") : phonesData,
        extra: [<Button key='b' onClick={() => setPhonesEditionMode(!phonesEditionMode)}> <FontAwesomeIcon icon={faEdit} /> </Button>]
      }
    }
    const keyToRendered = Object.keys(cardToDisplay).filter(cardKey => cardToDisplay[cardKey].content !== '')

    return keyToRendered.map((cardKey, index) => {
      return (
        <div key={index} className={index !== keyToRendered.length ? 'inner-margin' : ''}>
          <Card
            key={cardKey}
            type='inner'
            title={cardToDisplay[cardKey].title}
            extra={cardToDisplay[cardKey].extra}
          >
            {cardToDisplay[cardKey].content}
          </Card>
        </div>
      )
    })
  }

  /**
   * Render student personnal mails displayed in edition mode
   *
   * @returns React grape of the student personnal mails in edition mode
   */
  const renderMailsForm = () => {
    const formBody = personnalMails.concat(newEmails).map((emailRow, index) => {
      return (
        <div key={`email-row-${index}`} className='item-row'>
          <FormItem {...formItemLayout}>
            {getFieldDecorator(`email${index}`, { rules: [{ validator: checkEmail }], initialValue: personnalMails[index] })(
              <Input prefix={<FontAwesomeIcon icon='at' />} placeholder={props.t('Email address')} />
            )}
          </FormItem>
          <Button onClick={() => { removeRow(personnalMails, setPersonnalMails, index) }}>
            <FontAwesomeIcon icon='trash' />
          </Button>
        </div>
      )
    })

    return (
      <Form className='student-extra-contact-details'>
        <label>{props.t('student.additional.contact.info.email')}</label>
        {formBody}
        <div className='add-button-wrapper'>
          <Button type='primary' onClick={() => handleAddRow('emails')}>{props.t('Add email address')}</Button>
          <div className='flex-fill' />
          <Button type='primary' onClick={() => toggleEditionMode('emails')}>{props.t('Save')}</Button>
        </div>
      </Form>
    )
  }

  /**
   * Render student personnal phones number displayed in edition mode
   *
   * @returns React grape of the student personnal phones number in edition mode
   */
  const renderPhonesNumberForm = () => {
    const formBody = personnalPhoneNumbers.concat(newPhoneNumbers).map((phoneRow, index) => {
      return (
        <div key={`email-row-${index}`} className='item-row'>
          <FormItem {...formItemLayout}>
            {getFieldDecorator(`phone${index}`, { rules: [{ validator: checkPhone }], initialValue: personnalPhoneNumbers[index] })(
              <Input prefix={<FontAwesomeIcon icon='phone' />} placeholder={props.t('Phone address')} />
            )}
          </FormItem>
          <Button onClick={() => { removeRow(personnalPhoneNumbers, setPersonnalPhoneNumbers, index) }}>
            <FontAwesomeIcon icon='trash' />
          </Button>
        </div>
      )
    })

    return (
      <Form className='student-extra-contact-details'>
        <label>{props.t('student.additional.contact.info.phone')}</label>
        {formBody}
        <div className='add-button-wrapper'>
          <Button type='primary' onClick={() => handleAddRow('phones')}>{props.t('Add phone number')}</Button>
          <div className='flex-fill' />
          <Button type='primary' onClick={() => toggleEditionMode('phones')}>{props.t('Save')}</Button>
        </div>
      </Form>
    )
  }

  return (
    <div>
      {renderPersonnalInformation(personnalMails, personnalPhoneNumbers)}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PersonnalInformationsForm))
