import React, { useMemo } from 'react'

import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const DESIDERATA_VALUES = {
  className: 'desiderata-warning-icon',
  style: { color: '#3c8dbc' },
  title: 'Student desiderata'
}

const OUTSIDE_OF_INTERNSHIP_TIME_VALUES = {
  className: 'outside-of-internship-time-icon',
  style: {},
  title: 'Outside of internship schedule'
}

const ValidationWarning = ({ t, desiderataWarning = false }) => {
  const values = useMemo(() => desiderataWarning ? DESIDERATA_VALUES : OUTSIDE_OF_INTERNSHIP_TIME_VALUES, [desiderataWarning])

  return (
    <Tooltip placement='right' title={t(values.title)}>
      <span className={values.className}>
        <FontAwesomeIcon icon={faExclamationCircle} style={values.style} />
      </span>
    </Tooltip>
  )
}

export default ValidationWarning
