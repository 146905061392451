import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Popover, Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { request, generalErrorHandler } from '../../../utils'
import '../../../assets/new-messages-notification.scss'

const NewMessagesNotification = props => {
  const [loading, setLoading] = useState(false)

  const newMessages = props.getUnreadMessages || 0

  const markAllAsRead = async () => {
    setLoading(true)
    try {
      await request('/internship-message/read-all', 'POST', {}, props.getUser)
      props.readAllMessages()
    } catch (err) {
      generalErrorHandler(err)
    }
    setLoading(false)
  }

  const notificationButton = (
    <button
      className={`new-messages-notification-wrapper ${newMessages > 1000 ? 'small-text' : ''} ${newMessages === 0 ? 'disabled' : ''}`}
      type='button' onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faBell} />
      {newMessages > 0 && <div className='unread-messages'>{newMessages}</div>}
    </button>
  )

  if (newMessages === 0) {
    return (
      <Tooltip placement='right' title={props.t('You don\'t have unread messages')}>
        {notificationButton}
      </Tooltip>
    )
  }
  return (
    <Popover
      placement='right' content={
        <Button
          loading={loading}
          onClick={markAllAsRead}
          type='primary'
        >
          {props.t('Mark all as read')}
        </Button>
      }
    >
      {notificationButton}
    </Popover>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessagesNotification)
