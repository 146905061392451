import moment, { isMoment } from 'moment'

const DATA_SHARING_FR = "J'accepte que mes données personnelles soient utilisées à des fins de recrutement par les institutions utilisant Interneo."
const DATA_SHARING_EN = 'I agree that my personal data can be used for recruitment purposes by the institutions using Interneo.'
const DATA_SHARING_NL = 'Ik ga akkoord dat mijn persoonsgegevens worden gebruikt voor aanwervingen door de instellingen die Interneo gebruiken.'
const CARPOOL_FR = "J'accepte de donner accès à mes informations de contact disponibles sur Interneo (emails, téléphones, régions de covoiturage et horaires éventuels) aux autres étudiants désirant faire du covoiturage."
const CARPOOL_EN = 'I agree to give access to my contact information available on Interneo (emails, phone numbers, carpooling regions and schedules) to other students wishing to carpool.'
const CARPOOL_NL = "Ik ga ermee akkoord om andere studenten die willen carpoolen toegang te geven tot mijn contactgegevens op Interneo (e-mails, telefoonnummers, carpoolregio's en eventuele tijden)."

export const DATA_SHARING_TEXTS = [DATA_SHARING_EN, DATA_SHARING_FR, DATA_SHARING_NL]
export const CARPOOL_OPTIN_TEXTS = [CARPOOL_EN, CARPOOL_FR, CARPOOL_NL]

export const DATA_SHARING_LANGUAGES = {
  en: DATA_SHARING_EN,
  fr: DATA_SHARING_FR,
  nl: DATA_SHARING_NL
}
export const CARPOOL_LANGUAGES = {
  en: CARPOOL_EN,
  fr: CARPOOL_FR,
  nl: CARPOOL_NL
}

export const DATA_SHARING_TYPE = 'data_sharing'
export const CARPOOL_TYPE = 'data_carpool'
export const UNSUPPORTED_TYPE = 'unsupported'

export class Optin {
  constructor ({ accepted, id, text, time, user, fromUrl }) {
    this.accepted = accepted ?? false
    this.id = id ?? -1
    this.text = text ?? null
    this.user = user ?? null
    this.fromUrl = fromUrl ?? window.location.href

    if (isMoment(time)) {
      this.time = time
    } else if (typeof time === 'string') {
      this.time = moment(time.split('+')[0])
    } else {
      this.time = moment()
    }

    if (DATA_SHARING_TEXTS.includes(text)) {
      this.type = DATA_SHARING_TYPE
    } else if (CARPOOL_OPTIN_TEXTS.includes(text)) {
      this.type = CARPOOL_TYPE
    } else {
      this.type = UNSUPPORTED_TYPE
    }
  }

  toRequestBody () {
    return {
      accepted: this.accepted,
      text: this.text,
      fromUrl: this.fromUrl,
      user: this.user === null ? null : this.user.id
    }
  }

  clone (parameters = {}) {
    const co = new Optin(this)

    Object.keys(parameters).forEach(k => {
      co[k] = parameters[k]
    })

    return co
  }
}
