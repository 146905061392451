// Compute the devider to use in order to reduce the total of a partial quota
const computeQuotaPartialOverlappingWeight = (startDate, endDate, periodStartDate, periodEndDate) => {
  const overlapStartDate = startDate >= periodStartDate ? startDate : periodStartDate
  const overlapEndDate = endDate <= periodEndDate ? endDate : periodEndDate
  const weight = (overlapEndDate.diff(overlapStartDate)) / (periodEndDate.diff(periodStartDate))

  if (weight > 1) {
    return 1
  }

  if (weight < 0) {
    return 0
  }

  return weight
}

const isOverlapping = (startDate, endDate, periodStartDate, periodEndDate) => {
  return !(
    startDate > periodEndDate || endDate < periodStartDate
  )
}

const isPartialPlaces = (startDate, endDate, periodStartDate, periodEndDate) => {
  return startDate > periodStartDate || endDate < periodEndDate
}

const getEndDate = (date, timeOffset) => {
  date.add(timeOffset)

  const formattedDate = date.format('HH:mm')

  if (['00:59', '01:00'].includes(formattedDate)) {
    date.add({ hours: -1 })
  }

  return date
}

const setEndDate = (date, timeOffset) => {
  if (timeOffset.add) {
    date.add(timeOffset.add)
  }

  if (timeOffset.set) {
    date.set(timeOffset.set)
  }

  return date
}

const getEndDateByPeriod = (startDate, period) => {
  if (!startDate) {
    return null
  }

  const endDate = startDate.clone()

  if (!period) {
    return endDate
  }

  endDate.add(period.cellQuantity ?? 1, period.cellPeriod)
  endDate.subtract(1, 's')

  return endDate
}

const getMonthFirstDay = date => {
  return date.startOf('month')
}

const getWeekFirstDay = date => {
  return date.startOf('week')
}

const getIsoWeekFirstDay = date => {
  const firstDayOfWeek = date.clone().startOf('isoWeek')

  firstDayOfWeek.set({ hour: 0, minute: 0, second: 0 })

  return firstDayOfWeek
}

const purgeBackendDate = date => {
  if (typeof date === 'string') {
    return date.split('+')[0].replace('T', ' ')
  }

  return date
}

export {
  computeQuotaPartialOverlappingWeight,
  isOverlapping,
  isPartialPlaces,
  getEndDate,
  setEndDate,
  getEndDateByPeriod,
  getMonthFirstDay,
  getWeekFirstDay,
  getIsoWeekFirstDay,
  purgeBackendDate
}
