import React, { useState, useEffect } from 'react'
import { connect } from '../../reducers/Dispatchers'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import { Select } from 'antd'
import { canonizeString, generalErrorHandler, requestWithPromise, validateFormInput } from '../../utils'
import '../../assets/contact-persons-view.scss'
import { getInstitutions } from '../../reducers/InstitutionsReducer'
import { addSchoolUser, getSchools } from '../../reducers/SchoolsReducer'
import { getSchoolSections } from '../../reducers/SchoolSectionsReducer/actions'
import { getUser } from '../../reducers/UserReducer'
import { isLocalAdmin } from '../../Selectors/localAdministrator/localAdministrator'
import { getTranslate } from 'react-localize-redux'
import { getUserManagedSectorIdList } from '../../Selectors/user/user'
import { bindActionCreators } from 'redux'
import { fetchAllContactPersonsInstitution, fetchAllContactPersonsSchool, fetchAllContactPersonsSection, fetchAllContactPersonsSector, getContactPersons, updateContactPerson } from '../../reducers/ContactPersonsReducer/actions'
import { getSectors } from '../../reducers/SectorsReducer/actions'
import { INSTITUTION_CONTEXT, SCHOOL_CONTEXT } from '../../utils/constants'
import ContactPersonSectionsWidget from '../../Components/shared/ContactPersonSectionsWidget'
import ContactPersonSectorsWidget from '../../Components/shared/ContactPersonSectorsWidget'
import { formatOldSection, isNewSection, schoolSectionToString } from '../../utils/entitiesFormaters/SchoolSection'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getInstitutions: getInstitutions(state),
    getSchools: getSchools(state.getSchools),
    getSchoolSections: getSchoolSections(state.schoolSections),
    getUser: getUser(state.getUser),
    isLocalAdmin: isLocalAdmin(state.getUser),
    getUserManagedSectorIdList: getUserManagedSectorIdList(state.getUser),
    getContactPersons: getContactPersons(state),
    getSectors: getSectors(state)
  }
}

const mapDispatchToProps = dispatch => ({
  addSchoolUser: bindActionCreators(addSchoolUser, dispatch),
  fetchAllContactPersonsInstitution: bindActionCreators(fetchAllContactPersonsInstitution, dispatch),
  fetchAllContactPersonsSchool: bindActionCreators(fetchAllContactPersonsSchool, dispatch),
  fetchAllContactPersonsSector: bindActionCreators(fetchAllContactPersonsSector, dispatch),
  fetchAllContactPersonsSection: bindActionCreators(fetchAllContactPersonsSection, dispatch),
  updateContactPerson: bindActionCreators(updateContactPerson, dispatch)
})

const Option = Select.Option

const ContactPersonsView = props => {
  const [contactPersons, setContactPersons] = useState([])
  const [optionsSelect, setOptionSelect] = useState([])
  const [selectValue, setSelectValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedContactPersonSector, setSelectedContactPersonSector] = useState(null)
  const [selectedContactPersonSchoolSection, setSelectedContactPersonSchoolSection] = useState(null)
  const [institution, setInstitutiton] = useState(null)
  const [school, setSchool] = useState(null)

  useEffect(() => {
    if (props.getUser && props.getUser.context === INSTITUTION_CONTEXT) {
      if (props.getInstitutions.length > 0 && typeof props.fetchAllContactPersonsInstitution === 'function' && typeof props.fetchAllContactPersonsSector === 'function') {
        setLoading(true)
        props.fetchAllContactPersonsInstitution(props.getInstitutions[0].id, props.getUser)
        setInstitutiton(props.getInstitutions[0].id)

        requestWithPromise(`/api/institutions/${props.getInstitutions[0].id}/sectors?withContactPerson=1`, 'GET', null, props.getUser)
          .then(jsonData => {
            setOptionSelect(jsonData.data)
          })
      }
    }
  }, [props.getInstitutions[0]?.id, props.fetchAllContactPersonsInstitution, props.fetchAllContactPersonsSector, props.getUser])

  useEffect(() => {
    if (props.getUser && props.getUser.context === SCHOOL_CONTEXT) {
      if (props.getSchools.length > 0 && typeof props.fetchAllContactPersonsSchool === 'function') {
        setLoading(true)
        props.fetchAllContactPersonsSchool(props.getSchools[0].id, props.getUser)
        setSchool(props.getSchools[0].id)

        requestWithPromise(`/api/schools/${props.getSchools[0].id}/school-sections?withContactPerson=1`, 'GET', null, props.getUser)
          .then(jsonData => {
            setOptionSelect(jsonData.data[props.getSchools[0].id])
          })
      }
    }
  }, [props.getSchools[0]?.id, props.fetchAllContactPersonsSchool, props.getUser])

  useEffect(() => {
    if (props.getContactPersons) {
      loadContactPersons()
    }
  }, [props.getContactPersons])

  useEffect(() => {
    if (props.getSectors) {
      setOptionSelect(props.getSectors)
    }
  }, [props.getSectors])

  const loadContactPersons = async () => {
    try {
      setContactPersons(props.getContactPersons)
      setLoading(false)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  const columns = [
    { type: DATA_TYPE_ID, key: 'id' },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Lastname'),
      key: 'lastname',
      validate: data => {
        return validateFormInput('freeText', data, true)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Firstname'),
      key: 'firstname',
      validate: data => {
        return validateFormInput('freeText', data, true)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Email'),
      key: 'email',
      validate: data => {
        return validateFormInput('email', data)
      }
    },
    {
      type: DATA_TYPE_STRING,
      name: props.t('Phone'),
      key: 'phone'
    }
  ]

  const additionalActions = []

  if (props.getUser.context === SCHOOL_CONTEXT) {
    additionalActions.push({
      iconName: 'graduation-cap',
      type: 'primary',
      title: props.t('Display sections for this user'),
      onClick: u => setSelectedContactPersonSchoolSection(u)
    })
  } else if (props.getUser.context === INSTITUTION_CONTEXT) {
    additionalActions.push({
      iconName: 'stethoscope',
      type: 'primary',
      title: props.t('Display care units by this user'),
      onClick: u => setSelectedContactPersonSector(u)
    })
  }

  const handleSaveContactPerson = async (contact, stopSmartTableLoading) => {
    try {
      await props.updateContactPerson(contact, props.getUser)
      stopSmartTableLoading()
    } catch (err) {
      generalErrorHandler(err)
      stopSmartTableLoading()
    }
  }

  const handleChangeSelectSector = value => {
    if (value === 'all') {
      props.fetchAllContactPersonsInstitution(props.getInstitutions[0].id, props.getUser)
    } else {
      props.fetchAllContactPersonsSector(value, props.getUser)
    }

    setSelectValue(value)
  }

  const handleChangeSelectSchoolSection = value => {
    if (value === 'all') {
      props.fetchAllContactPersonsSchool(props.getSchools[0].id, props.getUser)
    } else {
      props.fetchAllContactPersonsSection(value, props.getUser)
    }

    setSelectValue(value)
  }

  const renderSelect = () => {
    if (optionsSelect) {
      return (
        <div>
          <Select
            showSearch
            className='select-sector'
            value={selectValue === null ? 'all' : selectValue}
            filterOption={(input, option) =>
              canonizeString(option.props.children).indexOf(
                canonizeString(input)
              ) > -1}
            onChange={e => {
              props.getUser.context === SCHOOL_CONTEXT
                ? handleChangeSelectSchoolSection(e)
                : handleChangeSelectSector(e)
            }}
          >
            {props.getUser.context === SCHOOL_CONTEXT
              ? <Option key='all' value='all' title={props.t('All school sections')}>{props.t('All school sections')}</Option>
              : <Option key='all' value='all' title={props.t('All care units')}>{props.t('All care units')}</Option>}

            {optionsSelect.map(s => {
              const sectionName = isNewSection(s)
                ? schoolSectionToString(s)
                : formatOldSection(s)

              return (
                <Option key={s.id} value={s.id} title={sectionName}>
                  {sectionName}
                </Option>
              )
            })}
          </Select>
        </div>
      )
    }
  }

  return (
    <>
      {renderSelect()}
      <SmartTable
        columns={columns}
        data={contactPersons}
        loading={loading}
        noDataText={props.t('There are no contact persons')}
        onDataEdit={handleSaveContactPerson}
        additionalActions={additionalActions}
      />
      <ContactPersonSectorsWidget
        user={selectedContactPersonSector}
        institution={institution}
        onClose={() => setSelectedContactPersonSector(null)}
      />
      <ContactPersonSectionsWidget
        user={selectedContactPersonSchoolSection}
        school={school}
        onClose={() => setSelectedContactPersonSchoolSection(null)}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonsView)
