import React, { useState, useEffect } from 'react'
import { Drawer, Checkbox, Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { anySchoolSectionToString } from '../../utils/entitiesFormaters/SchoolSection'
import { linkSchoolSection } from '../../utils/api/sectorDocument'

import '../../assets/institution-sector-files-assignment.scss'

const InstitutionSectorFileSectionsAssignment = props => {
  const [sections, setSections] = useState(null)
  const [selectedSections, setSelectedSections] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const allSections = []

    props.getSchools.forEach(school => {
      const schoolSections = props.getSchoolSections[school.id].map(section => {
        return { value: section.id, label: anySchoolSectionToString(section) }
      })

      allSections.push(...schoolSections)
    })

    setSections(allSections)
  }, [])

  useEffect(() => {
    if (props.file && props.file.sections) {
      const initialSelectedSections = props.file.sections.map(item => item.id)

      setSelectedSections(initialSelectedSections)
      setIndeterminate(!!initialSelectedSections.length && initialSelectedSections.length < sections.length)
      setAllChecked(initialSelectedSections.length === sections.length)
    } else {
      setSelectedSections([])
      setIndeterminate(false)
      setAllChecked(false)
    }
  }, [props.file])

  const handleSectionSelection = (selectedSections) => {
    setSelectedSections(selectedSections)
    setIndeterminate(!!selectedSections.length && selectedSections.length < sections.length)
    setAllChecked(selectedSections.length === sections.length)
  }

  const handleCheckAll = (e) => {
    setSelectedSections(e.target.checked ? sections.map(item => item.value) : [])
    setIndeterminate(false)
    setAllChecked(e.target.checked)
  }

  const save = async () => {
    setLoading(true)

    linkSchoolSection(props.getUser, props.file, selectedSections).then(json => {
      if (json?.data) {
        props.onSave(json.data)
      }

      setLoading(false)
    })
  }

  return (
    <Drawer
      title={`${props.t('Select sections for file:')} ${props.file ? props.file.name : ''}`}
      width={typeof props.width === 'undefined' ? '40%' : props.width}
      onClose={props.onClose}
      visible={props.file !== null}
    >
      {sections && !sections.length && <div>{props.t('No sections available')}</div>}
      {sections && sections.length > 0 && (
        <div className='sections-selection-for-file'>
          <div className='description'>
            {props.t('Assign the file to the following sections')}
          </div>
          <div className='select-all-wrapper'>
            <Checkbox
              indeterminate={indeterminate}
              onChange={handleCheckAll}
              checked={allChecked}
              disabled={loading}
            >
              {props.t('Select all')}
            </Checkbox>
          </div>
          <Checkbox.Group
            options={sections}
            value={selectedSections}
            onChange={handleSectionSelection}
            disabled={loading}
          />
          <div>
            <Button type='primary' onClick={save} disabled={loading}>{props.t('Save')}</Button>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSectorFileSectionsAssignment)
