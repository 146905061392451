import { createSelector } from 'reselect'
import { getSectors } from '../../reducers/SectorsReducer/actions'
import { arrayToObject } from '../../utils'

export const selectSectorsAsObject = createSelector(
  state => getSectors(state),
  sectors => {
    return arrayToObject(sectors, ['id'])
  }
)

export const orderByNameSectors = createSelector(
  state => getSectors(state),
  sectors => {
    return sectors.sort((a, b) => {
      if (!a.name) {
        return -1
      }

      if (!b.name) {
        return 1
      }

      return a.name.localeCompare(b.name)
    })
  }
)
