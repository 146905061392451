import React, { createContext, useEffect, useMemo, useState } from 'react'

import { getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { fetchSchoolOptions, getAllData } from '../utils/api/school'
import { getInstitutionSectors } from '../utils/api/institution'
import { DRAFT_INTERNSHIPS_TYPE } from '../utils/constants'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    user: getUser(state.getUser)
  }
}

export const SchoolContext = createContext()

const SchoolProvider = ({ children, currentLanguage, user }) => {
  const [actTypeCategories, setActTypeCategories] = useState([])
  const [school, setSchool] = useState(null)
  const [institutionConstraints, setInstitutionConstraints] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [schoolOptions, setSchoolOptions] = useState([])
  const [schoolPermissions, setSchoolPermissions] = useState({})
  const [schoolSections, setSchoolSections] = useState([])
  const [sectorsByInstitution, setSectorsByInstitution] = useState({})

  const [institutionSectorsLoading, setInstitutionSectorsLoading] = useState({})
  const [loading, setLoading] = useState(true)

  const useDraftInternships = useMemo(() => {
    let use = false

    for (const so of schoolOptions) {
      if (so.optionType.type === DRAFT_INTERNSHIPS_TYPE) {
        use = so.optionEnabled
        break
      }
    }

    return use
  }, [schoolOptions])

  useEffect(() => {
    if (user.school) {
      setLoading(true)

      getAllData(user, user.school).then(json => {
        if (json?.data) {
          setActTypeCategories(json.data.actTypeCategories)
          setSchool(json.data.school)
          setInstitutionConstraints(json.data.institutionConstraints)
          setInstitutions(json.data.institutions)
          setSchoolOptions(json.data.schoolOptions)
          setSchoolPermissions(json.data.schoolPermissions)
          setSchoolSections(json.data.schoolSections)
        }

        setLoading(false)
      })
    } else {
      setLoading(true)
      setSchool(null)
    }
  }, [user.school])

  const reloadSchoolOptions = user => {
    fetchSchoolOptions({ id: user.school.id }, user).then(json => {
      if (json?.data) {
        setSchoolOptions(json.data)
      }
    })
  }

  const loadInstitutionSectors = institution => {
    switchInstitutionSectorsLoading(true, institution.id)

    getInstitutionSectors(user, institution).then(json => {
      if (json?.data) {
        const updatedSectors = { ...sectorsByInstitution }

        updatedSectors[institution.id] = json.data

        setSectorsByInstitution(updatedSectors)
      }

      switchInstitutionSectorsLoading(false, institution.id)
    })
  }

  const switchInstitutionSectorsLoading = (value, institutionId) => {
    const newLoadings = { ...institutionSectorsLoading }

    newLoadings[institutionId] = value

    setInstitutionSectorsLoading(newLoadings)
  }

  return (
    <SchoolContext.Provider
      value={{
        actTypeCategories,
        institutionConstraints,
        institutions,
        school,
        schoolOptions,
        schoolPermissions,
        schoolSections,
        sectorsByInstitution,
        useDraftInternships,
        institutionSectorsLoading,
        schoolLoading: loading,
        loadInstitutionSectors,
        reloadSchoolOptions,
        setActTypeCategories,
        setInstitutions,
        setSectorsByInstitution
      }}
    >
      {children}
    </SchoolContext.Provider>
  )
}

export default connect(mapStateToProps)(SchoolProvider)
