import React from 'react'
import { string, bool, oneOfType, shape } from 'prop-types'
import { useLocation, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'

const MenuItem = ({ label, route, iconName, showLabel }) => {
  const location = useLocation()
  let active = route === location.pathname
  if (
    !active &&
    route === '/shifts-manager' &&
    location.pathname === '/presets-manager'
  ) {
    active = true
  }
  return (
    <Link to={route}>
      <Tooltip placement='right' title={label}>
        <div
          className={
            'flex-row inner-spacing menu-item ' + (active ? 'active ' : '')
          }
        >
          <div
            style={{
              minWidth: '36px',
              textAlign: 'center',
              marginRight: '10px'
            }}
          >
            <FontAwesomeIcon icon={iconName} style={{ textAlign: 'left' }} />
          </div>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {showLabel ? label : ''}
          </div>
        </div>
      </Tooltip>
    </Link>
  )
}

MenuItem.propTypes = {
  label: string,
  route: string,
  iconName: oneOfType([string, shape({ prefix: string, iconName: string })]),
  showLabel: bool
}

export default MenuItem
