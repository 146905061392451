import { GLOBAL_PLACES_KEY } from './Constants'

const hasSpecificQuotas = (quotas, sectorId, sectionId) => {
  if (!(sectorId && sectionId)) {
    return false
  }

  if (!(quotas[sectorId] && quotas[sectorId][sectionId])) {
    return false
  }

  return true
}

const getQuotaBySectorAndSection = (quotas, sectorId, sectionId, quotaId) => {
  return getQuotasBySection(quotas[sectorId], sectionId)[quotaId]
}

const getQuotasBySection = (quotas, section) => {
  return section === GLOBAL_PLACES_KEY
    ? quotas[section]
    : quotas[section][GLOBAL_PLACES_KEY]
}

export {
  hasSpecificQuotas,
  getQuotaBySectorAndSection,
  getQuotasBySection
}
