export const makeNewInstitutionConstraint = (institutionId, constraintType, entityName) => {
  return {
    institution: institutionId,
    type: constraintType?.type ?? null,
    constraintType: constraintType?.id ?? null,
    entityName: entityName,
    propertyName: null,
    enable: true
  }
}
