import React, { Component } from 'react'

class AppLoading extends Component {
  render () {
    return (
      <div className='loading-ring' />
    )
  }
}

export default AppLoading
