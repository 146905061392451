import React from 'react'

const CheckboxUserSectorTable = (
  { setUserContactPerson, setManagedUser, sectorId, isChecked, handleChangeChecked, indexSector, type }
) => {
  const checkEntity = () => {
    if (type === 0) {
      if (typeof setManagedUser === 'function') {
        setManagedUser(sectorId, isChecked)
      }
    } else {
      if (typeof setUserContactPerson === 'function') {
        setUserContactPerson(sectorId, isChecked)
      }
    }

    handleChangeChecked(indexSector, type)
  }

  return (
    <div className='div-checkbox'>
      <input checked={isChecked} onChange={checkEntity} className='checkbox' type='checkbox' />
    </div>
  )
}

export default (CheckboxUserSectorTable)
