import React, { useMemo, useState } from 'react'

import { Collapse, Divider, List, Modal } from 'antd'
import UnitActsFormModal from './UnitActsFormModal'
import TimeActsFormModal from './TimeActsFormModal'
import { formatTimeFromMinutes } from '../../../../../utils'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const { Panel } = Collapse

const mapStateToProps = state => { return { t: getTranslate(state.locale) } }

const ActsModal = ({ actTypes, visible, onOk, onCancel, t }) => {
  const [actModalVisible, setActModalVisible] = useState(false)
  const [subtitle, setSubtitle] = useState('')
  const [isUnit, setIsUnit] = useState(false)
  const [selectedActType, setSelectedActType] = useState({})
  const [newActs, setNewActs] = useState([])

  const data = actTypes

  const handleClose = (isSubmit, values = null) => {
    if (!isNullValues(values)) {
      isSubmit
        ? addValuesToActs(values)
        : handleCancel()
    }

    setSelectedActType({})
    setIsUnit(false)
    setSubtitle('')
    setActModalVisible(false)
  }

  const isNullValues = values => {
    if (values === null) {
      return false
    }

    if (typeof values.unit !== 'undefined') {
      return values.unit === 0
    }

    return values.hours === '0' && values.minutes === '00'
  }

  const handleCancel = () => {
    onCancel()
    setNewActs([])
  }

  const addValuesToActs = values => {
    const acts = [...newActs]

    const alreadyCreatedAct = acts.find(act => act.actType === selectedActType.id)

    if (alreadyCreatedAct) {
      alreadyCreatedAct.values = addValuesByFormat(alreadyCreatedAct.values, values, alreadyCreatedAct.hoursFormat)
    } else {
      setNewActs([...newActs, {
        actType: selectedActType.id,
        name: selectedActType.name,
        hoursFormat: selectedActType.hoursFormat,
        actTypeCategory: selectedActType.actTypeCategory,
        values: values
      }])
    }
  }

  const addValuesByFormat = (initialValue, values, isTime) => {
    if (isTime) {
      const minutesTime = (
        (parseInt(initialValue.hours) + parseInt(values.hours)) * 60 +
        parseInt(initialValue.minutes) +
        parseInt(values.minutes)
      )
      const newTimeParts = formatTimeFromMinutes(minutesTime).split(':')

      return {
        hours: newTimeParts[0],
        minutes: newTimeParts[1]
      }
    }

    initialValue.unit += values.unit

    return initialValue
  }

  const handleClick = (item) => {
    setSubtitle(item.name)
    setSelectedActType(item)
    item.hoursFormat ? setIsUnit(false) : setIsUnit(true)
    setActModalVisible(true)
  }

  const groupedActTypes = useMemo(() => {
    return data.reduce((acc, item) => {
      const categoryName = item.actTypeCategory?.name ?? t('Without category')

      if (!acc[categoryName]) {
        acc[categoryName] = []
      }

      acc[categoryName].push(item)

      return acc
    }, {})
  }, [data, t])

  const renderContent = () => {
    return (
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Collapse>
          {Object.entries(groupedActTypes).map(([categoryName, items], index) => (
            <Panel header={categoryName} key={index}>
              <div>
                <List
                  size='large'
                  bordered
                  dataSource={items}
                  renderItem={item => <List.Item className='clickable' onClick={() => handleClick(item)}>{item.name}</List.Item>}
                />
              </div>
            </Panel>
          ))}
        </Collapse>
        <Divider />
        {
          newActs.map((a, index) => {
            const value = a.hoursFormat ? a.values.hours + ':' + a.values.minutes : a.values.unit
            return (
              <div key={`act_${index}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{a.name}</div>
                <div>{value}</div>
              </div>
            )
          })
        }
      </div>
    )
  }

  const getFormModal = () => {
    if (!actModalVisible) {
      return <div />
    }

    if (isUnit) {
      return (
        <UnitActsFormModal
          title={subtitle}
          onCancel={() => setActModalVisible(false)}
          onClose={handleClose}
        />
      )
    }

    return (
      <TimeActsFormModal
        title={subtitle}
        onCancel={() => setActModalVisible(false)}
        onClose={handleClose}
      />
    )
  }

  return (
    <div>
      <Modal
        title={t('Encode an act')}
        visible={visible}
        onCancel={handleCancel}
        onOk={() => {
          onOk(newActs)
          setNewActs([])
        }}
        okText={t('Save')}
      >
        {renderContent()}
      </Modal>
      {getFormModal()}
    </div>
  )
}

export default connect(mapStateToProps)(ActsModal)
