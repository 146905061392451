import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { getAllInternships } from '../../../../utils/api/internship'
import { getUser } from '../../../../reducers/UserReducer'
import { DATE_WITHOUT_TIME, DIRECTION_DESC, ORDER_BY_CREATED_AT } from '../../../../utils/constants'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import moment from 'moment'
import { unset } from 'lodash'
import useLocalStorage from '../../../../hooks/UseLocalStorage'
import { bindActionCreators } from 'redux'
import { startCheckingForMessages, stopCheckingForNewMessages } from '../../../../reducers/NewMessagesReducer'
import InternshipsTable from './InternshipsTable'
import InternshipProvider from '../../../../Context/InternshipsManager/InternshipProvider'
import InternshipsManagerProvider from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import { GlobalFiltersContext } from '../../../../Providers/GlobalFiltersProvider'

const DEFAULT_PARAMETERS = {
  orderBy: ORDER_BY_CREATED_AT,
  pageSize: 10,
  filters: {
    institution: null,
    states: []
  },
  period: {
    startDate: moment().toISOString().split('T')[0],
    endDate: moment().add(1, 'years').toISOString().split('T')[0]
  },
  orderDirection: DIRECTION_DESC
}
const SESSION_STORAGE_PARAMETERS_KEY = 'internships-manager-view.parameters'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const mapDispatchToProp = dispatch => ({
  startCheckingForMessages: bindActionCreators(startCheckingForMessages, dispatch),
  stopCheckingForNewMessages: bindActionCreators(stopCheckingForNewMessages, dispatch)
})

const InternshipsListView = ({ user, startCheckingForMessages, stopCheckingForNewMessages }) => {
  const { selectedInstitution, internshipSearch, internshipDate, setInternshipDate, setInternshipSearch } = useContext(GlobalFiltersContext)

  const [internships, setInternships] = useState({ data: [], meta: { pageSize: 10 } })
  const [loading, setLoading] = useState(false)
  const [rawParameters, setRawParameters] = useLocalStorage(
    SESSION_STORAGE_PARAMETERS_KEY,
    { ...DEFAULT_PARAMETERS, filters: { ...DEFAULT_PARAMETERS.filters, institution: selectedInstitution } }
  )

  const parameters = useMemo(() => new TableParameters({
    ...rawParameters,
    search: internshipSearch ?? rawParameters.search,
    period: internshipDate
      ? { startDate: internshipDate.format(DATE_WITHOUT_TIME), endDate: internshipDate.clone().add(1, 'years').format(DATE_WITHOUT_TIME) }
      : rawParameters.period
  }), [rawParameters, internshipSearch, internshipDate])

  useEffect(() => {
    if (user && typeof startCheckingForMessages === 'function') {
      startCheckingForMessages(user)
    }

    return () => {
      stopCheckingForNewMessages()
    }
  }, [user, startCheckingForMessages])

  useEffect(() => {
    setLoading(true)
    fetchInternships(parameters)
  }, [selectedInstitution, parameters])

  const fetchInternships = useCallback(parameters => {
    const formattedParameters = {
      ...parameters,
      filters: { ...parameters.filters },
      startDate: parameters.period.startDate,
      endDate: parameters.period.endDate,
      contexts: ['coordinator'],
      states: parameters.filters.states
    }

    unset(formattedParameters, 'filters[states]')

    getAllInternships(user, formattedParameters).then(json => {
      if (json) {
        json.meta.pages = { ...json.meta.pages, pageSize: json.meta.pages.page_size, perPage: json.meta.pages.page_size, totalPages: json.meta.pages.total_pages }
        setInternships(json)
      }
    }).finally(() => setLoading(false))
  }, [setInternships, setLoading, user])

  const handleParametersChange = useCallback(parameters => {
    if (internshipDate) {
      setInternshipDate(null)
    }

    if (internshipSearch) {
      setInternshipSearch(null)
    }

    setRawParameters(parameters)
  }, [internshipDate, internshipSearch, setRawParameters, setInternshipDate, setInternshipSearch])

  return (
    <InternshipsManagerProvider>
      <InternshipProvider refreshQuotas={() => fetchInternships(parameters)}>
        <InternshipsTable
          data={internships}
          loading={loading}
          parameters={parameters}
          onParametersChange={handleParametersChange}
          onDataChange={fetchInternships}
        />
      </InternshipProvider>
    </InternshipsManagerProvider>

  )
}

export default connect(mapStateToProps, mapDispatchToProp)(InternshipsListView)
