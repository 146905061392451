import React from 'react'
const LOCAL_STORAGE_KEY = 'interneoAppVersion'
export default class AppClearCache extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      isLatestVersion: false,
      emptyCacheStorage: () => {
        console.info('Clearing cache and hard reloading...')
        if (global.caches) {
          // Service worker cache should be cleared with caches.delete()
          global.caches.keys().then(function (names) {
            for (const name of names) global.caches.delete(name)
          })
        }
        // delete browser cache and hard reload
        window.location.reload(true)
      }
    }
  }

  componentDidMount () {
    global.fetch(`/meta.json?date=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((meta) => {
        try {
          const latestVersion = meta.version
          const currentVersion = global.localStorage.getItem(LOCAL_STORAGE_KEY) || ''
          const shouldForceRefresh = latestVersion !== currentVersion
          if (shouldForceRefresh) {
            console.info(`We have a new version - ${latestVersion}. Should force refresh`)
            global.localStorage.setItem(LOCAL_STORAGE_KEY, latestVersion)
            this.setState({ loading: false, isLatestVersion: false })
          } else {
            this.setState({ loading: false, isLatestVersion: true })
          }
        } catch (err) {
          this.setState({ loading: false, isLatestVersion: true })
        }
      })
  }

  render () {
    const { loading, isLatestVersion, emptyCacheStorage } = this.state
    return this.props.children({ loading, isLatestVersion, emptyCacheStorage })
  }
}
