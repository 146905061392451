import React from 'react'

import { stringOptionFilter } from '../../utils/SelectOptionTools'
import { Col, InputNumber, Row, Select, Tooltip } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'

const Option = Select.Option
const SELECT_STYLE = { width: '200px' }
const TOOLTIP_PLACEMENT = 'top'

const PLACES_TITLE = 'Allocated places'
const PAUSE_TITLE = 'Pause'
export const PAUSE_KEY = 'name'

const PauseAllocationForm = props => {
  const handlePlacesChange = places => {
    if (Number.isInteger(places)) {
      props.onPlacesChanged(places)
    }

    if (places === '') {
      props.onPlacesChanged(0)
    }
  }
  const renderSelectOptions = () => {
    const options = []

    if (props.pauses) {
      props.pauses.forEach((pause, index) => {
        const value = props.t(pause[PAUSE_KEY])

        options.push(
          <Option key={`option_${index}`} value={value} data={pause}>
            <Tooltip placement={TOOLTIP_PLACEMENT} title={value}>
              {value}
            </Tooltip>
          </Option>
        )
      })
    }

    return options
  }

  return (
    <div className='allocate-quota-form'>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(PLACES_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <InputNumber
            value={props.places ?? 0}
            onChange={handlePlacesChange}
            disabled={props.disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(PAUSE_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <Select
            style={SELECT_STYLE}
            value={props.pause ? props.t(props.pause[PAUSE_KEY]) : '/'}
            showSearch
            onSelect={(value, option) => props.onPauseSelected(option.props.data)}
            filterOption={stringOptionFilter}
            disabled={props.disabled}
          >
            {renderSelectOptions()}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PauseAllocationForm)
