import React, { useContext, useState } from 'react'

import TitledSelector from './TitledSelector'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import SearchCheckbox from '../../../antd/Selects/SearchCheckbox'
import Loading from '../../../shared/Loading'

const DEFAULT_STYLE = {
  width: '100%',
  height: '100%',
  padding: '10px',
  paddingRight: '10px',
  border: '1px solid #e8e8e8',
  borderRadius: '10px',
  overflowY: 'auto'
}
const TITLED_SELECTOR_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginBottom: '10px'
}

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const Settings = ({ onSchoolsChanged, onSectionsChange, onSelectSector, t }) => {
  const { globalLoading, loading, sections, sectors, schools } = useContext(QuotasContext)

  const [selectedSector, setSelectedSector] = useState(null)
  const [selectedSections, setSelectedSections] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])

  const handleSectorSelect = newSector => {
    setSelectedSector(newSector)
    onSelectSector(newSector)
  }

  const handleSectionCheck = (section, add) => {
    const newSections = add
      ? [...selectedSections, section]
      : selectedSections.filter(ss => ss.id !== section.id)

    setSelectedSections(newSections)
    onSectionsChange(newSections.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation)))
  }

  const handleSchoolCheck = (school, add) => {
    const newSchools = add
      ? [...selectedSchools, school]
      : selectedSchools.filter(ss => ss.id !== school.id)

    setSelectedSchools(newSchools)
    onSchoolsChanged(newSchools)
  }

  return (
    <div style={DEFAULT_STYLE}>
      {!loading && !globalLoading && (
        <>
          <TitledSelector
            style={TITLED_SELECTOR_STYLE}
            data={sectors}
            dataKey='name'
            title={t('Care unit')}
            placeholder={t('Choose a care unit')}
            onDataSelected={handleSectorSelect}
          />
          {selectedSector && (
            <SearchCheckbox
              checkAllOption
              items={sections}
              labelKey='abbreviation'
              tooltipKey='name'
              title={t('Sections')}
              valueKey='id'
              onCheck={handleSectionCheck}
              onCheckAll={sections => {
                setSelectedSections(sections)
                onSectionsChange(sections)
              }}
            />
          )}
          {selectedSector && (
            <SearchCheckbox
              checkAllOption
              initCheckAll
              items={schools}
              labelKey='name'
              title={t('Schools')}
              valueKey='id'
              dataKey='name'
              onCheck={handleSchoolCheck}
              onCheckAll={schools => {
                setSelectedSchools(schools)
                onSchoolsChanged(schools)
              }}
            />
          )}
        </>
      )}
      {(loading || globalLoading) && <Loading size='1x' />}
    </div>
  )
}

export default connect(mapStateToProps)(Settings)
