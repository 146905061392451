import { Checkbox, Divider, Input } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { handleCheckAll, isAllChecked } from '../antdHelper'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const { Search } = Input

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const SearchCheckboxList = ({ data, label, values, onChange, t }) => {
  const [checkedItems, setCheckedItems] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setCheckedItems(values)
  }, [values, setCheckedItems])

  useEffect(() => {
    onChange(checkedItems)
  }, [checkedItems])

  const handleCheck = useCallback((sector, checked) => {
    if (checked) {
      setCheckedItems(checkedItems.filter(d => d.id !== sector.id))
    } else {
      setCheckedItems([...checkedItems, sector])
    }
  }, [checkedItems])

  const displayedData = useMemo(() => data.filter(d => search === '' || d[label].toLowerCase().includes(search)), [data, search, label])
  const allChecked = useMemo(() => isAllChecked(displayedData, checkedItems, d => d.id), [displayedData, checkedItems])

  const checkAll = useCallback(() => {
    setCheckedItems(handleCheckAll(displayedData, checkedItems, d => d.id, allChecked))
  }, [setCheckedItems, displayedData, checkedItems, allChecked])

  return (
    <div style={{ height: '100%' }}>
      <Search onChange={e => setSearch(e.target.value.toLowerCase())} />
      <Checkbox style={{ marginTop: '8px' }} checked={allChecked} onChange={checkAll}>
        {t('Select all')}
      </Checkbox>
      <Divider style={{ margin: '12px 0px 12px 0px' }} />
      <div className='flex-col ovy-a' style={{ maxHeight: 'calc(100% - 80px)' }}>
        {displayedData.map(d => {
          const checked = checkedItems.some(c => c.id === d.id)

          return (
            <Checkbox checked={checked} key={d.id} style={{ margin: '0 0 0 8px' }} onChange={() => handleCheck(d, checked)}>
              {d[label]}
            </Checkbox>
          )
        })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SearchCheckboxList)
