import React, { useEffect, useState } from 'react'

import TotalCell from './tableCells/TotalCell'
import TitleCell from './tableCells/TitleCell'
import RegularCell from './tableCells/RegularCell'
import { DEFAULT_EMPTY_TABLE_STYLE, DEFAULT_SPAN_STYLE } from '../allocation/AllocationTable'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'

import '../../../../assets/quotas.scss'
import '../assets/places-definition.scss'

const EMPTY_TABLE_MESSAGE = 'Choose at least a Care unit in order to add or manage places!'

const PlacesDisplayTable = (props) => {
  const { pauseActivated } = props.getAppStates
  const columns = props.columns ?? []

  const [sectors, setSectors] = useState([])
  const [tableCellsTotal, setTableCellsTotal] = useState([])

  useEffect(() => {
    if (props.sectors) {
      setSectors(objectToArray(props.sectors))
    }
  }, [props.sectors])

  useEffect(() => {
    if (props.data && sectors) {
      const { pauseActivated } = props.getAppStates
      const totalPerColumn = getTotalPerColumns(props.data, props.pauses, sectors, pauseActivated)

      setTableCellsTotal(totalPerColumn)
    }
  }, [props.data, props.pauses, props.getAppStates, sectors])

  const getTotalPerColumns = (data, pauses, sectors, pauseActivated) => {
    return data.map((columnData, index) => {
      const localTotal = {
        total: 0,
        details: {}
      }

      sectors.forEach(sector => {
        const sectorData = columnData[sector.id]

        if (sectorData) {
          const { total } = sectorData
          const details = pauseActivated
            ? getPausesDetailsIfExist(pauses, index, sector.id)
            : sectorData.details

          localTotal.total += total

          Object.keys(details).forEach(id => {
            if (!localTotal.details[id]) {
              localTotal.details[id] = 0
            }

            localTotal.details[id] += details[id]
          })
        }
      })

      return localTotal
    })
  }

  const getPausesDetailsIfExist = (pauses, index, sectorId, emptyValue = []) => {
    if (pauses.length === 0 || !pauses[index]) {
      return emptyValue
    }

    return pauses[index][sectorId]?.details ?? emptyValue
  }

  const objectToArray = (object) => {
    let array = []
    const objectKeys = Object.keys(object)
    if (objectKeys) {
      array = objectKeys.map(key => {
        return object[key]
      })
    }

    return array
  }

  const renderTableTotalRow = () => {
    const totalCells = columns.map((column, index) => {
      if (index === 0) {
        return renderTotalRowTitle(index)
      }

      return (
        <TotalCell
          key={`total-${index}`}
          dataIds={props.sectionIdsToDisplay ?? []}
          totalData={tableCellsTotal[index]}
        />
      )
    })

    return (
      <tr key='total' className='definition-total-row'>
        {totalCells}
      </tr>
    )
  }

  const renderTotalRowTitle = index => {
    return (
      <td key={'total' + index}> {props.t('Total')} </td>
    )
  }

  const renderColumnsHeader = () => {
    const tableHeader = columns.map((column, index) => {
      return (
        <th key={`header-${index}`}> {props.t(column.title)} </th>
      )
    })

    return (
      <tr className='definition-header-row'>
        {tableHeader}
      </tr>
    )
  }

  const renderTableBody = () => {
    const tableBody = []

    if (!columns || columns.length === 0) {
      return tableBody
    }

    tableBody.push(renderTableTotalRow())

    if (props.data.length === columns.length) {
      sectors.forEach((sector, index) => {
        tableBody.push(renderTableRow(sector, index))
      })
    }

    return tableBody
  }

  const renderTableRow = (sector, rowIndex) => {
    const cells = columns.map((column, colIndex) => {
      return renderTableCell(sector, colIndex, rowIndex)
    })

    return (
      <tr key={`row-${rowIndex}`}>
        {cells}
      </tr>
    )
  }

  const renderTableCell = (sector, colIndex, rowIndex) => {
    const key = `cell-${rowIndex}-${colIndex}`

    if (colIndex === 0) {
      return (
        <TitleCell
          key={key}
          data={sector}
          selector='name'
        />
      )
    }

    const data = { ...props.data[colIndex][sector.id] }

    if (pauseActivated) {
      data.details = getPausesDetailsOrEmpty(colIndex, sector.id)
    }

    return (
      <RegularCell
        key={key}
        dataIds={props.sectionIdsToDisplay ?? []}
        totalData={data}
        period={props.timePeriod}
        date={props.currentDate}
        onClick={() => props.onCellSelected(sector, rowIndex, colIndex)}
        onDoubleClick={() => props.onCellDoubleClicked(sector, rowIndex, colIndex)}
        selected={isCellSelected(rowIndex, colIndex)}
      />
    )
  }

  const isCellSelected = (rowIndex, colIndex) => {
    const selectedCell = props.selectedCell

    return selectedCell && selectedCell.rowIndex === rowIndex && selectedCell.colIndex === colIndex
  }

  const renderContainer = () => {
    if (isEmpty()) {
      return (
        <span style={DEFAULT_SPAN_STYLE}>
          {props.t(EMPTY_TABLE_MESSAGE)}
        </span>
      )
    }

    return (
      <table className='definition-table'>
        <thead>
          {renderColumnsHeader()}
        </thead>
        <tbody>
          {renderTableBody()}
        </tbody>
      </table>
    )
  }

  const isEmpty = () => {
    return sectors.length === 0
  }

  const getClasses = () => {
    let classes = 'places-definition-table-div'

    if (props.sectionIdsToDisplay && props.sectionIdsToDisplay.length > 0) {
      classes = classes + ' ' + 'expanded-cell-width'
    }

    return classes
  }

  const getPausesDetailsOrEmpty = (index, sectorId) => {
    if (props.pauses.length === 0) {
      return {}
    }

    const pauses = props.pauses[index][sectorId]

    return pauses?.details ?? {}
  }

  return (
    <div className={getClasses()} style={isEmpty() ? DEFAULT_EMPTY_TABLE_STYLE : {}}>
      {renderContainer()}
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacesDisplayTable)
