import React, { useMemo, useState, useEffect, useCallback } from 'react'

import moment from 'moment'
import ShiftsManager from '../../Components/shared/ShiftsManager/ShiftsManager'
import { urlSearchParamsToObject } from '../../utils'
import { setLanguage } from '../../utils/locale'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { getMe } from '../../utils/api/user'
import { HTTP_UNAUTHORIZED } from '../../utils/http'
import { onError } from '../../utils/apiHelper'
import { LANGUAGE_LOCALE_TAG_FR_BE, SUPPORTED_LANGUAGE_LOCALES } from '../../utils/constants'

const ShiftsWidget = props => {
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const { lg, start, sector, displayMax, filter, token } = useMemo(() => urlSearchParamsToObject(), [])
  const isReady = useMemo(() => !loading && startDate && endDate, [loading, startDate, endDate])

  const setWidgetLanguage = useCallback(lg => {
    const { addTranslationForLanguage, setActiveLanguage, getActiveLanguage } = props

    const language = lg && SUPPORTED_LANGUAGE_LOCALES.includes(lg) ? lg : LANGUAGE_LOCALE_TAG_FR_BE

    setLanguage({
      language,
      addTranslationForLanguage,
      setActiveLanguage,
      getActiveLanguage
    })
  }, [setLanguage, props.addTranslationForLanguage, props.setActiveLanguage, props.getActiveLanguage])

  const setDates = useCallback(start => {
    const startDate = start ? moment(start).startOf('isoWeek') : moment().startOf('isoWeek')
      .hours(0)
      .minutes(0)
      .seconds(0)
    const endDate = startDate.clone().add(3, 'weeks').endOf('isoWeek')

    setStartDate(startDate)
    setEndDate(endDate)
  }, [setStartDate, setEndDate])

  const loginWidgetUser = useCallback(token => {
    if (token) {
      setLoading(true)

      getMe(token).then(json => {
        if (json?.data) {
          const loggedInUser = { ...json.data }
          loggedInUser.context = 'HOSPITAL'
          loggedInUser.token = token
          props.setUser(loggedInUser)
        }

        setLoading(false)
      }).catch(error => {
        if (error.status === HTTP_UNAUTHORIZED) {
          onError(props.t('error.INVALID_TOKEN_PRODIVED'))
        }
      })
    }
  }, [setLoading, props.setUser, props.t])

  useEffect(() => setWidgetLanguage(lg), [lg])
  useEffect(() => setDates(start), [start])
  useEffect(() => loginWidgetUser(token), [token])

  // this should open a new tab
  const handleOnClick = () => {
    window.open(window.location.origin, '_blank')
  }

  return (
    <div>
      {isReady && token && (
        <ShiftsManager
          inWidgetMode
          start={startDate}
          studentsPerPage={displayMax}
          defaultFilter={filter ?? null}
          sector={sector}
          onWidgetCoverClick={handleOnClick}
          widgetMode
        />
      )}
      {!isReady && token && (
        <div className='loading-ring' />
      )}
      {!token && props.t('A token must be provided in order to use this page')}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsWidget)
