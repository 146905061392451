import { validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'
import { getFullAbbreviation } from './sectionToYear'

export const SECTOR_VALIDATORS = [
  { key: VALIDATION_FIELDS.DETAILS, validator: s => validateString(s.details) },
  { key: VALIDATION_FIELDS.SECTION_TO_YEAR, validator: s => !!s.sectionToYear }
]

export const getDetails = sp => {
  return sp.details
}

export const getFullSectionToYearAbbreviation = sp => {
  return getFullAbbreviation(sp.sectionToYear)
}
export class StudentPathway {
  constructor ({ id = -1, details = '', sectionToYear = null, sector = null } = {}) {
    this.id = id
    this.details = details
    this.sectionToYear = sectionToYear
    this.sector = sector
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = this.institution?.id ?? null
    body.manager = this.manager?.id ?? null
    body.tutors = this.tutors.map(t => t.id)

    return body
  }
}
