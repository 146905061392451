import React, { useEffect, useRef, useState } from 'react'
import Search from 'antd/lib/input/Search'
import { isArray } from 'lodash'
import { Checkbox } from 'antd'
import Loading from '../../shared/Loading'
import '../../../assets/lazy-checkbox.scss'
import { isFunction } from '../../../utils/typeTesting'

const LazyCheckbox = ({ checked, data, dataKey, dataItem, loading, showSearch, onChange, onParametersChange, onBottomReach }) => {
  const [checkedList, setCheckedList] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [search, setSearch] = useState('')

  const scrollRef = useRef(null)

  useEffect(() => {
    if (isArray(checked)) {
      const checkedList = {}

      checked.forEach(c => {
        checkedList[c.id] = true
      })

      setSelectedItems(checked)
      setCheckedList(checkedList)
    }
  }, [checked])

  const handleScroll = () => {
    if (
      scrollRef.current.scrollTop + scrollRef.current.offsetHeight === scrollRef.current.scrollHeight &&
      typeof onBottomReach === 'function'
    ) {
      onBottomReach()
    }
  }

  const handleChange = d => {
    const newCheckedList = { ...checkedList }

    newCheckedList[d.id] = !checkedList[d.id]

    const newSelectedItems = newCheckedList[d.id]
      ? [...selectedItems, d]
      : selectedItems.filter(si => si.id !== d.id)

    setSelectedItems(newSelectedItems)
    setCheckedList(newCheckedList)
    onChange(newSelectedItems)
  }

  return (
    <div className='lazy-checkbox-container'>
      {showSearch && (
        <Search
          value={search}
          onChange={e => {
            onParametersChange({ search: e.target.value })
            setSearch(e.target.value)
          }}
        />
      )}
      <div className='lazy-checkbox-body' ref={scrollRef} onScroll={handleScroll}>
        {isArray(data) && data.length > 0 && (
          data.map(d => {
            const label = isFunction(dataItem) ? dataItem(d) : d[dataKey]

            return (
              <Checkbox
                key={d.id}
                checked={checkedList[d.id]}
                onChange={() => handleChange(d)}
              >
                {label}
              </Checkbox>
            )
          })
        )}
        {loading && (<Loading size='1x' />)}
      </div>
    </div>
  )
}

export default LazyCheckbox
