import React, { useCallback, useMemo, useState } from 'react'

import { Drawer } from 'antd'
import InternshipRequestFilesTable from './InternshipRequestFilesTable'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import useRequest from '../../../../hooks/useRequest'
import { getUser } from '../../../../reducers/UserReducer'
import { deleteInternshipRequestFile, getInternshipRequestFiles } from '../../../../utils/api/internshipRequest'
import InternshipRequestFileModal from '../../../../Providers/Forms/InternshipRequestFileModal'
import { onError, onSuccess } from '../../../../utils/apiHelper'
import isFunction from 'lodash'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const DEFAULT_REQUEST = { data: null, callback: null }

export const InternshipRequestFilesDrawerContext = React.createContext()

const InternshipRequestFilesDrawer = ({ children, user, t }) => {
  const [selectedRequest, setSelectedRequest] = useState(DEFAULT_REQUEST)
  const [dirty, setDirty] = useState(false)
  const [parameters, setParameters] = useState({})
  const { data, loading, metadata, reload, setData } = useRequest({ request: getInternshipRequestFiles, parameters: useMemo(() => [user, selectedRequest.data, parameters], [user?.id, parameters, selectedRequest.data]) })

  const handleDelete = useCallback(file => {
    deleteInternshipRequestFile(user, selectedRequest.data, file).then(json => {
      if (json?.data) {
        setData(data.filter(f => f.id !== json.data.id))
        setDirty(true)
        onSuccess(t('internship_request_file.delete.success'))
      } else {
        onError(t('internship_request_file.delete.error'))
      }
    })
  }, [selectedRequest.data, user, data, setData, setDirty, t])

  const handleClose = useCallback(() => {
    if (dirty) {
      if (isFunction(selectedRequest.callback())) {
        selectedRequest.callback()
      }

      setDirty(false)
    }

    setSelectedRequest(DEFAULT_REQUEST)
  }, [setSelectedRequest, setDirty, selectedRequest.callback, dirty])

  return (
    <>
      <InternshipRequestFilesDrawerContext.Provider value={{ setSelectedRequest }}>
        {children}
      </InternshipRequestFilesDrawerContext.Provider>
      <Drawer
        title={t('internship_requests_table.actions.consult_files')}
        width={720}
        onClose={handleClose}
        visible={!!selectedRequest.data}
      >
        <InternshipRequestFileModal>
          <InternshipRequestFilesTable
            data={data}
            institution={user.institutions[0]}
            individualInternshipRequest={selectedRequest.data}
            loading={loading}
            metadata={metadata}
            parameters={parameters}
            onDelete={handleDelete}
            onParametersChange={setParameters}
            reload={() => {
              reload()
              setDirty(true)
            }}
          />
        </InternshipRequestFileModal>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(InternshipRequestFilesDrawer)
