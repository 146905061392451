import React from 'react'

import Joyride from 'react-joyride'

const GLOBAL_OPTIONS = {
  backgroundColor: '#335f8a',
  textColor: '#F5F3F2',
  arrowColor: '#335f8a',
  width: 350,
  fontFamily: 'Roboto',
  textAlign: 'center',
  borderRadius: 50
}

const TITLE = {
  textAlign: 'center',
  color: '#FFFFFF',
  marginRight: '30px'
}

const CONTENT = {
  textAlign: 'justify',
  color: '#F5F3F2'
}

const BUTTON_NEXT = {
  backgroundColor: '#5574a0',
  opacity: 20,
  color: '#d9e1eb',
  fontSize: '18px',
  fontWeight: 'bold'
}

const BUTTON_BACK = {
  color: '#a4b7cf',
  fontSize: '15px',
  fontWeight: 'bold'
}

const BUTTON_CLOSE = {
  width: '20px',
  height: '20px'
}

const InterneoJoyride = ({ callback, tourState, t }) => {
  return (
    <>
      <Joyride
        {...tourState}
        run={!!tourState}
        callback={callback}
        styles={{
          options: GLOBAL_OPTIONS,
          tooltipTitle: TITLE,
          tooltipContainer: CONTENT,
          buttonNext: BUTTON_NEXT,
          buttonBack: BUTTON_BACK,
          buttonClose: BUTTON_CLOSE
        }}
        locale={{
          next: t('Next'),
          skip: t('Skip intro'),
          back: t('Previous'),
          last: t('Finish')
        }}
      />
    </>
  )
}

export default InterneoJoyride
