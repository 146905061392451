import React, { useState } from 'react'
import Cookie from 'js-cookie'
import { func } from 'prop-types'
import { useHistory } from 'react-router-dom'
import LoginForm from '../../../Components/anonymous/LoginForm'
import AnonymousFormWrapper from '../../../Components/shared/AnonymousFormWrapper'
import { request } from '../../../utils'
import routes from '../../../routes'

const LoginView = props => {
  const [loginPending, setLoginPending] = useState(false)
  const history = useHistory()

  const login = async ({ username, password, remember }) => {
    setLoginPending(true)

    const body = {
      username: username.trim(),
      password
    }

    try {
      const response = await request('/auth', 'POST', body)
      const { token } = response

      if (typeof token === 'undefined') {
        setLoginPending(false)
        return
      }

      if (remember) {
        Cookie.set('token', JSON.stringify(token), { expires: 7 })
      } else {
        Cookie.remove('token')
      }

      props.onGetUserContextAndData(token).then(() => {
        if (typeof props.onAfterLogin === 'function') {
          props.onAfterLogin()
        }
      })
      setLoginPending(false)
    } catch (err) {
      Cookie.remove('token')
      console.warn(`Error logging in: ${err.message}`)
    }
  }

  return (
    <AnonymousFormWrapper>
      <LoginForm
        onLogin={login}
        loginPending={loginPending}
        onPasswordResetClick={() => {
          history.push(routes.PASSWORD_RESET_REQUEST)
        }}
      />
    </AnonymousFormWrapper>
  )
}

LoginView.propTypes = {
  onGetUserContextAndData: func.isRequired
}

export default LoginView
