import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getUser } from '../../../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import GenericStatesPellet from '../../StatesPellet/GenericStatesPellet'
import { internshipStates } from '../../../../utils/constants'
import { selectInstitutionConstraintsByInstitution } from '../../../../Selectors/InstitutionConstraints/InstitutionConstraintsSelector'
import { buildMissingPropertiesMessage } from '../../InternshipsManager/utils/utils'

const Option = Select.Option
const { AUTO_ACCEPTED, PENDING_STUDENT_DATA_MISSING } = internshipStates

const mapStateToProps = state => {
  return {
    constraints: selectInstitutionConstraintsByInstitution(state),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const StatesSelector = ({ constraints, disabled, internship, showLabel, states, t, user, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [missingConstraintsChunk, setMissingConstraintsChunk] = useState(null)

  useEffect(() => {
    if (internship.institution && internship.state === PENDING_STUDENT_DATA_MISSING) {
      setMissingConstraintsChunk(buildMissingPropertiesMessage(
        internship,
        constraints[internship.institution.id],
        t
      ))
    }
  }, [constraints, internship.institution, internship.state, t])

  const getClass = () => {
    let className = 'internship-state-pellet-wrapper'

    if (internship.state === AUTO_ACCEPTED) {
      className += ' auto-accepted-border'
    }

    return className
  }

  return (
    <Select
      value={internship.state}
      onChange={onChange}
      onDropdownVisibleChange={open => setIsOpen(open)}
      showArrow={false}
      disabled={disabled}
      style={{ width: isOpen ? 225 : 45 }}
      className={getClass()}
    >
      {typeof states === 'object' && states.map(s => {
        return (
          <Option value={s.state} key={s.state}>
            <div className='internship-state-pellet-option open'>
              {isOpen && (<div>{t(s.title)} </div>)}
              <GenericStatesPellet
                context={user.context}
                state={s.state}
                missingConstraintsChunk={missingConstraintsChunk}
                showLabel={showLabel}
              />
            </div>
          </Option>
        )
      })}
    </Select>
  )
}

export default connect(mapStateToProps)(StatesSelector)
