import moment from 'moment'
import { isObject } from '..'
import { SORT_DIRECTION_ASC } from '../../Components/shared/InternshipsManager/utils/constants'
import { API_DATE_FORMAT } from '../apiHelper'

export class TableParameters {
  constructor ({ search, orderBy, orderDirection, page, pageSize, period, filters = {} } = {}) {
    this.filters = filters
    this.search = search ?? ''
    this.orderBy = orderBy ?? null
    this.orderDirection = orderDirection ?? SORT_DIRECTION_ASC
    this.page = page ?? 1
    this.pageSize = pageSize ?? 10
    this.period = period
  }

  toQueryParameters () {
    return {
      endDate: isObject(this.period) ? moment(this.period.endDate).format(API_DATE_FORMAT) : null,
      filters: this.filters,
      search: this.search,
      orderBy: this.orderBy,
      orderDirection: this.orderDirection,
      page: this.page,
      pageSize: this.pageSize,
      startDate: isObject(this.period) ? moment(this.period.startDate).format(API_DATE_FORMAT) : null
    }
  }
}
