import React, { createContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import { fetchInstitutionGroup } from '../utils/api/institutionGroup'
import { isObject } from '../utils'
import { getInstitutionSectors } from '../utils/api/institution'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

export const InstitutionGroupContext = createContext()

const InstitutionGroupProvider = ({ children, user }) => {
  const [institutionGroup, setInstitutionGroup] = useState(null)
  const [institutions, setInstitutions] = useState([])
  const [sectorsByInstitution, setSectorsByInstitution] = useState({})
  const [schools, setSchools] = useState([])

  useEffect(() => {
    if (user.institutionGroup.id) {
      fetchInstitutionGroup(user, user.institutionGroup).then(data => {
        setInstitutionGroup(data)
        setInstitutions(data.institutions ?? [])
        setSchools(data.schools ?? [])
      })
    }
  }, [user.institutionGroup.id])

  const retrieveInstitutionSectors = institution => {
    let sectors = []

    if (isObject(institution)) {
      if (sectorsByInstitution[institution.id]) {
        sectors = sectorsByInstitution[institution.id]
      } else {
        getInstitutionSectors(user, institution).then(json => {
          if (json?.data) {
            const newSectorsByInstitution = { ...sectorsByInstitution }

            newSectorsByInstitution[institution.id] = json.data

            setSectorsByInstitution(newSectorsByInstitution)
          }
        })
      }
    }

    return sectors
  }

  return (
    <InstitutionGroupContext.Provider
      value={{
        institutions,
        institutionGroup,
        schools,
        retrieveInstitutionSectors,
        setInstitutionGroup,
        setInstitutions,
        setSchools
      }}
    >
      {children}
    </InstitutionGroupContext.Provider>
  )
}

export default connect(mapStateToProps)(InstitutionGroupProvider)
