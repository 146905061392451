import pull from 'lodash/pull'
const SET_SATISFACTION_RATIO_FILTER = 'SET_SATISFACTION_RATIO_FILTER'

export const getSatisfactionRatioFilters = (state) => {
  return state.satisfactionRatioFilters
}

export const setSatisfactionRatioFilter = (id, value) => {
  return {
    type: SET_SATISFACTION_RATIO_FILTER,
    payload: { id, value }
  }
}

const initialState = [
  { id: 'institutionEvaluationSatisfactionRatio', values: [] },
  { id: 'studentEvaluationSatisfactionRatio', values: [] },
  { id: 'matchScore', values: [] }
]

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SATISFACTION_RATIO_FILTER:
    {
      const updatedState = state.slice()
      const itemToUpdate = updatedState.find(item => item.id === action.payload.id)
      if (itemToUpdate) {
        if (itemToUpdate.values.includes(action.payload.value)) {
          pull(itemToUpdate.values, action.payload.value.toString())
        } else {
          itemToUpdate.values.push(action.payload.value)
        }
      }
      return updatedState
    }
    default:
      return state
  }
}
