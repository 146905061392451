import React, { useState, useEffect } from 'react'

import { sortingKeyFactory } from './FactoriesUtils'
import OptionButtonFactory from './OptionButtonFactory'
import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'

const DEFAULT_MAX_DATA_DISPLAYED = 10

const TableGenerator = (props) => {
  const [data, setData] = useState(props.rows)
  const [sortingKey, setSortingKey] = useState(props.initialSortingKey)
  const [maxDataDisplay, setMaxDataDisplay] = useState(DEFAULT_MAX_DATA_DISPLAYED)
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)

  // Set and filter data when new data is pass to the table
  useEffect(() => {
    if (typeof props.rows === 'object') {
      if (props.dataFilter && props.filterValue && props.columnFilteredKey) {
        setData(props.dataFilter(props.rows, props.filterValue, props.columnFilteredKey))
      } else {
        setData(props.rows)
      }
    }
  }, [props.rows, props.filterValue, props.columnFilteredKey])

  // Set MaxDataDisplay in table when his value is modify by the manager
  useEffect(() => {
    if (props.maxDataDisplay) {
      setMaxDataDisplay(props.maxDataDisplay)
    }
  }, [props.maxDataDisplay])

  // Set and modify max table page index when maxDataDisplay state is modify
  useEffect(() => {
    if (maxDataDisplay && data) {
      const dataLength = Object.keys(data).length
      setMaxPageIndex(dataLength % maxDataDisplay === 0 ? Math.floor(dataLength / maxDataDisplay) : Math.floor(dataLength / maxDataDisplay) + 1)
    }
  }, [maxDataDisplay])

  // Set and modify max table page index when data state is modify
  useEffect(() => {
    if (data && maxDataDisplay) {
      const dataLength = Object.keys(data).length
      setMaxPageIndex(dataLength % maxDataDisplay === 0 ? Math.floor(dataLength / maxDataDisplay) : Math.floor(dataLength / maxDataDisplay) + 1)
    }
  }, [data])

  /**
   * Add the increment parameter value to the pageIndex state of the Component
   *
   * @param {integer} increment
   */
  const incrementPageIndex = increment => {
    let newPageIndex = pageIndex + increment
    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  /**
   * Modify the current sortingKey state in order to modify the data sorting method and column
   *
   * @param {*} columnKey              a valid column key
   * @param {function} sortingFunction a function use in order to sort data state object keys
   */
  const changeSortingKey = (columnKey, sortingFunction) => {
    if (sortingFunction) {
      sortingKey && columnKey === sortingKey.columnKey
        ? setSortingKey({ ...sortingKey, reverse: !sortingKey.reverse })
        : setSortingKey(sortingKeyFactory(columnKey, sortingFunction))
    }
  }

  /**
   * Build the message in case of no data is provided
   *
   * @returns grappe react
   */
  const defaultNoDataMessageFactory = () => {
    return (
      <strong className='flex-row'>
        <div className='flex-fill' />
        {props.t('No data is available')}
        <div className='flex-fill' />
      </strong>
    )
  }

  /**
   * Use the parent callback in order to clean a launched timeout
   *
   * @param {*} dataId the data identifier
   */
  const cleanTimeOut = (dataId) => {
    props.cleanTimeOut(dataId)
  }

  /**
   * Sort the data keys in order to sort data in function of the column selected
   *
   * @param {Object} allData all the data pass to the TableGenerator
   * @param {Object} sorting an object make with the sortingKeyFactory function of the /shared/TablePieces/FactoriesUtils file
   *
   * @returns the sorted data keys array
   */
  const renderSortData = (allData, sorting) => {
    return (sorting
      ? sorting.sortingMethod(Object.keys(allData), allData, sorting.columnKey, sorting.reverse)
      : Object.keys(allData)).slice((pageIndex - 1) * maxDataDisplay, (pageIndex) * maxDataDisplay)
  }

  /**
   * Render the table header
   *
   * @param {array} columns array containing all the columns properties
   *
   * @returns grappe react containing the table header
   */
  const renderTableHeader = (columns) => {
    const tableHeaderData = columns.map(column => {
      return (
        <th key={column.name} onClick={() => changeSortingKey(column.key, column.sortingMethod)}>
          {props.t(column.name)}
        </th>
      )
    })

    return (
      <thead>
        <tr>
          {tableHeaderData}
        </tr>
      </thead>
    )
  }

  /**
   * Render the table body
   *
   * @param {array} columns array containing all the columns properties
   *
   * @returns grappe react containing the table header
   */
  const renderTableBody = (columns) => {
    const tableBodyData = renderSortData(data, sortingKey).map(key => {
      const tableRowData = columns.map((column, index) => {
        if (column.type) {
          return (
            renderTdBody(column, data, index - (columns.length - 1), key)
          )
        } else if (index === columns.length - 1) {
          return (
            <td key={column.key}>
              <div className='flex-row'>
                {data[key][column.key]}
                {props.optionalButtons ? renderOptionalButtons() : undefined}
              </div>
            </td>
          )
        } else {
          return (
            <td key={column.key}>
              {data[key][column.key]}
            </td>
          )
        }
      })

      return (
        <tr key={key}>
          {tableRowData}
        </tr>
      )
    })

    return (
      <tbody>
        {props.newEntity ? renderAddingLine(columns, props.newEntity) : undefined}
        {tableBodyData}
      </tbody>
    )
  }

  /**
   * Render all individual cell of the table
   * Add optional buttons in the cells contained in the last table column
   * Add all props in the component contained in the cell
   *
   * @param {Object} column   an object containing the column parameters
   * @param {Object} allData  all the data pass to the TableGenerator
   * @param {integer} index   the index in the columns array
   * @param {*} dataKey       the identifier for the data
   *
   * @returns grappe react containing the table body
   */
  const renderTdBody = (column, allData, index, dataKey = null) => {
    const newEntityProps = dataKey === null && props.newEntityProps ? props.newEntity[column.key] : undefined
    const checkIfWrong = dataKey !== null
      ? props.wrongCells[dataKey] && props.wrongCells[dataKey][column.name]
      : props.newLineWrongCells && props.newLineWrongCells[column.name]

    // render a classical cell of the table
    if (dataKey !== null && index === 0 && props.optionalButtons) {
      return (
        <td key={column.key} className={checkIfWrong ? 'wrong-data' : ''}>
          <div className='flex-row'>
            {column.typeFactory({ ...column.props, cleanTimeOut: () => cleanTimeOut(dataKey) }, column.key, dataKey, props.rows[dataKey])}
            {renderOptionalButtons(dataKey)}
          </div>
        </td>
      )
    // render a cell for the add line of the table
    } else {
      const newProps = newEntityProps ? { ...column.props, ...newEntityProps, cleanTimeOut: () => cleanTimeOut(dataKey) } : { ...column.props, cleanTimeOut: () => cleanTimeOut(dataKey) }
      const lineData = dataKey === null ? props.newEntity : props.rows[dataKey]

      return (
        <td key={column.key} className={checkIfWrong ? 'wrong-data' : ''}>
          {column.typeFactory(newProps, column.key, dataKey, lineData)}
        </td>
      )
    }
  }

  /**
   * Render the table adding line
   *
   * @param {array} columns     array containing all the columns properties
   * @param {Object} entityData the data of the new entity
   *
   * @returns grappe react containing the table adding line
   */
  const renderAddingLine = (columns, entityData) => {
    const addingDataRow = columns.map(column => {
      return (
        renderTdBody(column, entityData)
      )
    })

    return (
      <tr key={-1}>
        {addingDataRow}
      </tr>
    )
  }

  /**
   * Render all optional buttons of the last column
   *
   * @param {*} dataId the data identifier
   *
   * @returns grappe react containing the table header
   */
  const renderOptionalButtons = dataId => {
    return props.optionalButtons.map((option, index) => {
      let disabled = false

      if (option.factory === 'OptionButtonFactory' && option.props.iconName === 'id-badge') {
        const data = { ...props.getBadgesAssignations[dataId] }
        if (data.status === -1) {
          disabled = true
        }
      }

      return (
        renderExternalComponent(option.factory, { ...option.props, dataId: dataId, key: dataId + ':' + index, disabled: disabled })
      )
    }
    )
  }

  /**
   * Contains all external components that must be render by the table
   * Render a component choose by is name with his factory
   *
   * @param {string} componentName the name of the component factory
   * @param {*} props               the props to pass to the factory
   * @returns
   */
  const renderExternalComponent = (componentName, props) => {
    switch (componentName) {
      case 'OptionButtonFactory':
        return (<OptionButtonFactory {...props} />)
      default:
        return (<div />)
    }
  }

  /**
   * Choose if the data are in loading or if there is no data or if there is data
   *
   * @returns data are loading message or
   *    a grappe react containing the no data or
   *    a grappe react containing the table header
   */
  const renderDataStates = () => {
    if (props.columns === undefined || props.rows === undefined) {
      return props.t('Data are loading')
    }

    if (Object.keys(data).length === 0 && !props.newEntity) {
      return props.customNoDataMessage === undefined
        ? defaultNoDataMessageFactory()
        : props.customNoDataMessage
    }

    return (
      <table className='table-factory'>
        {renderTableHeader(props.columns)}
        {renderTableBody(props.columns)}
      </table>
    )
  }

  /**
   * Set up props for the Tooltip Components includes in the SmartTable footer
   *
   * @returns rendered SmartTable footer
   */
  const renderTableFooter = (maxDisplay, dataLength, visibleDataLength) => {
    let entriesText =
        dataLength +
        ' ' +
        (dataLength > 1 ? props.t('lines') : props.t('line'))
    const pageIndexText = pageIndex + '/' + maxPageIndex

    if (dataLength !== visibleDataLength) {
      entriesText +=
          ' - ' +
          visibleDataLength +
          ' ' +
          (visibleDataLength > 1 ? props.t('results') : props.t('result'))
    }

    return (
      <div className='flex-row footer hidden-sigleton-data'>
        <div>{entriesText}</div>
        <div className='flex-fill' />
        <div className='flex-row'>
          <Tooltip placement='top' title={props.t('First page')}>
            <Button
              type='default'
              size='small'
              disabled={maxPageIndex === 1 || pageIndex === 1}
              onClick={() => incrementPageIndex(-maxPageIndex + 1)}
            >
              <FontAwesomeIcon icon='angle-double-left' />
            </Button>
          </Tooltip>
          <div className='h-spacing' />
          <Tooltip placement='top' title={props.t('Previous page')}>
            <Button
              type='default'
              size='small'
              disabled={maxPageIndex === 1 || pageIndex === 1}
              onClick={() => incrementPageIndex(-1)}
            >
              <FontAwesomeIcon icon='angle-left' />
            </Button>
          </Tooltip>
          <div className='h-spacing' />
          <div className='page-index'>
            {pageIndexText}
          </div>
          <div className='h-spacing' />
          <Tooltip placement='top' title={props.t('Next page')}>
            <Button
              type='default'
              size='small'
              disabled={
                maxPageIndex === 1 || pageIndex === maxPageIndex
              }
              onClick={() => incrementPageIndex(1)}
            >
              <FontAwesomeIcon icon='angle-right' />
            </Button>
          </Tooltip>
          <div className='h-spacing' />
          <Tooltip placement='top' title={props.t('Last page')}>
            <Button
              type='default'
              size='small'
              disabled={
                maxPageIndex === 1 || pageIndex === maxPageIndex
              }
              onClick={() => incrementPageIndex(maxPageIndex - 1)}
            >
              <FontAwesomeIcon icon='angle-double-right' />
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div>
        {renderDataStates()}
      </div>
      <div>
        {props.rows && data && Object.keys(props.rows).length > 0
          ? renderTableFooter(props.maxDataDisplay, Object.keys(props.rows).length, Object.keys(data).length)
          : undefined}
      </div>
    </div>

  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableGenerator)
