import { request, requestImage } from '../../utils'
import { TYPE_EMAIL, TYPE_PHONE } from '../../utils/entities/studentExtraInfo'

const INITIAL_STATE = {
  studentInformations: {},
  studentExtraInformations: {},
  studentPrivacyInformations: {},
  studentProfileImage: ''
}

const SET_STUDENT_INFORMATIONS = 'SET_STUDENT_INFORMATIONS'
const SET_STUDENT_EXTRA_INFORMATIONS = 'SET_STUDENT_EXTRA_INFORMATIONS'
const SET_STUDENT_PRIVACY_INFORMATIONS = 'SET_STUDENT_PRIVACY_INFORMATIONS'
const SET_STUDENT_PROFILE_IMAGE = 'SET_STUDENT_PROFILE_IMAGE'
const REMOVE_STUDENT_PROFILE_IMAGE = 'REMOVE_STUDENT_PROFILE_IMAGE'

export const getProfileData = (state) => {
  return state
}

/**
 * Get the stored student informations in the redux store
 *
 * @param {*} state the state related to the profile page part of the redux store
 * @returns an object containing the student's informations
 */
export const getStudentInformations = (state) => {
  return state.studentInformations
}

/**
 * @deprecated
 * Get the stored student extra informations in the redux store
 *
 * @param {*} state the state related to the profile page part of the redux store
 * @returns an object containing the student's extra informations
 */
export const getStudentExtraInformations = (state) => {
  return state.studentExtraInformations
}

/**
 * Get the current student profile Image from the redux store
 *
 * @param {*} state
 * @returns
 */
export const getStudentProfileImage = (state) => {
  return state.studentProfileImage
}

/**
 * Get student extra informations (the informations define by the student) from the backend
 * Store the retrieved data in the redux store
 *
 * @param {Object} user the current user
 * @returns thunk function
 */
export const fetchStudentExtraInformations = (user) => {
  return async function fetchStudentExtraInformationsThunk (dispatch, getState) {
    const response = await request(
      '/student/get-additional-contact-info',
      'GET',
      null,
      user
    )

    const studentExtraInformations = {
      emails: [],
      phones: []
    }

    response.data.forEach(extraInfo => {
      if (extraInfo.type === TYPE_EMAIL) {
        studentExtraInformations.emails.push(extraInfo.value)
      } else if (extraInfo.type === TYPE_PHONE) {
        studentExtraInformations.phones.push(extraInfo.value)
      }
    })

    dispatch({ type: SET_STUDENT_EXTRA_INFORMATIONS, payload: studentExtraInformations })
  }
}

/**
 * Get student profile Image from the backend
 * Store the retrieved image in the redux store
 *
 * @param {string} imageName the image name
 * @param {Object} user      the current user
 * @returns
 */
export const fetchStudentProfileImage = (imageName, user) => {
  return async function fetchStudentProfileImageThunk (dispatch, getState) {
    const response = await requestImage(
      '/profil/image/' + imageName,
      'GET',
      null,
      user
    )

    dispatch({ type: SET_STUDENT_PROFILE_IMAGE, payload: response })
  }
}

/**
 * Set data in the studentInformations part of the store
 *
 * @param {Object} studentInformations the new studentInformations to store
 * @returns a redux action
 */
export const setStudentInformations = (studentInformations) => {
  return {
    type: SET_STUDENT_INFORMATIONS,
    payload: studentInformations
  }
}

/**
 * Set data in the studentExtraInformations part of the store
 *
 * @param {Object} studentExtraInformations the new studentExtraInformations to store
 * @returns a redux action
 */
export const setStudentExtraInformations = (studentExtraInformations) => {
  return {
    type: SET_STUDENT_EXTRA_INFORMATIONS,
    payload: studentExtraInformations
  }
}

/**
 * Set data in the studentPrivacyInformations part of the store
 *
 * @param {Object} studentPrivacyInformations the new studentPrivacyInformations to store
 * @returns a redux store
 */
export const setStudentPrivacyInformations = (studentPrivacyInformations) => {
  return {
    type: SET_STUDENT_PRIVACY_INFORMATIONS,
    payload: studentPrivacyInformations
  }
}

/**
 * Set data in the studentProfileImage part of the store
 *
 * @param {Object} studentProfileImage the new studentProfileImage to store
 * @returns a redux action
 */
export const setStudentProfileImage = (studentProfileImage) => {
  return {
    type: SET_STUDENT_PROFILE_IMAGE,
    payload: studentProfileImage
  }
}

export const removeStudentProfileImage = () => {
  return {
    type: REMOVE_STUDENT_PROFILE_IMAGE
  }
}

/**
 * Reducer for the profile informations
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDENT_INFORMATIONS: {
      return { ...state, studentInformations: action.payload }
    }
    case SET_STUDENT_EXTRA_INFORMATIONS: {
      return { ...state, studentExtraInformations: action.payload }
    }
    case SET_STUDENT_PRIVACY_INFORMATIONS: {
      return { ...state, studentPrivacyInformations: action.payload }
    }
    case SET_STUDENT_PROFILE_IMAGE: {
      return { ...state, studentProfileImage: action.payload }
    }
    case REMOVE_STUDENT_PROFILE_IMAGE: {
      const studentImage = state.studentProfileImage

      URL.revokeObjectURL(studentImage)

      return { ...state, studentProfileImage: '' }
    }
    default:
      return state
  }
}
