import React, { useContext } from 'react'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../../reducers/Dispatchers'
import DatePickerWithDisplacement from './DatePickerWithDisplacement'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { Tooltip } from 'antd'

const LegendAndDates = ({ inWidgetMode, startDate, onDateChange, t }) => {
  const { eventCodes } = useContext(GlobalContext)

  return (
    <div className='flex-row'>
      {!inWidgetMode && eventCodes.length > 0 && (
        <div className='event-codes-legend'>
          {t('Available event codes : ')}
          {eventCodes.map((e, index) => {
            return (
              <Tooltip key={'code_' + index} title={t('event.code.label.' + e.type)}>
                <b> {index !== 0 && ', '} {t('event.code.' + e.type)} </b>
              </Tooltip>
            )
          })}
        </div>
      )}
      <div className='flex-fill' />
      <DatePickerWithDisplacement
        startDate={startDate}
        onDateChange={onDateChange}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LegendAndDates)
