import React, { useState, useEffect, useRef } from 'react'

import ChatWidget, { MESSAGE_RECEIVED, MESSAGE_SENT } from './ChatWidget'
import moment from 'moment'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, generalErrorHandler } from '../../utils'
import { INSTITUTION_CONTEXT, SCHOOL_CONTEXT } from '../../utils/constants'
import { sendInternshipMessage } from '../../utils/api/internshipMessage'
import { displayError } from '../../utils/apiHelper'

function ChatManager (props) {
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [messageWasMarkedAsUnread, setMessageWasMarkedAsUnread] = useState(false)

  const isFirstRun = useRef(true)

  const zendeskWidget = document.getElementsByClassName('zEWidget-launcher')[0]

  let refreshInterval

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }
    if (props.getChatInternship === null) {
      setMessages([])
      autoRefreshChatManager(false)

      if (typeof zendeskWidget !== 'undefined') {
        zendeskWidget.style.bottom = '0px'
      }
    } else {
      setLoading(true)
      getMessages(props.getChatInternship)
      setMessageWasMarkedAsUnread(false)
      autoRefreshChatManager(true)

      if (typeof zendeskWidget !== 'undefined') zendeskWidget.style.bottom = '450px'
    }

    return function cleanup () {
      autoRefreshChatManager(false)
    }
  }, [props.getChatInternship])

  useEffect(() => {
    if (messageWasMarkedAsUnread) {
      props.setInternshipForChat(null)
    } else {
      clearInterval(refreshInterval)
    }
  }, [messageWasMarkedAsUnread])

  function autoRefreshChatManager (enabled) {
    clearInterval(refreshInterval)

    if (enabled) {
      refreshInterval = setInterval(
        () => {
          const position = document.getElementsByClassName('messages-container')[0].scrollTop
          getMessages(props.getChatInternship, position)
        }
        , 5000)
    }
  }

  function getMessages (internship, position) {
    if (typeof internship === 'undefined' || internship === null) return

    request('/internship-message/consult/' + internship.id, 'GET', null, props.getUser)
      .then((json) => {
        let newMessages = json.data
        const internshipId = messages[0] ? messages[0].internship : 0
        const newInternshipId = newMessages[0] ? newMessages[0].internship : 0

        if (
          internshipId !== newInternshipId ||
          (internshipId === newInternshipId && messages.length !== newMessages.length)
        ) {
          setConversationRead(internship)

          newMessages = newMessages.map(m => {
            m.origin = props.getUser.username === m.from.username ? MESSAGE_SENT : MESSAGE_RECEIVED
            return m
          })

          setLoading(false)
          setMessages(newMessages)
        }
        setLoading(false)
        document.getElementsByClassName('messages-container')[0].scrollTop = position
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  function postMessage (message) {
    setLoading(true)
    sendInternshipMessage(props.getUser, { internship: props.getChatInternship.id, content: message }).then(json => {
      const message = json.data
      let newMessages = messages

      message.from = { username: props.getUser.username }

      newMessages = newMessages.concat(message)

      setLoading(false)
      setMessages(newMessages)
    }).catch(error => {
      displayError(error, 'internship_message.send')
      setLoading(false)
    })

    setConversationRead(props.getChatInternship)
  }

  function setConversationRead (internship) {
    if (messageWasMarkedAsUnread) {
      return
    }

    props.readMessage(internship.id)
    request('/internship-message/read', 'POST', { internship: internship.id }, props.getUser)
  }

  function handleCloseChatManager () {
    setConversationRead(props.getChatInternship)
    clearInterval(refreshInterval)
    props.setInternshipForChat(null)
    setMessageWasMarkedAsUnread(false)

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  function handleMessageUnread () {
    props.setInternshipForChat(null)

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  const getStudentName = internship => {
    if (internship.studentName) {
      return internship.studentName
    }

    if (internship.student) {
      return typeof internship.student === 'object'
        ? `${internship.student.lastname} ${internship.student.firstname}`
        : `${internship.lastname} ${internship.firstname}`
    }

    return props.t('Without student')
  }

  const getSchoolName = internship => {
    let name = ''

    if (internship.school && typeof internship.school === 'object') {
      name = `${props.t('Contact')} ${internship.school?.name ?? internship.school}`
    } else if (internship.school) {
      name = `${props.t('Contact')} ${internship.schoolName ?? internship.school}`
    } else {
      name = props.t('Without school')
    }

    return name
  }

  const getInstitutionName = internship => {
    let name = ''

    if (internship.institution && typeof internship.institution === 'object') {
      name = `${props.t('Contact')} ${internship.institution?.name ?? internship.institution}`
    } else if (internship.institution) {
      name = `${props.t('Contact')} ${internship.institutionName ?? internship.institution}`
    } else {
      name = props.t('Without institution')
    }

    return name
  }

  const getDate = date => {
    if (date.includes('/')) {
      return date
    }

    if (date.includes('+')) {
      date = date.split('+')[0]
    }

    const formatedDate = moment(date).format('DD/MM/YY')

    if (formatedDate === 'Invalid date') {
      return date
    }

    return formatedDate
  }

  const internship = props.getChatInternship

  if (!internship) return null

  const title = getStudentName(internship)
  let subtitle = ''

  if (props.getUser.context === SCHOOL_CONTEXT) {
    subtitle = getInstitutionName(internship)
  } else if (props.getUser.context === INSTITUTION_CONTEXT) {
    subtitle = getSchoolName(internship)
  }

  subtitle = `${subtitle}, ${props.t('from')} ${getDate(internship.startDate)}`
  subtitle = `${subtitle} ${props.t('to')} ${getDate(internship.endDate)}`

  return (
    <ChatWidget
      loading={loading}
      messages={loading ? [] : messages}
      onClose={handleCloseChatManager}
      sendMessage={postMessage}
      subtitle={subtitle}
      title={title}
      onUnread={handleMessageUnread}
      messageWasMarkedAsUnread={messageWasMarkedAsUnread}
      startDate={internship.startDate}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatManager)
