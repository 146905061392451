import { isBoolean } from 'lodash'
import { getIdOrNull, validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'

export const ACT_TYPE_VALIDATORS = [
  { key: VALIDATION_FIELDS.NAME, validator: s => validateString(s.name, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.TYPE, validator: s => isBoolean(s.hoursFormat) }
]

export const ACT_TYPES = {
  false: 'numerical',
  true: 'schedule'
}

export const getName = a => {
  return a.name
}

export const getCategory = a => {
  return a.actTypeCategory?.name ?? null
}

export const getType = a => {
  return a.hoursFormat
}

export class ActType {
  constructor ({ id = -1, actTypeCategory = null, hoursFormat = false, name = '', school = null } = {}) {
    this.id = id
    this.actTypeCategory = actTypeCategory
    this.hoursFormat = hoursFormat
    this.name = name
    this.school = school
  }

  toRequestBody = () => {
    const body = { ...this }

    body.actTypeCategory = getIdOrNull(this.actTypeCategory)
    body.school = getIdOrNull(this.school)

    return body
  }
}
