export const SHIFT_MANAGER = {
  SET_PAUSE_QUOTAS_COUNTS: 'SET_PAUSE_QUOTAS_COUNTS',
  SET_SHIFTS_COUNTS: 'SET_SHIFTS_COUNTS',
  SET_SELECTED_SECTOR: 'SET_SELECTED_SECTOR',
  SET_SELECTED_INSTITUTION: 'SET_SELECTED_INSTITUTION',
  SET_PAUSE_QUOTAS_STATE: 'SET_PAUSE_QUOTAS_STATE',
  SET_SHIFTS_STATE: 'SET_SHIFTS_STATE',
  SET_PAUSE_USED_INSTITUTIONS: 'SET_PAUSE_USED_INSTITUTIONS',
  UPDATE_SHIFT_COUNTS: 'UPDATE_SHIFT_COUNTS',
  UPDATE_SHIFT_COUNTS_BY_PRESETS: 'UPDATE_SHIFT_COUNTS_BY_PRESETS',
  SET_DISPLAY_EXCEEDING_MODAL: 'SET_DISPLAY_EXCEEDING_MODAL'
}
