import { FULL_DATE_FORMAT, getTimezoneLessMoment } from '../momentjs'

export class RequestPeriod {
  constructor ({ id = -1, startDate = null, endDate = null } = {}) {
    this.id = id
    this.startDate = getTimezoneLessMoment(startDate)
    this.endDate = getTimezoneLessMoment(endDate)
  }

  toRequestBody = () => {
    const body = { ...this }

    body.startDate.set({ hour: 0, minute: 0, second: 0 })
    body.endDate.set({ hour: 23, minute: 59, second: 59 })

    body.startDate = body.startDate.format(FULL_DATE_FORMAT)
    body.endDate = body.endDate.format(FULL_DATE_FORMAT)

    return body
  }
}
