const numAsString = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']

export const camelCasetoKebabCase = string => {
  if (typeof string !== 'string' || string.includes('-')) {
    return string
  }

  return capitalizeFirstLetter(string).match(/[A-Z][a-z]+/g).map(s => s.toLowerCase()).join('-')
}

export const snakeCaseToCamelCase = string => {
  if (typeof string !== 'string') {
    return string
  }

  const stringParts = string.split('_')
  let stringToCamel = ''

  stringParts.forEach((s, index) => {
    stringToCamel += index === 0 ? s : capitalizeFirstLetter(s)
  })

  return stringToCamel
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * This function has to be improved for more generalize used
 */
export const numToWords = number => {
  if (number > 9 || number < 0) {
    return 'Not supported yet'
  }

  return numAsString[number]
}
