import React, { useContext, useState } from 'react'

import ActionsFieldsAndButtons from './ActionsFieldsAndButtons'
import LegendAndDates from './LegendAndDates'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import { downloadFileWithPromise, generalErrorHandler, getJobResultWithPromise, isObject, requestWithPromise } from '../../../../utils'
import { DATE_FORMAT_API } from '../../../../utils/constants'
import { isA404Error } from '../../../../Errors/utils'
import ContextShiftsInterface from './ContextShiftsInterface'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const ShiftsManagerHeader = props => {
  const [exportLoading, setExportLoading] = useState(false)
  const [filterValue, setFilterValue] = useState('')

  const { eventCodes } = useContext(GlobalContext)

  const handleFilterChange = filterValue => {
    props.onFilterChange(filterValue)
    setFilterValue(filterValue)
  }

  const handleExportCurrentView = (dateInterval, checked) => {
    setExportLoading(true)

    const body = {
      start: dateInterval ? dateInterval.start : props.startDate.format(DATE_FORMAT_API),
      end: dateInterval ? dateInterval.end : props.endDate.format(DATE_FORMAT_API),
      language: props.getActiveLanguage,
      eventCodesTypes: {}
    }

    for (const eventCode of eventCodes) {
      body.eventCodesTypes[eventCode.type] = props.t('event.code.' + eventCode.type)
    }

    if (checked && filterValue !== null) {
      body.search = filterValue.trim().toLowerCase()
    }

    if (checked && isObject(props.getSelectedSector)) {
      body.sector = props.getSelectedSector.id
    }

    requestWithPromise(
      '/internship/export-shifts',
      'POST',
      body,
      props.getUser
    ).then(jsonResponse => {
      const longPollingIntervalExport = setInterval(() => {
        getJobResultWithPromise(jsonResponse.job, props.getUser)
          .then(jsonResponse => {
            if (
              jsonResponse &&
            (typeof jsonResponse.error === 'undefined' || jsonResponse.error === false) &&
            typeof jsonResponse.data !== 'undefined'
            ) {
              clearInterval(longPollingIntervalExport)
              setExportLoading(false)

              const fileName = jsonResponse.data.result

              downloadFileWithPromise(
                '/spreadsheet/download/' + fileName,
                fileName,
                props.getUser
              ).catch(err => {
                generalErrorHandler(err)
              })
            }
          }).catch(err => {
            if (!isA404Error(err)) {
              clearInterval(longPollingIntervalExport)
              setExportLoading(false)
              generalErrorHandler(err)
            }
          })
      }, 1500)
    })
  }

  const displayManagePresets = () => {
    props.displayManagePresets()
  }

  const toggleMultiSelect = () => {
    props.toggleMultiSelect()
  }

  const renderContainer = () => {
    if (props.inWidgetMode) {
      return (
        <LegendAndDates
          presetsLegend={[]}
          showTopTransitions={props.showTopTransitions}
          loading={props.loading}
          startDate={props.startDate}
          onDateChange={props.onDateChange}
          inWidgetMode
        />
      )
    }

    return (
      <div>
        <ContextShiftsInterface>
          <ActionsFieldsAndButtons
            checkedInternships={props.checkedInternships}
            limitedActions={props.limitedActions}
            multiSelectionMode={props.multiSelectionMode}
            showTopTransitions={props.showTopTransitions}
            exportLoading={exportLoading}
            useDraft={props.useDraft}
            isReadOnly={props.isReadOnly}
            onDeleteSchedules={props.onDeleteSchedules}
            onDraftSchedules={props.onDraftSchedules}
            onDraftChange={props.onDraftChange}
            onFilterChange={handleFilterChange}
            onPublishSchedules={props.onPublishSchedules}
            onResetSelection={props.onResetSelection}
            displayManagePresets={displayManagePresets}
            onExport={handleExportCurrentView}
            toggleMultiSelect={toggleMultiSelect}
          />
        </ContextShiftsInterface>
        <div className='v-spacing' />
        <LegendAndDates
          presetsLegend={[]}
          showTopTransitions={props.showTopTransitions}
          loading={props.loading}
          startDate={props.startDate}
          onDateChange={props.onDateChange}
        />
      </div>
    )
  }

  return (
    <div
      className='shift-manager-header'
    >
      {renderContainer()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsManagerHeader)
