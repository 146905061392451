import React, { useState, useEffect } from 'react'

import { Collapse } from 'antd'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Loading from '../../HOC/Loading'
import { getUser } from '../../reducers/UserReducer'
import { getChangelog } from '../../utils/api/changelog'
import { LANGUAGE_TAG_FR } from '../../utils/constants'

const Panel = Collapse.Panel

const mapStateToProps = state => {
  return {
    activeLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const ChangeLogView = ({ user, activeLanguage, t }) => {
  const [rawChangelog, setRawChangelog] = useState(null)
  const [changelog, setChangelog] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user.id) {
      setLoading(true)

      getChangelog(user).then(json => {
        if (json?.data) {
          setRawChangelog(json.data)
        }

        setLoading(false)
      })
    }
  }, [user.id])

  useEffect(() => {
    if (activeLanguage && rawChangelog) {
      const translatedChangelog = []

      rawChangelog.forEach(item => {
        let translation = item.messages.find(message => message.locale === activeLanguage)

        if (!translation) {
          translation = item.messages.find(message => message.locale === LANGUAGE_TAG_FR)
        }

        if (translation) {
          translatedChangelog.push({ version: item.name, message: translation.message })
        }
      })

      setChangelog(translatedChangelog)
    }
  }, [activeLanguage, rawChangelog])

  return (
    <div>
      <Loading loading={loading} size='4x'>
        <Collapse defaultActiveKey={['0']} bordered={false} accordion className='changelog'>
          {changelog.map((item, index) => (
            <Panel header={`${t('Changelog version')} ${item.version}`} key={index.toString()}>
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
            </Panel>))}
        </Collapse>
      </Loading>
    </div>
  )
}

export default connect(mapStateToProps)(ChangeLogView)
