import React, { useEffect, useState } from 'react'

import { Input } from 'antd'
import { getLuminanceFromColorCode } from '../../../../utils/colors'
import useOuterClick from '../../../institution/Quotas/hooks/useOuterClick'
import { isNumber } from '../../../../utils'

const TimeInput = props => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 })
  const [oldTime, setOldTime] = useState({ hours: 0, minutes: 0, seconds: 0 })
  const [isDirty, setIsDirty] = useState(false)

  const luminance = props.color ? getLuminanceFromColorCode(props.color) : 'black'

  const innerRef = useOuterClick(() => handleClickOutside())

  useEffect(() => {
    if (props.time) {
      setTime({ ...props.time })
      setOldTime({ ...props.time })
      setIsDirty(false)
    }
  }, [props.time])

  const handleClickOutside = () => {
    if (isDirty) {
      setIsDirty(false)

      if (isValidTime(time)) {
        setOldTime({ ...time })
        props.onTimeChange(time)
      } else {
        setTime({ ...oldTime })
      }
    }
  }

  const resetTimeIfNotDirty = key => {
    if (!isDirty || isValidTime(time)) {
      const newTime = { ...time }

      newTime[key] = null

      setTime(newTime)
      setIsDirty(true)
    } else {
      const newTime = { ...oldTime }

      newTime[key] = null

      setTime(newTime)
    }
  }

  const isValidTime = timeToBeTested => {
    return isNumber(timeToBeTested.hours) && isNumber(timeToBeTested.minutes) && isNumber(timeToBeTested.seconds)
  }

  const timeToString = time => {
    if (time === null) {
      return ''
    }

    if (time < 10 && time >= 0) {
      return `0${time}`
    }

    return '' + time
  }

  const handleTimeChange = (e, key) => {
    const numericalValue = parseInt(e.target.value)

    if (!isNaN(numericalValue)) {
      const newTime = { ...time }

      newTime[key] = getValidTime(numericalValue, key)

      setTime(newTime)
      if (!isDirty) {
        setIsDirty(true)
      }
    }
  }

  const getValidTime = (time, timeType) => {
    if (time < 0) {
      return 0
    }

    if (timeType === 'hours') {
      return time > 24 ? 24 : time
    }

    if (timeType === 'minutes' || timeType === 'seconds') {
      return time > 60 ? 60 : time
    }

    return time
  }

  const renderInputs = () => {
    const { hours, minutes, seconds } = time
    const spawnStyle = { color: luminance }
    const inputs = [
      <Input
        key='hours'
        value={timeToString(hours)}
        style={getStyle()} tabIndex={props.useTabIndex ? 0 : -1}
        onClick={() => resetTimeIfNotDirty('hours')}
        onChange={e => handleTimeChange(e, 'hours')}
        disabled={props.disabled}
      />,
      <span key='hours-minutes' style={spawnStyle}>:</span>,
      <Input
        key='minutes'
        value={timeToString(minutes)}
        style={getStyle()} tabIndex={props.useTabIndex ? 0 : -1}
        onClick={() => resetTimeIfNotDirty('minutes')}
        onChange={e => handleTimeChange(e, 'minutes')}
        disabled={props.disabled}
      />
    ]

    if (props.includeSeconds) {
      inputs.push(<span key='minutes-seconds' style={spawnStyle}>:</span>)
      inputs.push(
        <Input
          key='seconds'
          value={timeToString(seconds)}
          style={getStyle()}
          onClick={() => resetTimeIfNotDirty('seconds')}
          onChange={e => handleTimeChange(e, 'seconds')}
          disabled={props.disabled}
        />
      )
    }

    return inputs
  }

  const getStyle = () => {
    if (props.errorMode) {
      return { backgroundColor: 'white', color: 'black' }
    }

    if (props.color) {
      return { backgroundColor: props.color, color: luminance }
    }

    return {}
  }

  return (
    <div className='time-input' ref={innerRef}>
      {renderInputs()}
    </div>
  )
}

export default TimeInput
