import React, { useState, useEffect } from 'react'

import { Input } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { validateFormInput } from '../../../utils'

const CustomInput = props => {
  const [value, setValue] = useState()

  useEffect(()=>{
    if (props[props.getter]) {
      setValue(props.dataId === null ? props[props.getter] : props[props.getter][props.dataId])
    }
  }, [props.dataId === null ? props[props.getter] : props[props.getter][props.dataId]])

  const onDataEdit = (newValue) => {
    let valueCopy = {...value}

    valueCopy[props.columnKey] = newValue

    setValue(valueCopy)
  }

  const onBlur = () => {
    if (props.dataId === null) {
      props[props.setter](value)
    } else {
      let badgesAssignations = {...props[props.getter]}

      badgesAssignations[props.dataId] = value

      props[props.setter](badgesAssignations)
      props[props.setDirty](props.dataId)
    }

    if (props.fetchRfidNumber && validateFormInput('freeText', value.badgeNumber)) {
      props.fetchBadgeRFIDNumber(props.getInstitutions[0].id, value.badgeNumber, props.getUser, props.dataId)
    }
  }

  return(
    <Input 
      value={value ? value[props.columnKey] : undefined}
      placeholder={props.t("Empty")}
      onChange={e => onDataEdit(e.target.value)}
      onBlur={onBlur}
      onFocus={props.cleanTimeOut}
    />
  )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomInput)