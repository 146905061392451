import { requestWithPromise } from '..'
import { DELETE, GET, makeQueryString, PATCH, POST } from '../apiHelper'

export const getExternalContacts = (user, parameters) => {
  return requestWithPromise(
    `/api/external-contacts${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const addExternalContact = (user, externalContact) => {
  return requestWithPromise(
    '/api/external-contacts',
    POST,
    externalContact.toRequestBody(),
    user,
    true,
    true
  )
}

export const updateExternalContact = (user, externalContact) => {
  return requestWithPromise(
    `/api/external-contacts/${externalContact.id}`,
    PATCH,
    externalContact.toRequestBody(),
    user,
    true,
    true
  )
}

export const deleteExternalContact = (user, externalContact) => {
  return requestWithPromise(
    `/api/external-contacts/${externalContact.id}`,
    DELETE,
    null,
    user,
    true,
    true
  )
}
