import React, { useContext } from 'react'
import { ShiftManagerContext } from '../../../../Providers/ShiftManagerProvider'

const ContextShiftsInterface = ({ children }) => {
  const { fontSizes, setFontSizes } = useContext(ShiftManagerContext)

  return React.cloneElement(children, { fontSizes, setFontSizes })
}

export default ContextShiftsInterface
