import ua from 'universal-analytics'
import { getGoogleTrackingId } from '../utils'

export default class Analytics {
  static _instance;

  static get instance () {
    if (!this._instance) {
      console.warn('Analytics service must be initialized first!')
    }
    return this._instance
  }

  static initialize () {
    return new Analytics()
  }

  constructor () {
    Analytics._instance = this
    this._visitor = ua(getGoogleTrackingId())
  }

  static setUserDetails (user, language) {
    const persona = user.roles.join(',')
    Analytics.instance._visitor.set('userId', user.id)
    Analytics.instance._visitor.set('Persona', persona)
    Analytics.instance._visitor.set('Language', language)
    Analytics.instance._visitor.set('Entity', user.context)

    try {
      if (window.heap) {
        window.heap.addUserProperties({
          Persona: persona,
          Language: language,
          Entity: user.context
        })
      }
    } catch (err) {
      console.warn(`Heap analytics error: ${err.message}`)
    }
  }

  static pageView (pageName) {
    Analytics.instance._visitor.pageview(pageName).send()
  }
}
