import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Select } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput } from '../../utils'

const FormItem = Form.Item
const Option = Select.Option
class NormalUserSettings extends React.Component {
  checkUsername = (rule, value, callback) => {
    if (!validateFormInput('username', value)) {
      // maybe the user name is the same as the email. Try to validate the email now
      if (!validateFormInput('email', value)) {
        // nope the username is clearly not ok
        callback(this.props.t('This value is not correct !'))
      }
    }
    callback()
  }

  checkLastname = (rule, value, callback) => {
    if (!validateFormInput('name', value)) callback(this.props.t('This value is not correct !'))
    callback()
  }

  checkFirstname = (rule, value, callback) => {
    if (!validateFormInput('name', value)) callback(this.props.t('This value is not correct !'))
    callback()
  }

  checkEmail = (rule, value, callback) => {
    if (!validateFormInput('email', value)) callback(this.props.t('This value is not correct !'))
    callback()
  }

  checkLanguage = (rule, value, callback) => {
    if (!value) callback(this.props.t('Language is required !'))
    callback()
  }

  render () {
    const {
      form: { getFieldDecorator },
      getUser: { lastname, firstname, username, email, language },
      getLanguages,
      t
    } = this.props

    const formItemLayout = {
      labelCol: { xs: { span: 24 } },
      wrapperCol: { xs: { span: 24 } }
    }

    return (
      <Form>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('lastname', { rules: [{ validator: this.checkLastname }], initialValue: lastname })(
            <Input prefix={<FontAwesomeIcon icon='id-badge' />} placeholder={t('Lastname')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('firstname', { rules: [{ validator: this.checkFirstname }], initialValue: firstname })(
            <Input prefix={<FontAwesomeIcon icon='id-badge' />} placeholder={t('Firstname')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('username', { rules: [{ validator: this.checkUsername }], initialValue: username })(
            <Input prefix={<FontAwesomeIcon icon='id-badge' />} placeholder={t('Username')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('email', { rules: [{ validator: this.checkEmail }], initialValue: email })(
            <Input prefix={<FontAwesomeIcon icon='at' />} placeholder={t('Email address')} />
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator('language', { rules: [{ validator: this.checkLanguage }], initialValue: language || undefined })(
            <Select placeholder={t('Select your language')}>
              {getLanguages.map(item => (
                <Option key={item.code} value={item.code} title={t(item.name)}>
                  {t(item.name)}
                </Option>
              ))}

            </Select>
          )}
        </FormItem>
      </Form>
    )
  }
}

const UserSettings = Form.create()(NormalUserSettings)
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
