import { SHIFT_MANAGER } from './actionsType'

const {
  SET_DISPLAY_EXCEEDING_MODAL, SET_PAUSE_QUOTAS_COUNTS, SET_PAUSE_QUOTAS_STATE,
  SET_PAUSE_USED_INSTITUTIONS, SET_SELECTED_INSTITUTION, SET_SELECTED_SECTOR,
  SET_SHIFTS_COUNTS, SET_SHIFTS_STATE, UPDATE_SHIFT_COUNTS, UPDATE_SHIFT_COUNTS_BY_PRESETS
} = SHIFT_MANAGER

const INITIAL_STATE = {
  selectedSector: {},
  selectedInstitution: {},
  pauseQuotasCounts: [],
  shiftsCounts: [],
  pauseInstitutions: [],
  displayExceedingModal: false,
  pauseQuotasIsLoading: false,
  shiftsIsLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAUSE_QUOTAS_COUNTS: {
      return { ...state, pauseQuotasCounts: action.payload, pauseQuotasIsLoading: false }
    }
    case SET_SHIFTS_COUNTS: {
      return { ...state, shiftsCounts: action.payload, shiftsIsLoading: false }
    }
    case SET_SELECTED_INSTITUTION: {
      return { ...state, selectedInstitution: action.payload }
    }
    case SET_SELECTED_SECTOR: {
      return { ...state, selectedSector: action.payload }
    }
    case SET_PAUSE_QUOTAS_STATE: {
      return { ...state, pauseQuotasIsLoading: action.payload }
    }
    case SET_SHIFTS_STATE: {
      return { ...state, shiftsIsLoading: action.payload }
    }
    case SET_PAUSE_USED_INSTITUTIONS: {
      return { ...state, pauseInstitutions: action.payload, pauseInstitutionsIsLoading: false }
    }
    case UPDATE_SHIFT_COUNTS: {
      const newState = { ...state }
      const { index, value } = action.payload

      if (newState.shiftsCounts[index]) {
        newState.shiftsCounts[index].total += value
      }

      return newState
    }
    case UPDATE_SHIFT_COUNTS_BY_PRESETS: {
      const newState = { ...state }
      const { index, newPreset, oldPreset } = action.payload

      if (newPreset) {
        newState.shiftsCounts[index].total += 1

        if (newPreset.shiftPause && newPreset.shiftPause.id) {
          if (!newState.shiftsCounts[index].details[newPreset.shiftPause.id]) {
            newState.shiftsCounts[index].details[newPreset.shiftPause.id] = 0
          }

          newState.shiftsCounts[index].details[newPreset.shiftPause.id] += 1
        }
      }

      if (oldPreset) {
        newState.shiftsCounts[index].total -= 1

        if (oldPreset.shiftPause && oldPreset.shiftPause.id) {
          if (!newState.shiftsCounts[index].details[oldPreset.shiftPause.id]) {
            newState.shiftsCounts[index].details[oldPreset.shiftPause.id] = 0
          }

          newState.shiftsCounts[index].details[oldPreset.shiftPause.id] -= 1
        }
      }

      return newState
    }
    case SET_DISPLAY_EXCEEDING_MODAL: {
      return { ...state, displayExceedingModal: action.payload }
    }
    default:
      return state
  }
}
