import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const FormItem = ({ children, className, label, icon, required, style, t }) => {
  const finalClassName = useMemo(() => className ? `form-item ${className}` : 'form-item', [className])

  return (
    <div style={style} className={finalClassName}>
      <div className='form-label'>
        <label> <FontAwesomeIcon icon={icon} /><b> {t(label)} </b></label>
        {required && <span> {t('form.label.required')} </span>}
      </div>
      {children}
    </div>
  )
}

export default connect(mapStateToProps)(FormItem)
