export const TYPE_EMAIL = 'email'
export const TYPE_PHONE = 'phone'
export const TYPE_REGION = 'CARPOOL_REGION'

const SUPPORTED_TYPES = [TYPE_EMAIL, TYPE_PHONE, TYPE_REGION]

export class StudentExtraInfo {
  constructor ({ id, type, value, student }) {
    this.id = id ?? -1
    this.value = value ?? ''
    this.student = student ?? null

    if (type !== null && !SUPPORTED_TYPES.includes(type)) {
      throw Error('This type is not supported by the StudentExtraInformation object')
    }

    this.type = type ?? null
  }

  toRequestBody () {
    return {
      value: this.value,
      type: this.type
    }
  }
}
