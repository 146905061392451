export const handleFilter = (value, option) => {
  return option.props.filter.toLowerCase().includes(value.toLowerCase())
}

export const isAllChecked = (data, checkedValues, getKey) => {
  let checked = data.length > 0

  for (const value of data) {
    if (!checked) {
      break
    }

    checked = checkedValues.some(c => getKey(c) === getKey(value))
  }

  return checked
}

export const handleCheckAll = (data, checkedValues, getKey, isAllChecked) => {
  let newCheckedValues = []

  if (isAllChecked) {
    checkedValues.forEach(c => {
      if (!data.some(d => getKey(d) === getKey(c))) {
        newCheckedValues.push(c)
      }
    })
  } else {
    newCheckedValues = [...checkedValues]

    data.forEach(d => {
      if (!newCheckedValues.some(c => getKey(c) === getKey(d))) {
        newCheckedValues.push(d)
      }
    })
  }

  return newCheckedValues
}
