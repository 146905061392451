import { ROLE_NURSE, ROLE_OBSERVER, ROLE_SUPERVISOR, ROLE_HOSPITAL_ADMIN } from './constants'
import { isArray } from 'lodash'

export const isNurse = roles => {
  return isArray(roles) && roles.includes(ROLE_NURSE)
}

export const isObserver = roles => {
  return isArray(roles) && roles.includes(ROLE_OBSERVER)
}

export const isSupervisor = roles => {
  return isArray(roles) && roles.includes(ROLE_SUPERVISOR)
}

export const isHospitalAdmin = roles => {
  return isArray(roles) && roles.includes(ROLE_HOSPITAL_ADMIN)
}

export default { isNurse, isObserver, isSupervisor }
