import {
  faMoon,
  faStar,
  faSun,
  faExclamationTriangle,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'

const ICONS = {
  moon: faMoon,
  star: faStar,
  sun: faSun,
  plusCircle: faPlusCircle,
  warning: faExclamationTriangle
}

const getIconByName = iconName => {
  return ICONS[iconName]
}

export {
  ICONS,
  getIconByName
}
