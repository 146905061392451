import { createSelector } from 'reselect'

const getUserManagedSectors = state => state.affiliatedSectors

export const getUserManagedSectorIdList = createSelector(
  [getUserManagedSectors],
  (affiliatedSectors) => {
    if (affiliatedSectors === undefined) {
      return []
    }

    return affiliatedSectors.map(sector => {
      return sector.id
    })
  }
)
