import { Button, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

import '../../assets/school-modules-settings.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ModulesList = ({ emptySave, modules, title, t, onSave }) => {
  const [checkedOptions, setCheckedOptions] = useState([])

  useEffect(() => {
    const checkedOptions = []

    modules.forEach(m => {
      if (m.optionEnabled) {
        checkedOptions.push(m.id)
      }
    })

    setCheckedOptions(checkedOptions)
  }, [modules])

  const handleModuleCheck = ({ target }) => {
    const newCheckedOptions = target.checked
      ? [...checkedOptions, target.value]
      : checkedOptions.filter(e => e !== target.value)

    setCheckedOptions(newCheckedOptions)
  }

  return (
    <div className='modules-list'>
      <h3>{t(title ?? 'No available modules activated. Please activate at least one of the following module to use this page:')}</h3>
      <div className='ant-checkbox-group'>
        {modules.map((o, index) => {
          return (
            <Checkbox key={'option_' + index} value={o.id} onChange={handleModuleCheck} checked={!!checkedOptions.find(id => id === o.id)}>
              {t(o.optionType.type + '.module')}
            </Checkbox>
          )
        })}
      </div>
      <Button onClick={() => onSave(checkedOptions)} disabled={checkedOptions.length === 0 && !emptySave}>
        {t('Save')}
      </Button>
    </div>
  )
}

export default connect(mapStateToProps)(ModulesList)
