import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST } from '../apiHelper'

export const fetchInstitutionGroup = (user, institutionGroup) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}`,
    GET,
    null,
    user
  ).then(json => {
    return json?.data ?? {}
  })
}

export const updateInstitutionGroup = (user, institutionGroup) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}`,
    PATCH,
    institutionGroup.toRequestBody(),
    user
  )
}

export const linkInstitionIntoInstitutionGroup = (user, institutionGroup, institution) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}/institutions`,
    POST,
    { institution: institution.id },
    user
  )
}

export const deleteInstitutionFromInstitutionGroup = (user, institutionGroup, institution) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}/institutions/${institution.id}`,
    DELETE,
    null,
    user
  )
}

export const linkSchoolToInstitutionGroup = (user, institutionGroup, school) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}/schools`,
    POST,
    { school: school.id },
    user
  )
}

export const unlinkSchoolFromInstitutionGroup = (user, institutionGroup, school) => {
  return requestWithPromise(
    `/api/institution-groups/${institutionGroup.id}/schools/${school.id}`,
    DELETE,
    null,
    user
  )
}
