import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'

const IconDateCell = props => {
  const { placement, title, icon, onClick } = props

  return (
    <Tooltip placement={placement} title={title}>
      <div className='actionnable' onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </div>
    </Tooltip>
  )
}

export default IconDateCell
