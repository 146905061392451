import React, { useContext, useEffect, useState } from 'react'

import { Button, Col, Modal, Row, Tree } from 'antd'
import { getEmailByType, getSupportedEmailTypes } from '../../utils/api/institutionEmail'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Loading from './Loading'
import SendEmailMyselfForm from './SendEmailMyselfForm'
import { DESCRIPTIONS } from '../../utils/entities/email'
import TooltipIcon from '../icons/TooltipIcon'

const { TreeNode } = Tree

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const MAX_SIZE = 500

const ManagingEmailsPage = ({ user, t }) => {
  const { languages } = useContext(GlobalContext)

  const [treeData, setTreeData] = useState([])
  const [mailsLine, setMailsLine] = useState([])
  const [modulo, setModulo] = useState(3)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)
  const [visibleModal, setVisibleModal] = useState(false)
  const [selectedMail, setSelectedMail] = useState(null)

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
  }, [])

  useEffect(() => {
    getSupportedEmailTypes(user)
      .then(data => {
        const emailTypes = data
        const treeData = []

        for (const key in emailTypes) {
          treeData.push({ title: t(emailTypes[key]), key, type: emailTypes[key] })
        }

        setTreeData(treeData)
        setLoading(false)
      })
  }, [user])

  useEffect(() => {
    const mailsLine = []
    let subArray = []

    treeData.forEach((d, key) => {
      if (key % modulo === 0 && subArray.length > 0) {
        mailsLine.push(subArray)
        subArray = []
      }

      subArray.push(d)
    })

    if (subArray.length > 0) {
      mailsLine.push(subArray)
    }

    setMailsLine(mailsLine)
  }, [treeData, modulo])

  useEffect(() => {
    let modulo = Math.floor(windowWidth / MAX_SIZE)

    if (modulo > 3) {
      modulo = 3
    } else if (modulo < 1) {
      modulo = 1
    }

    setModulo(modulo)
  }, [windowWidth])

  const onLoadData = treeNode =>
    new Promise(resolve => {
      if (treeNode.props.children) {
        resolve()
        return
      }

      getEmailByType(user, treeNode.props.type).then(data => {
        const emails = data
        const children = []

        for (const key in languages) {
          let exist = false
          let currentEmail = null

          for (const email of emails) {
            if (email.languageLocale?.language.languageTag === languages[key].languageTag) {
              exist = true
              currentEmail = email
              break
            }
          }

          children.push({ title: t(languages[key].name), key: treeNode.props.eventKey - key, isLeaf: true, exist, data: currentEmail })
        }

        let exist = false
        let globalEmail = null

        for (const email of emails) {
          if (email.languageLocale === null) {
            exist = true
            globalEmail = email
            break
          }
        }

        children.push({ title: t('Global'), key: treeNode.props.eventKey - languages.length, isLeaf: true, exist, data: globalEmail })

        const treeNodeData = treeNode.props.dataRef
        treeNodeData.children = children
        setTreeData(treeData.filter(data => data.type === treeNodeData.type ? treeNodeData : data))
        resolve()
      })
    })

  const renderSendMailModal = selectedItem => {
    setVisibleModal(true)
    setSelectedMail(selectedItem)
  }

  const renderTreeNodes = data => {
    return (
      data.map((item, index) => {
        let description = null

        if (item.type) {
          description = DESCRIPTIONS[item.type] ?? null
        }

        if (item.children) {
          return (
            <TreeNode
              key={item.key}
              {...item}
              dataRef={item}
              title={
                <div className='flex-row' style={{ alignItems: 'center' }}>
                  <span style={{ fontWeight: 'bold' }}>{item.title}</span>
                  &nbsp;
                  <TooltipIcon title={description} />
                </div>
              }
              style={{ fontSize: '16px' }}
            >
              {renderTreeNodes(item.children)}
            </TreeNode>
          )
        }

        if (item.isLeaf) {
          if (item.exist) {
            return (
              <TreeNode
                icon={<FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(126, 183, 62)' }} />}
                style={{ fontSize: '15px' }}
                key={item.key} {...item}
                dataRef={item}
                title={
                  <span style={{ justifyContent: 'space-between', width: '100%' }}>
                    {item.title}
                  &nbsp;
                    <Button type='primary' size='small' onClick={() => renderSendMailModal(item)}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                  </span>
                }
              />
            )
          }

          return (
            <TreeNode
              selectable={false}
              icon={<FontAwesomeIcon icon={faCheckCircle} />} style={{ fontSize: '15px' }}
              key={item.key} {...item}
              dataRef={item}
              title={<span>{item.title}</span>}
            />
          )
        }

        return (
          <TreeNode
            key={item.key}
            {...item}
            dataRef={item}
            title={
              <div className='flex-row' style={{ alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>{item.title}</span>
                &nbsp;
                <TooltipIcon title={description} />
              </div>
            }
            style={{ fontSize: '16px' }}
          />
        )
      })
    )
  }

  return (
    <>
      <Modal
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={null}
        title={t('Send this email to yourself')}
        destroyOnClose
      >
        <SendEmailMyselfForm emailData={selectedMail} onClose={() => setVisibleModal(false)} />
      </Modal>
      {!loading && mailsLine.map((lineData, lineKey) => {
        return (
          <Row key={lineKey}>
            {lineData.map((item, itemKey) => {
              return (
                <Col key={lineKey + '-' + itemKey} span={8}>
                  <Tree loadData={onLoadData} showIcon selectable={false}>{renderTreeNodes([item])}</Tree>
                </Col>
              )
            })}
          </Row>
        )
      })}
      {loading &&
        <div style={{ height: '70vh' }}>
          <Loading size='10x' />
        </div>}
    </>
  )
}

export default connect(mapStateToProps)(ManagingEmailsPage)
