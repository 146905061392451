import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryString } from '../apiHelper'

export const getAllActTypes = (user, parameters = {}) => {
  return requestWithPromise(
    `/api/act-types${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const getActTypeSections = (user, actType) => {
  return requestWithPromise(
    `/api/act-types/${actType.id}/school-sections`,
    GET,
    null,
    user
  )
}

export const createActType = (user, body) => {
  return requestWithPromise(
    '/api/act-types',
    POST,
    body,
    user
  )
}

export const deleteActType = (user, actType) => {
  return requestWithPromise(
    `/api/act-types/${actType.id}`,
    DELETE,
    null,
    user
  )
}

export const updateActType = (user, actType, body) => {
  return requestWithPromise(
    `/api/act-types/${actType.id}`,
    PATCH,
    body,
    user
  )
}

export const linkSectionsToActType = (user, actType, schoolSectionsIds = []) => {
  return requestWithPromise(
    `/api/act-types/${actType.id}/school-sections`,
    POST,
    { ids: schoolSectionsIds },
    user
  )
}

export const unlinkSectionsToActType = (user, actType, schoolSectionsIds = []) => {
  return requestWithPromise(
    `/api/act-types/${actType.id}/school-sections${makeQueryString({ ids: schoolSectionsIds })}`,
    DELETE,
    null,
    user
  )
}
