import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

class AppCrash extends React.Component {
  componentWillUpdate (nextProps) {
    if (this.props.log === null && nextProps.log !== null) {
      console.log(this.props.log.error.toString())
      console.log(this.props.log.info.componentStack)
    }
  }

  render () {
    if (this.props.log === null) return null

    return (
      <Modal
        visible={this.props.log !== null}
        closable={false}
        title={this.props.t('Oops, something unexpected happened =/')}
        footer={[
          <div className='flex-row'>
            <div className='flex-fill' />
            <Button onClick={() => window.location.reload()}><FontAwesomeIcon icon='sync' />&nbsp;{this.props.t('Reload the application')}</Button>
            <div className='h-spacing' />
            <Button
              type='primary'
              onClick={this.props.onBack}
            >
              <FontAwesomeIcon icon='times' />&nbsp;{this.props.t('Close')}
            </Button>
          </div>
        ]}
      >
        <div style={{ textAlign: 'justify' }}>
          {this.props.t('You can try again by closing this message or reloading the application. If the error persists, use the blue button at the bottom right of the screen to contact us.')}
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppCrash)
