import React, { useContext, useEffect, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { canonizeString } from '../../../utils'
import { callFunctionIfDefined } from '../../../utils/functionHelper'
import Selector from './Selector'
import StudentsSelector from './StudentsSelector'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const StudentsAndYearsSelector = ({ disabled, internship, schoolSection, schoolSections, student, students, className, t, user, onSelect }) => {
  const { sectionToYearsBySection } = useContext(GlobalContext)

  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const internshipYears = useMemo(() => {
    let years = []

    if (internship.section) {
      years = sectionToYearsBySection[internship.section.sectionToYear ? internship.section.sectionToYear.section.id : -1] ?? []
    }

    return years
  }, [internship.section, sectionToYearsBySection])

  useEffect(() => {
    setSelectedStudent(internship.student ?? null)
  }, [internship.student])

  useEffect(() => {
    setSelectedYear(internship.internshipYear ?? null)
  }, [internship.internshipYear, sectionToYearsBySection])

  const handleStudentSelected = (value, data) => {
    data.internshipYear = null

    callFunctionIfDefined(onSelect, [data])
  }

  const handleInternshipYearSelected = (value, data) => {
    callFunctionIfDefined(onSelect, [{ internshipYear: data }])
  }

  const handleStudentFilter = (input, option) => {
    return canonizeString(option.props.filter).includes(canonizeString(input))
  }

  const getStudentValue = () => {
    if (selectedStudent !== null) {
      return selectedStudent.id
    }

    if (internship.section) {
      return -internship.section.id
    }

    return 0
  }

  return (
    <div className={className}>
      <StudentsSelector
        disabled={disabled}
        students={students}
        schoolSections={internship.school ? schoolSections[internship.school.id] : []}
        value={getStudentValue()}
        onSelect={handleStudentSelected}
        onSearch={handleStudentFilter}
      />
      <Selector
        data={internshipYears}
        dataKey='schoolYearName'
        disabled={disabled || !internshipYears.length}
        onSelect={handleInternshipYearSelected}
        showArrow={false}
        showEmpty
        showSearch
        value={selectedYear !== null ? selectedYear.id : -1}
      />
    </div>
  )
}

export default connect(mapStateToProps)(StudentsAndYearsSelector)
