import React from 'react'

import { Col, InputNumber, Row, Select, Tooltip } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../../reducers/Dispatchers'
import { stringOptionFilter } from '../utils/SelectOptionTools'

const Option = Select.Option
const SELECT_STYLE = { width: '200px' }
const TOOLTIP_PLACEMENT = 'top'
const WILD_CART_SYMBOL = '*'
const WILD_CART_TRANSLATION = 'Any'

const PLACES_TITLE = 'Allocated places'
const SCHOOLS_TITLE = 'School'
const SCHOOL_KEY = 'name'
const SCHOOL_PROPS = 'schools'
const SCHOOL_YEARS_TITLE = 'School year'
const SCHOOL_YEAR_KEY = 'schoolYearName'
const SCHOOL_YEAR_PROPS = 'schoolYears'

const QuotaAllocationForm = props => {
  const handlePlacesChanging = places => {
    if (Number.isInteger(places)) {
      props.onPlacesChanged(places)
    }

    if (places === '') {
      props.onPlacesChanged(0)
    }
  }
  const renderSelectOptions = (dataIdentifier, dataKey, withAllKeys = true) => {
    if (!dataIdentifier || !dataKey) {
      return []
    }

    const data = props[dataIdentifier] ?? []
    const options = []

    if (withAllKeys) {
      options.push(
        <Option key='option_default' value={WILD_CART_SYMBOL} data={{ id: -1 }}>
          <Tooltip placement={TOOLTIP_PLACEMENT} title={props.t(WILD_CART_TRANSLATION)}>
            <b>{WILD_CART_SYMBOL}</b>
          </Tooltip>
        </Option>
      )
    }

    data.forEach((option, index) => {
      const value = option[dataKey]

      options.push(
        <Option key={`option_${index}`} value={value} data={option}>
          <Tooltip placement={TOOLTIP_PLACEMENT} title={value}>
            {value}
          </Tooltip>
        </Option>
      )
    })

    return options
  }

  const generateTotalPlaces = () => {
    if (props.modification) {
      return ` / ${((props.remainingPlaces ?? 0) + (props.initialPlaces ?? 0)) - (props.places ?? 0)}`
    }

    return ''
  }

  const getSelectValue = (identifier, key) => {
    if (!props[identifier]) {
      return WILD_CART_SYMBOL
    }

    return props[identifier][key] ?? WILD_CART_SYMBOL
  }

  return (
    <div className='allocate-quota-form'>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(PLACES_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <InputNumber
            value={props.places ?? 0}
            onChange={handlePlacesChanging}
            disabled={props.disabled}
          />
          <b>{generateTotalPlaces()}</b>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(SCHOOLS_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <Select
            style={SELECT_STYLE}
            value={getSelectValue('selectedSchool', SCHOOL_KEY)}
            showSearch
            onSelect={props.onSchoolSelected}
            filterOption={stringOptionFilter}
            disabled={props.disabled}
          >
            {renderSelectOptions(SCHOOL_PROPS, SCHOOL_KEY, !props.modification)}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(SCHOOL_YEARS_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <Select
            style={SELECT_STYLE}
            value={getSelectValue('selectedSchoolYear', SCHOOL_YEAR_KEY)}
            showSearch
            onSelect={props.onSchoolYearSelected}
            filterOption={stringOptionFilter}
            disabled={props.disabled}
          >
            {renderSelectOptions(SCHOOL_YEAR_PROPS, SCHOOL_YEAR_KEY)}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotaAllocationForm)
