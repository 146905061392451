import { Col, Row } from 'antd'
import React from 'react'

const FormLine = ({ children, label, labelCol, wrapperCol }) => {
  return (
    <Row>
      <Col span={labelCol.span}>
        {label} :
      </Col>
      <Col span={wrapperCol.span}>
        {children}
      </Col>
    </Row>
  )
}

export default FormLine
