/**
 * REACT COMPONENT
 *
 * Have to purpose to extends the base behaviour of ANTD DatePicker.
 *
 * Display 4 additionnals buttons, 2 at the left and 2 at the right of the Datepicker
 *  in order to allow the user to rapidly displace the current date 1 week/month into
 *  the past or the futur.
 */
import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faChevronLeft, faChevronRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, Button, DatePicker } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../../reducers/Dispatchers'
import moment from 'moment'

const DEFAULT_FORMAT = 'DD/MM/YYYY'
const DEFAULT_PERIOD_NUMBER = 4
const DEFAULT_PERIOD = 'weeks'
const TEXT_BY_DISPLACEMENT = {
  DOUBLE_LEFT: 'Move 4 weeks in the past',
  LEFT: 'Move a week in the past',
  RIGHT: 'Move a week in the futur',
  DOUBLE_RIGHT: 'Move 4 weeks in the futur'
}
const ICON_BY_TRAVEL_PERIOD = {
  DOUBLE_LEFT: faAngleDoubleLeft,
  LEFT: faChevronLeft,
  RIGHT: faChevronRight,
  DOUBLE_RIGHT: faAngleDoubleRight
}

const DatePickerWithDisplacement = props => {
  const [period, setPeriod] = useState(DEFAULT_PERIOD)
  const [periodNumber, setPeriodNumber] = useState(DEFAULT_PERIOD_NUMBER)
  const [format, setFormat] = useState(DEFAULT_FORMAT)

  useEffect(() => {
    setPeriod(props.period ?? DEFAULT_PERIOD)
  }, [props.period])

  useEffect(() => {
    setFormat(props.format ?? DEFAULT_FORMAT)
  }, [props.format])

  useEffect(() => {
    setPeriodNumber(props.periodNumber ?? DEFAULT_PERIOD_NUMBER)
  }, [props.periodNumber])

  const renderDatesNavigation = () => {
    return (
      <div
        className={`dates-details-header ${props.showTopTransitions
              ? 'with-transition'
              : ''
        }`}
      >
        {renderDateChevron('DOUBLE_LEFT')}
        {renderDateChevron('LEFT')}
        {renderDateDisplayer()}
        {renderDateChevron('RIGHT')}
        {renderDateChevron('DOUBLE_RIGHT')}
      </div>)
  }

  const renderDateChevron = direction => {
    const periodNumberByDirection = {
      DOUBLE_LEFT: -periodNumber,
      LEFT: -1,
      RIGHT: 1,
      DOUBLE_RIGHT: periodNumber
    }

    return (
      <Tooltip
        placement='top'
        title={props.t(TEXT_BY_DISPLACEMENT[direction])}
      >
        <Button
          disabled={props.loading}
          onClick={() => handleDateDisplacement(direction, periodNumberByDirection[direction])}
        >
          <FontAwesomeIcon
            icon={ICON_BY_TRAVEL_PERIOD[direction]}
          />
        </Button>
      </Tooltip>
    )
  }

  const handleDateDisplacement = (direction, periodToAdd) => {
    const currentDate = moment(props.startDate, format)

    currentDate.add(periodToAdd, period)

    handleDateChange(currentDate, currentDate.format(format))
  }

  const handleDateChange = (date, dateString) => {
    props.onDateChange(date, dateString)
  }

  const renderDateDisplayer = () => {
    return (
      <DatePicker
        value={props.startDate ? moment(props.startDate, format) : moment()}
        format={format}
        onChange={handleDateChange}
      />
    )
  }

  return (
    <div>
      {renderDatesNavigation()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerWithDisplacement)
