import React, { useEffect, useState, useRef } from 'react'

import { getTableComponent } from '../../../utils/componentFactory'
import PopoverIconButton from '../Quotas/shared/PopoverIconButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import './assets/standard-table.scss'

const POPOVER_CONTENT = 'Are you sure you want to delete this line?'

const StandardTableLine = props => {
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState([])

  const savingTimer = useRef(null)

  useEffect(() => {
    return () => {
      setData(null)
    }
  }, [])

  useEffect(() => {
    if (props.data) {
      setData(props.data)
      dataIsCorrect(props.data)
    }
  }, [props.data])

  const handleChange = (newValue, key) => {
    clearTimeout(savingTimer.current)

    const newData = { ...data }

    newData[key] = newValue

    setData(newData)

    if (dataIsCorrect(newData)) {
      savingTimer.current = setTimeout(() => {
        props.onSave(newData)
      }, 250)
    }
  }

  const dataIsCorrect = data => {
    const newErrors = []

    props.columns.forEach((col, index) => {
      if (typeof col.check === 'function' && !col.check(data)) {
        newErrors.push(index)
      }
    })

    setErrors(newErrors)

    return newErrors.length === 0
  }

  const renderRow = () => {
    const body = []

    if (data && props.columns) {
      props.columns.forEach((col, index) => {
        body.push(renderCellComponent(col, index))
      })

      body.push(
        <td key='actions' className='additionnal-actions'>
          <PopoverIconButton
            title='Delete'
            type='danger'
            size='small'
            popoverType='danger'
            tooltipTitle='Delete'
            content={POPOVER_CONTENT}
            onConfirm={() => { props.onDelete(data) }}
            icon={faTrash}
          />
        </td>
      )
    }

    return body
  }

  const renderCellComponent = (column, index) => {
    const Component = getTableComponent(column.type)

    if (errors.includes(index)) {
      return (
        <td key={`td-${index}`} className='error-cell'>
          <Component
            data={data}
            dataKey={column.key}
            onChange={handleChange}
          />
        </td>
      )
    }

    return (
      <td key={`td-${index}`}>
        <Component
          data={data}
          dataKey={column.key}
          onChange={handleChange}
        />
      </td>
    )
  }

  return (
    <tr className='standard-table-line'>
      {renderRow()}
    </tr>
  )
}

export default StandardTableLine
