import { Button, Modal } from 'antd'
import React from 'react'
import { callFunctionIfDefined } from '../../../utils/functionHelper'

const BODY = 'alert.late.internship.request'
const TITLE = 'Start date does not observe waiting period'

const EarlyDateModal = props => {
  const { visible, onVisibleChange, t } = props

  const handleClick = () => {
    callFunctionIfDefined(onVisibleChange, [false])
  }

  return (
    <Modal
      title={t(TITLE)}
      visible={visible}
      footer={
        <Button type='primary' onClick={handleClick}>
            OK
        </Button>
      }
    >
      {t(BODY)}
    </Modal>
  )
}

export default EarlyDateModal
