import { NO_SECTION_TEXT } from '../../Components/shared/InternshipsManager/utils/constants'

const anySchoolSectionToString = (schoolSection, withoutSchoolYear = false) => {
  if (!schoolSection) {
    return NO_SECTION_TEXT
  }

  return isNewSection(schoolSection)
    ? schoolSectionToString(schoolSection, withoutSchoolYear)
    : oldSchoolSectionTostring(schoolSection)
}

const schoolSectionToString = (schoolSection, withoutSchoolYear = false) => {
  const { name, schoolYearName, sectionName } = schoolSection
  return (name ?? sectionName) + formatSchoolYearNameIfExist(schoolYearName, withoutSchoolYear)
}

const formatSchoolYearNameIfExist = (schoolYearName, withoutSchoolYear) => {
  return schoolYearName && !withoutSchoolYear
    ? ' (' + schoolYearName + ')'
    : ''
}

const oldSchoolSectionTostring = schoolSection => {
  return schoolSection.name
}

const isNewSection = section => {
  return !!section.sectionToYear
}

const getSchoolRootSectionByInternship = internship => {
  return { name: internship.schoolName }
}

const formatOldSection = section => {
  let schoolYear = ''

  if (section.schoolYear) {
    schoolYear = displaySchoolYearName(section.oldSchoolYearName)
  }

  return section.name + schoolYear
}

const displaySchoolYearName = schoolYear => {
  return ' (' + schoolYear + ')'
}

export {
  anySchoolSectionToString,
  schoolSectionToString,
  isNewSection,
  getSchoolRootSectionByInternship,
  formatOldSection
}
