import React, { useContext } from 'react'

import { Divider, Row, Select, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const { Option } = Select

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const EventCodeSelect = ({ t, update, value, handleEventCodeSelection }) => {
  const { eventCodes } = useContext(GlobalContext)

  return (
    <div>
      {update && (
        <Divider orientation='left'> {t('Event code')} </Divider>
      )}
      <Row gutter={16}>
        <Row className='desiderata-eventcode-select'>
          <Select
            placeholder={update ? value : t('Encode an event')}
            value={value}
            size='large'
            style={{ width: '100%' }}
            onChange={handleEventCodeSelection}
          >
            <Option value={null} key='WITHOUT-EVENT_CODE' data={null}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('No event code')}
                <Tooltip
                  placement='right'
                  title={t('No event code')}
                >
                  <FontAwesomeIcon icon='info-circle' />
                </Tooltip>
              </div>
            </Option>
            {
              eventCodes.map(ec => {
                return (
                  <Option value={ec.type} key={ec.type} data={ec}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {t('event.code.' + ec.type)}
                      <Tooltip
                        placement='right'
                        title={t('event.code.label.' + ec.type)}
                      >
                        <FontAwesomeIcon icon='info-circle' />
                      </Tooltip>
                    </div>
                  </Option>
                )
              })
            }
          </Select>
        </Row>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps)(EventCodeSelect)
