import { connect, mapDispatchToProps, mapStateToProps } from '../../../../../reducers/Dispatchers'
import { Button, Form, InputNumber, Modal } from 'antd'
import React, { useState } from 'react'

const TimeActsFormModal = (props) => {
  const [values, setValues] = useState({ hours: '0', minutes: '00' })

  const checkValue = (value) => {
    return value || '00'
  }

  const handleClose = () => {
    const newValues = { ...values }

    newValues.minutes = makeTimeFromNumber(newValues.minutes)
    props.onClose(true, newValues)
  }

  const makeTimeFromNumber = time => {
    const timeAsString = time.toString()

    return timeAsString.length === 1 ? `0${timeAsString}` : timeAsString
  }

  const okButtonProps = { htmlType: 'submit', form: 'actEditionForm' }
  const footer =
    (
      <div>
        <Button key='back' onClick={() => props.onClose(false)}>
          {props.t('Cancel')}
        </Button>
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            handleClose()
            setValues({})
          }}
        >
          {props.t('Submit')}
        </Button>
      </div>
    )

  return (
    <Modal
      title={props.title}
      visible
      onCancel={props.onCancel}
      okButtonProps={okButtonProps}
      footer={footer}
    >
      <Form
        id='actEditionForm'
        layout='vertical'
        initialvalues={values}
      >
        <Form.Item>
          <InputNumber
            size='large'
            min={0}
            defaultValue={checkValue(values.hours)}
            placeholder={checkValue(values.hours)}
            onChange={(value) => {
              const formattedValue = checkValue(value)
              setValues({ ...values, hours: formattedValue })
            }}
          /> :
          <InputNumber
            step={15}
            min={0}
            max={45}
            size='large'
            defaultValue={checkValue(values.minutes)}
            placeholder={checkValue(values.minutes)}
            onChange={(value) => {
              const formattedValue = checkValue(value)
              setValues({ ...values, minutes: formattedValue })
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeActsFormModal)
