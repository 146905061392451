import { requestWithPromise } from '..'
import { GET, makeQueryString } from '../apiHelper'

export const getSchoolSectionYears = (section, user) => {
  return requestWithPromise(
    `/api/sections/${section.id}/section-to-years`,
    GET,
    null,
    user
  )
}

export const getActTypes = (user, section, parameters = { 'get-hours': 1 }) => {
  return requestWithPromise(
    `/api/school-sections/${section.id}/act-types${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}
