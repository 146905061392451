import React, { useState, useEffect } from 'react'
import { ChromePicker } from 'react-color'

const ShiftPresetColorSelector = props => {
  const [color, setColor] = useState({ hex: props.color || '#FFF' })

  useEffect(() => { setColor(props.color || '#FFF') }, [props.color])

  return (
    <div className='shift-preset-color-selector'>
      <div className='color-selector-cover' onClick={props.onClose} />
      <div className='color-selector-container'>
        <ChromePicker onChange={setColor} color={color} onChangeComplete={props.onChange} />
      </div>
    </div>
  )
}

export default ShiftPresetColorSelector
