import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Popconfirm, Select, TimePicker } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { PAUSE_KEY } from '../../../Components/institution/Quotas/definition/forms/PauseAllocationForm'
import IconTooltip from '../../../Components/institution/Quotas/definition/IconTooltip'
import { getIconByName } from '../../../utils/fontAwesomeHelper'
import ShiftPresetColorSelector from './ShiftPresetColorSelector'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { FULL_TIME_FORMAT, HOURS_MINUTES_FORMAT } from '../../../utils/momentjs'

import '../../../assets/shifts-presets-manager.scss'

import moment from 'moment'

const Option = Select.Option
const TOOLTIP_PLACEMENT = 'left'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ShiftPresetCard = ({ disabled, preset, usePauses, t, onDelete, onSave, isValid }) => {
  const { pauses } = useContext(GlobalContext)

  const [localPreset, setLocalPreset] = useState(null)
  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(false)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  useEffect(() => {
    setLocalPreset({ ...preset, valid: localIsValid(preset), dirty: false })
  }, [preset])

  const timer = useRef(null)

  const handleBlur = () => {
    if (localPreset.valid && localPreset.dirty && !isPopConfirmOpen && !displayColorPicker) {
      timer.current = setTimeout(() => {
        onSave(localPreset)
      }, 500)
    }
  }

  const handleChange = (key, value) => {
    const newPreset = { ...localPreset }

    newPreset[key] = value
    newPreset.valid = localIsValid(newPreset)
    newPreset.dirty = true

    setLocalPreset(newPreset)
  }

  const localIsValid = preset => {
    return preset.periodCode.toString().trim().length > 0 && (typeof isValid !== 'function' || isValid(preset))
  }

  return (
    <div
      className={`shift-preset-container ${localPreset === null || localPreset.valid ? '' : 'invalid'}`}
      style={localPreset === null ? { display: 'none' } : {}}
      onFocus={() => clearTimeout(timer.current)}
      onBlur={handleBlur}
    >
      {localPreset !== null && (
        <>
          <div className='preset-card-header'>
            <Input
              disabled={disabled}
              className='preset-code-input'
              value={localPreset.periodCode.toUpperCase()}
              onChange={e => handleChange('periodCode', e.target.value)}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            />
            {usePauses && (
              <Select
                disabled={disabled}
                value={localPreset.shiftPause === null ? '/' : localPreset.shiftPause.id}
                onSelect={(value, option) => handleChange('shiftPause', option.props.data)}
                showArrow={false}
              >
                {pauses && (
                  <Option value={null} data={null}>
               /
                  </Option>
                )}
                {pauses && pauses.map((pause, index) => {
                  const value = pause[PAUSE_KEY]

                  return (
                    <Option key={`option_${index}`} value={pause.id} data={pause}>
                      <IconTooltip
                        title={value}
                        placement={TOOLTIP_PLACEMENT}
                        icon={getIconByName(pause.icon)}
                      />
                    </Option>
                  )
                })}
              </Select>
            )}
          </div>
          <div className='preset-time-wrapper'>
            <label>{t('Period')} :</label>
            <div className='times-container'>
              <TimePicker
                disabled={disabled}
                value={moment(localPreset.startTime, HOURS_MINUTES_FORMAT)}
                format={HOURS_MINUTES_FORMAT}
                popupClassName='minimal-pannel-size'
                onChange={time => {
                  if (time) {
                    handleChange('startTime', time.format(FULL_TIME_FORMAT))
                  }
                }}
              />
           -
              <TimePicker
                disabled={disabled}
                value={moment(localPreset.endTime, HOURS_MINUTES_FORMAT)}
                format={HOURS_MINUTES_FORMAT}
                popupClassName='minimal-pannel-size'
                onChange={time => {
                  if (time) {
                    handleChange('endTime', time.format(FULL_TIME_FORMAT))
                  }
                }}
              />
            </div>
          </div>
          <div className='preset-pause-wrapper'>
            <div className='label-input'>
              <label>{t('Pause duration')} :</label>
              <input
                disabled={disabled}
                type='number'
                value={localPreset.pause}
                className='preset-time-input'
                onChange={e => handleChange('pause', e.target.value)}
                min={0}
                max={600}
              />
            </div>
          </div>
          <div className='color-selector-wrapper'>
            <button
              disabled={disabled}
              className='selected-color'
              onClick={() => setDisplayColorPicker(true)}
              style={{ background: localPreset.color || 'transparent' }}
            >
              {(!localPreset.color || localPreset.color.toUpperCase() === '#FFFFFF') && t('Select color')}
            </button>
            {displayColorPicker &&
              <ShiftPresetColorSelector
                color={localPreset.color}
                onClose={() => {
                  setDisplayColorPicker(false)

                  if (localPreset.valid && localPreset.dirty) {
                    timer.current = setTimeout(() => {
                      onSave(localPreset)
                    }, 500)
                  }
                }}
                onChange={(color) => handleChange('color', color.hex)}
              />}
          </div>
          <Popconfirm
            placement='top'
            okType='danger'
            title={t('Delete this data ?')}
            okText={t('Yes')}
            cancelText={t('Cancel')}
            onClose={() => setIsPopConfirmOpen(false)}
            onConfirm={() => onDelete(localPreset)}
            onOpenChange={() => setIsPopConfirmOpen(!isPopConfirmOpen)}
          >
            <button className='remove-preset-btn' onClick={() => setIsPopConfirmOpen(true)}>
              <FontAwesomeIcon icon='times' />
            </button>
          </Popconfirm>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ShiftPresetCard)
