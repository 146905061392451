import React, { useContext } from 'react'

import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { addTranslationForLanguage, getActiveLanguage, getLanguages, getTranslate, setActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'
import { setLanguage } from '../../utils/locale'
import { getUser, updateUserProfile } from '../../reducers/UserReducer'
import { bindActionCreators } from 'redux'
import Cookie from 'js-cookie'
import { updateUser } from '../../utils/api/user'
import UserSettings from './UserSettings'
import { GlobalContext } from '../../Providers/GlobalProvider'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    languages: getLanguages(state.locale),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addTranslation: bindActionCreators(addTranslationForLanguage, dispatch),
    setActiveLanguage: bindActionCreators(setActiveLanguage, dispatch),
    updateUserProfile: bindActionCreators(updateUserProfile, dispatch)
  }
}

const LanguagesMenu = ({ currentLanguage, icon, languages, title, type, user, t, addTranslation, onLogout, setActiveLanguage, updateUserProfile }) => {
  const { invalidateEventCodes } = useContext(GlobalContext)

  const updateUserLanguage = language => {
    if (user.id && user.language !== language) {
      updateUser(user, { id: user.id, language }).then(json => {
        updateUserProfile(json.data)
        invalidateEventCodes()
        if (
          typeof Cookie.get('user') !== 'undefined' &&
            Cookie.get('user') !== 'undefined' &&
            typeof Cookie.get('token') !== 'undefined' &&
            Cookie.get('token') !== 'undefined'
        ) {
          Cookie.set('user', JSON.stringify(json.data), { expires: 7 })
        }
      })
    }
  }

  const renderMenu = () => {
    let menuItems = []

    if (onLogout) {
      menuItems = [
        <Menu.Item key='menu-item-1'>
          <UserSettings />
        </Menu.Item>,
        <Menu.Divider key='menu-divider-1' />,
        <Menu.Item key='menu-item-2' onClick={onLogout}>
          <FontAwesomeIcon icon='sign-out-alt' />
          {t('Log out')}
        </Menu.Item>,
        <Menu.Divider key='menu-divider-2' />
      ]
    }

    languages.forEach(l => {
      menuItems.push(
        <Menu.Item
          key={l.code} onClick={e => {
            setLanguage({
              language: e.key,
              addTranslationForLanguage: addTranslation,
              setActiveLanguage,
              getActiveLanguage,
              getUser: () => { return user }
            })
            updateUserLanguage(e.key)
          }}
          className='ant-dropdown-menu-item'
        >
          <FontAwesomeIcon
            icon={faGlobeEurope}
            style={{
              color: currentLanguage === l.code
                ? 'inherit'
                : 'transparent'
            }}
          />
          {t(l.name)}
        </Menu.Item>
      )
    })

    return (
      <Menu>
        {menuItems}
      </Menu>
    )
  }

  return (
    <Dropdown
      overlay={renderMenu()}
      trigger={['click']}
    >
      <Tooltip placement='left' title={title}>
        <Button type={type ?? 'default'}>
          <FontAwesomeIcon icon={icon} />
        </Button>
      </Tooltip>
    </Dropdown>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesMenu)
