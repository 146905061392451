import React from 'react'
import { string } from 'prop-types'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapStateToProps, connect } from '../../reducers/Dispatchers'
import { Tooltip } from 'antd'
import '../../assets/emailing-check.scss'

const LIGHT_GREEN = '#BEE46C'
const BABY_BLUE = '#89CFF0'

const PositiveMatchEmailingCheck = ({ emailingStatus, customTooltip }) => {
  let firstCheckClass = ''
  let color = ''

  switch (emailingStatus) {
    case 'ONLY_EARLY':
      firstCheckClass = 'check-highlighted'
      color = BABY_BLUE
      break
    case 'ONLY_LATE':
      firstCheckClass = 'check-highlighted'
      color = LIGHT_GREEN
      break
    case 'BOTH':
      firstCheckClass = 'check-highlighted'
      color = LIGHT_GREEN
      break
    default:
  }

  if (emailingStatus === 'NOT_ALLOWED') {
    return (
      <div className='email-check-status'>
        <Tooltip
          placement='top'
          className='tooltip-wrapper'
          title={customTooltip}
        >
          <span className='not-allowed'>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className='email-check-status'>
      <Tooltip
        placement='top'
        className='tooltip-wrapper'
        title={customTooltip}
      >
        <span className={firstCheckClass}>
          <FontAwesomeIcon icon={faEnvelope} style={{ color }} />
        </span>
      </Tooltip>
    </div>
  )
}

PositiveMatchEmailingCheck.propTypes = {
  status: string
}

PositiveMatchEmailingCheck.defaultProps = {
  status: 'NOT_ALLOWED'
}

export default connect(mapStateToProps)(PositiveMatchEmailingCheck)
