import { fetch } from '..'
import { POST } from '../apiHelper'

export const sendInternshipMessage = (user, { internship, content }) => {
  return fetch(
    '/internship-message/send',
    POST,
    { internship, content },
    user
  )
}
