import { Button, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import LazySelect, { ALL_DATA } from '../../antd/Selects/LazySelect'
import { getAllSchools } from '../../../utils/api/school'
import { getAllSectionToYears } from '../../../utils/api/sectionToYear'
import { getAllSectors } from '../../../utils/api/sector'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import FormLine from './FormLine'

import '../../../assets/school-permission-form.scss'

const REQUEST_PARAMETERS = { paginate: true }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const SchoolPermissionForm = ({ data, onSubmit, t }) => {
  const [item, setItem] = useState(null)

  useEffect(() => {
    setItem({ ...data })

    return () => {
      setItem(null)
    }
  }, [data])

  return (
    <div className='school-permission-form'>
      {item !== null && (
        <>
          <FormLine label={t('School')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <LazySelect
              allOption
              dataKey='name'
              getData={getAllSchools}
              parameters={REQUEST_PARAMETERS}
              placement='top'
              showSearch
              value={item.school === null ? ALL_DATA.value : item.school}
              onSelect={newValue => setItem({ ...item, school: newValue.id === ALL_DATA.value ? null : newValue })}
            />
          </FormLine>
          <FormLine label={t('Section')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <LazySelect
              allOption
              dataKey={d => `${d.sectionAbbreviation} (${d.schoolYearName})`}
              getData={getAllSectionToYears}
              parameters={REQUEST_PARAMETERS}
              placement='top'
              showSearch
              value={item.sectionToYear === null ? ALL_DATA.value : item.sectionToYear}
              onSelect={newValue => setItem({ ...item, sectionToYear: newValue.id === ALL_DATA.value ? null : newValue })}
            />
          </FormLine>
          <FormLine label={t('Sector')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <LazySelect
              allOption
              dataKey='name'
              getData={getAllSectors}
              parameters={REQUEST_PARAMETERS}
              placement='top'
              showSearch
              value={item.sector === null ? ALL_DATA.value : item.sector}
              onSelect={newValue => setItem({ ...item, sector: newValue.id === ALL_DATA.value ? null : newValue })}
            />
          </FormLine>
          <FormLine label={t('Granted')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Switch checked={item.permissionEnabled} onChange={checked => setItem({ ...item, permissionEnabled: checked })} />
          </FormLine>
          <div className='form-footer'>
            <Button type='primary' onClick={() => onSubmit(item)}>{t('Validate')}</Button>
          </div>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(SchoolPermissionForm)
