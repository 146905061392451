import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Col, List, Modal, Row, Select, Tooltip } from 'antd'
import LazyCheckbox from '../../antd/Checkbox/LazyCheckbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { assignManagedBy } from '../../../utils/api/sector'
import { getPaginateUsers } from '../../../utils/api/user'
import { getUser } from '../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { ROLE_HOSPITAL_ADMIN, ROLE_NURSE, ROLE_VALIDATOR } from '../../../utils/constants'
import { debounce } from 'lodash'

import '../../../assets/assign-users-modal.scss'

const ALL_ROLES = 'ALL_ROLES'

const PARAMS = { paginate: false }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const UsersAssignModal = ({ sector, visible, onClose, onAssign, careUnitUsers, user, t }) => {
  const [checkedUsers, setCheckedUsers] = useState([])
  const [selectedRole, setSelectedRole] = useState(ALL_ROLES)
  const [inputPattern, setInputPattern] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [users, setUsers] = useState([])

  const fetchUsers = useCallback(() => {
    getPaginateUsers(user, PARAMS).then(json => {
      if (json?.data) {
        let data = json.data.filter(user => !careUnitUsers.some(assignedUser => assignedUser.id === user.id))

        data = data.filter(user => !user.roles.includes(ROLE_VALIDATOR))

        data = data.map(item => ({
          ...item,
          firstname: item.firstname ? item.firstname : '',
          lastname: item.lastname ? item.lastname : ''
        }))

        data = data.map(item => ({
          ...item,
          fullname: `${item.firstname} ${item.lastname}`
        }))

        setUsers(data)
      }
    })
  }, [user, setUsers])

  const verifyFullName = useCallback(name => name === ' ' ? 'email' : 'fullname', [])

  const handleSelectedRoleChange = useCallback(value => setSelectedRole(value), [setSelectedRole])

  const handleParamsChange = useCallback(({ search }) => setInputPattern(search), [setInputPattern])

  const filterUsers = useCallback(() => {
    let result = [...users]

    if (selectedRole !== ALL_ROLES) {
      result = result.filter(user => user.roles.includes(selectedRole))
    }

    if (inputPattern) {
      result = result.filter(user => user.email.toLowerCase().includes(inputPattern.toLowerCase()))
    }

    setFilteredUsers(result)
  }, [users, selectedRole, inputPattern, setFilteredUsers])

  const uncheckUser = useCallback(target => {
    const result = checkedUsers.filter(item => item.id !== target.id)
    setCheckedUsers(result)
  }, [checkedUsers, setCheckedUsers])

  const checkedUsersIds = useMemo(() => checkedUsers.map(user => user.id))

  const handleAssign = useCallback(() => {
    assignManagedBy(user, sector, checkedUsersIds).then(() => {
      onClose()
      onAssign()
    })
  }, [checkedUsersIds])

  useEffect(() => fetchUsers(), [fetchUsers])

  useEffect(() => {
    const filter = debounce(() => filterUsers(), 500)

    filter()

    return () => filter.cancel()
  }, [filterUsers])

  const renderSelectedUsers = useCallback(u => (
    <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        {`${u[verifyFullName(u.fullname)]} (${t(u.roles[0] === ROLE_HOSPITAL_ADMIN ? 'Administrator' : 'Nurse')})`}
      </span>
      <Button type='danger' onClick={() => uncheckUser(u)}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </List.Item>
  ), [uncheckUser])

  return (
    <Modal
      title={t('Assign users')}
      visible={visible}
      onCancel={onClose}
      onOk={handleAssign}
      okText={t('Save')}
      wrapClassName='assign-users-modal'
    >
      <Row gutter={48} style={{ display: 'flex' }}>
        <Col span={12}>
          <div style={{ fontWeight: 'bolder', marginBottom: '10px' }}> {t('Users')} </div>
          <div> {t('Displayed roles')} </div>
          <Select
            defaultValue='ALL_ROLES' style={{ width: '100%' }}
            onChange={handleSelectedRoleChange}
          >
            <Select.Option value='ALL_ROLES'> {t('All roles')} </Select.Option>
            <Select.Option value={ROLE_HOSPITAL_ADMIN}> {t('Administrator')} </Select.Option>
            <Select.Option value={ROLE_NURSE}> {t('Nurse')} </Select.Option>
          </Select>
          <div style={{ marginTop: '5px' }}> {t('Search')} </div>
          <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <LazyCheckbox
              checked={checkedUsers}
              data={filteredUsers}
              dataItem={d => {
                const displayNameKey = verifyFullName(d.fullname)

                return (
                  <Tooltip
                    placement='right'
                    title={d.email}
                  >
                    {`${d[displayNameKey]} (${t(d.roles[0] === ROLE_HOSPITAL_ADMIN ? 'Administrator' : 'Nurse')})`}
                  </Tooltip>
                )
              }}
              placeholder={t('Select a user')}
              placement='top'
              onChange={setCheckedUsers}
              onParametersChange={handleParamsChange}
              showSearch
            />
          </div>
        </Col>
        <Col span={12} style={{ borderLeft: '1px solid #e8e8e8' }}>
          <div style={{ fontWeight: 'bolder', marginBottom: '10px' }}> {t('Selected users')} </div>
          <List
            dataSource={checkedUsers}
            renderItem={renderSelectedUsers}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default connect(mapStateToProps)(UsersAssignModal)
