import { Tooltip } from 'antd'
import React from 'react'
import { DAY_AND_MONTH_FORMAT } from '../utils/constants'

const CONTENT_STYLE = { paddingTop: '7px' }

const NO_SCHOOL_TEXT = 'NO_SCHOOL'
const NO_SECTOR_TEXT = 'NO_SECTOR'
const DURATION_TEXT = 'Internship duration'
const QUOTAS_INFORMATION_TEXT = 'Only accepted internships are counted in the quotas.'
const INTERNSHIP_REMAINING_PLACES_TEXT = 'internship place(s) remaining for'
const INTERNSHIP_EXCESS_PLACES_TEXT = 'internship place(s) in excess for'

const QuotaDateCell = ({ placement, value, startDate, endDate, school, sector, t }) => {
  const getClassName = () => {
    if (value > 0) {
      return 'underloaded'
    }

    if (value === 0) {
      return 'balanced'
    }

    if (value < 0) {
      return 'overloaded'
    }

    return ''
  }

  const renderTitle = () => {
    const quotasInformationMessage = t(QUOTAS_INFORMATION_TEXT)
    const endOfText = `${school === null ? NO_SCHOOL_TEXT : school?.name} ${t('in')} ${sector === null ? NO_SECTOR_TEXT : sector?.name}.`
    const placesText = value < 0
      ? INTERNSHIP_EXCESS_PLACES_TEXT
      : INTERNSHIP_REMAINING_PLACES_TEXT

    return (
      <div>
        <span>
          {`${Math.abs(value)} ${t(placesText)} ${endOfText}`}
          <br />
          <br />
          {quotasInformationMessage}
        </span>
        <br />
        <br />
        <div>{t(DURATION_TEXT)} </div>
        <div>{startDate.format(DAY_AND_MONTH_FORMAT)} - {endDate.format(DAY_AND_MONTH_FORMAT)}</div>
      </div>
    )
  }

  return (
    <Tooltip placement={placement} title={renderTitle()}>
      <div className={getClassName()} style={CONTENT_STYLE}>
        {value}
      </div>
    </Tooltip>
  )
}

export default QuotaDateCell
