import React, { useContext, useMemo } from 'react'

import { Button, Card } from 'antd'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faEdit, faEnvelope, faGlobe, faIdBadge, faIdCard, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'

import '../../../assets/institution-group-view.scss'
import { DataGrid } from '../../../Components/shared/DataGrid/DataGrid'
import { WindowWidthAware } from '../../../HOC/WindowWidthAware'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const InstitutionGroupView = ({ onSelectInstitutionGroup, t }) => {
  const { institutionGroup } = useContext(InstitutionGroupContext)

  const details = useMemo(() => [
    {
      icon: faIdCard,
      title: t('institution_group_view.institution_group.name'),
      content: institutionGroup.name ? institutionGroup.name : t('institution_group_view.institution_group.without_name')
    },
    {
      icon: faIdBadge,
      title: t('institution_group_view.institution_group.acronym'),
      content: institutionGroup.acronym ? institutionGroup.acronym : t('institution_group_view.institution_group.without_acronym')
    },
    {
      icon: faLocationArrow,
      title: t('institution_group_view.institution_group.address'),
      content: institutionGroup.address ? institutionGroup.address : t('institution_group_view.institution_group.without_address')
    },
    {
      icon: faCity,
      title: t('institution_group_view.institution_group.city'),
      content: institutionGroup.city ? institutionGroup.city : t('institution_group_view.institution_group.without_city')
    },
    {
      icon: faEnvelope,
      title: t('institution_group_view.institution_group.zipcode'),
      content: institutionGroup.zipcode ? institutionGroup.zipcode : t('institution_group_view.institution_group.without_zipcode')
    },
    {
      icon: faGlobe,
      title: t('institution_group_view.institution_group.country'),
      content: institutionGroup?.country.name ?? t('institution_group_view.institution_group.without_country')
    }
  ], [institutionGroup, faIdCard, faIdBadge, faLocationArrow, faCity, faEnvelope, faGlobe, t])

  return (
    <div className='institution-group-view-container'>
      <Card
        title={t('institution_group_view.title')}
      >
        <WindowWidthAware>
          <DataGrid data={details} />
        </WindowWidthAware>
        <div className='actions-container'>
          <Button onClick={() => onSelectInstitutionGroup(institutionGroup)}><FontAwesomeIcon icon={faEdit} /> {t('Edit')} </Button>
        </div>
      </Card>
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionGroupView)
