import React, { useState, useEffect, useContext, useMemo } from 'react'

import { Drawer, Form, Select, Button } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { generalErrorHandler, requestWithPromise } from '../../utils'
import { SchoolContext } from '../../Providers/SchoolProvider'
import { getInstitutionSectors } from '../../utils/api/institution'
import Loading from '../../HOC/Loading'

const UserValidatorInstitutionSector = props => {
  const { institutions } = useContext(SchoolContext)

  const [institutionSectors, setInstitutionSectors] = useState([])
  const [saving, setSaving] = useState(false)
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [sectorIdToBeDeleted, setSectorIdToBeDeleted] = useState(undefined)
  const [isChecked, setIsChecked] = useState(false)
  const [userContactPersonSectors, setUserContactPersonSectors] = useState([])
  const [loadingUserData, setLoadingUserData] = useState(true)
  const [loadingSectors, setLoadingSectors] = useState(true)

  const sortedInstitutions = useMemo(() => {
    const sortedInstitutions = [...institutions]
    return sortedInstitutions.sort((a, b) => a.name.localeCompare(b.name))
  }, [institutions])

  useEffect(() => {
    if (selectedInstitution) {
      setLoadingSectors(true)

      getInstitutionSectors(props.getUser, selectedInstitution).then(json => {
        if (json?.data) {
          setInstitutionSectors(json.data)
        }

        setLoadingSectors(false)
      })
    }
  }, [selectedInstitution, props.getUser])

  useEffect(() => {
    if (props.user) {
      getUserDetails(props.getUser, props.user)
    } else {
      setSaving(false)
    }
  }, [props.user?.id, props.getUser])

  const getUserDetails = async (user, selectedUser) => {
    setLoadingUserData(true)
    try {
      const { data: { institutions, supervisedSectors, contactPersonSectors } } = await requestWithPromise('/api/User/' + selectedUser.id + '/all', 'GET', null, user)

      if (institutions && institutions.length) {
        const institution = institutions[0].id ? institutions[0] : { id: institutions[0] }

        const userSector = supervisedSectors[0].id ? supervisedSectors[0] : { id: supervisedSectors[0] }
        const newUserContactPersonSectors = contactPersonSectors.map(contactPerson => contactPerson.id)

        setSelectedInstitution(institution)
        setSelectedSector(userSector)
        setSectorIdToBeDeleted(undefined)
        setUserContactPersonSectors(newUserContactPersonSectors)
        setIsChecked(newUserContactPersonSectors.includes(userSector.id))
      }
    } catch (err) {
      generalErrorHandler(err)
    }
    setLoadingUserData(false)
  }

  const handleInstitutionChange = selectedInstitutionId => {
    setSelectedInstitution(institutions.find(i => i.id === selectedInstitutionId))
    setSelectedSector(null)
    setIsChecked(false)
  }

  const handleSectorChange = selectedSectorId => {
    const newUserContactPersonSectors = [...userContactPersonSectors]

    if (selectedSector && newUserContactPersonSectors.includes(selectedSector.id)) {
      newUserContactPersonSectors.splice(newUserContactPersonSectors.indexOf(selectedSector.id))
      setUserContactPersonSectors(newUserContactPersonSectors)
    }

    if (sectorIdToBeDeleted === undefined && isChecked) {
      setSectorIdToBeDeleted(selectedSector.id)
    } else if (sectorIdToBeDeleted === undefined && !isChecked) {
      setSectorIdToBeDeleted(-1)
    }

    setIsChecked(userContactPersonSectors.includes(selectedSectorId))
    setSelectedSector({ id: selectedSectorId })
  }

  const checkContactPerson = () => {
    const newUserContactPersonSectors = [...userContactPersonSectors]

    if (selectedSector?.id !== undefined && newUserContactPersonSectors.includes(selectedSector.id)) {
      newUserContactPersonSectors.splice(newUserContactPersonSectors.indexOf(selectedSector.id))
      setUserContactPersonSectors(newUserContactPersonSectors)

      if (sectorIdToBeDeleted === undefined) {
        setSectorIdToBeDeleted(selectedSector.id)
      }
    } else {
      newUserContactPersonSectors.push(selectedSector.id)
      setUserContactPersonSectors(newUserContactPersonSectors)

      if (sectorIdToBeDeleted === undefined) {
        setSectorIdToBeDeleted(-1)
      }
    }

    setIsChecked(!isChecked)
  }

  const validateInstitution = (rule, value, callback) => {
    if (!value) callback(this.props.t('Institution is required'))
    callback()
  }

  const validateSector = (rule, value, callback) => {
    if (!value) callback(this.props.t('Care unit is required'))
    callback()
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSaving(true)

    let sectors = []

    if (!userContactPersonSectors.includes(sectorIdToBeDeleted)) {
      sectors = [...userContactPersonSectors]

      if (sectorIdToBeDeleted !== undefined && sectorIdToBeDeleted !== -1) sectors.push(sectorIdToBeDeleted)
    }

    props.onSave({ ...props.user, institution: selectedInstitution.id, sector: selectedSector.id }, props.onClose, true, sectors)

    return false
  }

  const FormItem = Form.Item
  const { Option } = Select
  const { getFieldDecorator } = props.form
  return (
    <Drawer
      title={props.user ? `${props.t('Institution and care unit validated by')} ${props.user.email}` : ''}
      width='640px'
      onClose={props.onClose}
      visible={props.user !== null}
    >
      {loadingUserData && <div className='loading-ring' />}
      {!loadingUserData && (
        <Form onSubmit={handleFormSubmit}>
          <FormItem>
            <label>{props.t('Select the institution for validator')}</label>
            {getFieldDecorator('institution', { rules: [{ validator: validateInstitution }], initialValue: selectedInstitution?.id })(
              <Select
                placeholder={props.t('Select institution')}
                onChange={handleInstitutionChange}
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {sortedInstitutions.map(i => (
                  <Option key={`user-validator-institution-${i.id}`} value={i.id} title={i.name}>
                    {i.name}
                  </Option>
                ))}

              </Select>
            )}
          </FormItem>
          <Loading loading={loadingSectors}>
            <FormItem>
              <label>{props.t('Select the care unit for validator')}</label>
              {getFieldDecorator('sector', { rules: [{ validator: validateSector }], initialValue: selectedSector?.id })(
                <div>
                  <Select
                    placeholder={props.t('Select care unit')}
                    onChange={handleSectorChange}
                    disabled={!institutionSectors.length}
                    className='selectValidator'
                    value={selectedSector?.id}
                  >
                    {institutionSectors.map(sector => (
                      <Option key={`user-validator-sector-${sector.id}`} value={sector.id} title={sector.name}>
                        {sector.name}
                      </Option>
                    ))}

                  </Select>
                  <div className='divCheckboxValidator'>
                    {props.t('Contact person')} : <input disabled={!selectedSector} checked={isChecked} onChange={checkContactPerson} className='checkbox checkboxValidator' type='checkbox' />
                  </div>
                </div>
              )}
            </FormItem>
          </Loading>
          <FormItem>
            <Button type='primary' htmlType='submit' disabled={!selectedInstitution || !selectedSector} loading={saving}>
              {props.t('Save')}
            </Button>
          </FormItem>
        </Form>
      )}

    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserValidatorInstitutionSector))
