import React, { useEffect, useState } from 'react'

import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import '../../../../assets/smart-table.scss'

const DEFAULT_ENTITY_NAME = 'line'

const TablePagination = props => {
  const [entityName, setEntityName] = useState(DEFAULT_ENTITY_NAME)
  const [totalDataText, setTotalDataText] = useState('')
  const [pageIndexText, setPageIndexText] = useState('')

  useEffect(() => {
    if (typeof props.entitiesTotal === 'number') {
      setTotalDataText(getTotalDataText(props.entitiesTotal))
    }
  }, [props.entitiesTotal, props.getActiveLanguage])

  useEffect(() => {
    if (props.entityName) {
      setEntityName(props.entityName)
    }
  }, [props.entityName])

  useEffect(() => {
    if (
      typeof props.pageIndex === 'number' &&
      typeof props.maxPageIndex === 'number'
    ) {
      setPageIndexText(getPageIndexText(
        props.maxPageIndex === 0 ? props.maxPageIndex : props.pageIndex,
        props.maxPageIndex
      ))
    }
  }, [props.pageIndex, props.maxPageIndex])

  const getTotalDataText = totalDataNumber => {
    const finalEntityName = totalDataNumber > 1 ? entityName + 's' : entityName

    return totalDataNumber + ' ' + props.t(finalEntityName)
  }

  const getPageIndexText = (pageIndex, maxPageIndex) => {
    return pageIndex + '/' + maxPageIndex
  }

  return (
    <div className='flex-row table-footer'>
      <div className='total-data-text'>{totalDataText}</div>
      <div className='flex-fill' />
      <div className='flex-row'>
        <Tooltip placement='top' title={props.t('First page')}>
          <Button
            type='default'
            size='small'
            disabled={props.maxPageIndex === 1 || props.pageIndex === 1}
            onClick={() => props.onPageIndexChange(1)}
          >
            <FontAwesomeIcon icon='angle-double-left' />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Previous page')}>
          <Button
            type='default'
            size='small'
            disabled={props.maxPageIndex === 1 || props.pageIndex === 1}
            onClick={() => props.onPageIndexChange(props.pageIndex - 1)}
          >
            <FontAwesomeIcon icon='angle-left' />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <div className='page-index'>
          {pageIndexText}
        </div>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Next page')}>
          <Button
            type='default'
            size='small'
            disabled={
              props.maxPageIndex <= 1 || props.pageIndex >= props.maxPageIndex
            }
            onClick={() => props.onPageIndexChange(props.pageIndex + 1)}
          >
            <FontAwesomeIcon icon='angle-right' />
          </Button>
        </Tooltip>
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t('Last page')}>
          <Button
            type='default'
            size='small'
            disabled={
              props.maxPageIndex <= 1 || props.pageIndex >= props.maxPageIndex
            }
            onClick={() => props.onPageIndexChange(props.maxPageIndex)}
          >
            <FontAwesomeIcon icon='angle-double-right' />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination)
