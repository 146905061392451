import React, { useState, useEffect } from 'react'

import { Tree } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

import '../../../../assets/quotas.scss'

const DEFAULT_TITLE = 'Sections'
const SELECT_ALL_KEY = 'Select all'
const SELECT_ALL_DATA = { key: SELECT_ALL_KEY, index: -2, selectable: false, checkable: true }
const SELECT_ALL_TITLE = 'Select all'
const DESELECT_ALL_TITLE = 'Unselect'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const OptionsSelector = ({ treeData, checkAll, title, t, onCheckChanged }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [localTreeData, setLocalTreeData] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)

  useEffect(() => {
    if (checkedKeys) {
      setCheckedKeys(checkedKeys)
    }
  }, [checkedKeys])

  useEffect(() => {
    if (treeData) {
      setCheckedKeys([])
      setIsAllSelected(false)
      setLocalTreeData(treeData)
    }
  }, [treeData])

  const switchExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const onCheck = (checkedKeys: React.Key[], info: any) => {
    let currentCheckedKeys = [...checkedKeys]

    currentCheckedKeys = updateKeysIfNecessary(currentCheckedKeys)

    setCheckedKeys(currentCheckedKeys)
    onCheckChanged(currentCheckedKeys.filter(key => key !== SELECT_ALL_KEY).map(key => parseInt(key)))
  }

  const updateKeysIfNecessary = currentCheckedKeys => {
    if (!checkAll) {
      return currentCheckedKeys
    }

    if (!isAllSelected && currentCheckedKeys.includes(SELECT_ALL_KEY)) {
      currentCheckedKeys = localTreeData.map(data => data.key)
      currentCheckedKeys.push(SELECT_ALL_KEY)

      setIsAllSelected(true)
    }

    if (isAllSelected && !currentCheckedKeys.includes(SELECT_ALL_KEY)) {
      currentCheckedKeys = []

      setIsAllSelected(false)
    }

    return currentCheckedKeys
  }

  const buildTreeData = () => {
    if (checkAll && localTreeData) {
      const selectAll = { ...SELECT_ALL_DATA, title: t(isAllSelected ? DESELECT_ALL_TITLE : SELECT_ALL_TITLE) }

      return [selectAll, ...localTreeData]
    }

    return localTreeData ?? []
  }

  return (
    <div className='options-selector flex-column'>
      <div className='options-title' onClick={switchExpanded}>
        <h4><b> {title || DEFAULT_TITLE} </b></h4>
        <div className='h-spacing' />
        <FontAwesomeIcon style={{ marginBottom: '0.5em' }} icon={isExpanded ? faAngleDown : faAngleRight} />
      </div>
      {isExpanded && (
        <div className='options-tree'>
          <Tree
            checkable
            selectable
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={buildTreeData()}
          />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(OptionsSelector)
