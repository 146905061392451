import React, { Component } from 'react'

import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING } from '../SmartTable'
import { validateFormInput, request, generalErrorHandler, requestWithPromise } from '../../../utils'
import { Modal, notification, Input, Button, Form, Radio, Tabs, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'
import { refuseInternship } from '../../../utils/api/internship'

const RadioGroup = Radio.Group
const TabPane = Tabs.TabPane

const RADIO_MAX_WIDTH = '450px'

class NormalInternshipRejection extends Component {
  state = {
    visible: this.props.internship !== null,
    selectedReason: 0,
    comment: '',
    loading: false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ visible: nextProps.internship !== null })
  }

  handleDatacreation = (reason) => {
    if (reason.name.length === 0) {
      notification.error({ message: this.props.t('This reason is not correct !'), placement: 'bottomRight' })
      return
    }

    request('/api/RejectionReason', 'POST', { institution: this.props.getUser.institutions[0].id, name: reason.name }, this.props.getUser)
      .then((json) => {
        const rejection = json.data
        const institution = this.props.getInstitutions[0]
        const rejectionReasons = institution.rejectionReasons
        rejectionReasons.unshift(rejection)
        institution.rejectionReasons = rejectionReasons
        this.props.setInstitutions([institution])
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  handleDataEdition = (reason) => {
    if (reason.name.length === 0) {
      notification.error({ message: this.props.t('This reason is not correct !'), placement: 'bottomRight' })
      return
    }

    request('/api/RejectionReason/' + reason.id, 'PATCH', { name: reason.name }, this.props.getUser)
      .then((json) => {
        const rejection = json.data
        const institution = this.props.getInstitutions[0]
        let rejectionReasons = institution.rejectionReasons
        rejectionReasons = rejectionReasons.map(rr => {
          if (rr.id === reason.id) {
            rr = rejection
          }
          return rr
        })
        institution.rejectionReasons = rejectionReasons
        this.props.setInstitutions([institution])
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  handleDataDeletion = (reason) => {
    request('/api/RejectionReason/' + reason.id, 'DELETE', null, this.props.getUser)
      .then((json) => {
        const institution = this.props.getInstitutions[0]
        let rejectionReasons = institution.rejectionReasons
        rejectionReasons = rejectionReasons.filter(rr => rr.id !== reason.id)
        institution.rejectionReasons = rejectionReasons
        this.props.setInstitutions([institution])
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  checkComment = (rule, value, callback) => {
    if (!validateFormInput('freeText', value)) callback('Veuillez introduire une raison de refus.')
    else callback()
  }

  showModal = () => {
    this.props.form.resetFields()
    this.setState({
      type: 'default',
      visible: true
    })
  }

  handleCancel = (rejectionApproved = false) => {
    this.setState({
      visible: false
    }, () => {
      if (typeof this.props.onClose === 'function') {
        this.props.onClose(rejectionApproved)
      }
    })
  }

  handleInternshipRejection = async () => {
    const user = this.props.getUser

    if (this.state.selectedReason === 0 && this.state.comment.length === 0) {
      notification.error({ message: this.props.t('Please complete your rejection reason.'), placement: 'bottomRight' })
      return
    }

    this.setState({ loading: true })

    const reasons = []

    if (this.state.selectedReason !== 0) {
      reasons.push(this.state.selectedReason)
    }

    refuseInternship(this.props.getUser, this.props.internship, reasons, this.state.comment).then(() => {
      let comment = this.props.t('Internship refused') + ' : '

      if (this.state.selectedReason === 0) {
        comment += this.state.comment
      } else {
        comment += this.props.getInstitutions[0].rejectionReasons.filter(rr => rr.id === this.state.selectedReason)[0].name
      }

      requestWithPromise('/internship-message/send', 'POST', { internship: this.props.internship.id, content: comment }, user)
        .then(() => {
          this.props.readMessage(this.props.internship.id)
          request('/internship-message/read', 'POST', { internship: this.props.internship.id }, user)

          this.setState({ loading: false })
          this.handleCancel(true)
        })
    })
  }

  renderRejectionReasons = () => {
    let reasons = null
    if (this.props.getInstitutions.length > 0) {
      reasons = (
        this.props.getInstitutions[0].rejectionReasons.map(rr => {
          return (
            <Radio
              key={rr.id}
              style={{
                display: 'block',
                overflow: 'hidden',
                maxWidth: RADIO_MAX_WIDTH,
                textOverflow: 'ellipsis'
              }}
              value={rr.id}
            >
              <Tooltip placement='left' title={rr.name}>
                {rr.name}
              </Tooltip>
            </Radio>
          )
        })
      )
    }

    return (
      <div>
        {reasons}
        <Radio style={{ display: 'block' }} value={0}>
          <Input
            placeholder={this.props.t('Other')}
            value={this.state.comment}
            onChange={(v) => this.setState({ comment: v.target.value })}
          />
        </Radio>
      </div>
    )
  }

  render () {
    let hint = null
    if (this.props.getInstitutions.length > 0 && this.props.getInstitutions[0].rejectionReasons.length === 0) {
      hint = (
        <div style={{ marginTop: '30px', color: '#9E9E9E' }}>
          {this.props.t('You can add rejection reasons in the second tab.')}
        </div>
      )
    }

    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      { type: DATA_TYPE_STRING, name: this.props.t('Reason'), key: 'name' }
    ]

    let rejections = []
    if (this.props.getInstitutions.length) {
      rejections = this.props.getInstitutions[0].rejectionReasons.map(s => {
        return {
          id: s.id,
          name: s.name
        }
      })
    }

    return (
      <div>
        <Modal
          title={this.props.t('Refuse an internship')}
          visible={this.state.visible}
          onCancel={() => { this.handleCancel() }}
          footer={[
            <Button onClick={() => { this.handleCancel() }} key='cancel' type='default' loading={this.state.loading}>
              <FontAwesomeIcon icon='times' />&nbsp;{this.props.t('Cancel')}
            </Button>,
            <Button onClick={this.handleInternshipRejection} key='save' type='primary' loading={this.state.loading}>
              <FontAwesomeIcon icon='save' />&nbsp;{this.props.t('Save')}
            </Button>
          ]}
        >
          <Tabs defaultActiveKey='1' onChange={null} animated={false}>
            <TabPane tab={this.props.t('Refuse an internship')} key='1'>
              {this.props.t('Please select a reason to refuse this internship')} :
              <div className='v-spacing' />
              <RadioGroup onChange={(v) => this.setState({ selectedReason: v.target.value })} value={this.state.selectedReason}>
                {this.renderRejectionReasons()}
              </RadioGroup>
              {hint}
            </TabPane>
            <TabPane tab={this.props.t('Rejection reasons')} key='2'>
              <SmartTable
                columns={columns}
                data={rejections}
                loading={!this.props.getDataReady.institutions}
                noDataText={this.props.t('You do not have any internship rejection reason.')}
                onDataAdd={this.handleDatacreation}
                onDataDelete={this.handleDataDeletion}
                onDataEdit={this.handleDataEdition}
              />
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    )
  }
}

const InternshipRejection = Form.create()(NormalInternshipRejection)
export default connect(mapStateToProps, mapDispatchToProps)(InternshipRejection)
