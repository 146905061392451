export const ABSENCE_CATEGORY = 'ABSENCE'

const CELL_TRESHOLDS = {
  UNFILLED: 1,
  FILLED: 0,
  OVERFILLED: -1
}

const MULTI_SELECT_MODE = {
  SINGLE: 'SINGLE',
  COLUMN: 'COLUMN',
  ROW: 'ROW'
}

const FILLED_COLOR = '#f9a36d'
const OVERFILLED_COLOR = '#ff6961'
const OVERFILLED_COLOR_TRANSPARENCY = 'rgba(255, 105, 97, 0.4)'
const DEFAULT_ICON_COLOR = 'rgb(55, 135, 191)'

const getTresholdByPlaces = places => {
  if (places > 0) {
    return CELL_TRESHOLDS.UNFILLED
  }

  if (places < 0) {
    return CELL_TRESHOLDS.OVERFILLED
  }

  return CELL_TRESHOLDS.FILLED
}

export {
  CELL_TRESHOLDS,
  MULTI_SELECT_MODE,
  FILLED_COLOR,
  OVERFILLED_COLOR,
  OVERFILLED_COLOR_TRANSPARENCY,
  DEFAULT_ICON_COLOR,
  getTresholdByPlaces
}
