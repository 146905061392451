import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'

import '../../assets/student-sheet.scss'

const InformationDisplay = (props) => {
  return (
    <div className='information-displayer-container'>
      {
        props.information.map(i => {
          return (
            <Tooltip placement='left' title={typeof props.hideTitle === 'undefined' ? '' : i.title} key={i.id}>
              <div className='information-line flex-row' style={{ marginBottom: '5px' }}>
                <div style={{ width: '20px' }}>
                  <FontAwesomeIcon icon={i.icon} />
                </div>
                <div className={i.color ? 'flex-column text-color-' + i.color : 'flex-column'}>
                  {typeof props.hideTitle === 'undefined' ? <b>{i.title}</b> : null}
                  {i.caption}
                </div>
              </div>
            </Tooltip>
          )
        })
      }
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationDisplay)
