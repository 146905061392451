import React from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const InstitutionSectorsView = ({
  displayManagePresets, institutionOptions, institutions, isLocalAdmin, filesCount, optionsLoaded, user, fetchSectorsFilesCount,
  setInstitutions, setUserManagedSectors, t
}) => {
  return (
    <div>
      <span>{t('tile_view')}</span>
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionSectorsView)
