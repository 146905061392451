import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'
import { browserName, browserVersion } from 'react-device-detect'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import appConfig from '../../config/index'

const UnsupportedBrowser = props => {
  const [displayed, setDisplayed] = useState(false)

  useEffect(() => {
    const { supportedBrowsers } = appConfig
    let showModal = true

    supportedBrowsers.forEach(({ name, minVersion }) => {
      if (name === browserName && showModal) {
        showModal = false
        if (minVersion !== 'all' && browserVersion < minVersion) {
          showModal = true
        }
      }
    })

    if (showModal) {
      setDisplayed(true)
    }
  }, [])

  const handleClose = () => {
    setDisplayed(false)
  }

  return displayed ? (
    <Modal
      visible
      title={<div><FontAwesomeIcon icon={faLightbulb} />&nbsp;{props.t('unsupported.browser.title')}</div>}
      onCancel={handleClose}
      footer={[
        <Button type='primary' key='ok-button' onClick={handleClose}>
          {props.t('I understand')}
        </Button>
      ]}
    >
      {props.t('unsupported.browser.body')}
    </Modal>
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsupportedBrowser)
