import React, { useState } from 'react'
import {Button, Checkbox, DatePicker} from 'antd'
import moment from 'moment'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'

const RangePicker = DatePicker.RangePicker

const dateFormat = 'DD/MM/YYYY'
const exportDateFormat = 'YYYY-MM-DD'

const ShiftsExportOptions = props => {
  const [customDate, setCustomDate] = useState({
    start: moment().format(exportDateFormat),
    end: moment().add(1, 'months').format(exportDateFormat)
  })
  const [checked, setChecked] = useState(false)

  const handleDateChange = (dates) => {
    if (dates.length === 0) {
      return
    }

    const startDate = dates[0]
    const endDate = dates[1]
    const startDateString = startDate.format(exportDateFormat)
    const endDateString = endDate.format(exportDateFormat)

    const updatedCustomDates = {
      start: startDateString, end: endDateString
    }
    setCustomDate(updatedCustomDates)
    props.onExportOptionSelected(updatedCustomDates, checked)
  }

  return (
    <ul className='shifts-export-options'>
      <li>
        <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
        >
          {props.t('Use current filters')}
        </Checkbox>
      </li>
      <li><Button onClick={() => { props.onExportOptionSelected(null, checked) }}>{props.t('Current view')}</Button></li>
      <li>
        <Button onClick={() => { props.onExportOptionSelected(customDate, checked) }}>{props.t('Custom period')}</Button>
        <div style={{ width: '2vw' }} />
        <RangePicker
          onChange={handleDateChange}
          format={dateFormat}
        />
      </li>
    </ul>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsExportOptions)
