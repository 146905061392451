import React from 'react'

const ChangeSectionStudent = props => {
  return (
    <div>
      {props.content}<br />
      <a href={props.href} target='_blank'>{props.hrefText}</a>
    </div>
  )
}

export default ChangeSectionStudent
