import { InputNumber, Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

const OptionValueModal = ({ option, title, onCancel, onOk }) => {
  const [value, setValue] = useState(null)

  const focus = useCallback(node => {
    if (node) {
      node.focus()
    }
  })

  useEffect(() => {
    if (option) {
      setValue(option.optionValue)
    }
  }, [option])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onOk(value)
    }
  }

  return (
    <Modal
      visible={!!option}
      title={title}
      onOk={() => onOk(value)}
      onCancel={onCancel}
      afterClose={() => setValue(null)}
      centered
      style={{ maxWidth: '300px' }}
    >
      <InputNumber ref={focus} min={0} max={100} value={value} onChange={setValue} onKeyDown={handleKeyDown} />
    </Modal>
  )
}

export default OptionValueModal
