import React, { useState, useEffect } from 'react'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING, DATA_TYPE_BOOLEAN } from '../../Components/shared/SmartTable'
import { Drawer } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { anySchoolSectionToString } from '../../utils/entitiesFormaters/SchoolSection'
import ContactPersonSections from './ContactPersonSections'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { getSchoolSchoolSections } from '../../utils/api/school'
import { isObject } from '../../utils'

const SchoolSections = props => {
  const [schoolSections, setSchoolSections] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedContactPersonSection, setSelectedContactPersonSection] = useState(null)

  useEffect(() => {
    if (isObject(props.school)) {
      setLoading(true)

      getSchoolSchoolSections(props.getUser, props.school).then(data => {
        if (data) {
          setSchoolSections(data.map(section => {
            section.name = anySchoolSectionToString(section)
            return section
          }))
        }

        setLoading(false)
      })
    } else {
      setSchoolSections([])
      setLoading(true)
    }
  }, [props.school, props.getUser.id])

  const render = () => {
    const columns = [
      { type: DATA_TYPE_ID, key: 'id' },
      { type: DATA_TYPE_STRING, name: props.t('Name'), key: 'name' },
      { type: DATA_TYPE_STRING, name: props.t('Comment for the students'), key: 'comment' },
      { type: DATA_TYPE_BOOLEAN, name: props.t('Acts encoding'), key: 'actsMandatory' }
    ]

    const additionalActions = [
      {
        iconName: faPeopleArrows,
        type: 'primary',
        title: props.t('The contact persons for this section'),
        onClick: s => setSelectedContactPersonSection(s)
      }
    ]

    const sections = schoolSections.map(s => {
      return {
        id: s.id,
        name: s.name,
        comment: s.comment,
        actsMandatory: s.actsMandatory
      }
    })

    return (
      <Drawer
        title={props.school !== null ? props.school.name : ''}
        width='80%'
        onClose={props.onClose}
        visible={props.school !== null}
      >
        <SmartTable
          columns={columns}
          data={sections}
          loading={loading}
          noDataText={props.t('This school does not have any section.')}
          additionalActions={additionalActions}
        />
        <ContactPersonSections
          section={selectedContactPersonSection}
          onClose={() => setSelectedContactPersonSection(null)}
        />
      </Drawer>
    )
  }

  return render()
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSections)
