import { Switch } from 'antd'
import React from 'react'

const TitledSwitch = props => {
  return (
    <div>
      <h4><b>{props.title}</b></h4>
      <Switch
        onChange={props.onChange}
        checked={props.checked}
      />
    </div>
  )
}

export default TitledSwitch
