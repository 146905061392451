import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import React, { useMemo } from 'react'
import { getAccessorByWidth } from '../../../utils'

export const DataGrid = ({ data = [], gutter = { xs: 8, sm: 16, md: 16, lg: 16 }, sizes = { xs: 1, sm: 2, md: 3, lg: 3 }, width }) => {
  const grid = useMemo(() => {
    const rawGrid = [[]]
    const accessor = getAccessorByWidth(width)
    let lastLineIndex = 0
    let itemsPerLine = 1

    if (sizes[accessor]) {
      itemsPerLine = sizes[accessor]
    }

    data.forEach(d => {
      if (rawGrid[lastLineIndex].length >= itemsPerLine) {
        rawGrid.push([])
        lastLineIndex++
      }

      rawGrid[lastLineIndex].push(d)
    })

    return rawGrid
  }, [data, sizes, width])

  return (
    <>
      {grid.map((row, index) => {
        const span = 24 / row.length
        return (
          <Row key={`row_${index}`} gutter={gutter}>
            {row.map((col, index) => {
              return (
                <Col key={`col_${index}`} className='gutter-row' span={span}>
                  <FontAwesomeIcon icon={col.icon} />
                  <b>{col.title}</b> &nbsp;
                  {col.content}
                </Col>
              )
            })}
          </Row>
        )
      })}
    </>
  )
}
