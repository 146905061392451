import React from 'react'

import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getNewDateByPeriod } from '../../../../utils/timePeriods'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'

import '../../../../assets/quotas.scss'
import { connect } from 'react-redux'

export const REMOVE_TIME = 'rearward'
export const ADD_TIME = 'forward'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const StandardMoveButton = ({ date, disabled, move, periodType, tooltipTitle, onClick }) => {
  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      onClick(getNewDateByPeriod(date, periodType, move === REMOVE_TIME))
    }
  }

  return (
    <div>
      <Tooltip placement='top' title={tooltipTitle}>
        <Button
          disabled={disabled}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={move === REMOVE_TIME ? faChevronLeft : faChevronRight} />
        </Button>
      </Tooltip>
    </div>
  )
}

export default connect(mapStateToProps)(StandardMoveButton)
