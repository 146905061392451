import React, { useContext, useMemo, useCallback } from 'react'

import DisplacementDatePicker from '../shared/DisplacementDatePicker'
import { Button, Select } from 'antd'
import { TIME_PERIODS_TYPE } from '../../../../utils/timePeriods'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import QuotasExportButton from '../definition/QuotasExportButton'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import ImportQuotasButton from '../definition/ImportQuotasButton'

import '../assets/quotas.scss'

const Option = Select.Option

const mapDispatchToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const TableMenu = ({
  addButtonText, date, periodType, disabledAdd, disabledModification, modificationButtonText,
  onAddClick, onDateChange, onModificationClick, onPeriodChange, onRefresh, t
}) => {
  const { institution } = useContext(InstitutionContext)

  const periods = useMemo(() => TIME_PERIODS_TYPE.map((option, index) => {
    return (
      <Option key={index} value={index}>
        {t(option.text)}
      </Option>
    )
  }), [t])

  const handlePeriodChange = useCallback(value => onPeriodChange(TIME_PERIODS_TYPE[value]), [onPeriodChange])

  return (
    <div className='quotas-table-menu'>
      <div className='actions-part'>
        <Select
          value={t(periodType.text ?? '')}
          onChange={handlePeriodChange}
        >
          {periods}
        </Select>
        <div className='h-spacing' />
        <Button
          disabled={disabledAdd || typeof onAddClick !== 'function'}
          onClick={onAddClick}
        >
          {t(addButtonText)}
        </Button>
        <div className='h-spacing' />
        <Button
          disabled={disabledModification || typeof onModificationClick !== 'function'}
          onClick={onModificationClick}
        >
          {t(modificationButtonText)}
        </Button>
      </div>
      <div className='flex-fill' />
      <div className='navigation-part'>
        <DisplacementDatePicker
          date={date}
          timePeriod={periodType}
          onDateChange={onDateChange}
        />
      </div>
      <div className='h-spacing' />
      <QuotasExportButton institution={institution} />
      <div className='h-spacing' />
      <ImportQuotasButton institution={institution} afterUpload={onRefresh} />
    </div>
  )
}

export default connect(mapDispatchToProps)(TableMenu)
