import moment, { isMoment } from 'moment'
import { EUROPEAN_DATE } from '../constants'
import { FULL_DATE_FORMAT, getTimezoneLessMoment } from '../momentjs'
import { GENDERS } from '../api/student'
import { VALIDATION_FIELDS } from '../validators'
import { isArray } from 'lodash'
import { validateString } from '..'
import { API_DATE_FORMAT } from '../apiHelper'
import { RequestPeriod } from './requestPeriod'
import { LanguageLocale } from './language'

export const STATES = {
  INCOMPLETE: 'INCOMPLETE',
  ON_HOLD: 'ON_HOLD',
  ACTION_REQUIRE: 'ACTION_REQUIRE'
}
export const REQUEST_ORIGINS = {
  INTERNEO_STUDENT: 'INTERNEO_STUDENT',
  WEB_PORTAL: 'WEB_PORTAL',
  MANUAL: 'MANUAL'
}

export const INTERNSHIP_REQUEST_VALIDATORS = [
  { key: VALIDATION_FIELDS.SCHOOL, validator: s => s.school !== null },
  { key: VALIDATION_FIELDS.REQUEST_ORIGIN, validator: s => Object.values(REQUEST_ORIGINS).includes(s.requestOrigin) },
  { key: VALIDATION_FIELDS.SECTION_TO_YEAR, validator: s => s.sectionToYear !== null },
  { key: VALIDATION_FIELDS.INSTITUTION, validator: s => s.institution !== null },
  { key: VALIDATION_FIELDS.SECTOR, validator: s => s.sector !== null },
  { key: VALIDATION_FIELDS.REQUEST_PERIODS, validator: s => isArray(s.requestPeriods) && s.requestPeriods.length > 0 },
  { key: VALIDATION_FIELDS.COMMENT, validator: s => validateString(s.comment) },
  { key: VALIDATION_FIELDS.GENDER, validator: s => Object.values(GENDERS).includes(s.gender) },
  { key: VALIDATION_FIELDS.FIRSTNAME, validator: s => validateString(s.firstName, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.LASTNAME, validator: s => validateString(s.lastName, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.EMAIL, validator: s => validateString(s.email, { isEmail: true }) },
  { key: VALIDATION_FIELDS.LANGUAGE, validator: s => s.languageLocale !== null }
]

export const getCreatedAt = r => r.createdAt ? getTimezoneLessMoment(r.createdAt).format(EUROPEAN_DATE) : null
export const getSectionName = r => r.sectionToYear ? r.sectionToYear.sectionName : null
export const getSectionAbbreviation = r => r.sectionToYear ? r.sectionToYear.sectionAbbreviation : null
export const getInternshipYear = r => r.sectionToYear ? r.sectionToYear.schoolYearName : null
export const getStartDate = r => isArray(r.requestPeriods) && r.requestPeriods.length > 0 ? getTimezoneLessMoment(r.requestPeriods[0].startDate).format(EUROPEAN_DATE) : null
export const getEndDate = r => isArray(r.requestPeriods) && r.requestPeriods.length > 0 ? getTimezoneLessMoment(r.requestPeriods[0].endDate).format(EUROPEAN_DATE) : null
export const getLastname = r => r.lastName
export const getFirstname = r => r.firstName
export const getEmail = r => r.email
export const getSectorName = r => r.sector ? r.sector.name : null
export const getSchoolName = r => r.school ? r.school.name : null
export const getComment = r => r.comment
export const getRequestOrigin = r => r.requestOrigin

export class InternshipRequest {
  constructor ({
    id = -1, createdAt = moment(), sectionToYear = null, institution = null, lastName = null, firstName = null, email = null, sector = null, gender = GENDERS.OTHER,
    school = null, comment = '', requestOrigin = REQUEST_ORIGINS.MANUAL, requestPeriods = [], phone = null, birthday = null, languageLocale = new LanguageLocale(), files = []
  } = {}) {
    this.id = id
    this.createdAt = getTimezoneLessMoment(createdAt)
    this.sectionToYear = sectionToYear
    this.institution = institution
    this.lastName = lastName
    this.firstName = firstName
    this.gender = gender
    this.email = email
    this.sector = sector
    this.school = school
    this.comment = comment
    this.requestOrigin = requestOrigin
    this.requestPeriods = requestPeriods.map(p => new RequestPeriod(p))
    this.phone = phone
    this.birthday = birthday !== null && !isMoment(birthday) ? getTimezoneLessMoment(birthday) : birthday
    this.languageLocale = languageLocale
    this.files = files
  }

  toRequestBody = () => {
    const body = { ...this }

    body.createdAt = this.createdAt.format(FULL_DATE_FORMAT)
    body.institution = this.institution.id
    body.school = this.school.id
    body.sectionToYear = this.sectionToYear.id
    body.sector = this.sector.id
    body.requestPeriods = this.requestPeriods.map(period => period.toRequestBody())
    body.birthday = this.birthday ? this.birthday.format(API_DATE_FORMAT) : null
    body.languageLocale = new LanguageLocale(this.languageLocale).toRequestBody().languageTag

    return body
  }
}
