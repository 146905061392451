import { Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { TOOLTIP_TOP } from '../../institution/Quotas/utils/Constants'
import { customStringOptionFilter } from '../../institution/Quotas/utils/SelectOptionTools'
import { isArray } from 'lodash'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const Option = Select.Option

export const STRING = 0
export const DEFAULT_NULL_VALUE = null
const DEFAULT_NULL_LABEL = '/'
const WILD_CART_TITLE = 'All'
const WILD_CART_LABEL = '*'
export const WILD_CART_VALUE = -1

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const StandardSelect = ({
  allOption, data, dataKey, dataType, disabled, emptyData, emptyOption, showSearch, style, tooltipPlacement, value, onSelect, t
}) => {
  const [placement, setPlacement] = useState(TOOLTIP_TOP)
  const [noData, setNoData] = useState({ label: DEFAULT_NULL_LABEL, value: DEFAULT_NULL_VALUE })
  const allData = { label: WILD_CART_LABEL, value: WILD_CART_VALUE }

  useEffect(() => {
    if (tooltipPlacement) {
      setPlacement(tooltipPlacement)
    }
  }, [tooltipPlacement])

  useEffect(() => {
    if (typeof emptyData === 'object' && emptyData !== null && emptyData.value) {
      const newData = { value: emptyData.value, label: emptyData.label }

      if (emptyData.label) {
        newData.label = emptyData.label
      }

      setNoData(newData)
    } else if (typeof emptyData === 'string') {

    }
  }, [emptyData])

  const handleSelect = (value, option) => {
    if (typeof onSelect === 'function') {
      onSelect(option.props.data)
    }
  }

  const handleOptionFiltering = (input, option) => {
    if (dataType === STRING) {
      return customStringOptionFilter(input, option, dataKey)
    }

    return false
  }

  return (
    <Select
      style={style}
      value={value}
      showSearch={showSearch}
      onSelect={handleSelect}
      filterOption={handleOptionFiltering}
      disabled={disabled}
    >
      {allOption && (
        <Option key='all' value={allData.value} data={allData.value} label={t(allData.label)}>
          <Tooltip placement={placement} title={t(WILD_CART_TITLE)}>
            <b>{t(allData.label)}</b>
          </Tooltip>
        </Option>
      )}
      {emptyOption && (
        <Option key='no' value={noData.value} data={noData.value} label={t(noData.label)}>
          <Tooltip placement={placement} title={noData.label}>
            <b>{t(noData.label)}</b>
          </Tooltip>
        </Option>
      )}
      {isArray(data) && dataKey && data.map((o, index) => {
        return (
          <Option key={`option_${index}`} value={o[dataKey]} data={o}>
            <Tooltip placement={placement} title={o[dataKey]}>
              {o[dataKey]}
            </Tooltip>
          </Option>
        )
      })}
    </Select>
  )
}

export default connect(mapStateToProps)(StandardSelect)
