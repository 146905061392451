import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import SmartTable, { DATA_TYPE_STRING } from './SmartTable'
import { connect } from '../../reducers/Dispatchers'
import { getTranslate } from 'react-localize-redux'
import { requestWithPromise } from '../../utils'
import { getUser } from '../../reducers/UserReducer'
import { formatOldSection, isNewSection, schoolSectionToString } from '../../utils/entitiesFormaters/SchoolSection'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const ContactPersonSectionsWidget = props => {
  const [contactPersonSchoolSections, setContactPersonSchoolSections] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (props.user === null) {
      setContactPersonSchoolSections([])
    } else {
      requestWithPromise(`/api/users/${props.user.id}/contact-person-school-sections?school=${props.school}`, 'GET', null, props.getUser)
        .then(jsonData => {
          const sections = jsonData.data.map(section => {
            const sectionName = isNewSection(section)
              ? schoolSectionToString(section)
              : formatOldSection(section)

            return {
              id: section.id,
              name: sectionName
            }
          })
          setContactPersonSchoolSections(sections)
          setLoading(false)
        })
    }
  }, [props.user])

  const COLUMNS = [
    {
      type: DATA_TYPE_STRING,
      name: props.t('Sections'),
      key: 'name'
    }
  ]

  return (
    <Drawer
      title={props.user !== null ? props.t('Sections managed by') + ' ' + props.user.email : '?'}
      width='640px'
      onClose={props.onClose}
      visible={props.user !== null}
    >
      <SmartTable
        loading={loading}
        columns={COLUMNS}
        data={contactPersonSchoolSections}
        noDataText={props.t('Without section')}
      />
    </Drawer>
  )
}

export default connect(mapStateToProps)(ContactPersonSectionsWidget)
