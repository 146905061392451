import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, Checkbox } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { validateFormInput } from '../../utils'
const FormItem = Form.Item

class NormalSignUpForm extends React.Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSignUp(values)
      }
    })
  };

  checkUsername = (rule, value, callback) => {
    if (!validateFormInput('username', value)) {
      callback(this.props.t('This value is not correct !'))
    } else {
      callback()
    }
  };

  checkLastname = (rule, value, callback) => {
    if (!validateFormInput('name', value)) {
      callback(this.props.t('This value is not correct !'))
    } else {
      callback()
    }
  };

  checkFirstname = (rule, value, callback) => {
    if (!validateFormInput('name', value)) {
      callback(this.props.t('This value is not correct !'))
    } else {
      callback()
    }
  };

  checkEmail = (rule, value, callback) => {
    if (!validateFormInput('email', value)) {
      callback(this.props.t('The email address is not correct !'))
    } else {
      callback()
    }
  };

  checkPassword = (rule, value, callback) => {
    if (value && this.state.confirmDirty) {
      this.props.form.validateFields(['passwordConfirm'], { force: true })
    }

    if (!validateFormInput('password', value)) {
      callback(
        this.props.t('The password must contain between 8 and 255 characters !')
      )
    } else {
      callback()
    }
  };

  checkPasswordConfirm = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue('password')) {
      callback(this.props.t('The passwords do not match !'))
    } else {
      callback()
    }
  };

  checkAgreement = (rule, value, callback) => {
    if (!value) {
      callback(this.props.t('Please agree the terms and conditions !'))
    } else {
      callback()
    }
  };

  render () {
    const { getFieldDecorator } = this.props.form
    return (
      <Form
        onSubmit={this.handleSubmit}
        className='login-form full-height flex-column'
      >
        <FormItem>
          {getFieldDecorator('firstname', {
            rules: [{ validator: this.checkFirstname }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='id-badge' />}
              placeholder={this.props.t('Firstname')}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('lastname', {
            rules: [{ validator: this.checkLastname }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='id-badge' />}
              placeholder={this.props.t('Lastname')}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ validator: this.checkUsername }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='id-badge' />}
              placeholder={this.props.t('Username')}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [{ validator: this.checkEmail }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='at' />}
              placeholder={this.props.t('Email address')}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ validator: this.checkPassword }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='lock' />}
              type='password'
              placeholder={this.props.t('Password')}
              onBlur={e =>
                this.setState({
                  confirmDirty: this.state.confirmDirty || !!e.target.value
                })}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('passwordConfirm', {
            rules: [{ validator: this.checkPasswordConfirm }]
          })(
            <Input
              prefix={<FontAwesomeIcon icon='lock' />}
              type='password'
              placeholder={this.props.t('Confirm password')}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('agreement', {
            valuePropName: 'checked',
            initialValue: false,
            rules: [{ validator: this.checkAgreement }]
          })(
            <Checkbox>
              {this.props.t('I have read and accept the')}{' '}
              <a href=''>{this.props.t('terms and conditions')}</a>.
            </Checkbox>
          )}
        </FormItem>
        <FormItem>
          <Button type='primary' htmlType='submit' className='full-width'>
            <FontAwesomeIcon icon='sign-in-alt' />
            &nbsp;{this.props.t('Sign up')}
          </Button>
        </FormItem>
        <a onClick={this.props.onLoginLinkClick}>
          {this.props.t('Back to log in page')}
        </a>
      </Form>
    )
  }
}

const SignUpForm = Form.create()(NormalSignUpForm)
export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
