import React from 'react'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'
import PausesSubCell from './PausesSubCell'
import SectionsSubCell from './SectionsSubCell'

const TotalCell = props => {
  const { pauseActivated } = props.getAppStates
  const totalData = props.totalData

  const renderCellContent = () => {
    if (props.dataIds && props.dataIds.length > 0) {
      const Cell = pauseActivated
        ? PausesSubCell
        : SectionsSubCell

      return (
        <Cell
          total={totalData.total}
          details={totalData.details}
          dataIds={props.dataIds}
        />
      )
    }

    return totalData.total
  }

  const render = () => {
    let content = ''

    if (totalData) {
      content = renderCellContent()
    }

    return (
      <div className='regular-cell'>
        {content}
      </div>
    )
  }

  return (
    <td>
      {render()}
    </td>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalCell)
