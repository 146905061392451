import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, Card, List } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'
import { GlobalContext } from '../../../../../Providers/GlobalProvider'

export const INFO_BUTTON_COLOR = '#3787BF'
const CARD_GLOBAL_STYLE = { backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px', maxWidth: '200px' }
const CARD_HEAD_STYLE = { color: 'white', fontSize: '12px' }
const TITLE_STYLE = { overflow: 'unset', whiteSpace: 'pre-wrap' }
const CARD_BODY_STYLE = { padding: '0px 12px 0px 12px', textAlign: 'center' }
const LIST_STYLE = { color: 'white', fontSize: '10px' }
const DEFAULT_TITLE = 'Available event codes : '

const EventCodesTooltip = props => {
  const { eventCodes } = useContext(GlobalContext)

  const makeEventsCodesTooltipContent = () => {
    return (
      <Card
        size='small'
        style={CARD_GLOBAL_STYLE}
        headStyle={CARD_HEAD_STYLE}
        bodyStyle={CARD_BODY_STYLE}
        title={getTitle()}
      >
        <List
          style={LIST_STYLE}
          size='small'
          dataSource={eventCodes.map(e => props.t('event.code.' + e.type))}
          renderItem={item => {
            return (
              <List.Item
                style={{ borderBottom: '0px', fontWeight: 'bold', justifyContent: 'center' }}
              >
                {item}
              </List.Item>
            )
          }}
        />
      </Card>
    )
  }

  const getTitle = () => {
    return (
      <span style={TITLE_STYLE}>
        {props.t(props.title ?? DEFAULT_TITLE)}
      </span>
    )
  }

  return (
    <Tooltip
      placement={props.placement ?? 'right'}
      title={makeEventsCodesTooltipContent()}
    >
      <FontAwesomeIcon
        icon={props.icon ?? faInfoCircle} color={props.iconColor ?? INFO_BUTTON_COLOR}
      />
    </Tooltip>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCodesTooltip)
