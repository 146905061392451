import React from 'react'
import { bool, arrayOf, shape, string, number, oneOfType } from 'prop-types'

import MenuItem from './MenuItem'

const MenuMobile = ({ collapsed, menuItems }) => (
  <div
    className='menu hidden-desktop flex-column full-width'
    style={{ display: collapsed ? 'none' : 'initial' }}
  >
    <div className='flex-column' style={{ fontSize: '16px' }}>
      {menuItems.map(item => (
        <MenuItem key={item.route} {...item} showLabel />
      ))}
    </div>
  </div>
)

MenuMobile.propTypes = {
  collapsed: bool.isRequired,
  menuItems: arrayOf(
    shape({
      id: number,
      label: string,
      route: string,
      iconName: oneOfType([string, shape({ prefix: string, iconName: string })]),
      showLabel: bool
    })
  )
}

export default MenuMobile
