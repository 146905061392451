import React, { useState } from 'react'

import TooltipIconButton from './TooltipIconButton'
import useOuterClick from '../hooks/useOuterClick'
import { Button, Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'

import '../assets/quotas.scss'

const PopoverIconButton = props => {
  const [visible, setVisible] = useState(false)
  const innerRef = useOuterClick(() => setVisible(false))

  const render = () => {
    return (
      <div ref={innerRef}>
        <Popover
          overlayClassName='popover-icon-button'
          content={renderPopOverContent()}
          title={props.t(props.title)}
          trigger={props.trigger ?? 'click'}
          visible={visible}
        >
          {renderPopOverBody()}
        </Popover>
      </div>
    )
  }

  const renderPopOverContent = () => {
    return (
      <div>
        <div className='popover-icon-button-content'>
          {props.t(props.content)}
        </div>
        {props.actions ?? renderDefaultActions()}
      </div>
    )
  }

  // TODO 19-05-2022 make button boxes recursive
  const renderDefaultActions = () => {
    return (
      <div className='popover-icon-button-default-actions'>
        <div className='popover-icon-button-action'>
          <Button
            key='card-save-button'
            onClick={props.onConfirm}
          >
            {props.t('Yes')}
          </Button>
        </div>
        <div className='popover-icon-button-action'>
          <Button
            key='card-cancel-button'
            onClick={() => setVisible(false)}
          >
            {props.t('No')}
          </Button>
        </div>
      </div>
    )
  }

  const renderPopOverBody = () => {
    if (props.tooltipTitle) {
      return (
        <TooltipIconButton
          key='card-trash-button'
          title={props.tooltipTitle}
          type={props.type ?? 'default'}
          icon={props.icon}
          onClick={() => setVisible(true)}
        />
      )
    }

    return (
      <Button
        onClick={() => setVisible(true)}
        disabled={props.disabled ?? false}
        type={props.type ?? 'default'}
      >
        <FontAwesomeIcon icon={props.icon} />
      </Button>
    )
  }

  return render()
}

export default connect(mapStateToProps, mapDispatchToProps)(PopoverIconButton)
