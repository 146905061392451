import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryString } from '../apiHelper'

export const getAllAreas = (user) => {
  return requestWithPromise(
    '/api/areas',
    GET,
    null,
    user
  )
}
