import React, { useState, useEffect } from 'react'

import TooltipIconButton from '../shared/TooltipIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import { Card } from 'antd'
import { faBan, faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import GlobalPlacesForm from './forms/GlobalPlacesForm'
import PopoverIconButton from '../shared/PopoverIconButton'
import { BACKEND_DATE_FORMAT } from '../../../../utils/constants'
import { POPOVER_CONTENT } from './QuotaCard'
import moment from 'moment'

const EditableGlobalQuotaCard = props => {
  const [quota, setQuota] = useState(null)
  const [previousQuota, setPreviousQuota] = useState(null)
  const [editionMode, setEditionMode] = useState(false)

  useEffect(() => {
    if (props.quota) {
      setQuota(props.quota)
    }
  }, [props.quota])

  const handleEdition = () => {
    setEditionMode(true)
    setPreviousQuota({ ...quota })
  }

  const handleSuppression = () => {
    if (typeof props.onDelete === 'function') {
      props.onDelete(props.quota)
    }
  }

  const handleEditionCanceled = () => {
    setEditionMode(false)
    setQuota({ ...previousQuota })
    setPreviousQuota(null)
  }

  const handleSaving = () => {
    setEditionMode(false)
    setPreviousQuota(null)

    if (typeof props.onSave === 'function') {
      props.onSave(quota)
    }
  }

  const updateQuotaPlaces = newPlaces => {
    setQuota({ ...quota, total: newPlaces })
  }

  const updateQuotaDates = dates => {
    const newQuota = { ...quota }

    newQuota.startDate = dates[0].format(BACKEND_DATE_FORMAT)
    newQuota.endDate = dates[1].format(BACKEND_DATE_FORMAT)

    setQuota(newQuota)
  }

  const getCardActions = () => {
    if (editionMode) {
      return (
        [
          <TooltipIconButton
            key='card-save-button'
            title='Save'
            onClick={handleSaving}
            icon={faSave}
          />,
          <TooltipIconButton
            key='card-cancel-button'
            title='Cancel'
            onClick={handleEditionCanceled}
            icon={faBan}
          />
        ]
      )
    }

    return ([
      <TooltipIconButton
        key='card-edit-button'
        title='Edit'
        onClick={handleEdition}
        icon={faEdit}
      />,
      <PopoverIconButton
        key='card-trash-button'
        title='Delete'
        tooltipTitle='Delete'
        content={POPOVER_CONTENT}
        onConfirm={handleSuppression}
        icon={faTrash}
      />
    ])
  }

  const renderBody = () => {
    return (
      <div className='allocation-quota-card-body'>
        <QuickDualDatePickers
          startDate={quota ? moment(quota.startDate) : moment()}
          endDate={quota ? moment(quota.endDate) : moment()}
          onDatesChange={updateQuotaDates}
          disabled={!editionMode}
        />
        <GlobalPlacesForm
          places={quota ? quota.total : 0}
          onPlacesChanged={updateQuotaPlaces}
          disabled={!editionMode}
        />
      </div>
    )
  }

  return (
    <Card
      style={{ width: '100%' }}
      actions={getCardActions()}
    >
      {renderBody()}
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditableGlobalQuotaCard)
