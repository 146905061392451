import { createSelector } from 'reselect'
import { GLOBAL_PLACES_KEY } from '../../Components/institution/Quotas/utils/Constants'

export const selectInstitutionSectorsAsObject = createSelector(
  state => state.getInstitutions.list,
  (institutions) => {
    if (institutions[0] && institutions[0].sectors) {
      const sectorsListAsObject = {}

      institutions[0].sectors.forEach(sector => {
        sectorsListAsObject[sector.id] = sector
      })

      return sectorsListAsObject
    } else {
      return {}
    }
  }
)

export const selectQuotasAsTree = createSelector(
  state => state.quotas.quotas,
  (quotas) => {
    const quotasTree = {}

    Object.keys(quotas).forEach(id => {
      const quota = quotas[id]
      const treeNode = getInsertionPositionAndInitialisePath(quotasTree, quota)

      insertQuotaIntoTreeNode(treeNode, quota)
    })

    return quotasTree
  }
)

const getInsertionPositionAndInitialisePath = (tree, quota) => {
  const accessorList = ['sector', 'section', 'school', 'sectionToYear']
  let currentTreePart = tree

  for (const accessor of accessorList) {
    const quotaProperty = quota[accessor]

    if (!quotaProperty) {
      return currentTreePart
    }

    const { id } = quotaProperty

    if (!currentTreePart[id]) {
      currentTreePart[id] = {}
    }

    currentTreePart = currentTreePart[id]
  }

  return currentTreePart
}

const insertQuotaIntoTreeNode = (node, quota) => {
  if (quota.sectionToYear) {
    node[quota.id] = quota

    return
  }

  if (!node[GLOBAL_PLACES_KEY]) {
    node[GLOBAL_PLACES_KEY] = {}
  }

  node[GLOBAL_PLACES_KEY][quota.id] = quota
}
