import React, { useContext, useEffect, useMemo, useState } from 'react'

import FilesManager from '../../Components/shared/FilesManager'
import InstitutionSectors from '../../Components/school/InstitutionSectors'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_STRING, DATA_TYPE_SELECT } from '../../Components/shared/SmartTable'
import { request, validateFormInput, generalErrorHandler } from '../../utils'
import Analytics from '../../utils/analytics'
import SmartTableCustomExportButton from '../../Components/shared/SmartTableCustomExportButton'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getSchools } from '../../reducers/SchoolsReducer'
import { ROLE_ENCODER } from '../../utils/constants'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { deleteInstitutionOld, updateInstitution } from '../../utils/api/institution'
import { SchoolContext } from '../../Providers/SchoolProvider'

const mapStateToProps = state => {
  return {
    dataReady: state.getDataReady,
    schools: getSchools(state.getSchools),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const SchoolInstitutionsView = ({ dataReady, schools, user, t }) => {
  const { countries } = useContext(GlobalContext)
  const { institutions, setInstitutions, schoolLoading } = useContext(SchoolContext)

  const [columns, setColumns] = useState([])
  const [selectedInstitutionSectors, setSelectedInstitutionSectors] = useState(null)
  const [selectedInstitutionFiles, setSelectedInstitutionFiles] = useState(null)
  const [userCanManageFiles, setUserCanManageFiles] = useState(true)
  const [userCanCrud, setUserCanCrud] = useState(false)

  const mappedInstitutions = useMemo(() => institutions.map(i => {
    return {
      id: i.id,
      name: i.name,
      address: i.address,
      zipcode: i.zipcode,
      country: i.country === null ? null : i.country.id,
      city: i.city,
      description: i.description,
      countryDisabled: i.managed,
      managed: i.managed,
      filesCount: i.filesCount
    }
  }), [institutions])

  useEffect(() => {
    Analytics.pageView('/school-institution')
  }, [])

  useEffect(() => {
    setColumns([
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_STRING,
        name: t('Name'),
        key: 'name',
        validate: data => validateFormInput('freeText', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Address'),
        key: 'address',
        validate: data => validateFormInput('freeText', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Zipcode'),
        key: 'zipcode',
        validate: data => validateFormInput('freeText', data, true)
      },
      {
        type: DATA_TYPE_STRING,
        name: t('City'),
        key: 'city',
        validate: data => validateFormInput('name', data, true)
      },
      {
        type: DATA_TYPE_SELECT,
        name: t('Country'),
        key: 'country',
        options: countries.map(c => {
          return { id: c.id, name: t(c.name) }
        }),
        disabled: !userCanCrud

      },
      {
        type: DATA_TYPE_STRING,
        name: t('Description'),
        key: 'description'
      }
    ])
  }, [t, userCanCrud, countries])

  useEffect(() => {
    let canCrud = user.school?.managed ?? false

    if (canCrud) {
      canCrud = false

      if (schools.length > 0) {
        const { lockedInstitutionsCount } = schools[0]

        canCrud = lockedInstitutionsCount && user.roles.includes(ROLE_ENCODER)
      } else if (user.roles.includes(ROLE_ENCODER)) {
        canCrud = true
      }
    }

    setUserCanCrud(canCrud)
  }, [user.school, schools, user.roles])

  const addInstitution = (institution, stopLoading) => {
    institution.acronym = institution.name
    request('/api/Institution', 'POST', institution, user)
      .then(json => {
        if (json.status !== 'error') {
          setInstitutions([...institutions, json.data])
        }

        stopLoading()
      })
      .catch(error => { generalErrorHandler(error) })
  }

  const editInstitution = institution => {
    const updatedInstitution = { ...institution }

    if (typeof updatedInstitution.countryDisabled !== 'undefined') {
      delete updatedInstitution.countryDisabled
    }

    if (typeof updatedInstitution.filesCount !== 'undefined') {
      delete updatedInstitution.filesCount
    }

    updateInstitution(user, updatedInstitution).then(json => {
      if (json?.data) {
        setInstitutions(institutions.map(i => {
          if (i.id === institution.id) {
            Object.keys(json.data).map(k => {
              i[k] = typeof (i[k]) === 'object' ? i[k] : json.data[k]
              return k
            })

            if (
              typeof json.data.country !== 'undefined' &&
                json.data.country !== null
            ) {
              i.country = countries.filter(
                c => c.id === json.data.country
              )[0]
            }
          }
          return i
        }))
      }
    })
  }

  const handleDeleteInstitution = institution => {
    deleteInstitutionOld(user, institution).then(json => {
      if (json?.data) {
        setInstitutions(institutions.filter(i => i.id !== institution.id))
      }
    })
  }

  const handleFileManagement = institution => {
    setUserCanManageFiles(!institution.managed)
    setSelectedInstitutionFiles(institution)
  }

  const checkIfRowIsControllable = row => {
    if (row.id === -1) {
      return true
    }

    return user.roles.includes(ROLE_ENCODER) > -1 && row.managed === false
  }

  return (
    <div>
      <SmartTable
        columns={columns}
        data={mappedInstitutions}
        loading={schoolLoading}
        onDataAdd={userCanCrud ? addInstitution : undefined}
        onDataEdit={userCanCrud ? editInstitution : undefined}
        onDataDelete={userCanCrud ? handleDeleteInstitution : undefined}
        noDataText={<span> {t("Votre établissement n'a encore aucune institution vers laquelle envoyer des étudiants en stage.")} </span>}
        addDataText={t('Add an institution')}
        customExportButton={<SmartTableCustomExportButton path='institutions' />}
        checkIfRowIsControllable={userCanCrud ? checkIfRowIsControllable : undefined}
        additionalActions={[
          {
            iconName: 'file',
            type: 'primary',
            title: t('Manage files'),
            onClick: handleFileManagement,
            customClassCallback: data => { return data.filesCount > 0 ? 'green-button' : '' }
          },
          {
            iconName: 'stethoscope',
            type: 'primary',
            title: t('See care units'),
            onClick: setSelectedInstitutionSectors
          }
        ]}
      />
      <InstitutionSectors
        institution={selectedInstitutionSectors}
        onClose={() => setSelectedInstitutionSectors(null)}
      />
      <FilesManager
        entity={selectedInstitutionFiles}
        entityName='Institution'
        onClose={() => setSelectedInstitutionFiles(null)}
        userContextFiltered={userCanManageFiles}
        preventChanges={selectedInstitutionFiles ? selectedInstitutionFiles.managed === true : false}
      />
    </div>
  )
}

export default connect(mapStateToProps)(SchoolInstitutionsView)
