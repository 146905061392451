const SET_APP_WIDTH = 'SET_APP_WIDTH'
const SET_APP_HORIZONTAL_SCROLL = 'SET_APP_HORIZONTAL_SCROLL'
const CLEAR_APP_SCROLL_WIDTH = 'CLEAR_APP_SCROLL_WIDTH'

export const setAppWidth = width => {
  return {
    type: SET_APP_WIDTH,
    width
  }
}

export const setAppHorizontalScroll = scroll => {
  return {
    type: SET_APP_HORIZONTAL_SCROLL,
    scroll
  }
}

export const clearAppScrollWidthValues = () => {
  return {
    type: CLEAR_APP_SCROLL_WIDTH
  }
}

export const getAppScroll = state => {
  return state
}

export default (state = { width: null, hScroll: null }, action) => {
  switch (action.type) {
    case SET_APP_WIDTH:
      return { ...state, width: action.width }
    case SET_APP_HORIZONTAL_SCROLL:
      return { ...state, hScroll: action.scroll }
    case CLEAR_APP_SCROLL_WIDTH:
      return { width: null, hScroll: null }
    default:
      return state
  }
}
