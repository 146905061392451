import React, { useState, useEffect, useRef } from 'react'
import { generalErrorHandler } from '../../utils'
import { connect, mapDispatchToProps, mapStateToProps } from '../../reducers/Dispatchers'
import { getStudentOptins } from '../../utils/api/student'
import { DATA_SHARING_TYPE } from '../../utils/entities/optin'

function OptInInfo (props) {
  const [accepted, setAccepted] = useState([null])
  const [optInMessage, setOptInMessage] = useState([''])

  const prevStudentId = usePrevious(props.studentId)

  function usePrevious (value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    }, [value])
    return ref.current
  }

  useEffect(() => {
    if (prevStudentId !== null && props.studentId === null) {
      return
    } else if (!props.studentId || prevStudentId === props.studentId) {
      return
    } else if (!accepted) {
      return
    }

    getStudentOptins({ id: props.studentId }, props.getUser, { type: DATA_SHARING_TYPE }).then(json => {
      if (json?.data && json.data[0]?.accepted) {
        setAccepted([true])
        setOptInMessage(props.t('Accepts the use of their data for recruitment purposes'))
      } else {
        setAccepted([false])
        setOptInMessage(props.t('Does not accept the use of their data for recruitment purposes'))
      }
    }).catch(error => { generalErrorHandler(error) })
  }, [props.studentId])

  return (
    <div> {optInMessage}</div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(OptInInfo)
