import React, { useEffect, useState } from 'react'

const DEFAULT_DRAWER_SIZE = '640px'
const SWITCH_POINT_SIZE = 769

const ResizeDrawer = ({ children, defaultSize, visible }) => {
  const [width, setWidth] = useState(DEFAULT_DRAWER_SIZE ?? defaultSize)

  useEffect(() => {
    if (visible) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [visible])

  const handleResize = () => {
    setWidth(
      window.innerWidth >= SWITCH_POINT_SIZE ? DEFAULT_DRAWER_SIZE : window.innerWidth * 0.9
    )
  }

  return React.cloneElement(children, { width, visible })
}

export default ResizeDrawer
