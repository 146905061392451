import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import { Button } from 'antd'
import React, { useState } from 'react'
import CustomScheduleModal from './CustomScheduleModal';

const CustomScheduleButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false)

  const handleSubmit = (selectedValues) => {
    setModalVisible(false)
    props.handleCustomSchedule(selectedValues)
  }

  return (
    <div>
      <Button
        onClick={() => setModalVisible(true)}
        size='large'
        style={{ width: '100%', marginBottom: '15px' }}
      >
        {props.t('Custom schedule')}
      </Button>
      {props.text}
      <CustomScheduleModal
        title={props.t('Custom schedule')}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomScheduleButton)
