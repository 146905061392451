import React, { useCallback, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import FormItem from './FormItem'
import { Button, Input } from 'antd'
import { faIdBadge, faMinus, faPalette, faPlus, faSave, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { VALIDATION_FIELDS, isValid } from '../../../utils/validators'
import IconButton from '../../antd/Buttons/IconButton'
import { HOSPITAL_DEPARTMENT_VALIDATORS, HospitalDepartment } from '../../../utils/entities/hospitalDepartment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChromePicker } from 'react-color'
import { HOSPITAL_DEPARTMENT_COLORS } from '../../../config/colors'
import UsersHeap from '../../UsersHeap'

const COLOR_PICKER_GRID = {
  margin: '10px auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 25px)',
  gridTemplateRows: 'repeat(auto, 25px)',
  gridColumnGap: '15px',
  gridRowGap: '5px',
  justifyContent: 'center'
}

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const HospitalDepartmentForm = ({ item, t, users = [], onSubmit }) => {
  const [error, setError] = useState({ missingFields: [] })
  const [data, setData] = useState({ ...item })
  const [pickerVisible, setPickerVisible] = useState(false)

  const errorClass = useCallback(error.missingFields.reduce((acc, f) => {
    acc[f] = 'error-input-border'

    return acc
  }, {}), [error])

  const handleSubmit = useCallback(() => {
    const errors = isValid(data, HOSPITAL_DEPARTMENT_VALIDATORS)

    if (errors.keys.length === 0) {
      onSubmit(new HospitalDepartment(data))
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [data, error, setError, t])

  return (
    <div className='flex-col'>
      <FormItem icon={faIdBadge} label='form.label.name' required>
        <Input className={errorClass[VALIDATION_FIELDS.NAME]} value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
      </FormItem>
      <FormItem icon={faUserTie} label='form.label.hospital_department_administrator'>
        <UsersHeap values={data.managers ?? []} users={users} onSelect={managers => setData({ ...data, managers })} />
      </FormItem>
      <FormItem icon={faPalette} label='data_table.column_title.color'>
        <Button.Group
          defaultValue={data?.color}
          buttonstyle='solid'
          style={COLOR_PICKER_GRID}
        >
          {HOSPITAL_DEPARTMENT_COLORS.map(c => (
            <Button
              key={c.name}
              value={c.value}
              style={{ backgroundColor: c.value, borderRadius: '5px' }}
              onClick={e => setData({ ...data, color: e.target.value })}
            />
          ))}

        </Button.Group>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ backgroundColor: 'transparent', borderRadius: '5px' }}
            onClick={() => { setPickerVisible(!pickerVisible) }}
          >
            <FontAwesomeIcon icon={pickerVisible ? faMinus : faPlus} />
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pickerVisible &&
            <ChromePicker
              color={data?.color}
              onChangeComplete={c => setData({
                ...data,
                color: c.hex
              })}
            />}
        </div>

      </FormItem>
      <IconButton type='primary' icon={faSave} onClick={handleSubmit} text={t('actions.save')} />
    </div>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentForm)
