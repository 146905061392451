import { isObject } from '..'

export class ScheduleComplement {
  constructor ({ actType, id, internship, positive, value, commentary }) {
    this.commentary = commentary ?? ''
    this.id = id ?? -1
    this.positive = positive ?? true
    this.value = value ?? '00:00:00'
    this.actType = isObject(actType) ? actType : null
    this.internship = isObject(internship) ? internship : null
  }

  toRequestBody = () => {
    return {
      commentary: this.commentary,
      positive: this.positive,
      value: this.value,
      actType: this.actType ? this.actType.id : null,
      internship: this.internship ? this.internship.id : null
    }
  }
}
