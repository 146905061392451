import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { faAt, faGlobe, faPhone, faSchool, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

import '../../assets/student-information-modal.scss'
import { TYPE_EMAIL, TYPE_PHONE, TYPE_REGION } from '../../utils/entities/studentExtraInfo'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const StudentInformationsModal = ({ student, onCancel, t }) => {
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [personalPhones, setPersonalPhones] = useState([])
  const [personalEmails, setPersonalEmails] = useState([])
  const [personalRegions, setPersonalRegions] = useState([])

  useEffect(() => {
    if (student) {
      setPhone(student.phoneNumber ?? '')
      setEmail(student.email ?? '')

      if (student.extraContactInfo) {
        const phones = []
        const emails = []
        const regions = []

        student.extraContactInfo.forEach(ei => {
          if (ei.type === TYPE_PHONE) {
            phones.push(ei.value)
          } else if (ei.type === TYPE_EMAIL) {
            emails.push(ei.value)
          } else if (ei.type === TYPE_REGION) {
            regions.push(ei.value)
          }
        })

        setPersonalPhones(phones)
        setPersonalEmails(emails)
        setPersonalRegions(regions)
      }
    }
  }, [student])

  return (
    <Modal
      title={student && t('Contact information of ') + student.lastname + ' ' + student.firstname}
      visible={!!student}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <div className='student-information-container'>
        {(phone || email) && (
          <div className='school-information'>
            <h3> <FontAwesomeIcon icon={faSchool} /> {t('Scholar information')} </h3>
            {email && (
              <div className='information-container'>
                <h4> <FontAwesomeIcon icon={faAt} /> {t('Email')} </h4>
                <span> {email} </span>
              </div>
            )}
            {phone && (
              <div className='information-container'>
                <h4> <FontAwesomeIcon icon={faPhone} /> {t('Phone number')} </h4>
                <span> {phone} </span>
              </div>
            )}
          </div>
        )}
        {(personalPhones.length > 0 || personalEmails.length > 0 || personalRegions.length > 0) && (
          <div className='personal-information' style={phone || email ? { marginTop: '20px' } : {}}>
            <h3> <FontAwesomeIcon icon={faUser} /> {t('Personal information')} </h3>
            {personalEmails.length > 0 && (
              <div className='information-container'>
                <h4> <FontAwesomeIcon icon={faAt} /> {t('Email')} </h4>
                <div className='flex-column'>
                  {personalEmails.map((e, index) => (<span key={'email_' + index}> {e} </span>))}
                </div>
              </div>
            )}
            {personalPhones.length > 0 && (
              <div className='information-container'>
                <h4> <FontAwesomeIcon icon={faPhone} /> {t('Phone number')} </h4>
                <div className='flex-column'>
                  {personalPhones.map((p, index) => (<span key={'phone_' + index}> {p} </span>))}
                </div>
              </div>
            )}
            {personalRegions.length > 0 && (
              <div className='information-container'>
                <h4> <FontAwesomeIcon icon={faGlobe} /> {t('Carpooling regions')} </h4>
                <div className='flex-column'>
                  {personalRegions.map((p, index) => (<span key={'region_' + index}> {p} </span>))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(StudentInformationsModal)
