import { request, requestWithPromise } from '../../utils'
const INITIAL_STATE = {}

const SET_INTERNSHIP_DOCUMENTS = 'SET_INTERNSHIP_DOCUMENTS'

const getInternships = (state) => {
  return state
}

const fetchInternshipDocuments = (internshipId, user) => {
  return async function fetchInternshipDocumentsThunk (dispatch, getState) {
    const { data } = await request(
      '/api/internships/' + internshipId + '/documents',
      'GET',
      null,
      user
    )

    if (data) {
      dispatch(setInternshipDocuments(internshipId, data))
    }
  }
}

const updateInternshipWithCallback = (internship, body, user, callback = null) => {
  return async function updateInternshipWithCallback (dispatch, getState) {
    requestWithPromise(`/api/internships/${internship.id}`, 'PATCH', body, user)
      .then(jsonResponse => {
        if (jsonResponse && callback) {
          callback(internship, jsonResponse.data, dispatch)
        }
      })
  }
}

const setInternshipDocuments = (id, documents) => {
  return {
    type: SET_INTERNSHIP_DOCUMENTS,
    payload: {
      id: id,
      documents: documents
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INTERNSHIP_DOCUMENTS: {
      const internships = { ...state }

      if (!internships[action.payload.id]) {
        internships[action.payload.id] = {}
      }

      internships[action.payload.id].documents = action.payload.documents

      return { ...internships }
    }
    default:
      return state
  }
}

export {
  getInternships,
  fetchInternshipDocuments,
  setInternshipDocuments,
  updateInternshipWithCallback
}
