import React, { useContext, useEffect, useMemo, useState } from 'react'

import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import { BACKEND_DATE_FORMAT, ROLE_COORDINATOR, ROLE_ENCODER } from '../../../../utils/constants'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import InternshipValidationButton from '../../ShiftsManager/InternshipValidationButton'
import SchoolDateCell from '../DateCell/SchoolDateCell'
import SchoolInternshipData from '../InternshipData/SchoolInternshipData'
import StudentQuickMenu from '../StudentQuickMenu'
import { getStatesPellet } from '../utils/componentGetters'
import { getIndexesByColumns, getValidationTooltipText } from '../utils/utils'
import { Modal, Tooltip } from 'antd'
import { recursiveIncludes } from '../../../../utils'

const DEFAULT_INDEXES = { startIndex: 0, lastIndex: 0 }

const SchoolInternshipLine = ({ internshipContext, getQuotasState, onLineHover }) => {
  const {
    internship, overlaping, onPeriodUpdate, onUpdate, handleStateChange, handleDuplicateInternship, setOverlaping
  } = useContext(internshipContext)
  const {
    dateColumns, user, onInternshipSelected, onStudentSelected,
    onInternshipDelete, setChat, setInternshipToValidate, t
  } = useContext(InternshipsManagerContext)

  const [isHovered, setIsHovered] = useState(false)
  const [periodIndexes, setPeriodIndexes] = useState(DEFAULT_INDEXES)

  const isInternshipRequest = useMemo(() => !!internship.individualInternshipRequest, [internship])

  const StatesPellet = getStatesPellet(user.context)

  useEffect(() => {
    if (internship.startDate && internship.endDate) {
      setPeriodIndexes(getIndexesByColumns(internship, dateColumns))
    }
  }, [internship, dateColumns])

  const updatePeriod = ({ startDate, endDate }, direction, index) => {
    const newInternship = {
      ...internship,
      startDate: typeof startDate === 'undefined'
        ? internship.startDate
        : startDate.format(BACKEND_DATE_FORMAT),
      endDate: typeof endDate === 'undefined'
        ? internship.endDate
        : endDate.format(BACKEND_DATE_FORMAT)
    }

    callFunctionIfDefined(onPeriodUpdate, [newInternship])
  }

  const handleLineHover = () => {
    setIsHovered(true)
    onLineHover(periodIndexes)
  }

  const handleLineLeave = () => {
    setIsHovered(false)
  }

  return (
    <tr
      className='internship-line'
      onMouseOver={handleLineHover}
      onMouseLeave={handleLineLeave}
    >
      <Modal
        footer={null}
        visible={overlaping}
        title={t('Warning!')}
        onCancel={() => setOverlaping(false)}
      >
        {t('This student is already doing an internship during this period.')}
      </Modal>
      <td>
        <div className='internship-card-old'>
          <Tooltip title={isInternshipRequest ? t('internship_line.internship_request_info') : ''}>
            <div
              className='internship-card-old-div'
              style={
                internship.individualInternshipRequest
                  ? { border: '2px solid #ED7F10', borderRadius: '5px', backgroundColor: 'rgba(237, 127, 16, 0.4)' }
                  : {}
              }
            >
              <div className='quick-menu-container'>
                <StudentQuickMenu
                  noMargin
                  internship={internship}
                  onSelectedStudentInfo={onStudentSelected}
                  onSelectedInternshipInfo={onInternshipSelected}
                  onSelectedInternshipChat={setChat}
                  onInternshipDelete={onInternshipDelete}
                  onDuplicateInternship={handleDuplicateInternship}
                />
              </div>
              <SchoolInternshipData
                disabled={isInternshipRequest || !recursiveIncludes(user.roles, [ROLE_ENCODER, ROLE_COORDINATOR])}
                internship={internship}
                onInternshipUpdate={onUpdate}
              />
              <div className='status-validation-wrapper'>
                <StatesPellet
                  internship={internship}
                  onChange={handleStateChange}
                />
                {user.canValidateInternship && internship.shifts && internship.shifts.length > 0 &&
                  <InternshipValidationButton
                    validateButtonTooltip={t(getValidationTooltipText(internship))}
                    internship={internship}
                    onDisplayValidationDrawer={setInternshipToValidate}
                  />}
              </div>
            </div>
          </Tooltip>
        </div>
      </td>
      {dateColumns.map((dates, index) => {
        return (
          <SchoolDateCell
            key={index}
            disabled={isInternshipRequest}
            startDate={dates.startDate}
            endDate={dates.endDate}
            index={index}
            isHovered={isHovered}
            periodIndexes={periodIndexes}
            onPeriodChange={updatePeriod}
          />
        )
      })}
    </tr>
  )
}

export default SchoolInternshipLine
