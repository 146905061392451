import React from 'react'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const TooltipIcon = ({ title, t }) => {
  return (
    <Tooltip title={t(title)}>
      <FontAwesomeIcon icon={faInfoCircle} color='#3c8dbc' />
    </Tooltip>
  )
}

export default connect(mapStateToProps)(TooltipIcon)
