import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { orderByNameSectors } from '../../../../Selectors/Sectors/SectorsSelector'
import { callFunctionIfDefined } from '../../../../utils/functionHelper'
import Selector from '../../Selectors/Selector'

const NO_SCHOOL_TEXT = 'Without school'
const NO_STUDENT_TEXT = 'Without student'
const NO_SECTOR_TEXT = 'Without care unit'
const NO_INTERNSHIP_YEAR_TEXT = 'Without internship year'
const NO_SECTION_TEXT = 'Without section'

const mapStateToProps = state => {
  return {
    sectors: orderByNameSectors(state),
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const InstitutionInternshipData = ({ displaySectorSelector, internship, sectors, t, user, onUpdate, context = null }) => {
  const [textualData, setTextualData] = useState({})

  useEffect(() => {
    if (internship && typeof t === 'function') {
      const newTextualData = {
        school: internship.school ? internship.school.name : t(NO_SCHOOL_TEXT),
        student: internship.student ? `${internship.student.lastname} ${internship.student.firstname}` : t(NO_STUDENT_TEXT),
        sector: internship.sector ? internship.sector.name : t(NO_SECTOR_TEXT),
        internshipYear: internship.internshipYear ? internship.internshipYear.schoolYearName : t(NO_INTERNSHIP_YEAR_TEXT),
        section: getSectionAbbreviation(internship.section, t)
      }

      setTextualData(newTextualData)
    }
  }, [internship, t])

  const getSectionAbbreviation = (section, t) => {
    if (!section) {
      return t(NO_SECTION_TEXT)
    }

    if (section.sectionToYear) {
      return section.sectionToYear.sectionAbbreviation
    }

    return section.abbreviation
  }

  const handleSectorSelected = (id, sector) => {
    callFunctionIfDefined(onUpdate, [sector])
  }

  return (
    <div className='internship-info-old'>
      <div style={{ height: '25px', lineHeight: '25px', display: 'flex' }}>
        <span
          style={{ maxWidth: '340px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {textualData.school}
        </span>
            &nbsp; - &nbsp;
        <b> {textualData.student} </b>
      </div>
      <div className='flex-row' style={{ height: '25px', lineHeight: '25px' }}>
        {textualData.section} - {textualData.internshipYear} - {displaySectorSelector
          ? (
            <Selector
              data={sectors}
              dataKey='name'
              onSelect={handleSectorSelected}
              showArrow={false}
              showEmpty
              showSearch
              value={!internship.sector || internship.sector.parent === null ? -1 : internship.sector.id}
            />
          )
          : textualData.sector}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InstitutionInternshipData)
