import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Drawer, Button } from 'antd'
import { isArray } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalContext } from '../../Providers/GlobalProvider'
import SearchCheckbox from '../antd/Selects/SearchCheckbox'
import { linkAreaInstitution, unLinkAreaInstitution } from '../../utils/api/institution'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import { onError, onSuccess, resolvePromises } from '../../utils/apiHelper'

export const DOCUMENT_TYPE = 'document'
export const INTERNSHIP_VALIDATION_TYPE = 'validation'
export const INTERNSHIP_DOCUMENT_TYPE = 'internship_document'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const AreaManager = ({ entity, files, user, width, onClose, onUpdate, t }) => {
  const { areas } = useContext(GlobalContext)
  const { institution } = useContext(InstitutionContext)
  const [selectedAreas, setSelectedAreas] = useState([])

  const initialChecked = useMemo(() => institution.areas.map((area) => area.id), [institution.areas])

  useEffect(() => {
    setSelectedAreas(institution.areas)
  }, [institution.areas])

  const handleAreasCheck = (area, add) => {
    const newSections = add ? [...selectedAreas, area] : selectedAreas.filter(ss => ss.id !== area.id)
    setSelectedAreas(newSections)
  }

  const onSubmit = () => {
    const areArraysDifferent = institution.areas.length !== selectedAreas.length ||
      selectedAreas.some(element => !institution.areas.some(area => area.id === element.id))
    const addAreas = []
    const removeAreas = []

    if (areArraysDifferent) {
      selectedAreas.forEach(element => {
        const isPresent = institution.areas.some(area => area.id === element.id)

        if (!isPresent) {
          addAreas.push(element.id)
        }
      })

      // Trouver les éléments à supprimer
      institution.areas.forEach(element => {
        const isPresent = selectedAreas.some(area => area.id === element.id)

        if (!isPresent) {
          removeAreas.push(element.id)
        }
      })

      const promiseStack = []

      if (removeAreas.length > 0) {
        promiseStack.push(unLinkAreaInstitution(user, institution, removeAreas).then(json => {
          if (json?.data) {
            onSuccess(t('success'))
          } else {
            onError(t('error'))
          }
        }))
      }
      if (addAreas.length > 0) {
        promiseStack.push(linkAreaInstitution(user, institution, addAreas).then(json => {
          if (json?.data) {
            onSuccess(t('success'))
          } else {
            onError(t('error'))
          }
        }))
      }

      resolvePromises(promiseStack).then(onUpdate)
    }
  }

  return (
    <Drawer
      title={t('linked-regions')}
      width={typeof width === 'undefined' ? '30%' : width}
      onClose={onClose}
      visible={!!entity || isArray(files)}
    >
      <SearchCheckbox
        checkAllOption
        items={areas}
        labelKey='localName'
        tooltipKey='localName'
        title={t('Filter data')}
        valueKey='id'
        onCheck={handleAreasCheck}
        onCheckAll={setSelectedAreas}
        initChecked={initialChecked}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <Button
          type='primary'
          style={{ textAlign: 'center', width: '80%' }}
          onClick={onSubmit}
        >
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className='button-smart-table'
          >
            <FontAwesomeIcon icon='save' />
            &nbsp;
            {t('Save')}
          </div>
        </Button>
      </div>
    </Drawer>
  )
}

export default connect(mapStateToProps)(AreaManager)
