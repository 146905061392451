import React, { useMemo } from 'react'

import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { ORDER_BY } from '../../../../utils/constants'
import Cookies from 'js-cookie'
import { ACTIONS, Action, DeleteAction } from '../../../../Components/shared/DataTable/utils/actions'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { ActTypeCategory, getName } from '../../../../utils/entities/actTypeCategory'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const ActCategoriesTable = ({ data, loading, maxHeight, metadata, parameters, school, user, onParametersChange, onTriggerAction, t }) => {
  const defaultMaxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '230px' : '189px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: ORDER_BY.NAME, key: getName }
  ], [t])
  const actions = useMemo(() => [
    new Action({ title: 'act_type_categories_table.actions.edit', icon: faEdit, onClick: s => onTriggerAction(s, ACTIONS.EDIT_ACT_TYPE_CATEGORY) }),
    new DeleteAction({ title: 'act_type_categories_table.actions.delete', onDelete: s => onTriggerAction(s, ACTIONS.DELETE) })
  ], [onTriggerAction])

  return (
    <div style={{ height: maxHeight ?? defaultMaxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => onTriggerAction(new ActTypeCategory({ school }), ACTIONS.CREATE_ACT_TYPE_CATEGORY)}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(ActCategoriesTable)
