const isOldSection = section => {
  return section.sectionToYear === null
}

const getFullSectionName = (section, includeSchoolYear = true, useAbbreviation = true) => {
  const name = section.sectionToYear && useAbbreviation ? section.abbreviation : section.name

  if (!includeSchoolYear) {
    return name
  }

  return section.schoolYearName
    ? `${name} (${section.schoolYearName})`
    : getOldSectionNameIfExist(section, name)
}

const getOldSectionNameIfExist = (section, name) => {
  if (section.oldSchoolYearName) {
    return `${name} (${section.oldSchoolYearName})`
  }

  return name
}

export {
  isOldSection,
  getFullSectionName
}
