import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getHospitalDepartmentSectors, linkHospitalDepartmentSectors, unlinkHospitalDepartmentSectors } from '../../utils/api/hospitalDepartment'
import Loading from '../../HOC/Loading'
import SearchCheckboxList from '../../Components/antd/Lists/SearchCheckboxList'
import { onSuccess, resolvePromises } from '../../utils/apiHelper'
import { isFunction } from 'lodash'

export const HospitalDepartmentSectorsDrawerContext = createContext()

export const DEFAULT_STATE = { data: null, readOnly: false }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const HospitalDepartmentSectorsDrawer = ({ children, sectors = [], user, t }) => {
  const [selectedHospitalDepartment, setSelectedHospitalDepartment] = useState(DEFAULT_STATE)
  const [departmentSectors, setDepartmentSectors] = useState({ data: [], loading: true })
  const [linkedSectors, setLinkedSectors] = useState([])

  const purgeSectors = useMemo(() => sectors.filter(s => {
    if (selectedHospitalDepartment.data) {
      return !s.hospitalDepartment || s.hospitalDepartment.id === selectedHospitalDepartment.data.id
    }

    return false
  }), [selectedHospitalDepartment.data])

  useEffect(() => {
    if (selectedHospitalDepartment.data) {
      setDepartmentSectors({ data: [], loading: true })

      getHospitalDepartmentSectors(user, selectedHospitalDepartment.data).then(json => {
        setDepartmentSectors({ data: json.data ?? [], loading: false })
      })
    }
  }, [user, selectedHospitalDepartment.data, setDepartmentSectors])

  const handleClick = useCallback(() => {
    const sectorToBeUnlinked = departmentSectors.data.filter(s => !linkedSectors.some(ls => ls.id === s.id)).map(s => s.id)
    const sectorToBeLinked = linkedSectors.filter(ls => !departmentSectors.data.some(s => s.id === ls.id)).map(s => s.id)

    const promisesStack = []

    if (sectorToBeUnlinked.length > 0) {
      promisesStack.push(unlinkHospitalDepartmentSectors(user, selectedHospitalDepartment.data, sectorToBeUnlinked))
    }

    if (sectorToBeLinked.length > 0) {
      promisesStack.push(linkHospitalDepartmentSectors(user, selectedHospitalDepartment.data, sectorToBeLinked))
    }

    resolvePromises(promisesStack).then(() => {
      if (isFunction(selectedHospitalDepartment.callback)) {
        getHospitalDepartmentSectors(user, selectedHospitalDepartment.data).then(json => {
          selectedHospitalDepartment.callback(json.data)
        })
      }
      onSuccess(t('hospital_department_sectors_drawer.save.success'))
      setSelectedHospitalDepartment(DEFAULT_STATE)
    })
  }, [departmentSectors.data, linkedSectors, selectedHospitalDepartment.data, user, t])

  return (
    <>
      <HospitalDepartmentSectorsDrawerContext.Provider value={{ setSelectedHospitalDepartment }}>
        {children}
      </HospitalDepartmentSectorsDrawerContext.Provider>
      <Drawer
        title={t('hospital_department_sectors_drawer.title')}
        width='500px'
        onClose={() => setSelectedHospitalDepartment(DEFAULT_STATE)}
        visible={!!selectedHospitalDepartment.data}
        zIndex={5000}
        destroyOnClose
        bodyStyle={{ height: 'calc(100% - 55px)', display: 'flex', flexDirection: 'column' }}
      >
        <Loading loading={departmentSectors.loading}>
          <div style={{ maxHeight: 'calc(100% - 48px)' }}>
            <SearchCheckboxList
              data={purgeSectors}
              label='name'
              values={departmentSectors.data}
              onChange={setLinkedSectors}
            />
          </div>
        </Loading>
        <div className='mt-8 pd-8' style={{ height: '48px' }}>
          <Button type='primary' onClick={handleClick}>{t('Save')}</Button>
        </div>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentSectorsDrawer)
