import { requestWithPromise } from '..'
import { POST } from '../apiHelper'

export const linkSchoolSection = (user, sectorDocument, schoolSections) => {
  return requestWithPromise(
    `/api/sector-documents/${sectorDocument.id}/school-sections`,
    POST,
    { schoolSections },
    user
  )
}
