import React from 'react'

import { Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'

const TooltipIconButton = props => {
  const noClickCallback = () => {
    console.log('Missing onClick callback !')
  }

  const getText = () => {
    return <span> &nbsp; {props.text}</span>
  }

  return (
    <Tooltip
      placement={props.placement ?? 'top'}
      title={props.t(props.title)}
    >
      <Button
        onClick={props.onClick ?? noClickCallback}
        type={props.type ?? 'default'}
        size={props.size ?? 'default'}
        disabled={props.disabled ?? false}
        ghost={props.ghost}
      >
        <FontAwesomeIcon icon={props.icon} />
        {props.text && getText()}
      </Button>
    </Tooltip>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TooltipIconButton)
