import React, { useState, useEffect } from 'react'

import { Tooltip, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import moment from 'moment'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'

/**
 * A standart factory to build a DatePicker for table header
 * 
 * Needed props :
 *  {string}   getter       redux store method name in order to get data in the redux store
 *  {string}   setter       redux store method name in order to save modified data in the redux store
 *  {*}        dataId       a valid id for a data save in the redux store
 *  {*}        columnKey    a valid table column key
 *  {string}   setDirty     redux store method name in order to set this data as dirty
 *  {function} cleanTimeOut function to clean timemout on corresponding table data
 * 
 * Optionnal props : 
 *  {string}  placeholder the message to set if data value pass is null
 *  {boolean} disabled    set to true in order to disable the date modification
 * 
 * @param {*} props all the additionnal props pass to the component
 * @returns a react grappe
 */
const DatePickerFactory = props => {
  const [currentDate, setCurrentDate] = useState(moment())

  useEffect(() => {
    if (props[props.getter]) {
      if (props.dataId === null || props[props.getter][props.dataId]){
        setCurrentDate(props.dataId === null ? props[props.getter][props.columnKey] : props[props.getter][props.dataId][props.columnKey])
      }
    }
  }, [props.dataId === null ? props[props.getter] : props[props.getter][props.dataId]])

  /**
   * Callback function to save data in the redux store
   * If dataId is null, save data as a new entity data in the redux store
   * Else save data as a part of data redux store 
   * 
   * @param {moment} date new date to save
   */
  const sendData = (date) => {
    let newData = {...props[props.getter]}

    if (props.dataId === null) {
      newData[props.columnKey] = date
      props[props.setter](newData)
    } else {
      newData[props.dataId][props.columnKey] = date

      props[props.setter](newData)
      props[props.setDirty](props.dataId)
    }
  }

  /**
   * Callback function when date is pick on the datePicker
   * 
   * @param {moment} date       the new selected date
   * @param {string} dateString the new selected date formated as a string
   */
  const handleRangeChange = (date, dateString) => {

    setCurrentDate(date)
    sendData(date)
  }

  /**
   * Handle the open modification state of the datePicker
   * 
   * @param {boolean} open the open/close state of the datePicker panel
   */
  const onOpenChange = open => {
    if (open) {
      props.cleanTimeOut()
    } 

    sendData(currentDate)
  }

  //Component return
  return(
    <Tooltip
      title={props.t('Assignation date selector')}
      placement='top'
    >
      <DatePicker
        locale={locale}
        onChange={handleRangeChange}
        value={currentDate}
        format='DD/MM/YYYY'
        onOpenChange={onOpenChange}
        placeholder={props.placeholder ? props.t(props.placeholder) : props.t("Empty")}
        disabled={props.disabled ? props.disabled : false} 
      />
    </Tooltip>
  )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DatePickerFactory)