const helpFr = [
  {
    key: 'SCHOOL_INTERNSHIPS',
    content: [
      {
        titleKey: 'Introduction d’une demande de stage',
        text: "Introduction d'une demande de stage médical, paramédical ou infirmier sur Interneo. Cette vidéo illustre diverses actions sur les stages: ajouter, supprimer et annuler des stages, consulter les détails, etc",
        youtubeEmbed: 'iJCQmaXCej0'
      },
      {
        titleKey: 'Conventions et fichiers attachés aux stages',
        text: "Gestion des attestations et documents liés à un stage médical, paramédical ou infirmier sur Interneo. La plateforme permet aux utilisateurs d'attacher des fichier et des documents aux stages effectués, aux lieux de stage, etc. Par exemple : conventions de stages, tables de prestations, descriptions internes, règlements et prérequis de lieux de stage, règlements intérieurs d'unités de soin ou de maisons de repos, etc. Cette vidéo illustre diverses actions de manipulation des fichiers: upload, download/téléchargement, consultation des détails, recherche de la convention de stage, etc.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Messagerie Interneo',
        text: "Interneo possède un messagerie interne, permettant à chacun de communiquer de manière centralisée sur les stages. Ce module s'adresse à tous les intervenants du stage : professeur, coordinateur, superviseur, étudiant stagiaire, chefs de salle, infirmier, référent étudiants, promoteur, ICANE, etc.  La plateforme permet ainsi d'éviter des dizaines de courriels, d'e-mails, d'appels téléphonique. Tous les messages sont conservés en historique et partagés entre chaque personne ayant les droits d'accès à ces informations. L'information et sa communication sont centralisées, partagées et conservées. Cette vidéo illustre le fonctionnement de la messagerie liée aux stages.",
        youtubeEmbed: 'Q1TSs2RAI5A'
      },
      {
        titleKey: 'Encodage des horaires',
        text: 'Encodage des horaires prévisionnels par jour : heure de début, heure de fin, temps de pause, manipulation des calendriers, consultation des horaires prestés pour chaque étudiant ou chaque stage.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Aide et nouveautés',
        text: "Consultation des vidéos d'aide et du changelog : toutes les vidéos utilisateurs sont regroupées dans l'aide en haut à gauche. Le changelog regroupe les nouveautés apportées à chaque version est accessible en bas à droite.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Se connecter à Interneo',
        text: 'Comment se connecter ? En utilisant votre identifiant et votre mot de passe. Mot de passe oublié ? Voici comment faire pour le réinitialiser.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'INSTITUTION_INTERNSHIPS',
    content: [
      {
        titleKey: 'Introduction d’une demande de stage',
        text: "Introduction d'une demande de stage médical, paramédical ou infirmier sur Interneo. Cette vidéo illustre diverses actions sur les stages: ajouter, supprimer et annuler des stages, consulter les détails, etc",
        youtubeEmbed: 'iJCQmaXCej0'
      },
      {
        titleKey: 'Conventions et fichiers attachés aux stages',
        text: "Gestion des attestations et documents liés à un stage médical, paramédical ou infirmier sur Interneo. La plateforme permet aux utilisateurs d'attacher des fichier et des documents aux stages effectués, aux lieux de stage, etc. Par exemple : conventions de stages, tables de prestations, descriptions internes, règlements et prérequis de lieux de stage, règlements intérieurs d'unités de soin ou de maisons de repos, etc. Cette vidéo illustre diverses actions de manipulation des fichiers: upload, download/téléchargement, consultation des détails, recherche de la convention de stage, etc.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Messagerie Interneo',
        text: "Interneo possède un messagerie interne, permettant à chacun de communiquer de manière centralisée sur les stages. Ce module s'adresse à tous les intervenants du stage : professeur, coordinateur, superviseur, étudiant stagiaire, chefs de salle, infirmier, référent étudiants, promoteur, ICANE, etc.  La plateforme permet ainsi d'éviter des dizaines de courriels, d'e-mails, d'appels téléphonique. Tous les messages sont conservés en historique et partagés entre chaque personne ayant les droits d'accès à ces informations. L'information et sa communication sont centralisées, partagées et conservées. Cette vidéo illustre le fonctionnement de la messagerie liée aux stages.",
        youtubeEmbed: 'Q1TSs2RAI5A'
      },
      {
        titleKey: 'Encodage des horaires',
        text: 'Encodage des horaires prévisionnels par jour : heure de début, heure de fin, temps de pause, manipulation des calendriers, consultation des horaires prestés pour chaque étudiant ou chaque stage.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Aide et nouveautés',
        text: "Consultation des vidéos d'aide et du changelog : toutes les vidéos utilisateurs sont regroupées dans l'aide en haut à gauche. Le changelog regroupe les nouveautés apportées à chaque version est accessible en bas à droite.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Se connecter à Interneo',
        text: 'Comment se connecter ? En utilisant votre identifiant et votremot de passe. Mot de passe oublié ? Voici comment faire pour le réinitialiser.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'INSTITUTION_STUDENTS',
    content: [
      {
        titleKey: 'Conventions et fichiers attachés aux stages',
        text: "Gestion des attestations et documents liés à un stage médical, paramédical ou infirmier sur Interneo. La plateforme permet aux utilisateurs d'attacher des fichier et des documents aux stages effectués, aux lieux de stage, etc. Par exemple : conventions de stages, tables de prestations, descriptions internes, règlements et prérequis de lieux de stage, règlements intérieurs d'unités de soin ou de maisons de repos, etc. Cette vidéo illustre diverses actions de manipulation des fichiers: upload, download/téléchargement, consultation des détails, recherche de la convention de stage, etc.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Encodage des horaires',
        text: 'Encodage des horaires prévisionnels par jour : heure de début, heure de fin, temps de pause, manipulation des calendriers, consultation des horaires prestés pour chaque étudiant ou chaque stage.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Aide et nouveautés',
        text: "Consultation des vidéos d'aide et du changelog : toutes les vidéos utilisateurs sont regroupées dans l'aide en haut à gauche. Le changelog regroupe les nouveautés apportées à chaque version est accessible en bas à droite.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Se connecter à Interneo',
        text: 'Comment se connecter ? En utilisant votre identifiant et votremot de passe. Mot de passe oublié ? Voici comment faire pour le réinitialiser.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'SCHOOL_STUDENTS',
    content: [
      {
        titleKey: 'Conventions et fichiers attachés aux stages',
        text: "Gestion des attestations et documents liés à un stage médical, paramédical ou infirmier sur Interneo. La plateforme permet aux utilisateurs d'attacher des fichier et des documents aux stages effectués, aux lieux de stage, etc. Par exemple : conventions de stages, tables de prestations, descriptions internes, règlements et prérequis de lieux de stage, règlements intérieurs d'unités de soin ou de maisons de repos, etc. Cette vidéo illustre diverses actions de manipulation des fichiers: upload, download/téléchargement, consultation des détails, recherche de la convention de stage, etc.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Encodage des horaires',
        text: 'Encodage des horaires prévisionnels par jour : heure de début, heure de fin, temps de pause, manipulation des calendriers, consultation des horaires prestés pour chaque étudiant ou chaque stage.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Aide et nouveautés',
        text: "Consultation des vidéos d'aide et du changelog : toutes les vidéos utilisateurs sont regroupées dans l'aide en haut à gauche. Le changelog regroupe les nouveautés apportées à chaque version est accessible en bas à droite.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Se connecter à Interneo',
        text: 'Comment se connecter ? En utilisant votre identifiant et votremot de passe. Mot de passe oublié ? Voici comment faire pour le réinitialiser.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  }
]

export default helpFr
