import React from 'react'

const SimpleHeaderCell = props => {
  return (
    <th
      onClick={props.onClick}
    >
      {props.name}
      <br />
      {props.date}
    </th>
  )
}

export default SimpleHeaderCell
