import React, { useContext, useState } from 'react'
import { Alert, Modal, Row } from 'antd'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../reducers/Dispatchers'
import EventCodeSelect from './Components/EventCodeSelect'
import CustomScheduleButton from './Components/CustomScheduleButton'
import PresetSelect from './Components/PresetSelect'
import { GlobalContext } from '../../../Providers/GlobalProvider'

const DesiderataCreationModal = props => {
  const [values, setValues] = useState({})
  const [isPresetSelected, setIsPresetSelected] = useState(false)
  const [text, setText] = useState(null)

  const { eventCodes } = useContext(GlobalContext)

  const handlePresetSelection = selectedPreset => {
    setValues({ ...values, preset: selectedPreset, customSchedule: {} })
    setIsPresetSelected(true)
    setText(null)
  }

  const handleCustomSchedule = customSchedule => {
    setValues({
      ...values,
      preset: [],
      customSchedule: customSchedule
    })
    setIsPresetSelected(false)
    setText(renderCustomScheduleText(customSchedule))
  }

  const handleEventCodeSelection = selectedEventCode => {
    setValues({ ...values, eventCodeType: eventCodes.find(e => e.type === selectedEventCode), eventCode: { code: props.t('event.code.' + selectedEventCode) } })
  }

  const renderCustomScheduleText = customSchedule => {
    return (
      <div>
        <Row style={{ paddingLeft: '5px' }}>
          {props.t('Schedule')} : {customSchedule.startTime} - {customSchedule.endTime}
        </Row>
        <Row style={{ paddingLeft: '5px' }}>
          {props.t('Pause')} : {customSchedule.pause ?? '0'} min
        </Row>
      </div>
    )
  }

  const title = props.t('Desiderata from ') + props.dates.startStr + props.t(' to ') + props.dates.endStr

  return (
    <Modal
      title={title}
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={() => props.onSubmit(values)}
    >
      {props.warning && (
        <Alert
          style={{ marginBottom: '10px' }}
          message={props.t('Number of days insufficient: ') + props.desiderataLimit + props.t('creation_desiderata_modal.days_left')}
          description={props.t('insufficient_desiderata')}
          type='warning'
          showIcon
        />
      )}
      <PresetSelect
        presets={props.presets}
        handlePresetSelection={handlePresetSelection}
        isPresetSelected={isPresetSelected}
      />
      <CustomScheduleButton handleCustomSchedule={handleCustomSchedule} text={text} />
      <EventCodeSelect
        value={values.eventCodeType?.type ?? null}
        handleEventCodeSelection={handleEventCodeSelection}
      />
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DesiderataCreationModal)
