import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Input, Modal, Select } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faClinicMedical, faGlobeEurope, faIdCard, faUser } from '@fortawesome/free-solid-svg-icons'
import { createUser, restoreUserByEmail, updateUser } from '../../utils/api/user'
import { getUser } from '../../reducers/UserReducer'
import { DELETED_USER, DUPLICATED_USER, onError, onSuccess, onWarning } from '../../utils/apiHelper'
import { USER_VALIDATORS, User } from '../../utils/entities/user'
import { VALIDATION_FIELDS, isValid } from '../../utils/validators'
import {
  INSTITUTION_CONTEXT, ROLE_COORDINATOR, ROLE_ENCODER, ROLE_HOSPITAL_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN, ROLE_NURSE, ROLE_SUPERVISOR,
  ROLE_VALIDATOR,
  SUPPORTED_LANGUAGE_LOCALES
} from '../../utils/constants'
import { HTTP_FORBIDDEN, getJsonBody } from '../../utils/http'
import ErrorMessage from '../../Components/shared/Messages/ErrorMessage'

import '../../assets/form.scss'

const DEFAULT_ERROR = { details: {}, title: '', visible: false, style: { borderColor: 'red' }, missingFields: [] }
const DEFAULT_DELETE_MODAL = { user: null, body: null, props: { footer: null }, title: '' }
const CREATE_USER_ACTION = 'user_created'
const UPDATE_USER_ACTION = 'user_updated'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const UserFormModal = ({ children, institutions = [], user, onSave, t }) => {
  const [visible, setVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [error, setError] = useState(DEFAULT_ERROR)
  const [deletedModal, setDeleteModal] = useState(DEFAULT_DELETE_MODAL)

  const roles = useMemo(() => {
    let roles = []

    if (user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      roles = [ROLE_INSTITUTION_GROUP_ADMIN, ROLE_HOSPITAL_ADMIN, ROLE_NURSE]
    } else if (user.roles.includes(ROLE_HOSPITAL_ADMIN)) {
      roles = [ROLE_HOSPITAL_ADMIN, ROLE_NURSE]
    } else if (user.roles.includes(ROLE_ENCODER)) {
      roles = [ROLE_ENCODER, ROLE_COORDINATOR, ROLE_SUPERVISOR, ROLE_VALIDATOR]
    } else if (user.roles.includes(ROLE_COORDINATOR)) {
      roles = [ROLE_SUPERVISOR, ROLE_VALIDATOR]
    }

    return roles
  }, [user])

  const handleSubmit = useCallback(() => {
    const errors = isValid(selectedUser, USER_VALIDATORS)

    if (errors.keys.length === 0) {
      const buildedUser = new User(selectedUser)

      if (buildedUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
        buildedUser.institutionGroup = user.institutionGroup
        buildedUser.institutions = []
      }

      const parameters = buildedUser.toRequestBody()

      const promise = selectedUser.id === -1 ? createUser(user, parameters) : updateUser(user, parameters, true, true)
      const action = selectedUser.id === -1 ? CREATE_USER_ACTION : UPDATE_USER_ACTION

      promise.then(json => {
        if (json?.data) {
          onSuccess(t(`user_form_modal.${action}.success`))
          onSave(json.data)
          setVisible(false)
        }
      }).catch(e => {
        getJsonBody(e.body).then(json => {
          if (action === CREATE_USER_ACTION) {
            setVisible(false)

            if (json.message === DELETED_USER) {
              setDeleteModal({
                props: {},
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {t('revive.deleted.user', { email: parameters.username })}
                  </p>
                ),
                title: t('Restore an user ?'),
                user: { ...parameters, roles: parameters.roles[0] }
              })
            } else if (json.message === DUPLICATED_USER) {
              setDeleteModal({
                props: { footer: null },
                body: (
                  <p style={{ fontWeight: 'bold' }}>
                    <FontAwesomeIcon color='orange' icon='exclamation-circle' />&nbsp;
                    {t('There is already an account linked to this email address')}
                  </p>
                ),
                title: t('Invalid email/username'),
                user: { ...parameters, roles: parameters.roles[0] }
              })
            }
          } else if (json.message === DUPLICATED_USER) {
            onWarning(t(DUPLICATED_USER))
          } else {
            onError(t(`user_form_modal.${action}.error`))
          }
        })
      })
    } else {
      const details = {}

      Object.keys(errors.messages).forEach(key => {
        details[t(key)] = errors.messages[key].map(m => t(m))
      })

      setError({
        ...error,
        title: t('form.errors'),
        details,
        visible: true,
        missingFields: errors.keys
      })
    }
  }, [t, error, selectedUser, user, onSave])

  useEffect(() => {
    setVisible(!!selectedUser)
  }, [selectedUser])

  const handleReviveUser = () => {
    restoreUserByEmail(deletedModal.user, user).then(json => {
      onSuccess(t('user_form_modal.revival.success'))
      onSave(json.data)
    }).catch(e => {
      getJsonBody(e.body).then(json => {
        if (json?.code === HTTP_FORBIDDEN) {
          onError(t('user_form_modal.revival.forbidden'))
        } else {
          onError(t('user_form_modal.revival.error'))
        }
      })
    })

    setDeleteModal(DEFAULT_DELETE_MODAL)
  }

  return (
    <>
      {React.cloneElement(children, { onSelectUser: setSelectedUser })}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
        title={t('user_form_modal.title')}
        cancelText={t('modal.cancel_button')}
        okText={t(selectedUser && selectedUser.id === -1 ? 'modal.ok_button.create' : 'modal.ok_button.save')}
        okButtonProps={{ disabled: selectedUser === null }}
        afterClose={() => {
          setError(DEFAULT_ERROR)
          setSelectedUser(null)
        }}
      >
        {selectedUser !== null && (
          <div className='user-form'>
            {error.visible && (
              <ErrorMessage title={error.title} details={error.details} />
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className='form-item'>
                <div className='form-label'>
                  <label> {t('user_form_modal.label.firstname')} </label>
                  <span> {t('form.label.required')} </span>
                </div>
                <Input
                  style={error.missingFields.includes(VALIDATION_FIELDS.FIRSTNAME) ? error.style : { borderColor: 'lightgray' }}
                  value={selectedUser.firstname}
                  onChange={e => setSelectedUser({ ...selectedUser, firstname: e.target.value })}
                />
              </div>
              <div style={{ width: '1.5vh' }} />
              <div className='form-item'>
                <div className='form-label'>
                  <label> {t('user_form_modal.label.lastname')} </label>
                  <span> {t('form.label.required')} </span>
                </div>
                <Input
                  style={error.missingFields.includes(VALIDATION_FIELDS.LASTNAME) ? error.style : { borderColor: 'lightgray' }}
                  value={selectedUser.lastname}
                  onChange={e => setSelectedUser({ ...selectedUser, lastname: e.target.value })}
                />
              </div>
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faAt} /> {t('user_form_modal.label.email')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.EMAIL) ? error.style : { borderColor: 'lightgray' }}
                value={selectedUser.email}
                onChange={e => setSelectedUser({ ...selectedUser, email: e.target.value })}
              />
            </div>
            <div className='form-item'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faUser} /> {t('user_form_modal.label.username')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Input
                style={error.missingFields.includes(VALIDATION_FIELDS.USERNAME) ? error.style : { borderColor: 'lightgray' }}
                value={selectedUser.username}
                onChange={e => setSelectedUser({ ...selectedUser, username: e.target.value })}
              />
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label> <FontAwesomeIcon icon={faGlobeEurope} /> {t('user_form_modal.label.language')} </label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                className={error.missingFields.includes(VALIDATION_FIELDS.LANGUAGE) ? 'error-input-border' : ''}
                value={selectedUser.language}
                onChange={value => setSelectedUser({ ...selectedUser, language: value })}
              >
                {SUPPORTED_LANGUAGE_LOCALES.map(l => {
                  return (
                    <Select.Option key={l} value={l}>
                      {t('languages.' + l)}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
            <div className='form-item form-select'>
              <div className='form-label'>
                <label><FontAwesomeIcon icon={faIdCard} /> {t('user_form_modal.label.role')}</label>
                <span> {t('form.label.required')} </span>
              </div>
              <Select
                className={error.missingFields.includes(VALIDATION_FIELDS.ROLES) ? 'error-input-border' : ''}
                value={selectedUser.roles ? selectedUser.roles[0] : null}
                onChange={value => setSelectedUser({ ...selectedUser, roles: [value] })}
              >
                {roles.map(r => {
                  return (
                    <Select.Option key={r} value={r}>
                      {t(r)}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
            {
              user.context === INSTITUTION_CONTEXT && user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) &&
              selectedUser.roles.length > 0 && !selectedUser.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN) && (
                <div className='form-item form-select'>
                  <div className='form-label'>
                    <label><FontAwesomeIcon icon={faClinicMedical} /> {t('user_form_modal.label.institution')}</label>
                    <span> {t('form.label.required')} </span>
                  </div>
                  <Select
                    className={error.missingFields.includes(VALIDATION_FIELDS.INSTITUTIONS) ? 'error-input-border' : ''}
                    value={selectedUser.institutions.length > 0 ? selectedUser.institutions[0].id : null}
                    onChange={value => setSelectedUser({ ...selectedUser, institutions: [{ id: value }] })}
                  >
                    {institutions.map(i => {
                      return (
                        <Select.Option key={i.id} value={i.id}>
                          {i.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </div>
              )
            }
          </div>
        )}
      </Modal>
      <Modal
        title={deletedModal.title}
        visible={deletedModal.user !== null}
        onOk={handleReviveUser}
        onCancel={() => setDeleteModal(DEFAULT_DELETE_MODAL)}
        cancelText={t('Cancel')}
        okText={t('Restore')}
        {...deletedModal.props}
      >
        {deletedModal.body}
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(UserFormModal)
