import React, { useState, useEffect, useRef } from 'react'
import { Drawer, Select, Input, Button, Upload, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bool, func, shape, number, string } from 'prop-types'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
const Option = Select.Option

const entityOptions = [
  { id: 'sector', name: 'Sector' },
  { id: 'internship', name: 'Internship' },
  { id: 'student', name: 'Student' },
  { id: 'institution', name: 'Institution' },
  { id: 'school', name: 'School' }
]

const AddTagDrawer = props => {
  const [isValid, setIsValid] = useState(true)
  const [title, setTitle] = useState(props.t('Add tag'))
  const [nameEN, setNameEN] = useState('')
  const [nameFR, setNameFR] = useState('')
  const [nameNL, setNameNL] = useState('')
  const [visibility, setVisibility] = useState('private')
  const [entityName, setEntityName] = useState('')
  const [activeIconUrl, setActiveIconUrl] = useState(null)
  const [inactiveIconUrl, setInactiveIconUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const nameInputRef = useRef(null)

  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        nameInputRef.current.focus()
      }, 100)

      if (props.tagToEdit) {
        setNameEN(props.tagToEdit.nameEN || '')
        setNameFR(props.tagToEdit.nameFR || '')
        setNameNL(props.tagToEdit.nameNL || '')
        setVisibility(props.tagToEdit.visibility)
        setEntityName(props.tagToEdit.entityName || '')
        setActiveIconUrl(props.tagToEdit.icon)
        setInactiveIconUrl(props.tagToEdit.inactiveIcon)
        setTitle(props.t('Edit tag'))
      } else {
        setTitle(props.t('Add tag'))
      }
    }
  }, [props.visible])

  const handleDrawerClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose()
    }
    setTimeout(() => {
      // reset the form
      setNameEN('')
      setNameFR('')
      setNameNL('')
      setVisibility('private')
      setActiveIconUrl(null)
      setInactiveIconUrl(null)
    }, 0)
  }

  const beforeUpload = file => {
    return false
  }

  function getBase64 (file, callback) {
    const reader = new FileReader() //eslint-disable-line
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }

  const handleChange = ({ file }, isForInactiveIcon = false) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    let valid = true
    if (!isJpgOrPng) {
      notification.error({
        message: props.t('Only jpeg & pngs are allowed'),
        placement: 'bottomRight'
      })
      valid = false
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.error({
        message: props.t('Icon must smaller than 2MB!'),
        placement: 'bottomRight'
      })
      valid = false
    }
    if (isValid && !valid) {
      setIsValid(false)
      return
    } else if (!isValid && valid) {
      setIsValid(true)
    }

    // Get this url from response in real world.
    getBase64(file, iconUrl => {
      if (isForInactiveIcon) {
        setInactiveIconUrl(iconUrl)
      } else {
        setActiveIconUrl(iconUrl)
      }
    })
  }

  const handleNameChange = (e, language) => {
    switch (language) {
      case 'EN':
        setNameEN(e.target.value)
        break
      case 'FR':
        setNameFR(e.target.value)
        break
      case 'NL':
        setNameNL(e.target.value)
        break
    }
  }

  const handleVisibilityChange = (newVisibility) => {
    setVisibility(newVisibility)
  }

  const handleEntityNameChange = (newEntityName) => {
    setEntityName(newEntityName)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await props.saveTag({
        id: props.tagToEdit ? props.tagToEdit.id : undefined,
        nameEN,
        nameFR,
        nameNL,
        visibility,
        entityName,
        icon: activeIconUrl,
        inactiveIcon: inactiveIconUrl,
        type: props.getUser.context === 'ADMIN' ? 'builtin' : 'personal'
      })
      setLoading(false)
      handleDrawerClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const uploadButton = (
    <div className='upload-button'>
      <FontAwesomeIcon icon='plus' />
      {props.t('Select icon')}
    </div>
  )

  return (
    <Drawer
      title={title}
      width='640px'
      onClose={handleDrawerClose}
      visible={props.visible}
    >
      <div className='add-tag-form'>
        <div className='row names'>
          <label>{props.t('Translations of your tag name')}</label>
          <Input
            placeholder={props.t('French name')}
            onChange={(e) => { handleNameChange(e, 'FR') }}
            value={nameFR}
            ref={nameInputRef}
          />
          <Input
            placeholder={props.t('Dutch name')}
            onChange={(e) => { handleNameChange(e, 'NL') }}
            value={nameNL}
          />
          <Input
            placeholder={props.t('English name')}
            onChange={(e) => { handleNameChange(e, 'EN') }}
            value={nameEN}
          />
        </div>
        <div className='row'>
          <label>{props.t('Select the entity type for this tag')}</label>
          <Select
            value={entityName}
            onChange={handleEntityNameChange}
            placeholder={props.t('Entity type')}
          >
            {entityOptions.map(item => (
              <Option key={item.id} value={item.id} className='select-default'>
                {props.t(item.name)}
              </Option>
            ))}
          </Select>
        </div>
        <div className='row'>
          <label>{props.t('Set the visibility of the tag')}</label>
          <Select
            value={visibility}
            onChange={handleVisibilityChange}
          >
            <Option value='private' className='select-default'>
              {props.t('Private')}
            </Option>
            <Option value='institution' className='select-default'>
              {props.t('Institution')}
            </Option>
            <Option value='school' className='select-default'>
              {props.t('School')}
            </Option>
          </Select>
        </div>
        <div className='row columns'>
          <div className='column'>
            <label>{props.t('manage.tags.active.icon.description')}</label>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={file => { handleChange(file, false) }}
            >
              {activeIconUrl ? <img src={activeIconUrl} className='icon-img' alt='active icon' /> : uploadButton}
            </Upload>
          </div>
          <div className='column'>
            <label>{props.t('manage.tags.inactive.icon.description')}</label>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={file => { handleChange(file, true) }}
            >
              {inactiveIconUrl ? <img src={inactiveIconUrl} className='icon-img' alt='inactive icon' /> : uploadButton}
            </Upload>
          </div>
        </div>
        <div className='row'>
          <Button
            disabled={(!nameEN.length && !nameFR.length && !nameNL.length) || !entityName.length}
            type='primary'
            onClick={handleSave}
            loading={loading}
          >{props.t('Save')}
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

AddTagDrawer.propTypes = {
  visible: bool,
  onClose: func,
  tagToEdit: shape({
    id: number,
    name: string,
    icon: string,
    inactiveIcon: string,
    visibility: string
  })
}

AddTagDrawer.defaultProps = {
  visible: false,
  onClose: undefined,
  tagToEdit: undefined
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTagDrawer)
