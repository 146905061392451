import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getAllEventCodes = (user) => {
  return requestWithPromise(
    '/api/event-codes-types',
    GET,
    null,
    user
  ).then(json => {
    return json?.data ?? []
  })
}
