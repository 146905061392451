import { isObject, validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'

export const INSTITUTION_GROUP_VALIDATORS = [
  { key: VALIDATION_FIELDS.ACRONYM, validator: ig => validateString(ig.acronym, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.COUNTRY, validator: ig => isObject(ig.country) },
  { key: VALIDATION_FIELDS.NAME, validator: ig => validateString(ig.name, { notEmpty: true }) }
]

export class InstitutionGroup {
  constructor ({ id, acronym, address, city, country, name, zipcode } = {}) {
    this.id = id ?? -1
    this.acronym = acronym ?? ''
    this.address = address ?? ''
    this.city = city ?? ''
    this.country = country ?? null
    this.name = name ?? ''
    this.zipcode = zipcode ?? ''
  }

  toRequestBody = () => {
    return {
      id: this.id,
      acronym: this.acronym,
      address: this.address,
      city: this.city,
      country: this.country === null ? null : this.country.code,
      name: this.name,
      zipcode: this.zipcode
    }
  }
}
