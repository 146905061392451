import React, { useContext, useMemo } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import InternshipsManagerView from '../Views/shared/InternshipsManagerView'
import { ROLE_COORDINATOR, ROLE_ENCODER, ROLE_SUPERVISOR, ROLE_UNKNOWN } from '../utils/constants'
import routes from '../routes'
import SchoolStudentsView from '../Views/school/SchoolStudentsView'
import { getUser } from '../reducers/UserReducer'
import SchoolInstitutionsView from '../Views/school/SchoolInstitutionsView'
import SchoolSettingsView from '../Views/school/SchoolSettingsView'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { isArray } from 'lodash'
import ContactPersonsView from '../Views/shared/ContactPersonsView'
import { connect } from 'react-redux'
import { isMoment } from 'moment'
import { GlobalContext } from '../Providers/GlobalProvider'
import SchoolProvider from '../Providers/SchoolProvider'
import SchoolSectionsView from '../Views/school/SchoolSectionsView/SchoolSectionsView'

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const SchoolRouter = ({ history, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const currentRole = useMemo(() => isArray(user.roles) ? user.roles[0] : ROLE_UNKNOW, [user.roles])

  const goToShiftManager = date => {
    history.push(routes.SHIFTS_MANAGER)

    if (isMoment(date)) {
      setCurrentDate(date)
    }
  }

  return (
    <SchoolProvider>
      <Switch>
        <Route path={routes.INTERNSHIPS}>
          <InternshipsManagerView loadShiftsManager={goToShiftManager} />
        </Route>
        <Route path={routes.STUDENTS}>
          <SchoolStudentsView />
        </Route>
        {currentRole !== ROLE_SUPERVISOR && (
          <Route path={routes.SCHOOL_SECTION}>
            <SchoolSectionsView />
          </Route>
        )}
        <Route path={routes.INSTITUTIONS}>
          <SchoolInstitutionsView />
        </Route>
        {[ROLE_ENCODER, ROLE_COORDINATOR].includes(currentRole) && (
          <Route path={routes.SETTINGS}>
            <SchoolSettingsView />
          </Route>
        )}
        <Route path={routes.SHIFTS_MANAGER}>
          <ShiftsManagerView
            currentDate={currentDate}
            displayManagePresets={false}
          />
        </Route>
        {currentRole === ROLE_ENCODER && (
          <Route path={routes.PRESETS_MANAGER}>
            <ShiftPresetsManager
              sector={null}
            />
          </Route>
        )}
        <Route path={routes.TAGS_MANAGEMENT}>
          <TagsManagementView />
        </Route>
        <Route path={routes.CHANGELOG}>
          <ChangeLogView />
        </Route>
        {[ROLE_COORDINATOR, ROLE_ENCODER].includes(currentRole) && (
          <Route path={routes.CONTACT_PERSONS}>
            <ContactPersonsView />
          </Route>
        )}
      </Switch>
    </SchoolProvider>
  )
}

export default withRouter(connect(mapStateToProps)(SchoolRouter))
