import { validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'
import { isArray } from 'lodash'

export const HOSPITAL_DEPARTMENT_VALIDATORS = [
  { key: VALIDATION_FIELDS.NAME, validator: h => validateString(h.name, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.COLOR, validator: h => validateString(h.color, { nullable: true }) }
]

export const getName = h => {
  return h.name ?? null
}

export const getManagersLabel = h => {
  let label = ''

  if (isArray(h.managers)) {
    label = h.managers.map(m => {
      if (m.firstname && m.lastname) {
        return `${m.firstname} ${m.lastname} - ${m.email}`
      } else {
        return m.email
      }
    }).join(', ')
  }

  return label
}

export const getColor = h => {
  return h.color ?? null
}

export class HospitalDepartment {
  constructor ({ id, institution = null, name = '', managers = [], color = '' } = {}) {
    this.id = id ?? -1
    this.institution = institution
    this.name = name
    this.managers = managers
    this.color = color
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = this.institution?.id ?? null
    body.managers = this.managers.map(m => m.id)

    return body
  }
}
