import React from 'react'

import InternshipConstraints from './InternshipConstraints'

import './assets/constraints.scss'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'

const Constraints = props => {
  return (
    <div className='constraints'>
      <InternshipConstraints />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Constraints)
