import React, { useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../routes'
import ProfilePageView from '../Views/student/ProfilePageView'
import InternshipsView from '../Views/student/InternshipsView'
import { GlobalContext } from '../Providers/GlobalProvider'
import StudentProvider from '../Providers/StudentProvider'
import ChangeLogView from '../Views/shared/ChangeLogView'

const StudentRouter = () => {
  const { currentDate } = useContext(GlobalContext)

  return (
    <Switch>
      <StudentProvider>
        <Route path={routes.INTERNSHIPS}>
          <div className='inner-spacing'>
            <InternshipsView currentDate={currentDate} />
          </div>
        </Route>
        <Route path={routes.PROFILE_PAGE}>
          <ProfilePageView />
        </Route>
        <Route path={routes.CHANGELOG}>
          <ChangeLogView />
        </Route>
      </StudentProvider>
    </Switch>
  )
}

export default withRouter(StudentRouter)
