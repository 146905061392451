import { LANGUAGE_TAG_EN } from '../constants'

export class LanguageLocale {
  constructor ({ id = -1, languageTag = LANGUAGE_TAG_EN } = {}) {
    this.id = id
    this.languageTag = languageTag
  }

  toRequestBody = () => {
    return { ...this }
  }
}
