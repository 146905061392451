import React, { Component, useMemo } from 'react'
import { func } from 'prop-types'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

import ShiftsManager from '../../Components/shared/ShiftsManager/ShiftsManager'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { request, generalErrorHandler } from '../../utils'
import '../../assets/internships-manager.scss'
import Analytics from '../../utils/analytics'
import { DATE_FORMAT_API, ROLE_HOSPITAL_ADMIN, ROLE_OBSERVER } from '../../utils/constants'
import ShiftManagerProvider from '../../Providers/ShiftManagerProvider'

const NUMBER_OF_WEEK_TO_PRELOAD = 0

class ShiftsManagerView extends Component {
  static propTypes = {
    /**
     * displayManagePresets is a callback which will display the manage presets page.
     * TODO: In future replace it with routing
     */
    displayManagePresets: func
  };

  state = {
    start: moment()
      .startOf('isoWeek')
      .hours(0)
      .minutes(0)
      .seconds(0),
    end: null,
    internships: [],
    weeklyShiftsManagerDate: null,
    manuallySetStartDate: null,
    filterSearch: null
  };

  componentDidMount () {
    const {
      currentDate,
      getDataReady: { institutions, schools, students }
    } = this.props

    if (institutions && schools && students) {
      // always issue one call to get the latest number of unread messages
      this.props.startCheckingForMessages(this.props.getUser)

      if (currentDate < this.state.start) {
        this.getInternships(currentDate)
      } else {
        this.getInternships(this.state.start)
      }
    }
    Analytics.pageView('/institution-shift-manager')
  }

  componentWillUnmount () {
    this.props.stopCheckingForNewMessages()
  }

  componentWillUpdate (nextProps) {
    if (
      (!this.props.getDataReady.institutions ||
        !this.props.getDataReady.schools ||
        !this.props.getDataReady.students) &&
      nextProps.getDataReady.institutions &&
      nextProps.getDataReady.schools &&
      nextProps.getDataReady.students
    ) {
      if (nextProps.currentDate < this.state.start) {
        this.getInternships(nextProps.currentDate)
      } else {
        this.getInternships(this.state.start)
      }
    }

    const { internshipsWithMessages, newMessages } = nextProps.getInternshipMessagesList
    if (internshipsWithMessages.length > 0 &&
      (this.props.getInternshipMessagesList.newMessages !== newMessages ||
      this.props.getInternshipMessagesList.internshipsWithMessages.length !== internshipsWithMessages.length
      )) {
      this.updateNotifications(internshipsWithMessages)
    }
  }

  /**
   * endDate is set when user clicks on go back one week in shifts manager component
   */
  getInternships = (start, endDate = null) => {
    this.setState({ start: start, loadingInternships: true })
    const { getUser } = this.props

    const userIsHospitalAdmin = getUser.roles.includes('ROLE_HOSPITAL_ADMIN')

    /**
     * check if user is encoder. If he is encoder but not a hospital admin allow him to see the
     * shifts manager -> visibleForUserManagedSectors should not become false
     */
    const userIsEncoder = getUser.roles.includes('ROLE_ENCODER')
    const userIsValidator = getUser.roles.includes('ROLE_VALIDATOR')
    const userIsSupervisor = getUser.roles.includes('ROLE_SUPERVISOR')
    const userIsCoordinator = getUser.roles.includes('ROLE_COORDINATOR')

    const startDateRequest = start
      .clone()
      .add(-NUMBER_OF_WEEK_TO_PRELOAD, 'week')
      .format(DATE_FORMAT_API)
    let endDateRequest = start
      .clone()
      .add(3, 'week')
      .endOf('isoWeek')
      .add(NUMBER_OF_WEEK_TO_PRELOAD, 'week')
      .format(DATE_FORMAT_API)
    if (endDate) {
      endDateRequest = endDate.format(DATE_FORMAT_API)
    }

    request(
      '/internship/daterange/' + startDateRequest + '/' + endDateRequest,
      'GET',
      null,
      getUser
    )
      .then(json => {
        let shownInternshipsCount = 0
        let internships = json.data
        internships = internships.filter(i => !i.deleted)
        // sort internships by start date
        internships = sortBy(internships, 'startDate.date')
        internships = internships.map(i => {
          i.visibleSearch = true
          i.visibleState = true
          i.visibleInView = i.sector !== this.props.getInstitutions[0].rootSector.id
          i.visibleForUserManagedSectors = true

          if (!userIsHospitalAdmin && !userIsEncoder) {
            // if user is validator, we have to check the supervised sectors
            if (userIsValidator || userIsSupervisor) {
              i.visibleForUserManagedSectors =
              getUser.supervisedSectors.filter(ms => ms.id === i.sector).length > 0
            } else if (userIsCoordinator) {
              // this happens when the user is a SCHOOL COORDINATOR
              i.visibleForUserManagedSectors = getUser.coordinatedSections.filter(cs => cs.id === i.section).length > 0
            } else {
              i.visibleForUserManagedSectors =
              getUser.affiliatedSectors.filter(ms => ms.id === i.sector).length > 0
            }
          } else if (this.props.isLocalAdmin) {
            // this happens when the user is a HOSPITAL LOCAL ADMINISTRATOR,
            i.visibleForUserManagedSectors =
            getUser.affiliatedSectors.filter(ms => ms.id === i.sector).length > 0
          }

          if (
            i.visibleSearch &&
            i.visibleInView &&
            i.visibleState &&
            i.visibleForUserManagedSectors
          ) { shownInternshipsCount++ }
          return i
        })

        this.setState(
          {
            internships,
            internshipsShownCountWithoutPagination: shownInternshipsCount,
            loadingInternships: false,
            manuallySetStartDate: endDate !== null ? start : null,
            end: endDate
          },
          () => {
            if (internships.length > 0) {
              this.props.startCheckingForMessages(this.props.getUser)
              this.updateNotifications()
            }
          })

        if (!internships.length) {
          this.props.stopCheckingForNewMessages()
        }
      })
      .catch(error => {
        generalErrorHandler(error)
      })
  };

  updateNotifications = (internshipsNotifications = null) => {
    if (this.state.internships.length === 0) return

    const internshipsMessages = internshipsNotifications || this.props.getInternshipMessagesList.internshipsWithMessages
    let updateState = false
    const internships = this.state.internships.map(item => {
      // find the internship in the list of internships with messages
      const foundInternshipMessage = internshipsMessages.find(intMsg => intMsg.internship === item.id)
      if (foundInternshipMessage) {
        if (item.hasNewMessages !== foundInternshipMessage.hasNewMessages) {
          item.hasNewMessages = foundInternshipMessage.hasNewMessages
          updateState = true
        } else if (!item.hasMessages) {
          updateState = true
        }
        item.hasMessages = true
      } else {
        if (item.hasMessages) {
          updateState = true
        }
        item.hasMessages = false
      }
      return item
    })

    if (updateState) {
      this.setState({ internships })
    }
  };

  filterSearchHandler = (filterSearch) => {
    this.setState({ filterSearch: filterSearch })
  }

  getStartDate = () => {
    const { currentDate } = this.props
    const { start, manuallySetStartDate } = this.state

    if (manuallySetStartDate) {
      return manuallySetStartDate
    }

    return currentDate || start
  }

  render () {
    const { displayManagePresets, isReadOnly} = this.props
    const start = this.getStartDate()
    const end = start.clone()
      .add(3, 'week')
      .endOf('isoWeek')
      .add(NUMBER_OF_WEEK_TO_PRELOAD, 'week')

    return (
      <ShiftManagerProvider>
        <ShiftsManager
          start={start.format(DATE_FORMAT_API)}
          end={end.format(DATE_FORMAT_API)}
          limitedActions={isReadOnly}
          isReadOnly={isReadOnly}
          displayManagePresets={displayManagePresets}
        />
      </ShiftManagerProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsManagerView)
