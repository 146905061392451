export const AUTOMATIC_ACCEPTANCE_PERMISSION = 'automatic_acceptance'
export const SCHEDULE_EDITION_PERMISSION = 'schedule_edition'

export const VALID_TYPES = [
  AUTOMATIC_ACCEPTANCE_PERMISSION,
  SCHEDULE_EDITION_PERMISSION
]

export class SchoolPermission {
  constructor ({ id, institution, permissionEnabled, school, sector, sectionToYear, type }) {
    this.id = id ?? -1
    this.institution = institution ?? null
    this.permissionEnabled = permissionEnabled ?? false
    this.school = school ?? null
    this.sector = sector ?? null
    this.sectionToYear = sectionToYear ?? null
    this.type = type ?? ''
  }

  isValid () {
    return VALID_TYPES.includes(this.type)
  }

  toRequestBody () {
    return {
      institution: this.institution === null ? null : this.institution.id,
      permissionEnabled: this.permissionEnabled,
      school: this.school === null ? null : this.school.id,
      sector: this.sector === null ? null : this.sector.id,
      sectionToYear: this.sectionToYear === null ? null : this.sectionToYear.id,
      type: this.type
    }
  }
}
