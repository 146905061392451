import { requestMultipart, requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryStringFromObject } from '../apiHelper'

export const createQuota = (quota, user) => {
  return requestWithPromise(
    '/api/quotas',
    POST,
    quota.toBody,
    user
  )
}

export const duplicateQuota = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}/duplicate`,
    POST,
    {},
    user
  )
}

export const updateQuota = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}`,
    PATCH,
    quota.toBody,
    user
  )
}

export const deleteQuota = (quota, user) => {
  return requestWithPromise(
    `/api/quotas/${quota.id}`,
    DELETE,
    null,
    user,
    { catchError: false }
  )
}

export const getInternshipsQuotas = (internships, startDate, endDate, user, parameters = {}) => {
  const queryStringObject = {
    'start-date': startDate,
    'end-date': endDate,
    internships: internships.map(i => i.id)
  }

  Object.keys(parameters).forEach(key => {
    queryStringObject[key] = parameters[key]
  })

  return requestWithPromise(
    `/quota/internships${makeQueryStringFromObject(queryStringObject)}`,
    GET,
    null,
    user
  )
}

export const getAll = (user, parameters) => {
  return requestWithPromise(
    `/api/quotas${makeQueryStringFromObject(parameters)}`,
    GET,
    null,
    user
  )
}

export const importQuotas = (user, institution, language, file, optionsToDisable = {}) => {
  return requestMultipart(
    `/import/institutions/${institution.id}/quotas`,
    POST,
    { ...optionsToDisable, file, language },
    user,
    true
  )
}

export const addQuotasFromArray = (user, body) => {
  return requestWithPromise(
    '/api/quotas',
    POST,
    body,
    user
  )
}
