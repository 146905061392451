import React from 'react'

import { Select, Tooltip } from 'antd'

const Option = Select.Option
const DEFAULT_STYLE = { width: '100%' }
const TOOLTIP_PLACEMENT = 'top'

const TitledSelector = props => {
  const renderSelectOptions = () => {
    if (!props.data || !props.dataKey) {
      return []
    }

    const { data, dataKey } = props

    return data.map((option, index) => {
      const value = option[dataKey]

      return (
        <Option key={`option_${index}`} value={value} data={option}>
          <Tooltip placement={TOOLTIP_PLACEMENT} title={value}>
            {value}
          </Tooltip>
        </Option>
      )
    })
  }

  const optionFilter = (input, option) => {
    const value = option.props.value

    return value.toLowerCase().includes(input.toLowerCase())
  }

  const handleOptionSelected = (value, option) => {
    if (props.onDataSelected) {
      props.onDataSelected(option.props.data)
    }
  }

  return (
    <div style={props.style ?? DEFAULT_STYLE}>
      <h4><b>{props.title}</b></h4>
      <Select
        style={DEFAULT_STYLE}
        placeholder={props.placeholder}
        showSearch
        onSelect={handleOptionSelected}
        filterOption={optionFilter}
      >
        {renderSelectOptions()}
      </Select>
    </div>
  )
}

export default TitledSelector
