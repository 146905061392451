import React from 'react'

import { Button, DatePicker, Tooltip } from 'antd'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'

const WeekPicker = DatePicker.WeekPicker

const TOOLTIP_TEXT_CHEVRON_LEFT = 'Go back one week'
const TOOLTIP_TEXT_CHEVRON_RIGHT = 'Advance one week'
const DATE_PICKER_STYLE = { width: '125px' }
const NAVIGATION_PERIOD = 'week'

const WeekNavigator = props => {
  const { loading } = props

  const handleChevronClick = navigationQuantity => {
    handleChange(props.date.clone().add(navigationQuantity, NAVIGATION_PERIOD))
  }

  const handleChange = newDate => {
    if (typeof props.onDateChange === 'function') {
      props.onDateChange(newDate.startOf('isoWeek').hours(0).minutes(0).seconds(0))
    }
  }

  return (
    <div className='flex-row'>
      <Tooltip placement='top' title={props.t(TOOLTIP_TEXT_CHEVRON_LEFT)}>
        <Button
          onClick={() => handleChevronClick(-1)}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </Tooltip>
      <div className='h-spacing' />
      <WeekPicker
        allowClear={false}
        onChange={handleChange}
        format={props.dateFormat}
        value={props.date}
        style={DATE_PICKER_STYLE}
        disabled={loading}
      />
      <div className='h-spacing' />
      <Tooltip placement='top' title={props.t(TOOLTIP_TEXT_CHEVRON_RIGHT)}>
        <Button
          onClick={() => handleChevronClick(1)}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </Tooltip>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekNavigator)
