import { isObject } from '..'

export class ShiftPreset {
  constructor ({ color, endTime, id, institution, isDefault, pause, periodCode, sector, shiftPause, startTime }) {
    this.color = color ?? null
    this.endTime = endTime ?? '00:00:00'
    this.id = id ?? -1
    this.institution = institution ?? null
    this.isDefault = isDefault ?? false
    this.pause = pause ?? 0
    this.periodCode = periodCode ?? ''
    this.sector = sector ?? null
    this.shiftPause = shiftPause ?? null
    this.startTime = startTime ?? '00:00:00'
  }

  toRequestBody () {
    return {
      color: this.color,
      endTime: this.endTime,
      institution: this.institution,
      isDefault: this.isDefault,
      pause: this.pause,
      periodCode: this.periodCode,
      sector: this.sector,
      shiftPause: isObject(this.shiftPause) ? this.shiftPause.id : this.shiftPause,
      startTime: this.startTime
    }
  }
}
