import React, { useEffect, useState } from 'react'

import { Button, Input, Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { StudentExtraInfo, TYPE_REGION } from '../../utils/entities/studentExtraInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getUser } from '../../reducers/UserReducer'
import { createStudentExtraInformations, deleteStudentExtraInformations, updateStudentExtraInformations } from '../../utils/api/student'
import Loading from '../shared/Loading'
import { onSuccess, resolvePromises } from '../../utils/apiHelper'

import '../../assets/carpooling-region-modal.scss'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const CarpoolingRegionModal = ({ regions, user, visible, onCancel, onSave, t }) => {
  const [carpoolingRegions, setCarpoolingRegions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      setCarpoolingRegions(regions.map(r => {
        r.deleted = false
        r.dirty = false

        return r
      }))
    }
  }, [regions, visible])

  const handleChange = (newValue, dataIndex) => {
    setCarpoolingRegions(carpoolingRegions.map((cr, index) => {
      if (dataIndex !== index) {
        return cr
      }

      const newInfo = new StudentExtraInfo(cr)
      newInfo.value = newValue
      newInfo.dirty = true

      return newInfo
    }))
  }

  const handleDelete = dataIndex => {
    if (carpoolingRegions[dataIndex].id > 0) {
      setCarpoolingRegions(carpoolingRegions.map((cr, index) => {
        if (dataIndex !== index) {
          return cr
        }

        const newInfo = new StudentExtraInfo(cr)
        newInfo.deleted = true

        return newInfo
      }))
    } else {
      setCarpoolingRegions(carpoolingRegions.filter((cr, index) => dataIndex !== index))
    }
  }

  const handleSave = () => {
    const promisesStack = []
    setLoading(true)

    carpoolingRegions.forEach(cr => {
      if (cr.id <= 0 && cr.value !== '') {
        promisesStack.push(createStudentExtraInformations(user.student, user, cr).then(json => {
          return json?.data ?? null
        }))
      } else if (cr.deleted) {
        promisesStack.push(deleteStudentExtraInformations(user.student, user, cr).then(json => {
          return json?.data ? null : new StudentExtraInfo(cr)
        }))
      } else if (cr.dirty) {
        promisesStack.push(updateStudentExtraInformations(user.student, user, cr).then(json => {
          return json?.data ?? null
        }))
      } else if (cr.value !== '') {
        promisesStack.push(new Promise(resolve => {
          resolve(new StudentExtraInfo(cr))
        }))
      }
    })

    resolvePromises(promisesStack).then(results => {
      const resultingRegions = []

      results.forEach(r => {
        if (r !== null) {
          resultingRegions.push(new StudentExtraInfo(r))
        }
      })

      onSuccess(t('All the modifications has been made successfully'))
      onSave(resultingRegions)
      setLoading(false)
    })
  }

  const createRegion = () => {
    const newRegion = new StudentExtraInfo({ type: TYPE_REGION })

    newRegion.deleted = false
    newRegion.dirty = false

    return newRegion
  }

  return (
    <Modal
      footer={(
        <div className='carpooling-region-modal-footer'>
          <Button onClick={handleSave} style={{ color: 'white', backgroundColor: '#3c8dbc' }}> {t('Save')} </Button>
          <Button onClick={onCancel}> {t('Cancel')} </Button>
        </div>
      )}
      title={t('Manage carpooling regions')}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      {!loading && (
        <div className='carpooling-region-modal'>
          <div className='carpooling-modal-region-container'>
            {carpoolingRegions.map((cr, index) => {
              if (cr.deleted) {
                return null
              }

              return (
                <div key={'region_' + index} className='carpooling-region-info'>
                  <Input value={cr.value} onChange={e => handleChange(e.target.value, index)} />
                  <Button onClick={() => handleDelete(index)}> <FontAwesomeIcon icon={faTrash} /></Button>
                </div>
              )
            })}
          </div>
          <Button onClick={() => setCarpoolingRegions([...carpoolingRegions, createRegion()])}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      )}
      {loading && (
        <Loading size='2x' />
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(CarpoolingRegionModal)
