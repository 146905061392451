import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'antd'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../../reducers/Dispatchers'

const Search = Input.Search

/**
 * A standart factory to build a SearchField for table header
 * 
 * Needed props :
 *  {function} updateFilter the calback function call whe the filterSearch field value is modified
 * 
 * Optionnal props : 
 *  {string}  placeholder the message to set if data value pass is null
 * 
 * @param {*} props all the additionnal props pass to the component
 * @returns a grappe react
 */
const SearchFieldFactory = props => {

  const updateFilter = (newValue) => {
    props.updateFilter(newValue);
  }

  //Component return
  return (
    <div>
      <Search
        placeholder={props.placeholder ? props.placeholder : props.t('Filter data')}
        onKeyUp={e => updateFilter(e.target.value)}
        onPaste={e => updateFilter(e.clipboardData.getData('Text'))}
        style={{ width: 200 }}
      />
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFieldFactory)