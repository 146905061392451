import { requestWithPromise } from '../../utils'
import { arrayToUrlParameters } from '../../utils/apiHelper'
import { SUCCESS_REQUEST_STATUS } from '../../utils/constants'
import { buildURIByObject } from '../../utils/httpTools'
import { QUOTAS } from './actionsType'

const {
  SET_QUOTA, SET_QUOTAS, SET_QUOTAS_FOR_MULTIPLES_SECTORS, SET_QUOTAS_TREE,
  SET_QUOTAS_TREE_LOADING, MODIFY_QUOTA_TOTAL, DELETE_QUOTA
} = QUOTAS

const getQuotas = state => {
  return state.quotas.quotas
}

const getQuotasState = state => {
  return state.quotas
}

const fetchSectorQuotas = (sectorId, user) => {
  return async (dispatch, getState) => {
    requestWithPromise('/api/quotas/' + sectorId,
      'GET',
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: SET_QUOTAS,
          payload: jsonResponse.data
        })
      }
    })
  }
}

const fetchSectorsQuotas = (sectorsIds, user) => {
  return async (dispatch, getState) => {
    requestWithPromise('/api/quotas?' + arrayToUrlParameters(sectorsIds, 'sectors'),
      'GET',
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: SET_QUOTAS_FOR_MULTIPLES_SECTORS,
          payload: jsonResponse.data
        })
      }
    })
  }
}

const fetchInstitutionQuotas = (institutionId, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(`/api/quotas?institution=${institutionId}`,
      'GET',
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: SET_QUOTAS_FOR_MULTIPLES_SECTORS,
          payload: jsonResponse.data
        })
      }
    })
  }
}

const fetchQuotasTree = (uri, user, startIndex) => {
  const uriParams = buildURIByObject(uri)

  return async (dispatch, getState) => {
    dispatch({
      type: SET_QUOTAS_TREE_LOADING,
      payload: true
    })

    requestWithPromise(
      `/quotas-tree?${uriParams}`,
      'GET',
      null,
      user
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: SET_QUOTAS_TREE,
          payload: { trees: jsonResponse.data, startIndex }
        })
      }
    })
  }
}

const addQuota = (quotaData, user) => {
  const quota = {
    ...quotaData,
    sector: quotaData.sector.id,
    section: quotaData.section ? quotaData.section.id : null,
    school: quotaData.school ? quotaData.school.id : null,
    sectionToYear: quotaData.sectionToYear ? quotaData.sectionToYear.id : null
  }

  return async (dispatch, getState) => {
    requestWithPromise('/api/quotas', 'POST', quota, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: SET_QUOTA,
            payload: jsonResponse.data
          })
        }
      })
  }
}

const updateQuota = (quotaData, user) => {
  const quota = { ...quotaData }

  if (quota.school) {
    quota.school = quota.school?.id
  } else {
    quota.school = null
  }

  if (quota.sectionToYear) {
    quota.sectionToYear = quota.sectionToYear?.id
  } else {
    quota.sectionToYear = null
  }

  return async (dispatch, getState) => {
    requestWithPromise(`/api/quotas/${quota.id}`,
      'PATCH',
      quota,
      user
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.data) {
        dispatch({
          type: MODIFY_QUOTA_TOTAL,
          payload: jsonResponse.data
        })
      }
    })
  }
}

const duplicateQuota = (quota, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(`/api/quotas/${quota.id}/duplicate`, 'POST', {}, user)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.data) {
          dispatch({
            type: SET_QUOTA,
            payload: jsonResponse.data
          })
        }
      })
  }
}

const removeQuota = (quota, user) => {
  return async (dispatch, getState) => {
    requestWithPromise(`/api/quotas/${quota.id}`,
      'DELETE',
      null,
      user,
      { catchError: false }
    ).then(jsonResponse => {
      if (jsonResponse && jsonResponse.status === SUCCESS_REQUEST_STATUS) {
        dispatch({
          type: DELETE_QUOTA,
          payload: quota.id
        })
      }
    })
  }
}

export {
  getQuotas,
  getQuotasState,
  fetchSectorQuotas,
  fetchSectorsQuotas,
  fetchInstitutionQuotas,
  fetchQuotasTree,
  addQuota,
  duplicateQuota,
  updateQuota,
  removeQuota
}
