import React from 'react'

import { SMALL_DRAWER_WIDTH } from '../utils/Constants'
import { Drawer } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'

import '../../../../assets/smart-table.scss'
import '../../../../assets/quotas.scss'
import EditableQuotaCard from './EditableQuotaCard'

const DRAWER_TITLE = 'Allocate places'

const GlobalPlacesDrawer = props => {
  const quotas = props.quotas

  const handlePlaceUpdate = quota => {
    props.updateQuota(quota, props.getUser)
  }

  const handlePlacesSuppression = quota => {
    props.removeQuota(quota, props.getUser)
  }

  const handleOnClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  const renderBody = () => {
    const body = []

    if (quotas) {
      quotas.forEach(quota => {
        const { section, id } = quota

        if (!section) {
          body.push(
            <EditableQuotaCard
              key={'quota-card-' + id}
              quota={quota}
              onDelete={handlePlacesSuppression}
              onSave={handlePlaceUpdate}
            />
          )
        }
      })
    }

    return body
  }

  const renderDrawerTitle = () => {
    return (
      <div className='places-creater-drawer-title'>
        <h3 style={{ textAlign: 'center', margin: '0px' }}>
          {props.t(DRAWER_TITLE)}
        </h3>
      </div>
    )
  }

  return (
    <div>
      <Drawer
        title={renderDrawerTitle()}
        width={SMALL_DRAWER_WIDTH}
        onClose={handleOnClose}
        visible={props.visible}
      >
        {renderBody()}
      </Drawer>

    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalPlacesDrawer)
