import React, { useContext, useMemo } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { User, getEmail, getFirstname, getInstitutionsName, getLanguage, getLastConnection, getLastname, getRole, getUsername } from '../../../utils/entities/user'
import { Action, DeleteAction } from '../../../Components/shared/DataTable/utils/actions'
import { faEdit, faKey, faStethoscope } from '@fortawesome/free-solid-svg-icons'
import { ORDER_BY, ROLE_INSTITUTION_GROUP_ADMIN } from '../../../utils/constants'
import Cookie from 'js-cookie'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'
import { Filter, buildFilters } from '../../../Components/shared/DataTable/utils/tableFilter'
import SelectInstitutionFilter from '../../../Components/shared/DataTable/utils/SelectInstitutionFilter'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale) }
}

const UsersTable = ({ loading, parameters, users, onParametersChange, onSelectUser, onDeleteUser, onSelectUserPassword, onSelectUserSectors, t }) => {
  const institutionGroupContext = useContext(InstitutionGroupContext)
  const { selectedInstitution, setSelectedInstitution } = useContext(GlobalFiltersContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '240px' : '199px'})`, [Cookie.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('users_view.column_title.email'), orderBy: ORDER_BY.EMAIL, key: getEmail },
    { title: t('users_view.column_title.username'), orderBy: ORDER_BY.USERNAME, key: getUsername },
    { title: t('users_view.column_title.lastname'), orderBy: ORDER_BY.LASTNAME, key: getLastname },
    { title: t('users_view.column_title.firstname'), orderBy: ORDER_BY.FIRSTNAME, key: getFirstname },
    { title: t('users_view.column_title.language'), orderBy: ORDER_BY.LANGUAGE, key: u => t('languages.' + getLanguage(u)) },
    { title: t('users_view.column_title.role'), orderBy: ORDER_BY.ROLE, key: u => t(getRole(u)) },
    {
      title: t('users_view.column_title.institution'),
      orderBy: ORDER_BY.INSTITUTION,
      key: u => {
        const name = getInstitutionsName(u)

        return name.length > 25 ? `${name.slice(0, 22)}...` : name
      },
      tooltip: getInstitutionsName
    },
    { title: t('users_view.column_title.last_connection'), orderBy: ORDER_BY.LAST_CONNECTION, key: getLastConnection }
  ], [t, getEmail, getUsername, getLastname, getFirstname, getLanguage, getRole, getInstitutionsName])
  const actions = useMemo(() => [
    new Action({ title: 'users_view.actions.edit_user', icon: faEdit, onClick: onSelectUser }),
    new Action({
      title: 'users_view.actions.set_user_coordinate_sectors',
      icon: faStethoscope,
      onClick: onSelectUserSectors,
      disabled: d => d.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)
    }),
    new Action({ title: 'users_view.actions.reset_user_password', icon: faKey, onClick: onSelectUserPassword }),
    new DeleteAction({ onDelete: onDeleteUser })
  ], [onSelectUser, onDeleteUser, onSelectUserPassword, onSelectUserSectors])
  const filters = useMemo(() => buildFilters([
    new Filter({
      key: 'institution',
      label: t('Institution'),
      component: SelectInstitutionFilter,
      props: { institutions: institutionGroupContext?.institutions, value: selectedInstitution, onChange: setSelectedInstitution }
    })
  ]), [institutionGroupContext?.institutions, selectedInstitution, setSelectedInstitution, t])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={users.data}
        filters={filters}
        loading={loading}
        metadata={users.meta}
        parameters={parameters}
        onCreate={() => onSelectUser(new User())}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UsersTable)
