import { requestWithPromise } from '..'
import { DELETE, GET, PATCH, POST, makeQueryString } from '../apiHelper'

export const getSchoolPermissions = (user, parameters) => {
  return requestWithPromise(
    `/api/school-permissions${makeQueryString(parameters)}`,
    GET,
    null,
    user
  )
}

export const createSchoolPermission = (schoolPermission, user) => {
  return requestWithPromise(
    '/api/school-permissions',
    POST,
    schoolPermission.toRequestBody(),
    user,
    true,
    true
  )
}

export const deleteSchoolPermission = (schoolPermission, user) => {
  return requestWithPromise(
    `/api/school-permissions/${schoolPermission.id}`,
    DELETE,
    null,
    user,
    true,
    true
  )
}

export const modifySchoolPermission = (schoolPermission, user) => {
  return requestWithPromise(
    `/api/school-permissions/${schoolPermission.id}`,
    PATCH,
    schoolPermission.toRequestBody(),
    user,
    true,
    true
  )
}
