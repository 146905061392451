import { BACKEND_DATE_FORMAT } from '../constants'

const makeNewShift = (internshipId, startDate, endDate, periodCode = null, pause = null, eventCode = null, startTime = null, endTime = null) => {
  return {
    id: -1,
    internship: internshipId,
    endDate,
    startDate,
    periodCode,
    pause,
    eventCode,
    endTime,
    startTime,
    exactLocation: null
  }
}

const createNewQuota = (sector, startDate, endDate) => {
  return {
    sector: { id: sector.id },
    section: null,
    school: null,
    sectionToYear: null,
    total: 0,
    startDate: startDate.format(BACKEND_DATE_FORMAT),
    endDate: endDate.format(BACKEND_DATE_FORMAT)
  }
}

const createNewPauseQuota = (sector, startDate, endDate) => {
  return {
    sector: { id: sector.id },
    pause: null,
    section: null,
    school: null,
    sectionToYear: null,
    total: 0,
    startDate: startDate.format(BACKEND_DATE_FORMAT),
    endDate: endDate.format(BACKEND_DATE_FORMAT)
  }
}

export {
  makeNewShift,
  createNewQuota,
  createNewPauseQuota
}
