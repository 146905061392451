import React, { useEffect, useState } from 'react'

import { getRelatedInternships } from '../../utils/api/internship'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Button, Collapse, Divider, Drawer } from 'antd'
import moment from 'moment'
import { EUROPEAN_DATE } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import History from '../shared/History'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import StudentInformationsModal from './StudentInformationsModal'
import { Shift } from '../../utils/entities/shift'
import ResizeDrawer from '../../HOC/ResizeDrawer'
import { isObject } from '../../utils'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const CarpoolingDrawer = ({ internship, user, visible, width, onClose, t }) => {
  const [relatedInternships, setRelatedInternships] = useState([])
  const [selectedStudent, setSelectedStudent] = useState(null)

  useEffect(() => {
    getRelatedInternships(
      internship,
      user,
      { contexts: ['institution', 'extraStudentInformation', 'shifts', 'school', 'section', 'sector', 'actTypes'] }
    ).then(json => {
      if (json?.data) {
        setRelatedInternships(json.data)
      }
    })
  }, [internship, user])

  return (
    <ResizeDrawer visible={visible}>
      <Drawer
        title={(<h1 style={{ marginBottom: '0px' }}> {t("Carpooling's internships")} </h1>)}
        onClose={onClose}
      >
        <div className='carpooling-drawer-container'>
          {relatedInternships.length === 0 && (
            <div> <p>{t('No data')}</p> </div>
          )}
          {relatedInternships.map((i, index) => {
            const name = i.student.firstname + ' ' + i.student.lastname
            const sector = isObject(i.sector) ? i.sector.name : <span style={{ color: 'red' }}>{t('No care unit')}</span>
            const section = i.section && i.section.sectionToYear
              ? i.section.sectionToYear.sectionAbbreviation + ' (' + i.section.sectionToYear.schoolYearName + ')'
              : <span style={{ color: 'red' }}>{t('No section')}</span>
            const dates = t('From') + ' ' + moment(i.startDate).format(EUROPEAN_DATE) + ' ' + t('to') + ' ' + moment(i.endDate).format(EUROPEAN_DATE)

            return (
              <div key={index} className='carpooling-student'>
                <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap' }}> {name} - {sector} </span>
                  <Button onClick={() => setSelectedStudent(i.student)} type='primary'><FontAwesomeIcon icon='graduation-cap' /></Button>
                </h2>
                <p>&nbsp;{section}</p>
                <Collapse bordered={false} style={{ background: 'none' }}>
                  <Collapse.Panel header={dates} key='1' style={{ border: 'none' }}>
                    <div className='v-spacing' />
                    <History
                      data={i.shifts.map(s => {
                        const shift = new Shift(s)

                        return {
                          date: shift.startDate,
                          body: shift.isAbsent()
                            ? (<span className='absent-schedule'><b> {t('Absent')} </b></span>)
                            : shift.getSchedule()
                        }
                      })}
                      period={{
                        startDate: getTimezoneLessMoment(i.startDate < internship.startDate.date ? internship.startDate.date : i.startDate),
                        endDate: getTimezoneLessMoment(i.endDate > internship.endDate.date ? internship.endDate.date : i.endDate)
                      }}
                    />
                  </Collapse.Panel>
                </Collapse>
                <Divider />
              </div>
            )
          })}
        </div>
        <StudentInformationsModal
          student={selectedStudent}
          onCancel={() => setSelectedStudent(null)}
        />
      </Drawer>
    </ResizeDrawer>
  )
}

export default connect(mapStateToProps)(CarpoolingDrawer)
