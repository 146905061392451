import React, { useCallback, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import TooltipIconButton from '../../antd/Buttons/TooltipIconButton'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { Dropdown, Menu } from 'antd'
import { connect } from 'react-redux'
import { isObject } from '../../../utils'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const TableExternalActions = ({ actions, t }) => {
  const [visibilities, setVisibilities] = useState({})

  const switchVisibility = useCallback((key, boolValue, callback) => {
    const newVisibilities = { ...visibilities }

    newVisibilities[key] = boolValue

    setVisibilities(newVisibilities)

    if (typeof callback === 'function') {
      callback()
    }
  }, [visibilities, setVisibilities])

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            {actions.map(a => {
              return (
                <Menu.Item key={'menu-item-' + a.key} onClick={() => typeof a.onClick === 'function' ? a.onClick() : switchVisibility(a.key, true)}>
                  {a.title}
                </Menu.Item>
              )
            })}
          </Menu>
        }
        overlayClassName='custom-overlay'
        placement='bottomCenter'
        trigger={['click']}
      >
        <TooltipIconButton
          title={t('table_external_actions.button.title')}
          icon={faBolt}
        />
      </Dropdown>
      {actions.map(a => {
        if (a.Component) {
          const props = isObject(a.props) ? { ...a.props } : {}
          const oldOnClose = props.onClose
          const oldOnCancel = props.onCancel
          const oldOnOk = props.onOk

          props.visible = visibilities[a.key]
          props.onClose = () => switchVisibility(a.key, false, oldOnClose)
          props.onCancel = () => switchVisibility(a.key, false, oldOnCancel)
          props.onOk = () => switchVisibility(a.key, false, oldOnOk)

          return (
            <a.Component key={a.key} {...props} />
          )
        }
      })}
    </>
  )
}

export default connect(mapStateToProps)(TableExternalActions)
