import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { QuotasContext } from './QuotasProvider'
import { getPlaces } from '../../utils/api/sector'
import { API_DATE_FORMAT } from '../../utils/apiHelper'
import moment from 'moment'

export const AllocationContext = createContext()

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const AllocationProvider = ({ children, user }) => {
  const { period } = useContext(QuotasContext)

  const [fetchQuotas, setFetchQuotas] = useState({})
  const [places, setPlaces] = useState([])
  const [selectedSector, setSelectedSector] = useState(null)
  const [selectedSections, setSelectedSections] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])
  const [placesLoading, setPlacesLoading] = useState(false)

  const promiseId = useRef(null)

  useEffect(() => {
    if (selectedSector && user && period.startDate && period.endDate) {
      fetchPlaces(selectedSector, period, user)
    } else if (selectedSector === null) {
      setPlaces([])
    }
  }, [selectedSector, period, user, selectedSchools])

  const fetchPlaces = (sector, period, user) => {
    const id = moment().valueOf()
    promiseId.current = id

    setPlacesLoading(true)

    getPlaces(
      sector,
      user,
      {
        'start-date': period.startDate.format(API_DATE_FORMAT),
        'end-date': period.endDate.format(API_DATE_FORMAT),
        period: period.backendPeriod
      }
    ).then(json => {
      if (promiseId.current === id) {
        if (json && json.data) {
          setPlaces(json.data)
        }

        setPlacesLoading(false)
      }
    })
  }

  return (
    <AllocationContext.Provider
      value={{
        fetchPlaces: () => fetchPlaces(selectedSector, period, user),
        fetchQuotas,
        places,
        placesLoading,
        sector: selectedSector,
        sections: selectedSections,
        schools: selectedSchools,
        setFetchQuotas,
        setSchools: setSelectedSchools,
        setSections: setSelectedSections,
        setSector: setSelectedSector
      }}
    >
      {children}
    </AllocationContext.Provider>
  )
}

export default connect(mapStateToProps)(AllocationProvider)
