import React, { useEffect, useRef, useState } from 'react'

import { DatePicker, Select, Modal, Button } from 'antd'
import { validateFormInput } from '../../../utils'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { TERMINATED } from '../../../reducers/BadgesReducer'
import TableGenerator from '../../shared/TablePieces/TableGenerator'
import { AddButtonFactory, DatePickerFactory, SearchFieldFactory, SelectFactory, SettingsFactory } from '../../shared/TablePieces/TableHeadersTools/'
import { sortingKeyFactory } from '../../shared/TablePieces/FactoriesUtils'
import CustomInputDropdown from './CustomBadgesTableComponents'
import CustomInput from './CustomInput'
import moment from 'moment'
import '../../../assets/table-factory.scss'

/** ********** All the constants necessary to tableGenerator configuration **********/

const DATE_FORMAT_SHOWN = 'DD/MM/YYYY'
const MAX_STUDENTS_DISPLAYED = 10
const STUDENTS_KEYS = ['lastname', 'firstname', 'id']
const BADGES_ASSIGNATIONS_KEYS = ['studentLastName', 'studentFirstName', 'studentId']

const BADGE_NUMBER_COLUMN = {
  name: 'Badge number',
  key: 'badgeNumber',
  sortingType: 'text',
  dataType: 'freeText',
  type: 'Input',
  props: { disabled: false }
}
const RFID_NUMBER_COLUMN = {
  name: 'RFID number',
  key: 'rfidNumber',
  sortingType: 'text',
  dataType: 'freeText',
  type: 'Input',
  props: { disabled: false }
}
const START_DATE_COLUMN = {
  name: 'Start date',
  key: 'startDate',
  sortingType: 'date',
  type: 'DatePicker',
  props: {
    disabled: false,
    format: DATE_FORMAT_SHOWN
  }
}
const END_DATE_COLUMN = {
  name: 'End date',
  key: 'endDate',
  sortingType: 'date',
  type: 'DatePicker',
  props: {
    disabled: false,
    format: DATE_FORMAT_SHOWN
  }
}
const STUDENT_COLUMN = {
  name: 'Student',
  key: 'studentLastName',
  sortingType: 'text',
  type: 'CustomInputDropdown',
  props: {
    disabled: false
  }
}
const mockColumns = [BADGE_NUMBER_COLUMN, RFID_NUMBER_COLUMN, START_DATE_COLUMN, END_DATE_COLUMN, STUDENT_COLUMN]

const formatStudentData = (student, keys) => {
  let studentInfo = ''

  if (student[STUDENT_COLUMN.key] === null) {
    return studentInfo
  }

  keys.forEach(key => {
    if (key === 'studentId' || key === 'id') {
      studentInfo += ' : ' + student[key]
    } else {
      studentInfo += student[key] + ' '
    }
  })

  return studentInfo
}

const Option = Select.Option

const BadgesManager = (props) => {
  const [columns, setColumns] = useState(mockColumns)
  const [rows, setRows] = useState({})
  const [currentDate, setCurrentDate] = useState(moment())
  const [newEntity, setNewEntity] = useState()
  const [newEntityWrongCells, setNewEntityWrongCells] = useState({})
  const [wrongCells, setWrongCells] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [disableAddButton, setDisableAddButton] = useState(false)
  const [modalBody, setModalBody] = useState()
  const [filterValue, setFilterValue] = useState('')
  const [columnFilteredKey, setColumnFilteredKey] = useState(STUDENT_COLUMN.key)
  const [maxDataDisplay, setMaxDataDisplay] = useState()
  const [recoveryModalVisible, setRecoveryModalVisible] = useState(false)
  const [recoveryValue, setRecoveryValue] = useState(null)
  const [dataId, setDataId] = useState(null)

  const RECOVERY_STATUSES = [
    { key: 1, text: props.t('Lost') },
    { key: 2, text: props.t('Defect') },
    { key: 3, text: props.t('Stolen') },
    { key: 4, text: props.t('Recovered by institution') },
    { key: 0, text: props.t('Other') }
  ]
  const timer = useRef(null)

  // Table columns initialisation
  useEffect(() => {
    if (typeof columns === 'object') {
      const newColumns = columns.map(column => {
        const sortingMethodAttribution = {
          text: sortStringColumns,
          number: sortNumberColumns,
          date: sortDateColumns
        }
        const factories = {
          Input: inputFactory,
          DatePicker: datePickerFactory,
          CustomInputDropdown: CustomInputDropdownFactory
        }

        column.sortingMethod = sortingMethodAttribution[column.sortingType] ? sortingMethodAttribution[column.sortingType] : sortStringColumns
        column.typeFactory = factories[column.type]
        column.props = { ...column.props, ...dynamicProps[column.type] }

        return column
      })

      setColumns(newColumns)
    }
  }, [])

  // Fetch data from backend when display date change
  useEffect(() => {
    if (currentDate !== null && props.getInstitutions[0] !== undefined) {
      props.fetchBadgesAssignationsByDate(props.getInstitutions[0].id, currentDate.format('YYYY-MM-DD'), props.getUser)
    }
  }, [currentDate, props.getInstitutions[0]])

  // Get badgeAssignation data if they changed and launch update/create process for the changed data
  useEffect(() => {
    setRows(props.getBadgesAssignations)

    if (props.getDirtyBadgeAssignationId) {
      updateOrAddDataManager(props.getBadgesAssignations, props.getDirtyBadgeAssignationId)
      props.setDirtyBadgeAssignationId(undefined)
    }
  }, [props.getBadgesAssignations])

  // Update local data for newEntity when the store related data are modified and launch process to save new data in database
  useEffect(() => {
    setNewEntity(props.getPendingAssignation)
    if (props.getPendingAssignation) {
      updateOrAddDataManager(props.getPendingAssignation)
    }
  }, [props.getPendingAssignation])

  // Set or remove error message when local errors field state are modified
  useEffect(() => {
    if (Object.keys(newEntityWrongCells).length === 0 && Object.keys(wrongCells).length === 0) {
      setErrorMessage()
    }
  }, [newEntityWrongCells, wrongCells])

  // Set message in modal and display it when data duplication is detected
  useEffect(() => {
    let message = props.getBadgeAssignationErrorMessage
    if (message) {
      const splitMessage = message.split(' ')
      const firstname = splitMessage.pop()
      const lastname = splitMessage.pop()

      message = props.t(splitMessage.join(' ')) + ' ' + lastname + ' ' + firstname
    }
    setModalBody(message)
  }, [props.getBadgeAssignationErrorMessage])

  /** ********************** Table cells factories *************************/

  const inputFactory = (factoryProps, columnKey, dataId, lineData) => {
    const newProps = {
      ...factoryProps,
      placeholder: props.t('Empty'),
      columnKey: columnKey,
      dataId: dataId,
      getter: dataId === null ? 'getPendingAssignation' : 'getBadgesAssignations',
      setter: dataId === null ? 'setPendingAssignationData' : 'setBadgesAssignations',
      setDirty: 'setDirtyBadgeAssignationId',
      fetchRfidNumber: columnKey === BADGE_NUMBER_COLUMN.key
    }

    return (
      <CustomInput {...newProps} />
    )
  }

  const datePickerFactory = (factoryProps, columnKey, dataId, lineData) => {
    const newProps = {
      ...factoryProps,
      placeholder: 'Empty',
      columnKey: columnKey,
      dataId: dataId,
      getter: dataId === null ? 'getPendingAssignation' : 'getBadgesAssignations',
      setter: dataId === null ? 'setPendingAssignationData' : 'setBadgesAssignations',
      setDirty: 'setDirtyBadgeAssignationId',
      disabled: dataId === null && columnKey === END_DATE_COLUMN.key ? true : factoryProps.disabled
    }

    return (
      <DatePickerFactory {...newProps} />
    )
  }

  const CustomInputDropdownFactory = (factoryProps, columnKey, dataId, lineData) => {
    const newProps = {
      ...factoryProps,
      placeholder: props.t('Empty'),
      columnKey: columnKey,
      dataId: dataId,
      getter: dataId === null ? 'getPendingAssignation' : 'getBadgesAssignations',
      setter: dataId === null ? 'setPendingAssignationData' : 'setBadgesAssignations',
      setDirty: 'setDirtyBadgeAssignationId'
    }

    newProps.currentData = lineData
    newProps.dataKey = dataId

    return (
      <CustomInputDropdown {...newProps} />
    )
  }

  /** ************************** Methods factories **************************/

  /**
   * Build a new entity object
   *
   * @param {array} columns all the columns properties array
   * @returns a new entity object
   */
  const newEntityFactory = columns => {
    const newEntity = {}

    columns.forEach(column => {
      if (column.key === 'startDate') {
        newEntity[column.key] = moment()
      } else {
        newEntity[column.key] = null
      }
    })

    return newEntity
  }

  /**
   * Build the message display when no data is available for the date selected
   *
   * @returns React grappe containing the builded message
   */
  const noDataMessageFactory = () => {
    const displayCurrentDate = currentDate !== null ? currentDate.format('DD/MM/YYYY') : currentDate

    return (
      <strong className='flex-row'>
        <div className='flex-fill' />
        {props.t('No data is available for the date of ') + displayCurrentDate + '.'}
        <div className='flex-fill' />
      </strong>
    )
  }

  /**
   * Build a single error message with line sperarator from an array of messages
   *
   * @param {array} messagesArray the messages to concatenate
   * @param {string} separator     the caracteres use for separate the messages
   *
   * @returns an uniq message
   */
  const errorMessageFactory = (messagesArray, separator = '. ') => {
    let message = messagesArray.length > 1 ? props.t('Errors : ') : props.t('Error : ')
    message += '\n'

    messagesArray.forEach((error, index) => {
      if (index === messagesArray.length - 1) {
        message += error + '.'
      } else {
        message += error + separator
      }
    })

    return message
  }

  /** **************************** ON Action Methods *********************************/

  /**
   * Callback function for the creation of a new badgeAssignation in the backend
   *
   * @param {Object} data an object contains badgeAssignation entity properties
   */
  const onCreateBadgeAssignation = data => {
    setDisableAddButton(false)
    props.addBadgeAssignation(data, props.getInstitutions[0].id, props.getUser)
  }

  /**
   * Callback function to update of a badgeAssignation in the backend
   *
   * @param {Object} data an object contains badgeAssignation entity properties
   */
  const onUpdateBadgeAssignation = data => {
    props.updateBadgeAssignation(data, props.getInstitutions[0].id, props.getUser)
  }

  /**
   * Callback function to delete a badgeAssignation in the backend
   *
   * @param {integer} dataId the id of an badgeAssignation entity
   */
  const onDeleteBadgeAssignation = dataId => {
    props.deleteBadgeAssignation(dataId)
    props.removeBadgeAssignation(dataId, props.getUser, false)
  }

  /**
   * Set endDate property of a badgeAssignation to the current_date
   * If the current_date is before the displayed date, remove the badge assignation
   *  from the redux store
   * Save the modified badgeAssignation in the database
   *
   * @param {integer} dataId badgeAssignation identifier set to null if it's new Entity
   * @param recoveryValue
   */
  const onRecoverBadge = (dataId, recoveryValue) => {
    const newData = { ...props.getBadgesAssignations[dataId] }
    newData.endDate = moment()
    newData.status = TERMINATED
    newData.recoveryReason = recoveryValue
    if (currentDate > newData.endDate) {
      props.deleteBadgeAssignation(dataId)
    } else {
      props.modifyBadgeAssignation(dataId, newData)
    }

    props.updateBadgeAssignation(newData, props.getInstitutions[0].id, props.getUser, false)
    setRecoveryModalVisible(false)
    setRecoveryValue(null)
    setDataId(null)
  }

  const onRecoveryClick = dataId => {
    setDataId(dataId)
    setRecoveryModalVisible(true)
  }

  /**
   * Callback function to add a new badgeAssignation entity in the frontend table
   */
  const onDataAdd = () => {
    setDisableAddButton(true)
    props.setPendingAssignationData(newEntityFactory(columns))
  }

  /**
   * Callback function to modify the current date of search for the badgeAssignations entities
   *
   * @param {moment} date the date to pick all badgeAssignation with startDate <= date OR endDate >= date
   */
  const onDateChange = (date) => {
    setCurrentDate(date)
    setRows({})
  }

  /**
   * Callback function to modify the value use to filter badgeAssignation data in the table
   *
   * @param {string} newValue new filter value
   */
  const onFilterChange = newValue => {
    setFilterValue(newValue)
  }

  /**
   * Callback function to change the column on which the filter is performed
   *
   * @param {string} newValue a valid column name
   */
  const onColumnFilterKeyChange = newValue => {
    for (let i = 0; i < mockColumns.length; i++) {
      if (mockColumns[i].name === newValue) {
        setColumnFilteredKey(mockColumns[i].key)
        break
      }
    }
  }

  /**
   * Callback function to change the max data displayed on a table page
   *
   * @param {integer} linesValue the number of data displayed on a page
   */
  const onLineShownSelected = linesValue => {
    setMaxDataDisplay(linesValue)
  }

  /** ************** Data Verification Methods ***********************/

  /**
   * Verify if the not null constraint are respected
   *
   * @param {Object} dataSet        the badgeAssignation to test
   * @param {array} testList        the constraint array
   * @param {Object} status         an onbject with two field : message and wrongCells
   * @param {array} errorsMessages  all the errors messages already found
   *
   * @returns [status, errorsMessages]
   */
  const testIfIsNotEmpty = (dataSet, testList, status, errorsMessages) => {
    testList.forEach(condition => {
      if (dataSet[condition.key] === null || dataSet[condition.key] === '') {
        errorsMessages.push(props.t(condition.name + ' field cannot be empty'))
        status.wrongCells[condition.name] = condition.name
      }
    })

    return [status, errorsMessages]
  }

  /**
   * Verify if all the contraints on badge Assignation are respected
   * If not, it return a message and the cells that contaisn errors
   *
   * @param {Object} dataSet        the badgeAssignation to test
   * @param {Object} status         an onbject with two field : message and wrongCells
   * @param {array} errorsMessages  all the errors messages already found
   *
   * @returns [status, errorsMessages]
   */
  const dataConsistencyCheck = (dataSet, status, errorsMessages) => {
    const conditionList = [
      {
        key: BADGE_NUMBER_COLUMN.key,
        name: BADGE_NUMBER_COLUMN.name
      },
      {
        key: START_DATE_COLUMN.key,
        name: START_DATE_COLUMN.name
      },
      {
        key: STUDENT_COLUMN.key,
        name: STUDENT_COLUMN.name
      }
    ]

    if (dataSet.startDate > dataSet.endDate && dataSet.endDate !== null) {
      errorsMessages.push(props.t('Start Date field must be inferior or equal to the End Date field'))
      status.wrongCells[START_DATE_COLUMN.name] = START_DATE_COLUMN.name
      status.wrongCells[END_DATE_COLUMN.name] = END_DATE_COLUMN.name
    }

    [status, errorsMessages] = testIfIsNotEmpty(dataSet, conditionList, status, errorsMessages)
    return [status, errorsMessages]
  }

  /**
   * Verify if the input data is matching to the column type
   *
   * @param {Object} dataSet        the badgeAssignation to test
   * @param {Object} status         an onbject with two field : message and wrongCells
   * @param {array} errorsMessages  all the errors messages already found
   */
  const dataValidityCheck = (dataSet, status, errorsMessages) => {
    columns.forEach(column => {
      if (column.dataType && !validateFormInput(column.dataType, dataSet[column.key])) {
        errorsMessages.push(props.t('The column ') + column.name + props.t(' only accepts ') + props.t(column.dataType))
        status.wrongCells[column.name] = column.name
      }
    })
  }

  /**
   * Manage the data verifications
   * Launch all the verification methods for the badgeAssignation
   *
   * @param {Object} dataSet  the badgeAssignation to test
   *
   * @returns status containing an error message if errors were encountered
   */
  const dataVerificationManager = (dataSet) => {
    const status = {
      message: '',
      wrongCells: {}
    }
    const errorsMessages = []

    dataConsistencyCheck(dataSet, status, errorsMessages)
    dataValidityCheck(dataSet, status, errorsMessages)

    if (errorsMessages.length > 0) {
      status.message = errorMessageFactory(errorsMessages, '.\n')
    }

    return status
  }

  const updateOrAddDataManager = (allData, dataId = null) => {
    const dataValidation = dataVerificationManager(dataId === null ? allData : allData[dataId])
    const errorNumber = Object.keys(dataValidation.wrongCells).length

    if (errorNumber === 0) {
      if (dataId === null) {
        if (timer.current !== null) {
          clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => { onCreateBadgeAssignation(allData) }, 1000)
      } else {
        const newWrongCells = { ...wrongCells }

        if (errorNumber === 0) {
          delete newWrongCells[dataId]
        } else {
          newWrongCells[dataId] = dataValidation.wrongCells
        }

        if (timer.current !== null) {
          clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
          onUpdateBadgeAssignation(allData[dataId])
        }, 1500)

        setWrongCells(newWrongCells)
      }
    } else {
      setErrorMessage(dataValidation.message)
    }

    setNewEntityWrongCells(dataValidation.wrongCells)
  }

  /** ******************************** Helper Methods ***************************************/

  /**
 * Get the value displayed in a particular table column
 *
 * @param {Object} data      an object contains badgeAssignation entity properties
 * @param {string} columnKey a valid key of a table column
 * @returns the modified column value
 */
  const getDisplayColumnValue = (data, columnKey) => {
    switch (columnKey) {
      case STUDENT_COLUMN.key:
        return formatStudentData(data, BADGES_ASSIGNATIONS_KEYS)
      case START_DATE_COLUMN.key:
        return data[columnKey] ? data[columnKey].format('DD/MM/YYYY') : ''
      case END_DATE_COLUMN.key:
        return data[columnKey] ? data[columnKey].format('DD/MM/YYYY') : ''
      default:
        if (data[columnKey]) {
          return typeof data[columnKey] === 'string' ? data[columnKey] : data[columnKey].toString()
        } else {
          return ''
        }
    }
  }

  /**
 * Filter a set of data in order to only keep data if column value contains filter parameter value
 *
 * @param {Object} allData      the entire data of the table
 * @param {string} filter       the value that must be contains in column value
 * @param {*} columnFilteredKey a valid column key, can be found in Component constants set
 * @returns {Object} a filtered set of data, that can be inject in table
 */
  const applyFilter = (allData, filter, columnFilteredKey) => {
    if (filter === '') {
      return allData
    } else {
      const newAllData = {}

      Object.keys(allData).forEach(key => {
        if (getDisplayColumnValue(allData[key], columnFilteredKey).toLowerCase().includes(filter.toLowerCase())) {
          newAllData[key] = allData[key]
        }
      })

      return newAllData
    }
  }

  /**
 * Get a column name with the corresponding column key
 *
 * @param {array} columns the array of columns properties
 * @param {*} columnKey   a valid column key
 * @returns the column name
 */
  const getColumnNameWithKey = (columns, columnKey) => {
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].key === columnKey) {
        return columns[i].name
      }
    }
  }

  /** ************************ Sorting Methods for table columns ***************************************/

  const sortStringColumns = (keysArray, data, columnKey, reverse) => {
    reverse
      ? keysArray.sort((a, b) => {
        if (data[a][columnKey] === null && data[b][columnKey] === null) {
          return 0
        } else if (data[a][columnKey] === null) {
          return 1
        } else if (data[b][columnKey] === null) {
          return -1
        } else {
          if (data[a][columnKey].toLowerCase() > data[b][columnKey].toLowerCase()) {
            return -1
          } else if (data[a][columnKey].toLowerCase() < data[b][columnKey].toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      })
      : keysArray.sort((a, b) => {
        if (data[a][columnKey] === null && data[b][columnKey] === null) {
          return 0
        } else if (data[a][columnKey] === null) {
          return -1
        } else if (data[b][columnKey] === null) {
          return 1
        } else {
          if (data[a][columnKey].toLowerCase() > data[b][columnKey].toLowerCase()) {
            return 1
          } else if (data[a][columnKey].toLowerCase() < data[b][columnKey].toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      })

    return keysArray
  }

  const sortNumberColumns = (keysArray, data, columnKey, reverse) => {
    reverse
      ? keysArray.sort((a, b) => data[b][columnKey] - data[a][columnKey])
      : keysArray.sort((a, b) => data[a][columnKey] - data[b][columnKey])

    return keysArray
  }

  const sortDateColumns = (keysArray, data, columnKey, reverse) => {
    reverse
      ? keysArray.sort((a, b) => {
        if (data[b][columnKey] === null && data[a][columnKey] === null) {
          return 0
        } else if (data[b][columnKey] === null) {
          return -1
        } else if (data[a][columnKey] === null) {
          return 1
        } else {
          return data[b][columnKey].format('YYYYMMDD') - data[a][columnKey].format('YYYYMMDD')
        }
      })
      : keysArray.sort((a, b) => {
        if (data[b][columnKey] === null && data[a][columnKey] === null) {
          return 0
        } else if (data[b][columnKey] === null) {
          return 1
        } else if (data[a][columnKey] === null) {
          return -1
        } else {
          return data[a][columnKey].format('YYYYMMDD') - data[b][columnKey].format('YYYYMMDD')
        }
      })

    return keysArray
  }

  /** ******************* Additional parameters for table cells and columns ************************/

  const dynamicProps = {
    CustomInputDropdown: {
      currentDataKeys: BADGES_ASSIGNATIONS_KEYS,
      allDataKeys: STUDENTS_KEYS,
      idIdentifier: 'id',
      maxDataDisplay: MAX_STUDENTS_DISPLAYED + 1,
      searchMessage: props.t('Search student'),
      formatCurrentData: formatStudentData
    }
  }

  const optionalButtons = [
    {
      factory: 'OptionButtonFactory',
      props: {
        iconName: 'id-badge',
        addTooltip: true,
        title: props.t('Recover this badge ?'),
        mouseLeaveDelay: 0.001,
        addPopconfirm: true,
        callBack: onRecoveryClick
      }
    },
    {
      factory: 'OptionButtonFactory',
      props: {
        iconName: 'trash',
        type: 'danger',
        addTooltip: true,
        addPopconfirm: true,
        title: props.t('Delete this assignation ?'),
        mouseLeaveDelay: 0.001,
        callBack: onDeleteBadgeAssignation
      }
    }
  ]

  return (
    <div className='smart-table'>
      <div className='table-options flex-row'>
        <SearchFieldFactory
          updateFilter={onFilterChange}
          placeholder={props.t('Filter on ') + props.t(getColumnNameWithKey(columns, columnFilteredKey))}
        />
        <div className='h-spacing' />
        <SelectFactory
          data={columns}
          dataKey='name'
          defaultColumn='Student'
          onChange={onColumnFilterKeyChange}
        />
        <div className='h-spacing' />
        <SettingsFactory
          noDensitySelector
          noColumnHider
          onLineShownSelected={onLineShownSelected}
        />
        <div className='flex-fill' />
        <AddButtonFactory
          addFunction={onDataAdd}
          addText='Add'
          disabled={disableAddButton}
        />
        <div className='h-spacing' />
        <DatePicker
          onChange={onDateChange}
          value={currentDate}
        />
      </div>
      <div className='error-message text-breakline'>
        {errorMessage}
      </div>
      <TableGenerator
        columns={columns}
        rows={rows}
        newLineWrongCells={newEntityWrongCells}
        wrongCells={wrongCells}
        newEntity={newEntity}
        optionalButtons={optionalButtons}
        maxDataDisplay={maxDataDisplay}
        filterValue={filterValue}
        columnFilteredKey={columnFilteredKey}
        customNoDataMessage={noDataMessageFactory()}
        initialSortingKey={sortingKeyFactory(BADGE_NUMBER_COLUMN.key, sortStringColumns)}
        cleanTimeOut={() => clearTimeout(timer.current)}
        dataFilter={applyFilter}
      />
      <Modal
        title={props.t('Badge already owned')}
        visible={!!modalBody}
        footer={
          <Button
            onClick={() => props.setBadgeAssignationErrorMessage()}
            type='primary'
          > OK
          </Button>
        }
      >
        {props.t(modalBody || '')}
      </Modal>
      <Modal
        visible={recoveryModalVisible}
        title={props.t('Why do you want to recover this badge?')}
        onCancel={() => setRecoveryModalVisible(false)}
        footer={
          <div>
            <Button
              onClick={() => setRecoveryModalVisible(false)}
              type='secondary'
            > {props.t('Cancel')}
            </Button>
            <Button
              onClick={() => onRecoverBadge(dataId, recoveryValue)}
              type='primary'
            > OK
            </Button>
          </div>
        }
      >
        <Select style={{ width: '100%' }} onChange={(value) => setRecoveryValue(value)}>
          {RECOVERY_STATUSES.map(s => {
            return (
              <Option key={s.key} value={s.key}>{props.t(s.text)}</Option>
            )
          })}
        </Select>
      </Modal>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadgesManager)
