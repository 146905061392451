export const initTutorial = (tutorial, steps) => {
  const tourStep = []

  steps.forEach(s => {
    s.tutorialStepDatas.forEach(tsd => {
      const step = {
        title: tsd.title,
        target: s.target,
        disableBeacon: s.disableBeacon,
        placement: s.placement,
        spotlightClicks: s.spotlightClicks,
        type: tsd.type
      }

      tsd.tutorialStepProps.forEach(tsp => {
        step[tsp.name] = tsp.value
      })

      tourStep.push(step)
    })
  })

  return {
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: tourStep,
    disableCloseOnEsc: tutorial.disableCloseOnEsc,
    disableOverlayClose: tutorial.disableOverlayClose,
    showProgress: tutorial.showProgress,
    name: tutorial.name,
    link: tutorial.link
  }
}
