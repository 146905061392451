import { requestWithPromise } from '..'
import { GET } from '../apiHelper'

export const getConstants = user => {
  return requestWithPromise(
    '/global/constants',
    GET,
    null,
    user
  )
}
