import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'
import { getIconByName } from '../../../../utils/fontAwesomeHelper'
import { getTresholdByPlaces } from '../constants'
import { getCellStyleByTreshold } from '../Other/utils'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const PausesHeaderCell = props => {
  const { pauses } = useContext(GlobalContext)

  const renderPausesDetails = () => {
    const details = props.details
    const shiftsDetails = props.shiftsDetails
    const body = []

    if (details) {
      Object.keys(details).forEach(pauseId => {
        const pause = pauses.find(pause => pause.id === parseInt(pauseId))

        if (pause) {
          const { id, icon } = pause
          const totalPlaces = details[id] ?? 0
          const takenPlaces = shiftsDetails[id] ?? 0

          body.push(
            <Row key={`row-section-${id}`} style={getCellStyleByTreshold(getTresholdByPlaces(totalPlaces - takenPlaces))}>
              <Col span={6}>
                <FontAwesomeIcon icon={getIconByName(icon)} />
              </Col>
              <Col span={18}>
                {getRatioText(totalPlaces, takenPlaces)}
              </Col>
            </Row>
          )
        }
      })
    }

    return body
  }

  const getRatioText = (total, taken) => {
    return `${total - taken}/${total}`
  }

  const renderPauses = () => {
    const totalPlaces = props.total ?? 0
    const takenPlaces = props.shiftsTotal ?? 0

    return (
      <div className='pause-quotas-header'>
        <Row>
          <Col span={10} className='total-column'>
            <div className='total-column-container' style={getCellStyleByTreshold(getTresholdByPlaces(totalPlaces - takenPlaces))}>
              {getRatioText(totalPlaces, takenPlaces)}
            </div>
          </Col>
          <Col span={14}>
            {renderPausesDetails()}
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <th
      className='pause-quotas-th'
      onClick={props.onClick}
      style={{ minWidth: '110px', height: '16vh' }}
    >
      {props.name}
      <br />
      {props.date}
      <br />
      {renderPauses()}
    </th>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PausesHeaderCell)
