import { requestWithPromise } from '..'
import { GET, POST } from '../apiHelper'

export const getEvaluationQuestions = (user, institution) => {
  return requestWithPromise(
      `/evaluation/questions/${institution.id}`,
      GET,
      null,
      user
  )
}

export const saveEvaluationByStudent = (user, data) => {
  return requestWithPromise(
    '/evaluation/internship/by-student',
    POST,
    data,
    user
  )
}
