import React from 'react'

const Switch = ({ children, on }) => {
  return (
    <>
      {on && children}
    </>
  )
}

export default Switch
