import React, { useState, useEffect, useContext } from 'react'

import { Select } from 'antd'
import { GlobalContext } from '../../../../Providers/GlobalProvider'

const Option = Select.Option
const STYLE = {
  width: '100%'
}

const SchoolYearsSelect = ({ data, disabled, valueKey, onBlur, onChange, onFocus }) => {
  const { sectionToYearsBySection } = useContext(GlobalContext)

  const [displayValue, setDisplayValue] = useState(null)

  useEffect(() => {
    if (data && valueKey && data[valueKey]) {
      setDisplayValue(null)
    }
  }, [data, valueKey])

  const handleChange = (value, Option) => {
    const newData = { ...data }
    const currentSchoolYear = sectionToYearsBySection[newData.sectionId].find(year => year.id === value)

    newData[valueKey] = value
    newData.schoolYearName = currentSchoolYear.schoolYearName

    onChange(newData)
    setDisplayValue(value)
  }

  const renderOptions = () => {
    const schoolYears = sectionToYearsBySection[data.sectionId] ?? []

    return schoolYears.map(year => {
      const newOption = (
        <Option
          key={'option' + year.id}
          value={year.id}
        >
          {year.schoolYearName}
        </Option>
      )

      if (year.id === data[valueKey] && displayValue === null) {
        setDisplayValue(year.id)
      }

      return newOption
    })
  }

  return data[valueKey] !== undefined
    ? (
      <Select
        onChange={handleChange}
        value={displayValue}
        disabled={disabled}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        style={STYLE}
        className='tutoriel-select-school'
      >
        {renderOptions()}
      </Select>
    )
    : (<div />)
}

export default SchoolYearsSelect
