import React from 'react'
import { Drawer } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import Users from '../school/Users'

const SchoolUsers = props => {
  return (
    <Drawer
      title={props.school !== null ? props.school.name : ''}
      width='80%'
      onClose={props.onClose}
      visible={props.school !== null}
    >
      {props.school && <Users schoolId={props.school.id} enableDelete />}
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolUsers)
