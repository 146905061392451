import { Switch } from 'antd'
import React from 'react'
import IconTooltip from '../../institution/Quotas/definition/IconTooltip'
import './assets/hinted-switch.scss'

const HintedSwitch = props => {
  const tooltipTitle = (title, checked) => {
    if (typeof title === 'object') {
      return checked ? title.yes : title.no
    }

    return title
  }

  return (
    <div className={`hinted-switch ${props.className ?? ''}`}>
      <div className='switch-div'>
        <Switch
          onChange={props.onChange}
          checked={props.checked}
          size={typeof props.size !== 'undefined' ? props.size : 'default'}
        />
      </div>
      <div className='icon-tooltip-div'>
        <IconTooltip
          title={tooltipTitle(props.tooltipTitle, props.checked)}
        />
      </div>
    </div>
  )
}

export default HintedSwitch
