import moment from 'moment'
import { DATE_WITHOUT_TIME, SHORT_EUROPEAN_DATE } from './constants'
import { MONTH_STRING_FORMAT } from './momentjs'

export const YEAR_TYPE = 'YEAR'
export const MONTH_TYPE = 'MONTH'
export const WEEK_TYPE = 'WEEK'
export const DAY_TYPE = 'DAY'

export const TIME_PERIODS_TYPE = [
  {
    text: 'Year',
    type: YEAR_TYPE
  },
  {
    text: '2 months',
    type: MONTH_TYPE,
    quantity: 2
  },
  {
    text: 'Month',
    type: MONTH_TYPE
  }
]

export const getTimePeriod = (date, periodType) => {
  let quantity = (periodType.quantity ?? 1)
  let type = 'week'

  if (periodType.type === YEAR_TYPE) {
    quantity = quantity * 12
    type = 'month'
  } else if (periodType.type === MONTH_TYPE) {
    quantity = quantity * 4
    type = 'week'
  } else if (periodType.type === WEEK_TYPE) {
    quantity = quantity * 7
    type = 'day'
  }

  return new Period(date, quantity, type)
}

export const getNewDateByPeriod = (date, periodType, remove = false) => {
  const newDate = date.clone()
  const { type, quantity } = getDisplacementByPeriodType(periodType, remove)

  newDate.add(quantity, type)

  if (periodType.type === YEAR_TYPE) {
    newDate.startOf('month')
  }

  return newDate
}

export const getDisplacementByPeriodType = (periodType, remove = false) => {
  const displacement = {
    type: 'week',
    quantity: 1
  }

  if (periodType.type === YEAR_TYPE) {
    displacement.type = 'month'
  }

  if (remove) {
    displacement.quantity = 0 - displacement.quantity
  }

  return displacement
}

export const getStartDateByType = (date, type) => {
  const dateString = date.format(DATE_WITHOUT_TIME)
  let startDate

  if (type === 'month' || type === 'week') {
    startDate = moment(dateString).startOf(type)
  } else {
    startDate = moment(dateString)
  }

  return startDate
}

export const getEndDateByType = (date, type) => {
  const dateString = date.format(DATE_WITHOUT_TIME)
  let endDate

  if (type === 'month' || type === 'week') {
    endDate = moment(dateString).endOf(type)
  } else {
    endDate = moment(dateString)
  }

  return endDate
}

export const getColumnsByPeriod = period => {
  const columns = []

  for (let i = 0; i < period.quantity; i++) {
    const startDate = period.startDate.clone().add(i, period.type)

    columns.push({
      title: period.title(startDate),
      date: startDate
    })
  }

  return columns
}

export const getFormatByPeriod = period => {

}

export const getIndexByPeriodAndDate = (date, period) => {
  let index = 0

  if (date > period.endDate) {
    index = period.length
  } else if (date > period.startDate) {
    index = date.diff(period.startDate, period.type)
  }

  return index
}

export class Period {
  constructor (date, quantity, type) {
    this.type = type
    this.quantity = quantity
    this.startDate = date.clone().startOf(type)
    this.endDate = this.startDate.clone().add(quantity - 1, type).endOf(type)
  }

  title (date = null) {
    const startDate = (date ?? this.startDate)
    let title = ''

    if (this.type === 'month') {
      title = startDate.format(MONTH_STRING_FORMAT)
    } else if (this.type === 'week') {
      title = startDate.format(SHORT_EUROPEAN_DATE)
    }

    return title
  }

  get backendPeriod () {
    return 'P1' + this.type.charAt(0).toUpperCase()
  }

  get length () {
    return this.endDate.diff(this.startDate, this.type)
  }
}
