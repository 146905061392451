import { getIdOrNull, validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'

export const ACT_TYPE_CATEGORY_VALIDATORS = [
  { key: VALIDATION_FIELDS.NAME, validator: s => validateString(s.name, { notEmpty: true }) }
]

export const getName = a => {
  return a.name
}

export class ActTypeCategory {
  constructor ({ id = -1, name = '', school = {} } = {}) {
    this.id = id
    this.name = name
    this.school = school
  }

  toRequestBody = () => {
    const body = { ...this }

    body.school = getIdOrNull(this.school)

    return body
  }
}
