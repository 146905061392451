import { remove } from 'lodash'
import { canonizeString } from '../../utils'
import { STUDENTS } from './actionsType'

const {
  SET_STUDENTS, ADD_STUDENT, UPDATE_STUDENT,
  REMOVE_STUDENT
} = STUDENTS

const INITIAL_STATE = {
  properties: [],
  students: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_STUDENT:
      return { ...state, students: handleAddStudent({ state: state.students, student: action.student }) }
    case REMOVE_STUDENT:
      return { ...state, students: handleStudentRemoval({ ...action.payload, state: state.students }) }
    case SET_STUDENTS:
      return { ...state, students: action.students }
    case UPDATE_STUDENT:
      return { ...state, students: handleEditStudent({ state: state.students, student: action.student }) }
    default:
      return state
  }
}

const handleAddStudent = ({ state, student }) => {
  let studentsList = [...state, student]

  studentsList = studentsList.sort((a, b) =>
    canonizeString(a.lastname + a.firstname).localeCompare(
      canonizeString(b.lastname + b.firstname)
    )
  )
  return studentsList
}

const handleEditStudent = ({ state, student }) => {
  return state.map(item => {
    if (item.id === student.id) {
      return { ...student }
    }
    return item
  })
}

const handleStudentRemoval = ({ state, studentId }) => {
  const studentsList = state.slice()
  remove(studentsList, item => item.id === studentId)
  return studentsList
}
