const isFunction = subject => {
  return typeof subject === 'function'
}

const isUndefined = subject => {
  return typeof subject === 'undefined'
}

const isCustomTime = time => {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
}

export {
  isFunction,
  isUndefined,
  isCustomTime
}
