import React, { useEffect, useMemo, useState } from 'react'

import { Tabs } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import SchoolSectionsTable from './SchoolSectionsTable'
import Analytics from '../../../utils/analytics'
import ActsTableView from './ActsTableView/ActsTableView'
import { getUser } from '../../../reducers/UserReducer'

const { TabPane } = Tabs

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const SchoolSectionsView = ({ user, t }) => {
  const [activeKey, setActiveKey] = useState('1')

  const managed = useMemo(() => !!user.school?.managed, [user.school])

  useEffect(() => {
    Analytics.pageView('/school-sections')
  }, [])

  return (
    <Tabs animated={false} activeKey={activeKey} onChange={setActiveKey}>
      <TabPane key='1' tab={t('school_sections_view.tab_1.title')}>
        <SchoolSectionsTable managed={managed} onActEdition={() => setActiveKey('2')} />
      </TabPane>
      {true && (
        <TabPane key='2' tab={t('school_sections_view.tab_2.title')}>
          <ActsTableView />
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(SchoolSectionsView)
