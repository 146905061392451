import React, { Component } from 'react'

class Theme extends Component {
  componentDidMount () {
    this.updateCSS(this.props.variables)
  }

  componentDidUpdate (prevProps) {
    if (this.props.variables !== prevProps.variables) {
      this.updateCSS(this.props.variables)
    }
  }

  updateCSS (variables) {
    Object.entries(variables).map(v => {
      document.documentElement.style.setProperty(v[0], v[1])
      return true
    })
  }

  render () {
    return <div>{this.props.children}</div>
  }
}

export default Theme
