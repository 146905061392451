import React, { useMemo } from 'react'

import TooltipIconButton from '../shared/TooltipIconButton'
import QuickDualDatePickers from '../shared/QuickDualDatePickers'
import { Card, Col, Row } from 'antd'
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons'
import HintedSwitch from '../../../antd/switch/HintedSwitch'
import IconTooltip from './IconTooltip'
import { setPauseActivationState } from '../../../../reducers/AppStates/actions'
import { bindActionCreators } from 'redux'
import { isFunction } from 'lodash'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const PLACES_TEXT = 'Available places'
const DEFAULT_PLUS_BUTTON_TEXT = 'Allocate places'
const PAUSE_PLUS_BUTTON_TEXT = 'Allocate places to pause'
const DETAILS_BUTTON_TEXT = 'Display details of global places'
const TOOLTIP_TEXT = 'Attribute places in function of pauses'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const mapDispatchToProps = dispatch => ({ setPauseActivationState: bindActionCreators(setPauseActivationState, dispatch) })

const GlobalQuotaCard = ({
  allocateButtonDisable, endDate, institutionOptions, pauseActivated, pauseUsed, remaining, startDate, total, warning, warningMessage, onPlacesAllocated,
  onGlobalPlacesDisplayed, setPauseActivationState, t
}) => {
  const handlePlacesAllocation = () => {
    if (isFunction(onPlacesAllocated)) {
      onPlacesAllocated()
    }
  }

  const handleGlobalPlacesDisplaying = () => {
    if (isFunction(onGlobalPlacesDisplayed)) {
      onGlobalPlacesDisplayed()
    }
  }

  const cardActions = useMemo(() => {
    const actions = [
      <TooltipIconButton
        key='card-allocate-button'
        title={pauseActivated ? PAUSE_PLUS_BUTTON_TEXT : DEFAULT_PLUS_BUTTON_TEXT}
        onClick={handlePlacesAllocation}
        disabled={allocateButtonDisable}
        icon={faPlus}
      />,
      <TooltipIconButton
        key='card-display-button'
        title={DETAILS_BUTTON_TEXT}
        onClick={handleGlobalPlacesDisplaying}
        icon={faFile}
      />
    ]

    if (pauseUsed) {
      actions.push(
        <HintedSwitch
          key='card-pause-switch'
          onChange={setPauseActivationState}
          checked={pauseActivated}
          tooltipTitle={TOOLTIP_TEXT}
        />
      )
    }

    return actions
  }, [pauseUsed, handlePlacesAllocation, handleGlobalPlacesDisplaying, setPauseActivationState])

  const generateTotalPlaces = () => {
    let places = ''

    if (typeof total === 'number' && typeof remaining === 'number') {
      places = `${remaining} / ${total}`
    }

    return places
  }

  return (
    <Card style={{ width: '100%' }} actions={cardActions}>
      <div className='allocation-quota-card-body'>
        <QuickDualDatePickers
          startDate={startDate}
          endDate={endDate}
          disabled
        />
        <Row>
          <Col span={12}>
            <h4>
              <b>
                {t(PLACES_TEXT)}
              </b>
            </h4>
          </Col>
          <Col span={12}>
            <b className={warning ? 'tx-orange' : ''}>{generateTotalPlaces()}</b>
            &nbsp;
            {warning && (<IconTooltip iconColor='orange' title={warningMessage} />)}
          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalQuotaCard)
