import React, { useEffect, useMemo, useState } from 'react'

import FreeFieldDisplay from './FreeFielDisplay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { addNewStudentFreeField, fetchInstitutionFieldsTypes, getInstititionFieldsTypes, getStudentFreeFields } from '../../../reducers/FreeFieldsReducer'
import { connect } from 'react-redux'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { bindActionCreators } from 'redux'
import { ROLE_OBSERVER } from '../../../utils/constants'

import '../../../assets/smart-table.scss'

const STANDARD_ERROR_MESSAGE = 'There is no Free Fields linked to this student'
const NO_FREE_FIELDS_ERROR_MESSAGE = "Your institution doesn't have Free Fields"
const ADDING_BUTTON_LABEL = 'Add Free Field'

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  fieldTypes: getInstititionFieldsTypes(state.getFreeFields),
  t: getTranslate(state.locale),
  studentFreeFields: getStudentFreeFields(state.getFreeFields),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  fetchInstitutionFieldsTypes: bindActionCreators(fetchInstitutionFieldsTypes, dispatch),
  onCreate: bindActionCreators(addNewStudentFreeField, dispatch)
})

const FreeFields = ({ activeLanguage, fieldTypes, studentFreeFields, studentId, user, fetchInstitutionFieldsTypes, onCreate, t }) => {
  const [errorMessage, setErrorMessage] = useState(STANDARD_ERROR_MESSAGE)

  const isObserver = useMemo(() => user.roles.includes(ROLE_OBSERVER), [user])

  useEffect(() => {
    fetchInstitutionFieldsTypes(user)

    setErrorMessage(STANDARD_ERROR_MESSAGE)
  }, [Object.keys(fieldTypes === undefined ? {} : fieldTypes).length])

  const create = () => {
    if (Object.keys(fieldTypes).length === 0) {
      setErrorMessage(NO_FREE_FIELDS_ERROR_MESSAGE)
    } else {
      onCreate(studentId, fieldTypes[getAvailableFreeFieldsType()[0]], '', user, activeLanguage, t)
    }
  }

  const getAvailableFreeFieldsType = () => {
    const unavailableFreeFieldsTypes = Object.keys(studentFreeFields).map(key => {
      return studentFreeFields[key].freeFieldsTypeId
    })
    const availableInstitutionFreeFields = Object.keys(fieldTypes).filter(key => {
      return !unavailableFreeFieldsTypes.includes(fieldTypes[key].id) && fieldTypes[key].isEditable
    })

    return availableInstitutionFreeFields
  }

  const renderFreeFields = () => {
    const orderedStudentFreeFieldsKeys = Object.keys(studentFreeFields)

    orderedStudentFreeFieldsKeys.sort((a, b) => { return studentFreeFields[a].freeFieldsLabel.localeCompare(studentFreeFields[b].freeFieldsLabel) })

    return orderedStudentFreeFieldsKeys.map(key => (<FreeFieldDisplay key={key} id={key} disabled={isObserver} />))
  }

  return (
    <div className='flex-column smart-table'>
      <div className='flex-row'>
        <h3> Free Fields </h3>
        <div className='flex-fill' />
        <Button
          type='primary'
          onClick={create}
          disabled={fieldTypes === undefined || getAvailableFreeFieldsType().length === 0 || isObserver}
        >
          <FontAwesomeIcon key='button' icon='plus' />
          &nbsp;
          {t(ADDING_BUTTON_LABEL)}
        </Button>
      </div>
      {Object.keys(studentFreeFields).length === 0 && (
        <div> {t(errorMessage)} </div>
      )}
      {Object.keys(studentFreeFields).length !== 0 && (
        <table>
          <tbody>
            {renderFreeFields()}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeFields)
