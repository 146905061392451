import { INSTITUTION_OPTIONS } from './actionsType'

const {
  SET_INSTITUTION_OPTIONS, UPDATE_INSTITUTION_OPTIONS
} = INSTITUTION_OPTIONS

const INITIAL_STATE = {
  options: [],
  loaded: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INSTITUTION_OPTIONS: {
      const newState = { ...state }
      const options = action.payload

      newState.options = options
      newState.loaded = true

      return newState
    }
    case UPDATE_INSTITUTION_OPTIONS: {
      let newOptions = [...state.options]

      for (const optionToUpdate of action.payload) {
        newOptions = newOptions.map(option => {
          if (option.id === optionToUpdate.id) {
            return optionToUpdate
          }

          return option
        })
      }

      return { ...state, options: newOptions, loaded: true }
    }
    default:
      return state
  }
}
