const NEGATIVE_FILTER = 'RED_FACE'
const NEUTRAL_FILTER = 'ORANGE_FACE'
const POSITIVE_FILTER = 'GREEN_FACE'

const applyFilters = (smileyFilters, evaluatedStudentsNonFiltered) => {
  const filters = []

  smileyFilters.forEach(sr => {
    if (sr.id === 'institutionEvaluationSatisfactionRatio' && sr.values.length > 0) {
      filters.push({
        range: getFilterRanges(sr.values),
        property: 'institutionEvaluationSatisfactionRatio'
      })
    } else if (sr.id === 'studentEvaluationSatisfactionRatio' && sr.values.length > 0) {
      filters.push({
        range: getFilterRanges(sr.values),
        property: 'studentEvaluationSatisfactionRatio'
      })
    } else if (sr.id === 'matchScore' && sr.values.length > 0) {
      filters.push({
        range: getFilterRanges(sr.values),
        property: 'matchScore'
      })
    }
  })

  return evaluatedStudentsNonFiltered.filter(item => checkMultipleFilters(item, filters))
}

const getFilterRanges = (filters) => {
  const ranges = []

  filters.forEach(v => {
    if (v === NEGATIVE_FILTER) {
      ranges.push([0, 39])
    } else if (v === NEUTRAL_FILTER) {
      ranges.push([40, 79])
    } else if (v === POSITIVE_FILTER) {
      ranges.push([80, 100])
    }
  })

  return ranges
}

const checkMultipleFilters = (item, filters) => {
  const filterStatus = []

  filters.forEach(f => {
    filterStatus.push(checkFilterCondition(item, f.property, f.range))
  })

  let isValid = true

  filterStatus.forEach(fs => {
    isValid = isValid && fs
  })

  return isValid
}

const checkFilterCondition = (item, propertyName, filterRange) => {
  let isValid = false
  const value = getValue(item, propertyName)

  filterRange.forEach(fr => {
    if (value !== null && value >= fr[0] && value <= fr[1]) {
      isValid = true
    }
  })

  return isValid
}

const getValue = (item, propertyName) => {
  let value = null

  if (propertyName === 'studentEvaluationSatisfactionRatio') {
    value = item.didInstitutionEvaluate ? item[propertyName] : null
  } else if (propertyName === 'institutionEvaluationSatisfactionRatio') {
    value = item.didStudentEvaluate ? item[propertyName] : null
  } else if (propertyName === 'matchScore') {
    value = item[propertyName]
  }

  return value
}

export { applyFilters }
