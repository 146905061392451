import React from 'react'
import { Select, Tooltip } from 'antd'
import { TOOLTIP_TOP } from './Constants'
import { isObject } from '../../../../utils'

const Option = Select.Option

const stringOptionFilter = (input, option) => {
  const value = option.props.value

  return value.toLowerCase().includes(input.toLowerCase())
}

const customStringOptionFilter = (input, option, key) => {
  let text = null

  if (isObject(option.props.data)) {
    const value = option.props.data[key]

    if (value) {
      text = value
    }
  } else if (typeof option.props.label === 'string') {
    text = option.props.label
  }

  return text !== null && text.toLowerCase().includes(input.toLowerCase())
}

const renderSelectOptions = (data, dataKey, parameters) => {
  if (!data || !dataKey) {
    return []
  }

  const { tooltipPlacement, globalKey } = parameters ?? buildOptionsParameters()
  const options = []

  if (globalKey) {
    const { value, data, title } = globalKey

    options.push(
      <Option key='option_default' value={value} data={data}>
        <Tooltip placement={tooltipPlacement ?? TOOLTIP_TOP} title={title}>
          <b>{value}</b>
        </Tooltip>
      </Option>
    )
  }

  data.forEach((option, index) => {
    const value = option[dataKey]

    options.push(
      <Option key={`option_${index}`} value={value} data={option}>
        <Tooltip placement={tooltipPlacement ?? TOOLTIP_TOP} title={value}>
          {value}
        </Tooltip>
      </Option>
    )
  })

  return options
}

const buildOptionsParameters = (tooltipPlacement = null, globalKey = null) => {
  return {
    tooltipPlacement,
    globalKey
  }
}

const getOptionGlobalKey = (data, value, title) => {
  return { data, value, title }
}

export {
  stringOptionFilter,
  customStringOptionFilter,
  renderSelectOptions,
  buildOptionsParameters,
  getOptionGlobalKey
}
