import React, { useContext, useEffect, useMemo, useState } from 'react'

import ShiftsExportOptions from '../ShiftsExportOptions'
import { isFunction } from '../../../../utils/typeTesting'
import { Button, Popover, Input, Switch, Dropdown, Menu, Tooltip, Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleDown,
  faBars, faCog,
  faFile,
  faFileExport, faFont,
  faPaperPlane,
  faRecycle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { INSTITUTION_CONTEXT, QUOTA_PER_PAUSE } from '../../../../utils/constants'
import StandardSelect, { STRING } from '../../../antd/Selects/StandardSelect'
import { isObject } from '../../../../utils'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { getInstitutionOptions } from '../../../../reducers/InstitutionOptionsReducer/actions'
import { connect } from 'react-redux'
import { getSelectedInstitution, getSelectedSector, setSelectedInstitution, setSelectedSector } from '../../../../reducers/ShiftsManagerReducer/actions'
import { bindActionCreators } from 'redux'
import { getInstitutionSectors } from '../../../../utils/api/institution'
import { SchoolContext } from '../../../../Providers/SchoolProvider'
import { COLORS_PALETTE } from '../../../../config/colors'

const Search = Input.Search
const SEARCH_WIDTH = 175

const SECTOR_KEY = 'name'
const INSTITUTION_KEY = 'name'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    institutionOptions: getInstitutionOptions(state.institutionOptions),
    selectedInstitution: getSelectedInstitution(state),
    selectedSector: getSelectedSector(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedInstitution: bindActionCreators(setSelectedInstitution, dispatch),
    setSelectedSector: bindActionCreators(setSelectedSector, dispatch)
  }
}

const ActionsFieldsAndButtons = ({
  checkedInternships, disabled, exportLoading, institutionOptions, isReadOnly, limitedActions, multiSelectionMode, showTopTransitions,
  selectedInstitution, selectedSector, useDraft, user, displayManagePresets, onCancelSchedule, onDeleteSchedules, onDraftChange, onDraftSchedules,
  onExport, onFilterChange, onPublishSchedules, onResetSelection, setSelectedInstitution, setSelectedSector, toggleMultiSelect, t, setFontSizes
}) => {
  const { useDraftInternships, institutions } = useContext(SchoolContext)

  const [sectors, setSectors] = useState([])

  const usePausesModule = useMemo(() => {
    let use = false

    for (const io of institutionOptions) {
      if (io.optionType.type === QUOTA_PER_PAUSE) {
        use = io.optionEnabled
        break
      }
    }

    return use
  }, [institutionOptions])
  const isHospitalUser = useMemo(() => user.context === INSTITUTION_CONTEXT, [user.roles])

  useEffect(() => {
    if (selectedInstitution && selectedInstitution.id && user) {
      getInstitutionSectors(user, selectedInstitution).then(json => {
        if (json?.data) {
          setSectors(json.data)
        }
      })
    }
  }, [selectedInstitution, user, getInstitutionSectors])

  const handleFilterChange = filterValue => {
    onFilterChange(filterValue.trim())
  }

  const handleToggleMultiSelect = () => {
    toggleMultiSelect()
  }

  const handleManagePresetDisplaying = () => {
    if (isFunction(displayManagePresets)) {
      displayManagePresets()
    }
  }

  const handleInstitutionSelected = institution => {
    if (isObject(selectedSector) && selectedSector.id) {
      setSelectedSector({})
    }

    if (sectors.length > 0) {
      setSectors([])
    }

    setSelectedInstitution(institution)
  }

  const renderLegendWrapper = () => {
    let body = []

    if (!limitedActions) {
      body.push(
        <Button
          key='multi-selection-activator'
          ghost={!!multiSelectionMode}
          type={multiSelectionMode ? 'danger' : 'default'}
          onClick={handleToggleMultiSelect}
        >
          <FontAwesomeIcon icon={faBars} /> &nbsp; {t(multiSelectionMode ? 'Exit selection mode' : 'Select shifts')}
        </Button>
      )
    }

    if (isHospitalUser && !isReadOnly) {
      body.push(
        <Button
          key='presets-manager'
          type='default'
          onClick={handleManagePresetDisplaying}
          style={{ marginLeft: '10px' }}
        >
          <FontAwesomeIcon icon={faClock} />
            &nbsp;
          {t('Manage presets')}
        </Button>
      )
    }

    if (usePausesModule || !isHospitalUser) {
      body = body.concat(renderPausesModuleOptions())
    }

    return (
      <div className='legend-wrapper'>
        {body}
      </div>
    )
  }

  const renderPausesModuleOptions = () => {
    const body = []

    if (isHospitalUser === false) {
      body.push(
        <StandardSelect
          key='pauses-institutions-selector'
          showSearch
          emptyOption
          data={institutions}
          dataKey={INSTITUTION_KEY}
          dataType={STRING}
          nullData={{}}
          disabled={disabled}
          value={selectedInstitution && selectedInstitution[INSTITUTION_KEY] ? selectedInstitution[INSTITUTION_KEY] : '/'}
          onSelect={handleInstitutionSelected}
          style={{ width: '175px', marginLeft: '10px' }}
        />
      )
    }

    if (sectors.length > 0) {
      body.push(
        <StandardSelect
          key='pauses-sectors-selector'
          showSearch
          emptyOption
          data={sectors}
          dataKey={SECTOR_KEY}
          dataType={STRING}
          nullData={{}}
          disabled={disabled}
          value={selectedSector && selectedSector[SECTOR_KEY] ? selectedSector[SECTOR_KEY] : '/'}
          onSelect={setSelectedSector}
          style={{ width: '175px', marginLeft: '10px' }}
        />
      )
    }

    return body
  }

  const renderExportAndDatesButtons = () => {
    return (
      <div className={`export-buttons ${showTopTransitions ? 'with-transition' : ''}`}>
        <div className='h-spacing' />
        <Popover
          placement='left'
          title={(
            <div className='shifts-export-title'>
              <FontAwesomeIcon icon={faFileExport} />
              &nbsp;
              {t('Export')}
            </div>
          )}
          content={
            <ShiftsExportOptions onExportOptionSelected={onExport} />
          }
        >
          <Button
            type='default'
            loading={exportLoading}
          >
            <FontAwesomeIcon icon={faFileExport} />
            &nbsp;
            {t('Export')}
          </Button>
        </Popover>
      </div>
    )
  }

  const handleSelectAction = ({ key }) => {
    if (key === '1') {
      onPublishSchedules()
    } else if (key === '2') {
      onDraftSchedules()
    } else if (key === '3') {
      onResetSelection()
    } else if (key === '4') {
      onDeleteSchedules()
    }
  }

  return (
    <div className='flex-row'>
      <Search
        placeholder={t('Filter internships')}
        style={{ width: SEARCH_WIDTH }}
        className='app-tour-2'
        onKeyUp={e => handleFilterChange(e.target.value)}
        onPaste={e => handleFilterChange(e.clipboardData.getData('Text'))}
      />
      <div className='h-spacing' />
      {renderLegendWrapper()}
      {useDraftInternships && (
        <>
          <div className='h-spacing' />
          <div
            className='simili-button'
            onClick={() => onDraftChange(!useDraft)}
          >
            {t('shifts_manager_header.draft_mode_toggle')} &nbsp;
            <Switch checked={useDraft} />
          </div>
        </>
      )}

      <div className='h-spacing' />
      <Dropdown
        overlayStyle={{ width: '200px' }}
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item key='1' disabled style={{ padding: 0 }}>
              <div style={{ width: '100%', height: '100%', cursor: 'default', padding: '10px', color: 'black' }}>
                <FontAwesomeIcon color={COLORS_PALETTE.SECOND} icon={faFont} />
                {t('Font size')}
                <div style={{ height: '5px' }} />
                <Select
                  defaultValue='default'
                  style={{ width: 120 }}
                  onChange={value => {
                    if (value === 'default') {
                      setFontSizes({
                        content: '16px',
                        time: '8px'
                      })
                    } else if (value === 'large') {
                      setFontSizes({
                        content: '18px',
                        time: '12px'
                      })
                    } else if (value === 'xlarge') {
                      setFontSizes({
                        content: '22px',
                        time: '16px'
                      })
                    }
                  }}
                >
                  <Select.Option value='default'>{t('Default')}</Select.Option>
                  <Select.Option value='large'>{t('Large')}</Select.Option>
                  <Select.Option value='xlarge'>{t('Larger')}</Select.Option>
                </Select>
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <Tooltip
          placement='top'
          title={t('Settings')}
        >
          <Button type='default'><FontAwesomeIcon icon={faCog} /></Button>
        </Tooltip>
      </Dropdown>

      <div className='flex-fill' />
      {checkedInternships.length > 0 && (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={handleSelectAction}>
              {useDraft && (
                <Menu.Item key='1' style={{ color: 'green' }}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                  {t('shifts_manager_header.action_publish')}
                </Menu.Item>
              )}
              {!useDraft && useDraftInternships && (
                <Menu.Item key='2' style={{ color: '#39A7FF' }}>
                  <FontAwesomeIcon icon={faFile} />
                  {t('shifts_manager_header.action_mark_as_draft')}
                </Menu.Item>
              )}
              <Menu.Item key='3'>
                <FontAwesomeIcon icon={faRecycle} />
                {t('shifts_manager_header.action_reset_selection')}
              </Menu.Item>
                <Menu.Item key='4' style={{ color: 'red' }}>
                <FontAwesomeIcon icon={faTrash} />
                {t('shifts_manager_header.action_delete_schedules')}
              </Menu.Item>
            </Menu>
          }
        >
          <Button>
            {t('Actions')} &nbsp;
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        </Dropdown>
      )}
      {renderExportAndDatesButtons()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsFieldsAndButtons)
