import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'

const ClickableIcon = ({ disabled = false, icon, size = 'default', title = '', type = 'default', onClick }) => {
  return (
    <Tooltip
      title={title}
    >
      <Button
        className='clickable-icon'
        disabled={disabled}
        size={size}
        type={type}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    </Tooltip>
  )
}

export default ClickableIcon
