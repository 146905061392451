import { APP_STATES } from './actionsType'

const { SET_PAUSE_ACTIVATION } = APP_STATES

export const getAppStates = state => {
  return state.appStates
}

export const setPauseActivationState = newState => ({
  type: SET_PAUSE_ACTIVATION,
  payload: newState
})
