import { Button, Popconfirm, Select, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import TableFooter from '../shared/Tables/TableFooter'
import TableHeader from '../shared/Tables/TableHeader'
import removeAccents from 'remove-accents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNewSection } from '../../utils/entitiesFormaters/SchoolSection'

const DEFAULT_LINE_SHOWN = 20
const SEARCH_DEFAULT_TIMER = 500
const Option = Select.Option
const DATA_TYPE_SELECT = 0
const DATA_TYPE_STRING = 1

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale)
  }
}

const UserStudentTable = ({ data, columns, additionalActions, density, searchWithFilter, t, onDataAdd, onDataEdit, school }) => {
  const [pageIndex, setPageIndex] = useState(1)
  const [maxPageIndex, setMaxPageIndex] = useState(1)
  const [linesCount, setLinesCount] = useState(0)
  const [studentList, setStudentList] = useState([])
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const timer = useRef(null)

  useEffect(() => {
    setMaxPageIndex(data.length % DEFAULT_LINE_SHOWN === 0
      ? Math.floor(data.length / DEFAULT_LINE_SHOWN)
      : Math.floor(data.length / DEFAULT_LINE_SHOWN) + 1
    )

    setLinesCount(data.length)

    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (data.length > 0) {
      setStudentList(data)
    } else {
      setStudentList([])
    }
  }, [data])

  useEffect(() => {
    setIsDataAvailable(studentList.length > 0 || data.length > 0)
  }, [data, studentList])

  const handleIndexChange = increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }

  const handleSearchFieldChange = value => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      searchWithFilter(value)
    }, SEARCH_DEFAULT_TIMER)
  }

  const renderContainer = () => {
    if (isDataAvailable) {
      return (
        <table>
          <thead>
            {renderHead()}
          </thead>
          <tbody>
            {renderBody()}
          </tbody>
        </table>
      )
    }

    return <span> {t('No data are actually available for this page.')} </span>
  }

  const renderHead = () => {
    return (
      <tr>
        {columns && renderHeadDetails()}
        {additionalActions && <th style={{ width: '100px', marginLeft: '2px' }} />}
      </tr>
    )
  }

  const renderHeadDetails = () => {
    return columns.map((column, index) => {
      return (
        <th key={index} style={{ cursor: 'default' }}>
          {t(column.name)}
        </th>
      )
    })
  }

  const renderBody = () => {
    if (columns) {
      const minIndex = DEFAULT_LINE_SHOWN * (pageIndex - 1)
      const maxIndex = minIndex + DEFAULT_LINE_SHOWN

      return studentList.slice(minIndex, maxIndex).map((data, index) => {
        return (
          <tr key={'row_' + index} style={{ height: density ?? '32px' }}>
            {columns.map((column, index) => {
              if (column.type === DATA_TYPE_SELECT) return renderSelect(data, index, column.options)
              if (column.type === DATA_TYPE_STRING) return renderString(data, index)
            })}
            {additionalActions ? renderAdditionalActions(data) : undefined}
          </tr>
        )
      })
    }

    return <tr> {t('No columns have been founded')} </tr>
  }

  const renderSelect = (data, index, options) => {
    const noDataSelected = t('Select a data')

    return (
      <td key={`col_${index}`}>
        <Tooltip placement='top' title={data.name}>
          <Select
            showSearch
            value={data.id === null ? noDataSelected : data.id}
            filterOption={(input, option) =>
              canonizeString(option.props.children).indexOf(
                canonizeString(input)
              ) > -1}
            onChange={e => {
              updateDataValue(data, e)
            }}
          >
            {options.map(o => {
              return (
                <Option key={o.id} value={o.id} title={o.lastname + ' ' + o.firstname}>
                  {o.lastname + ' ' + o.firstname}
                </Option>
              )
            })}
          </Select>
        </Tooltip>
      </td>
    )
  }

  const canonizeString = (string) => {
    return removeAccents(string.toString()).toLowerCase()
  }

  const updateDataValue = (prevData, value) => {
    const student = {
      prevId: prevData.id,
      newId: value
    }

    if (prevData.id === null) {
      onDataAdd(student)
    } else {
      onDataEdit(student)
    }
  }

  const renderString = (data, index) => {
    const noDataSelected = ''

    return (
      <td key={`col_${index}`}>
        <Tooltip placement='top' title={data.id === null ? noDataSelected : getSectionAbbreviation(data.sections)}>
          <span style={{ cursor: 'default', textAlign: 'center' }}>
            {data.id === null ? noDataSelected : getSectionName(data.sections)}
          </span>
        </Tooltip>
      </td>
    )
  }

  const getSectionName = (sections) => {
    for (const section of sections) {
      if (section.school.id === school.id) {
        return isNewSection(section)
          ? '[' + section.sectionToYear.schoolYearName + '] ' + section.sectionToYear.sectionName
          : section.name
      }
    }
  }

  const getSectionAbbreviation = (sections) => {
    for (const section of sections) {
      if (section.school.id === school.id) {
        return isNewSection(section)
          ? section.sectionToYear.sectionAbbreviation
          : section.abbreviation
      }
    }
  }

  const renderAdditionalActions = rowData => {
    const additionalActionsJSX = additionalActions.map(action => {
      return (
        <Tooltip
          placement='top'
          title={t(action.title)}
          key={'action-' + action.title}
        >
          {typeof action.titlePopconfirm !== 'undefined'
            ? buttonConfirmAction(action, rowData)
            : buttonAction(action, rowData)}
        </Tooltip>
      )
    })

    return (
      <td className='buttons'>
        <div className='flex-row'>
          {additionalActionsJSX}
        </div>
      </td>
    )
  }

  const buttonConfirmAction = (action, rowData) => {
    return (
      <Popconfirm
        placement='top'
        okType={action.type}
        title={action.titlePopconfirm}
        okText={t('Yes')}
        cancelText={t('Cancel')}
        onConfirm={() => action.handleOnClick(rowData)}
      >
        <Button
          type={action.type}
          size='small'
          className='button'
          disabled={action.disabled ? action.disabled(rowData) : false}
        >
          <FontAwesomeIcon icon={action.icon} />
        </Button>
      </Popconfirm>
    )
  }

  const buttonAction = (action, rowData) => {
    return (
      <Button
        type={action.type}
        size='small'
        className='button'
        onClick={() => action.handleOnClick(rowData)}
        disabled={action.disabled ? action.disabled(rowData) : false}
      >
        <FontAwesomeIcon icon={action.icon} />
      </Button>
    )
  }

  const addNewLine = () => {
    const newStudentList = [...studentList]
    const newStudent = {
      ...studentList[0],
      name: '',
      id: null
    }

    newStudentList.unshift(newStudent)
    setStudentList(newStudentList)
  }

  return (
    <div className='interactive-table'>
      <TableHeader
        onDataAdd={addNewLine}
        onDataFilter={handleSearchFieldChange}
        addButtonText={t('Add a student')}
        disableSettings='true'
      />

      {renderContainer()}

      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export {
  DATA_TYPE_SELECT,
  DATA_TYPE_STRING
}
export default connect(mapStateToProps)(UserStudentTable)
