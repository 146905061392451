import React, { useCallback, useState } from 'react'

import { Modal } from 'antd'
import Selector from '../../../../Components/shared/Selectors/Selector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope } from '@fortawesome/free-solid-svg-icons'
import { COLORS_PALETTE } from '../../../../config/colors'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { updateInternship } from '../../../../utils/api/internship'
import { onError, onSuccess } from '../../../../utils/apiHelper'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const ChangedInternshipSectorModal = ({ internship, sectors, visible, user, t, onCancel, onUpdate }) => {
  const [selectedSectorId, setSelectedSectorId] = useState(null)

  const handleUpdate = useCallback(() => {
    if (selectedSectorId) {
      updateInternship(internship, { sector: selectedSectorId }, user).then(json => {
        if (json?.data) {
          onUpdate(json.data)
          onSuccess(t('change_internship_sector_modal.actions.success'))
        } else {
          onError(t('change_internship_sector_modal.actions.error'))
        }
      })
    }
  }, [onUpdate, selectedSectorId, t, user, internship])

  return (
    <Modal
      title={t('change_internship_sector_modal.title')}
      visible={visible}
      onCancel={onCancel}
      onOk={handleUpdate}
      okText={t('Save changes')}
      cancelText={t('Cancel')}
    >
      <div className='flex-row' style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <span> <FontAwesomeIcon icon={faStethoscope} color={COLORS_PALETTE.SECOND} /> {t('Care unit')} : </span>
            &nbsp;
        <Selector
          data={sectors}
          dataKey='name'
          onSelect={setSelectedSectorId}
          showArrow={false}
          showEmpty
          showSearch
          value={selectedSectorId ?? internship?.sector?.id}
        />
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(ChangedInternshipSectorModal)
