const GLOBAL_PLACES_NAME = 'Global'
const GLOBAL_PLACES_KEY = 'global'
const PARTIAL_PLACES_STATE = 0
const FULL_PLACES_STATE = 1
const GLOBAL_QUOTA_NAME = '*'
const GLOBAL_QUOTA_KEY = 'all'
const BACKEND_GLOBAL_QUOTA_IDENTIFIER = '-1'
const BACKEND_GLOBAL_PLACES_IDENTIFIER = '-1'

const ALLOCATION_ADDING_BUTTON_TEXT = 'Add quota'
const ALLOCATION_MANAGE_BUTTON_TEXT = 'Manage quota'
const DEFINITION_ADDING_BUTTON_TEXT = 'Add places'
const DEFINITION_MANAGE_BUTTON_TEXT = 'Allocating places'

const DEFAULT_DRAWER_WIDTH = '550px'
const SMALL_DRAWER_WIDTH = '450px'

const TOOLTIP_TOP = 'top'
const TOOLTIP_RIGHT = 'right'
const TOOLTIP_LEFT = 'left'
const DEFAULT_INFO_TOOLTIP_COLOR = '#3787BF'

const FA_1X = '1x'
const FA_2X = '2x'
const FA_3X = '3x'
const FA_4X = '4x'
const FA_5X = '5x'

const getPauseQuotaTreeInitialNode = () => {
  return {
    total: 0,
    details: {},
    quotas: []
  }
}

export {
  GLOBAL_PLACES_NAME,
  GLOBAL_PLACES_KEY,
  PARTIAL_PLACES_STATE,
  FULL_PLACES_STATE,
  GLOBAL_QUOTA_NAME,
  GLOBAL_QUOTA_KEY,
  BACKEND_GLOBAL_QUOTA_IDENTIFIER,
  BACKEND_GLOBAL_PLACES_IDENTIFIER,
  ALLOCATION_ADDING_BUTTON_TEXT,
  ALLOCATION_MANAGE_BUTTON_TEXT,
  DEFINITION_ADDING_BUTTON_TEXT,
  DEFINITION_MANAGE_BUTTON_TEXT,
  DEFAULT_DRAWER_WIDTH,
  TOOLTIP_TOP,
  TOOLTIP_RIGHT,
  TOOLTIP_LEFT,
  DEFAULT_INFO_TOOLTIP_COLOR,
  SMALL_DRAWER_WIDTH,
  FA_1X,
  FA_2X,
  FA_3X,
  FA_4X,
  FA_5X,
  getPauseQuotaTreeInitialNode
}
