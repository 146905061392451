import React from 'react'

import { Form, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pullAt from 'lodash/pullAt'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { validateFormInput } from '../../utils'
import '../../assets/student-extra-contact-details.scss'

const FormItem = Form.Item

class UserSettingsStudentExtraInfo extends React.Component {
  state = {
    emailRows: [],
    phoneRows: []
  }

  componentDidMount () {
    this.extractPropsDataInState()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.data !== this.props.data) {
      this.extractPropsDataInState()
    }
  }

  extractPropsDataInState = () => {
    const { emailRows, phoneRows } = this.props.data
    this.setState({
      emailRows: [...emailRows],
      phoneRows: [...phoneRows]
    })
  }

  checkEmail = (rule, value, callback) => {
    if (!validateFormInput('email', value)) callback(this.props.t('This value is not correct !'))
    callback()
  }

  checkPhone = (rule, value, callback) => {
    if (!validateFormInput('freeTextRequired', value)) callback(this.props.t('Phone is required !'))
    callback()
  }

  handleAddEmailRow = () => {
    this.setState({ emailRows: [...this.state.emailRows, { value: '' }] })
  }

  handleAddPhoneRow = () => {
    this.setState({ phoneRows: [...this.state.phoneRows, { value: '' }] })
  }

  removeEmailRow = index => {
    const updatedEmails = this.state.emailRows.slice()
    pullAt(updatedEmails, [index])
    this.setState({ emailRows: updatedEmails })
  }

  removePhoneRow = index => {
    const updatedPhones = this.state.phoneRows.slice()
    pullAt(updatedPhones, [index])
    this.setState({ phoneRows: updatedPhones })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { xs: { span: 24 } },
      wrapperCol: { xs: { span: 24 } }
    }

    return (
      <Form className='student-extra-contact-details'>
        <label>{this.props.t('student.additional.contact.info.email')}</label>
        {this.state.emailRows.map((emailRow, index) => (
          <div key={`email-row-${index}`} className='item-row'>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(`email${index}`, { rules: [{ validator: this.checkEmail }], initialValue: emailRow.value })(
                <Input prefix={<FontAwesomeIcon icon='at' />} placeholder={this.props.t('Email address')} />
              )}
            </FormItem>
            <Button onClick={() => { this.removeEmailRow(index) }}>
              <FontAwesomeIcon icon='trash' />
            </Button>
          </div>
        ))}
        <div className='add-button-wrapper'>
          <Button type='primary' onClick={this.handleAddEmailRow}>{this.props.t('Add email address')}</Button>
        </div>
        <div className='separation-line' />
        <label>{this.props.t('student.additional.contact.info.phone')}</label>
        {this.state.phoneRows.map((phoneRow, index) => (
          <div key={`phone-row-${index}`} className='item-row'>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(`phone${index}`, { rules: [{ validator: this.checkPhone }], initialValue: phoneRow.value })(
                <Input prefix={<FontAwesomeIcon icon='phone' />} placeholder={this.props.t('Phone')} />
              )}
            </FormItem>
            <Button onClick={() => { this.removePhoneRow(index) }}>
              <FontAwesomeIcon icon='trash' />
            </Button>
          </div>
        ))}
        <div className='add-button-wrapper'>
          <Button type='primary' onClick={this.handleAddPhoneRow}>{this.props.t('Add phone number')}</Button>
        </div>
      </Form>
    )
  }
}

const UserSettingsStudentExtraInfoForm = Form.create()(UserSettingsStudentExtraInfo)
export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsStudentExtraInfoForm)
