import React, { useState } from 'react'
import { Form, Button, Modal, InputNumber, TimePicker } from 'antd'
import { connect, mapDispatchToProps, mapStateToProps } from '../../../../reducers/Dispatchers'

const CustomScheduleModal = props => {
  const [values, setValues] = useState({})

  const handleClose = isSubmit => {
    if (isSubmit) {
      if (!values.startTime || !values.endTime) {
        alert(props.t('Please enter start and end times in your custom schedule'))

        return
      }

      if (values.startTime && values.startTime.length < 8) {
        values.startTime += ':00'
      }

      if (values.endTime && values.endTime.length < 8) {
        values.endTime += ':00'
      }

      props.onSubmit(values)
    } else {
      props.onCancel()
    }
  }

  const title = props.t('Custom schedule')
  const okButtonProps = { htmlType: 'submit', form: 'customScheduleForm' }
  const footer = (
    <div>
      <Button key='back' onClick={() => handleClose(false)}>
        {props.t('Cancel')}
      </Button>
      <Button
        key='submit'
        type='primary'
        onClick={() => handleClose(true)}
      >
        {props.t('Submit')}
      </Button>
    </div>
  )

  const renderContent = () => {
    const format = 'HH:mm'

    return (
      <Form
        id='customScheduleForm'
        layout='vertical'
        initialvalues={values}
      >
        <Form.Item name='startTime' label={props.t('Start time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            format={format}
            allowClear
            onChange={(value, timeString) => {
              setValues({
                ...values,
                startTime: timeString
              })
            }}
          />
        </Form.Item>
        <Form.Item name='endTime' label={props.t('End time')}>
          <TimePicker
            size='large'
            minuteStep={5}
            format={format}
            allowClear
            onChange={(value, timeString) => {
              setValues({
                ...values,
                endTime: timeString
              })
            }}
          />
        </Form.Item>
        <Form.Item name='pause' label={props.t('Pause')}>
          <InputNumber
            size='large'
            min={0}
            step={5}
            allowClear
            onChange={(value) => {
              setValues({ ...values, pause: value })
            }}
          />
        </Form.Item>
      </Form>
    )
  }

  return (
    <Modal
      title={title}
      visible={props.visible}
      onCancel={() => handleClose(false)}
      okButtonProps={okButtonProps}
      footer={footer}
    >
      {renderContent()}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomScheduleModal)
