import { request } from '../../utils'
import { TYPE_EMAIL, TYPE_PHONE } from '../../utils/entities/studentExtraInfo'

const INITIAL_STATE = {
  extra: {},
  prvacy: {}
}

const SET_STUDENT_EXTRA_INFORMATIONS = 'SET_STUDENT_EXTRA_INFORMATIONS'
const SET_STUDENT_OPT_IN = 'SET_STUDENT_OPT_IN'

export const getStudentsExtraInformations = (state) => {
  return state.extra
}

export const getStudentsPrivacyInformations = (state) => {
  return state.privacy
}

export const fetchStudentExtraInformationsById = (studentId, user) => {
  return async function fetchStudentExtraInformationsByIdThunk (dispatch, getState) {
    const response = await request(
      '/student/get-additional-contact-info-by-id/' + studentId,
      'GET',
      null,
      user
    )

    const studentExtraInformations = {
      emails: [],
      phones: []
    }

    response.data.forEach(extraInfo => {
      if (extraInfo.type === TYPE_EMAIL) {
        studentExtraInformations.emails.push(extraInfo.value)
      } else if (extraInfo.type === TYPE_PHONE) {
        studentExtraInformations.phones.push(extraInfo.value)
      }
    })

    dispatch(setStudentsExtraInformations(studentExtraInformations, studentId))
  }
}

export const modifyStudentOptIn = (optIn, studentId, user) => {
  return async function fetchStudentProfileImageThunk (dispatch, getState) {
    const response = await request(
      '/student/optin/' + studentId,
      'PATCH',
      { optIn: optIn },
      user
    )

    dispatch(setStudentsOptIn(response.data, studentId))
  }
}

export const setStudentsExtraInformations = (studentExtraInformations, studentId) => {
  return {
    type: SET_STUDENT_EXTRA_INFORMATIONS,
    payload: {
      infos: studentExtraInformations,
      id: studentId
    }
  }
}

export const setStudentsOptIn = (studentOptIn, studentId) => {
  return {
    type: SET_STUDENT_OPT_IN,
    payload: {
      optIn: studentOptIn,
      id: studentId
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDENT_EXTRA_INFORMATIONS: {
      const newStudentsExtraInformations = { ...state.extra }

      newStudentsExtraInformations[action.payload.id] = action.payload.infos

      return { ...state, extra: newStudentsExtraInformations }
    }
    case SET_STUDENT_OPT_IN: {
      const newStudentsPrivaceInformations = { ...state.privacy }

      newStudentsPrivaceInformations[action.payload.id] = action.payload.optIn

      return { ...state, privacy: newStudentsPrivaceInformations }
    }
    default:
      return state
  }
}
