export class InternshipEvaluation {
  constructor ({ id = -1, studentEvaluationAnswers, studentEvaluationComment } = {}) {
    this.id = id
    this.studentEvaluationAnswers = studentEvaluationAnswers
    this.studentEvaluationComment = studentEvaluationComment
  }

  toRequestBody = () => {
    const body = { ...this }

    return body
  }
}
