import React, {useEffect, useState} from 'react'
import { Tag, Tooltip } from 'antd'


const CompactedList = ({data}) => {
  const firstElement = data[0];
  const rest = data.slice(1);

  return (
    <div>
      <span>{firstElement} </span>
      {(rest.length > 0) && (
      <Tooltip overlayStyle={{maxWidth: 'none'}} title={<ul style={{padding :  '0',margin : '0', listStyleType: 'none'}}>
        {rest.map((r) => (
          <li>{r}</li>
        ))}
      </ul>}>
        <Tag color='blue'>+{data.length - 1}</Tag>
      </Tooltip>
      )}
    </div>
  );
};

export default CompactedList;