import React, { useContext, useState } from 'react'

import Settings from './Settings'
import { ALLOCATION_ADDING_BUTTON_TEXT, ALLOCATION_MANAGE_BUTTON_TEXT } from '../utils/Constants'
import TableMenu from './TableMenu'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { TIME_PERIODS_TYPE } from '../../../../utils/timePeriods'
import AllocationTablesContainer from './AllocationTablesContainer'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import AllocationForm from './AllocationForm'
import { getTranslate } from 'react-localize-redux'
import { AllocationContext } from '../../../../Providers/Quotas/AllocationProvider'

import '../../../../assets/quota-allocation.scss'
import { getAll } from '../../../../utils/api/quota'
import { DATE_FORMAT_API } from '../../../../utils/constants'
import { isObject } from '../../../../utils'
import AllocationModificationDrawer from './AllocationModificationDrawer'

const ALLOCATION_MODAL_TITLE = 'Allocate a quota'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const PlacesAllocationContainer = ({ user, t }) => {
  const { date, setDate, period, periodType, setPeriodType } = useContext(QuotasContext)
  const { places, schools, sections, sector, fetchPlaces, setFetchQuotas, setSchools, setSections, setSector } = useContext(AllocationContext)

  const [openAllocation, setOpenAllocation] = useState(false)
  const [openModification, setOpenModification] = useState(false)
  const [selectedCell, setSelectedCell] = useState(null)

  const afterSubmit = ({ section }) => {
    fetchPlaces()
    setFetchQuotas(buildQuotasFetching(section))
  }

  const buildQuotasFetching = section => {
    const key = isObject(section) ? section.id : -1
    const fetch = {}

    fetch[key] = () => getAll(
      user,
      {
        'start-date': period.startDate.format(DATE_FORMAT_API),
        'end-date': period.endDate.format(DATE_FORMAT_API),
        sector: sector.id,
        section: isObject(section) ? section.id : null,
        schools: schools.map(s => s.id),
        contexts: ['school', 'sectionToYear']
      }
    )

    return fetch
  }

  const handleDateChange = date => {
    setSelectedCell(null)
    setDate(date)
  }

  const handlePeriodChange = period => {
    setSelectedCell(null)
    setPeriodType(period)
  }

  const handleSelectSector = sector => {
    setSector(sector)
    setSelectedCell(null)
  }

  const handleSectionsChanged = sections => {
    setSections(sections)
    setSelectedCell(null)
  }

  const handleSchoolsChanged = schools => {
    setSchools(schools)
    setSelectedCell(null)
  }

  return (
    <div className='places-allocation-container'>
      <div className='places-allocation-settings'>
        <Settings
          onSchoolsChanged={handleSchoolsChanged}
          onSectionsChange={handleSectionsChanged}
          onSelectSector={handleSelectSector}
        />
      </div>
      <div className='places-allocation-tables-container'>
        <TableMenu
          addButtonText={ALLOCATION_ADDING_BUTTON_TEXT}
          date={date}
          defaultPeriod={TIME_PERIODS_TYPE[1]}
          disabledAdd={!sector}
          disabledModification={!selectedCell}
          periodType={periodType}
          modificationButtonText={ALLOCATION_MANAGE_BUTTON_TEXT}
          onAddClick={() => setOpenAllocation(true)}
          onDateChange={handleDateChange}
          onModificationClick={() => setOpenModification(true)}
          onPeriodChange={handlePeriodChange}
        />
        <AllocationTablesContainer
          places={places}
          schools={schools}
          sections={sections}
          sector={sector}
          selectedCell={selectedCell}
          disabled={!sector}
          onCellClick={setSelectedCell}
          onCellDoubleClick={cell => {
            setSelectedCell(cell)
            setOpenModification(true)
          }}
        />
      </div>
      <Modal
        width={600}
        title={<h3 style={{ textAlign: 'center', margin: '0px' }}> {t(ALLOCATION_MODAL_TITLE)} </h3>}
        visible={openAllocation}
        footer={false}
        onCancel={() => setOpenAllocation(false)}
        destroyOnClose
      >
        <AllocationForm
          cell={selectedCell}
          date={date}
          period={period}
          places={places}
          schools={schools}
          sector={sector}
          afterSubmit={afterSubmit}
          onCancel={() => setOpenAllocation(false)}
        />
      </Modal>
      <AllocationModificationDrawer
        period={period}
        places={places}
        school={selectedCell === null ? null : selectedCell.school}
        section={selectedCell === null ? null : selectedCell.section}
        sector={sector}
        visible={openModification}
        onClose={(section, invalidation) => {
          if (invalidation) {
            fetchPlaces()
            setFetchQuotas(buildQuotasFetching(section))
          }

          setOpenModification(false)
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps)(PlacesAllocationContainer)
