import { createSelector } from 'reselect'

const getUserRoles = state => state.roles
const getUserManagedSectors = state => state.affiliatedSectors

export const isLocalAdmin = createSelector(
  [getUserRoles, getUserManagedSectors],
  (roles, affiliatedSectors) => {
    if (roles === undefined || affiliatedSectors === undefined) {
      return false
    }

    return roles.includes('ROLE_HOSPITAL_ADMIN') && affiliatedSectors.length !== 0
  }
)
