import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { getInternshipEvaluation, setInstitutionInternshipEvaluation } from '../../../../utils/api/internship'
import { InternshipEvaluation } from '../../../../utils/entities/internshipEvaluation'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import { getUser } from '../../../../reducers/UserReducer'
import EvaluationPanel from './EvaluationPanel'
import Loading from '../../../../HOC/Loading'

const mapStateToProps = state => ({
  activeLanguage: getActiveLanguage(state.locale).code,
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InstitutionEvaluationPanel = ({ activeLanguage, internship, user, t }) => {
  const { evaluationQuestions } = useContext(InstitutionContext)

  const [evaluation, setEvaluation] = useState(null)
  const [loading, setLoading] = useState(true)

  const language = useMemo(() => activeLanguage.split('_')[0], [activeLanguage])

  const loadEvaluation = useCallback(() => {
    if (user && internship) {
      setLoading(true)

      getInternshipEvaluation(user, internship).then(json => {
        if (json?.data) {
          setEvaluation(new InternshipEvaluation(json.data))
        }

        setLoading(false)
      })
    }
  }, [internship, user, setLoading, setEvaluation])

  const submitFeedback = ({ studentEvaluationAnswers, studentEvaluationComment }) => {
    const questions = studentEvaluationAnswers.map(item => ({ questionId: item.id, questionType: item.questionType, answer: item.answer }))

    const dataToSend = {
      internshipId: internship.id,
      answers: questions,
      comment: studentEvaluationComment ? studentEvaluationComment.trim() : null
    }

    setLoading(true)
    setInstitutionInternshipEvaluation(user, dataToSend).then(json => {
      loadEvaluation()
    })
  }

  useEffect(() => {
    loadEvaluation(user, internship)
  }, [loadEvaluation])

  const questions = useMemo(() => evaluationQuestions.map(q => {
    const label = q.questions.find(qq => qq.language === language)?.value ?? 'MISSING_LABEL'
    let answer = null

    if (evaluation?.studentEvaluationAnswers) {
      answer = evaluation.studentEvaluationAnswers.find(a => a.question === q.id)?.answer ?? null
    }

    return { id: q.id, label, answer }
  }), [evaluationQuestions, evaluation, language])

  const studentName = useMemo(() => {
    let name = t('Unknown student')

    if (internship?.student && internship.student.firstname) {
      name = internship.student.firstname
    }

    return name
  }, [internship?.student, t])

  return (
    <Loading loading={loading}>
      <EvaluationPanel
        comment={evaluation?.studentEvaluationComment ?? ''}
        questions={questions}
        name={studentName}
        readOnlyMode={!!evaluation?.studentEvaluationAnswers?.length}
        submitted={evaluation?.id}
        onSubmit={submitFeedback}
      />
    </Loading>
  )
}

export default connect(mapStateToProps)(InstitutionEvaluationPanel)
