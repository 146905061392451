import React, { useState } from 'react'

import { CustomRangePicker } from './CustomRangePicker'
import { getEndDate } from '../utils/DateTools'
import { Button, Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const PLUS_ICON_SIZE = '1x'
const BUTTONS_CONFIGURATION_ARRAY = [
  {
    title: 'week',
    displacement: { weeks: 1 }
  },
  {
    title: 'month',
    displacement: { months: 1 }
  }
]

const QuickDualDatePickers = ({ disabled, endDate, period, startDate, style, onDatesChange }) => {
  const [isButtonOpen, setIsButtonOpen] = useState(false)

  const handleDatesChange = dates => {
    if (onDatesChange) {
      onDatesChange(dates)
    }
  }

  const handlePopoverVisibilityChange = visible => {
    if (!visible) {
      setIsButtonOpen(false)
    }
  }

  const renderDatesOptions = () => {
    return (
      <Popover
        placement='bottomLeft'
        content={renderQuickDatesButtons()}
        trigger='click'
        onVisibleChange={visible => handlePopoverVisibilityChange(visible)}
      >
        <Button
          type='primary'
          onClick={() => setIsButtonOpen(!isButtonOpen)}
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon={isButtonOpen ? faMinus : faPlus}
            size={PLUS_ICON_SIZE}
          />
        </Button>
      </Popover>
    )
  }

  const renderQuickDatesButtons = () => {
    const buttons = BUTTONS_CONFIGURATION_ARRAY.map((buttonConfig, index) => {
      const { title, displacement } = buttonConfig
      return (
        <Button
          key={'period_button_' + index}
          type='primary'
          onClick={() => modifyEndDate(displacement)}
        >
          {title}
        </Button>
      )
    })

    return (
      <div className='quick-popover-buttons-div'>
        {buttons}
      </div>
    )
  }

  const modifyEndDate = displacement => {
    const newEndDate = endDate.clone()

    getEndDate(newEndDate, displacement)

    handleDatesChange([startDate, newEndDate])
  }

  return (
    <div className='quick-move-buttons' style={style}>
      <CustomRangePicker
        startDate={startDate}
        endDate={endDate}
        period={period}
        onDatesChange={handleDatesChange}
        disabled={disabled}
      />
      {renderDatesOptions()}
    </div>
  )
}

export default QuickDualDatePickers
