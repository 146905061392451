import remove from 'lodash/remove'
import { request, generalErrorHandler } from '../../utils'
import appConfig from '../../config'
import { LANGUAGE_LOCALE_TAG_EN, LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_LOCALE_TAG_FR_FR, LANGUAGE_LOCALE_TAG_NL } from '../../utils/constants'

const SET_TAGS = 'SET_TAGS'
const SET_TAGGED_ENTITIES = 'SET_TAGGED_ENTITIES'
const RESET_TAGS = 'RESET_TAGS'
const TOGGLE_TAG = 'TOGGLE_TAG'
const ADD_TAGGED_ENTITY = 'ADD_TAGGED_ENTITY'

export const getTags = state => {
  return state.list
}

export const getTagsLoading = state => {
  return state.loading
}

export const getTagsFetched = state => {
  return state.fetched
}

const translateTagNameForDisplay = (tag, languageCode) => {
  let translatedName = tag.nameFR
  switch (languageCode) {
    case LANGUAGE_LOCALE_TAG_FR_FR:
      if (tag.nameFR.length) {
        translatedName = tag.nameFR
      } else if (tag.nameEN.length) {
        translatedName = tag.nameEN
      } else {
        translatedName = tag.nameNL
      }
      break
    case LANGUAGE_LOCALE_TAG_FR_BE:
      if (tag.nameFR.length) {
        translatedName = tag.nameFR
      } else if (tag.nameEN.length) {
        translatedName = tag.nameEN
      } else {
        translatedName = tag.nameNL
      }
      break
    case LANGUAGE_LOCALE_TAG_EN:
      if (tag.nameEN.length) {
        translatedName = tag.nameEN
      } else if (tag.nameFR.length) {
        translatedName = tag.nameFR
      } else {
        translatedName = tag.nameNL
      }
      break
    case LANGUAGE_LOCALE_TAG_NL:
      if (tag.nameNL.length) {
        translatedName = tag.nameNL
      } else if (tag.nameFR.length) {
        translatedName = tag.nameFR
      } else {
        translatedName = tag.nameEN
      }
      break
  }
  return { ...tag, translatedName }
}

const getCurrentLanguageCode = languages => {
  let languageCode = LANGUAGE_LOCALE_TAG_FR_BE
  const language = languages.find(item => item.active === true)

  if (language) {
    languageCode = language.code
  }

  return languageCode
}

export const fetchTags = () => {
  return async (dispatch, getState) => {
    const { getUser, tags, locale: { languages } } = getState()

    if (!tags.fetched) {
      try {
        let tagsList = []
        const { data, nextPage } = await request(`/tags/0/${appConfig.apiPageSize}`, 'GET', null, getUser)
        const languageCode = getCurrentLanguageCode(languages)
        let nextPageInfo = nextPage
        tagsList.push(...data.map(item => translateTagNameForDisplay(item, languageCode)))
        while (nextPageInfo) {
          const newPageResults = await request(nextPageInfo, 'GET', null, getUser)
          if (newPageResults.data) {
            tagsList.push(...newPageResults.data.map(item => translateTagNameForDisplay(item, languageCode)))
          }
          nextPageInfo = newPageResults.nextPage
        }

        // and now iterate through tags and decide if the tag is active or not for current institution/school
        if (getUser.context !== 'ADMIN') {
          const activeTagSearchType = getUser.context === 'HOSPITAL' ? 'institution' : 'school'
          const activeTagSearchId = getUser.context === 'HOSPITAL' ? getUser.institutions[0].id : getUser.school.id

          tagsList = tagsList.map(item => {
            let tagIsActive = false
            if (item.activeTags && item.activeTags.length) {
              if (!tagIsActive && item.visibility === 'private' && item.activeTags.find(activeTag => activeTag.type === 'private' && activeTag.id === getUser.id)) {
                tagIsActive = true
              } else if (!tagIsActive && item.activeTags.find(activeTag => activeTag.type === activeTagSearchType && activeTag.id === activeTagSearchId)) {
                tagIsActive = true
              }
            }
            item.active = tagIsActive
            const { activeTags, ...restOfItem } = item
            return restOfItem
          })
        }

        if (data) {
          dispatch({
            type: SET_TAGS,
            tags: tagsList
          })
          return tagsList
        }
      } catch (err) {
        generalErrorHandler(err)
        return null
      }
    } else {
      return tags.list
    }
  }
}

export const saveTag = tag => {
  return async (dispatch, getState) => {
    const { getUser, tags, locale: { languages } } = getState()
    const url = !tag.id ? '/api/Tag' : `/api/Tag/${tag.id}/none`

    try {
      const { data } = await request(url, !tag.id ? 'POST' : 'PATCH', tag, getUser)
      const languageCode = getCurrentLanguageCode(languages)

      const updatedTags = tags.list.slice()
      if (!tag.id) {
      // create
        updatedTags.push({
          id: data.id,
          icon: tag.icon,
          inactiveIcon: tag.inactiveIcon,
          visibility: tag.visibility,
          entityName: tag.entityName,
          nameEN: tag.nameEN,
          nameFR: tag.nameFR,
          nameNL: tag.nameNL,
          active: true,
          type: data.type,
          translatedName: translateTagNameForDisplay(tag, languageCode).translatedName
        })
      } else {
        const tagToUpdate = updatedTags.find(item => item.id === tag.id)
        if (tagToUpdate) {
          tagToUpdate.nameEN = tag.nameEN
          tagToUpdate.nameFR = tag.nameFR
          tagToUpdate.nameNL = tag.nameNL
          tagToUpdate.icon = tag.icon
          tagToUpdate.inactiveIcon = tag.inactiveIcon
          tagToUpdate.visibility = tag.visibility
          tagToUpdate.entityName = tag.entityName
          tagToUpdate.active = tag.active
          tagToUpdate.translatedName = translateTagNameForDisplay(tag, languageCode).translatedName
        }
      }
      dispatch({
        type: SET_TAGS,
        tags: updatedTags.map(item => {
          const { shown, dirty, dataValidation, correct, ...realTagProps } = item
          return { ...realTagProps }
        })
      })
      return tag
    } catch (err) {
      generalErrorHandler(err)
      throw new Error(err)
    }
  }
}

export const toggleActiveTag = tagId => {
  return async (dispatch, getState) => {
    const { getUser, tags } = getState()
    try {
      const { data } = await request(`/tags/toggle-active/${tagId}`, 'POST', {}, getUser)
      // update the active state in the list of tags

      let tagToDeactivateDetails = null
      const updatedTagsList = tags.list.map(item => {
        if (item.id === data.id) {
          const activeTagSearchType = getUser.context === 'HOSPITAL' ? 'institution' : 'school'
          const activeTagSearchId = getUser.context === 'HOSPITAL' ? getUser.institutions[0].id : getUser.school.id
          let tagIsActive = false
          if (data.activeTags && data.activeTags.length) {
            if (item.visibility === 'private' && data.activeTags.find(activeTag => activeTag.entityType === 'private' && activeTag.entityId === getUser.id)) {
              tagIsActive = true
            } else if (data.activeTags.find(activeTag => activeTag.entityType === activeTagSearchType && activeTag.entityId === activeTagSearchId)) {
              tagIsActive = true
            }
          }
          item.active = tagIsActive
          if (!tagIsActive) {
            tagToDeactivateDetails = { tagId, entityName: item.entityName }
          }
        }
        const { shown, dirty, dataValidation, correct, ...realTagProps } = item
        return { ...realTagProps }
      })
      dispatch({
        type: SET_TAGS,
        tags: updatedTagsList
      })
      if (tagToDeactivateDetails) {
        dispatch({
          type: TOGGLE_TAG,
          tag: tagToDeactivateDetails
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const deleteTag = tag => {
  return async (dispatch, getState) => {
    const { getUser, tags } = getState()
    const url = `/api/Tag/${tag.id}`
    try {
      await request(url, 'DELETE', tag, getUser)
      const updatedTags = tags.list.slice()
      remove(updatedTags, item => item.id === tag.id)
      dispatch({
        type: SET_TAGS,
        tags: updatedTags
      })
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const tagEntity = entity => {
  return async (dispatch, getState) => {
    const { getUser, tags } = getState()
    try {
      request('/tag-entity', 'POST', entity, getUser)
      const updatedTaggedEntities = { ...tags.taggedEntities }
      if (updatedTaggedEntities[entity.entityName]) {
        const removedEntity = remove(updatedTaggedEntities[entity.entityName], item => item.tagId === entity.tagId && item.entityId === entity.entityId)
        if (!removedEntity || !removedEntity.length) {
          updatedTaggedEntities[entity.entityName].push({ tagId: entity.tagId, entityId: entity.entityId })
        }
      } else {
        updatedTaggedEntities[entity.entityName] = [{ tagId: entity.tagId, entityId: entity.entityId }]
      }

      dispatch({
        type: SET_TAGGED_ENTITIES,
        taggedEntities: updatedTaggedEntities
      })
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const getTaggedEntities = state => {
  return state.taggedEntities
}

export const addTaggedEntity = (taggedEntity, entityName) => {
  return {
    type: ADD_TAGGED_ENTITY,
    taggedEntity: { ...taggedEntity, entityName }
  }
}

export const fetchTaggedEntities = entityName => {
  return async (dispatch, getState) => {
    const { getUser, tags: { taggedEntities } } = getState()
    if (taggedEntities[entityName]) {
      return taggedEntities[entityName]
    }
    try {
      const { data } = await request(`/tag-entity/${entityName}`, 'GET', null, getUser)
      const updatedTaggedEntities = { ...taggedEntities }
      updatedTaggedEntities[entityName] = data
      dispatch({
        type: SET_TAGGED_ENTITIES,
        taggedEntities: updatedTaggedEntities
      })
      return data
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

export const setTags = (tags) => {
  return {
    type: SET_TAGS,
    tags
  }
}

export const resetTags = () => {
  return {
    type: RESET_TAGS
  }
}

export const getTagsByEntityType = type => {
  return (dispatch, getState) => {
    const { tags: { list } } = getState()
    return list.filter(item => item.entityName === type.toLowerCase() && item.active === true)
  }
}

export const toggleTag = (tag) => {
  return {
    type: TOGGLE_TAG,
    tag
  }
}

export const getEnabledTagsForEntityName = entityName => {
  return (dispatch, getState) => {
    const { tags: { enabledTags } } = getState()
    if (enabledTags[entityName]) {
      return enabledTags[entityName]
    }
    return []
  }
}

export default (state = { list: [], loading: true, fetched: false, taggedEntities: {}, enabledTags: {} }, action) => {
  switch (action.type) {
    case SET_TAGS:
      return { ...state, list: action.tags, loading: false, fetched: true }
    case SET_TAGGED_ENTITIES:
    {
      return { ...state, taggedEntities: action.taggedEntities }
    }
    case TOGGLE_TAG: {
      const updatedEnabledTags = { ...state.enabledTags }
      const { tagId, entityName } = action.tag
      if (updatedEnabledTags[entityName]) {
        const removedTag = remove(updatedEnabledTags[entityName], item => item === tagId)
        if (!removedTag || !removedTag.length) {
          updatedEnabledTags[entityName].push(tagId)
        }
      } else {
        updatedEnabledTags[entityName] = [tagId]
      }
      return { ...state, enabledTags: updatedEnabledTags }
    }
    case RESET_TAGS: {
      return { list: [], loading: true, fetched: false, taggedEntities: {}, enabledTags: {} }
    }
    case ADD_TAGGED_ENTITY: {
      const updatedTaggedEntities = { ...state.taggedEntities }
      const { entityName, ...newTag } = action.taggedEntity
      if (updatedTaggedEntities[entityName]) {
        updatedTaggedEntities[entityName].push(newTag)
      }

      return { ...state, taggedEntities: updatedTaggedEntities }
    }
    default:
      return state
  }
}
