import React, { useContext, useMemo } from 'react'

import Cookie from 'js-cookie'
import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { connect } from 'react-redux'
import { ORDER_BY } from '../../../../utils/constants'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { Tag } from 'antd'
import { getCreatedAt, getInternshipYear, getSectionAbbreviation, getStartDate, getSectionName, getEndDate, getLastname, getFirstname, getEmail, getSectorName, getSchoolName, getComment, getRequestOrigin, InternshipRequest, REQUEST_ORIGINS, STATES } from '../../../../utils/entities/internshipRequest'
import { IndividualInternshipRequestModalContext, MODAL_TYPES } from '../../../../Providers/Forms/IndividualInternshipRequestModal'
import { faCheck, faEdit, faEnvelope, faFile, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Action, DeleteAction } from '../../../../Components/shared/DataTable/utils/actions'
import { InternshipRequestFilesDrawerContext } from './InternshipRequestFilesDrawer'

const TAGS = {
  INCOMPLETE: { label: 'internship_request.status.incomplete', color: 'orange' },
  ON_HOLD: { label: 'internship_request.status.on_hold', color: 'blue' },
  ACTION_REQUIRE: { label: 'internship_request.status.action_require', color: 'red' }
}

const mapStateToProps = state => ({ t: getTranslate(state.locale), currentLanguage: getActiveLanguage(state.locale).code })

const InternshipsRequestsTable = ({
  currentLanguage, data, institution = null, loading, metadata, parameters, onDelete, onParametersChange, onRefuse, reload, t
}) => {
  const { setSelectedRequest } = useContext(IndividualInternshipRequestModalContext)
  const filesContext = useContext(InternshipRequestFilesDrawerContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '235px' : '194px'})`, [Cookie.get('switch_user_id')])
  const columns = useMemo(() => [
    {
      title: t('data_table.column_title.state'),
      orderBy: ORDER_BY.STATE,
      key: r => <Tag color={TAGS[r.state].color}> {t(TAGS[r.state].label)} </Tag>
    },
    { title: t('data_table.column_title.creation_date'), orderBy: ORDER_BY.CREATED_AT, key: getCreatedAt },
    { title: t('data_table.column_title.section'), orderBy: ORDER_BY.SECTION, key: getSectionAbbreviation, tooltip: getSectionName },
    { title: t('data_table.column_title.internship_year'), orderBy: ORDER_BY.SCHOOL_YEAR, key: getInternshipYear },
    { title: t('data_table.column_title.start_date'), orderBy: ORDER_BY.START_DATE, key: getStartDate },
    { title: t('data_table.column_title.end_date'), orderBy: ORDER_BY.END_DATE, key: getEndDate },
    { title: t('data_table.column_title.lastname'), orderBy: ORDER_BY.LAST_NAME, key: getLastname },
    { title: t('data_table.column_title.firstname'), orderBy: ORDER_BY.FIRST_NAME, key: getFirstname },
    { title: t('data_table.column_title.email'), orderBy: ORDER_BY.EMAIL, key: getEmail },
    { title: t('data_table.column_title.sector'), orderBy: ORDER_BY.SECTOR, key: getSectorName },
    { title: t('data_table.column_title.school'), orderBy: ORDER_BY.SCHOOL, key: getSchoolName },
    { title: t('data_table.column_title.comment'), orderBy: ORDER_BY.COMMENT, key: getComment },
    { title: t('data_table.column_title.request_origin'), orderBy: ORDER_BY.REQUEST_ORIGIN, key: r => t('indidivual_internship_request.origine.' + getRequestOrigin(r).toLowerCase()) }
  ], [t])
  const actions = useMemo(() => [
    new Action({
      title: 'internship_requests_table.actions.edit',
      icon: faEdit,
      onClick: i => setSelectedRequest({ data: new InternshipRequest({ ...i }), callback: reload, type: MODAL_TYPES.FORM })
    }),
    new Action({
      title: 'internship_requests_table.actions.consult_files',
      className: d => d.files.length ? 'ant-green-button' : '',
      icon: faFile,
      onClick: i => filesContext.setSelectedRequest({ data: new InternshipRequest({ ...i }), callback: reload })
    }),
    new Action({
      title: 'internship_requests_table.actions.consult_student_data',
      icon: faEnvelope,
      onClick: i => setSelectedRequest({ data: new InternshipRequest({ ...i }), callback: reload, type: MODAL_TYPES.DISPLAY })
    }),
    new Action({
      disabled: r => r.state === STATES.INCOMPLETE,
      title: 'internship_requests_table.actions.accept_internship_request',
      icon: faCheck,
      onClick: i => setSelectedRequest({ data: new InternshipRequest({ ...i }), callback: reload, type: MODAL_TYPES.ACCEPT })
    }),
    new DeleteAction({
      icon: faTimes,
      popconfirmTitle: 'internship_requests_table.actions.refuse_content',
      title: 'internship_requests_table.actions.refuse_title',
      disabled: r => r.requestOrigin !== REQUEST_ORIGINS.MANUAL,
      onDelete: onRefuse
    }),
    new DeleteAction({ onDelete })
  ], [filesContext.setSelectedRequest, setSelectedRequest, reload, onDelete, onRefuse])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => setSelectedRequest({
          data: new InternshipRequest({ requestOrigin: REQUEST_ORIGINS.MANUAL, institution, language: { languageTag: currentLanguage } }),
          callback: reload,
          type: MODAL_TYPES.FORM
        })}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsRequestsTable)
