import React, { useCallback, useContext, useEffect, useState } from 'react'

import PlacesDisplayTable from './PlacesDisplayTable'
import PlacesFormDrawer from './PlacesFormDrawer'
import PlacesManagingDrawer from './PlacesManagingDrawer'
import Settings from './Settings'
import { buildAntdTreeFromArray, buildAntdTreeFromMap, buildTableData, generateColumns } from '../utils/DataBuilders'
import { BACKEND_DATE_FORMAT } from '../../../../utils/constants'
import { DEFINITION_ADDING_BUTTON_TEXT, DEFINITION_MANAGE_BUTTON_TEXT, getPauseQuotaTreeInitialNode, GLOBAL_PLACES_KEY } from '../utils/Constants'
import moment from 'moment'
import { isOverlapping } from '../utils/DateTools'
import { getAppStates } from '../../../../reducers/AppStates/actions'
import { QuotasContext } from '../../../../Providers/Quotas/QuotasProvider'
import { bindActionCreators } from 'redux'
import { fetchPausesQuotasByInstitution, getPauseQuotas } from '../../../../reducers/PauseQuotasReducer/PauseQuotasReducer'
import { getQuotas, fetchSectorQuotas, addQuota, updateQuota } from '../../../../reducers/QuotasReducer/actions'
import { connect, useDispatch } from 'react-redux'
import TableMenu from '../allocation/TableMenu'
import { TIME_PERIODS_TYPE } from '../../../../utils/timePeriods'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { Tooltip } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { addQuotasFromArray } from '../../../../utils/api/quota'
import { QUOTAS } from '../../../../reducers/QuotasReducer/actionsType'
import useLocalStorage from '../../../../hooks/UseLocalStorage'

const MENU_SIZE_PX = 75
const MENU_DIV_STYLE = { height: `${MENU_SIZE_PX}px` }

const LOCAL_STORAGE_SELECTED_SECTORS = 'quotaAllocation.selectedSectors'

export const DEFAULT_TIME_PERIODS = [
  {
    type: 'Year',
    period: 'year',
    displacement: 'month',
    cellPeriod: 'month',
    quantity: 1,
    columnsNumber: 12
  },
  {
    type: '2 months',
    period: 'month',
    cellPeriod: 'week',
    quantity: 1,
    columnsNumber: 8
  },
  {
    type: 'Month',
    period: 'month',
    cellPeriod: 'week',
    quantity: 1,
    columnsNumber: 4
  },
  {
    type: 'Week',
    period: 'week',
    cellPeriod: 'day',
    quantity: 1,
    columnsNumber: 7
  }
]

const mapStateToProps = state => ({
  appStates: getAppStates(state),
  pauseQuotas: getPauseQuotas(state),
  quotas: getQuotas(state),
  t: getTranslate(state.locale)
})

const mapDispatchToProps = dispatch => ({
  addQuota: bindActionCreators(addQuota, dispatch),
  updateQuota: bindActionCreators(updateQuota, dispatch),
  fetchPausesQuotas: bindActionCreators(fetchPausesQuotasByInstitution, dispatch),
  fetchSectorQuotas: bindActionCreators(fetchSectorQuotas, dispatch),
  fetchSectorsQuotas: bindActionCreators(fetchSectorQuotas, dispatch)
})

const PlacesDefiner = ({ appStates, pauseQuotas, pausesRequired, quotas, fetchPausesQuotas, fetchSectorQuotas, t }) => {
  const { pauses, sections } = useContext(GlobalContext)
  const { date, period, periodType, sectors, setDate, setPeriodType, user } = useContext(QuotasContext)
  const { pauseActivated } = appStates

  const [placesPerColumn, setPlacesPerColumn] = useState([])
  const [pausePlacesPerColumn, setPausePlacesPerColumn] = useState([])
  const [openAddingForm, setOpenAddingForm] = useState(false)
  const [openQuotaManagingForm, setOpenQuotaManagingForm] = useState(false)
  const [tableColumns, setTableColumns] = useState([])
  const [sectionIdsToDisplay, setSectionIdsToDisplay] = useState([])
  const [selectedCell, setSelectedCell] = useState({})
  const [pausesAsAntdTree, setPausesAsAntdTree] = useState([])
  const [sectionsAsAntdTree, setSectionsAsAntdTree] = useState([])

  const getDate = () => {
    if (!selectedCell.colIndex) {
      return tableColumns[1]
        ? tableColumns[1].startDate
        : moment()
    }

    return tableColumns[selectedCell.colIndex].startDate
  }

  const [selectedSectors, setSelectedSectors] = useLocalStorage(LOCAL_STORAGE_SELECTED_SECTORS + user.id, [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (user && pausesRequired) {
      fetchPausesQuotas(user.institutions[0].id, user)
    }
  }, [user, pausesRequired])

  useEffect(() => {
    if (date && period) {
      setTableColumns(generateColumns(date, period))
    }
  }, [date, period])

  useEffect(() => {
    if (quotas) {
      setPlacesPerColumn(buildTableData(quotas, tableColumns))
    }
  }, [tableColumns, quotas])

  useEffect(() => {
    if (Object.keys(pauseQuotas).length > 0) {
      setPausePlacesPerColumn(buildPauseQuotasTree(pauseQuotas, tableColumns))
    }
  }, [tableColumns, pauseQuotas])

  useEffect(() => {
    setSectionIdsToDisplay([])
  }, [appStates])

  useEffect(() => {
    setPausesAsAntdTree(buildAntdTreeFromArray(pauses, 'id', p => (t(p.name))))
  }, [pauses, t])

  useEffect(() => {
    setSectionsAsAntdTree(buildAntdTreeFromMap(sections, 'id', s => <Tooltip title={s.name}> {s.abbreviation} </Tooltip>))
  }, [sections])

  const buildPauseQuotasTree = (quotas, columns) => {
    return columns.map(column => {
      return buildPauseQuotasTreeByPeriods(quotas, column.startDate, column.endDate)
    })
  }

  const buildPauseQuotasTreeByPeriods = (quotas, periodStartDate, periodEndDate) => {
    const tree = {}

    Object.keys(quotas).forEach(id => {
      const pauseQuota = quotas[id]
      const startDate = moment(pauseQuota.startDate)
      const endDate = moment(pauseQuota.endDate)

      if (isOverlapping(startDate, endDate, periodStartDate, periodEndDate)) {
        insertPauseQuotaByAccessors(tree, pauseQuota, ['sector', 'section', 'school', 'sectionToYear'])
      }
    })

    return tree
  }

  const insertPauseQuotaByAccessors = (tree, pauseQuota, accessors) => {
    const pauseId = pauseQuota.pause.id ?? -1

    let currentNode = tree

    for (let i = 0; i < accessors.length; i++) {
      const previousKey = i > 1 ? pauseQuota[accessors[i - 1]]?.id : null
      const currentKey = pauseQuota[accessors[i]]?.id
      const nextKey = i < accessors.length - 1 ? pauseQuota[accessors[i + 1]]?.id : null

      if (!currentKey) {
        return
      }

      if (previousKey && nextKey) {
        currentNode[previousKey].total += pauseQuota.total

        currentNode = currentNode[previousKey]
      }

      initialiseTreeIfNecessary(currentNode, currentKey, getPauseQuotaTreeInitialNode())
      modifyNodeByKeys(currentNode, currentKey, nextKey, pauseQuota)

      if (!currentNode[currentKey].details[pauseId]) {
        currentNode[currentKey].details[pauseId] = 0
      }

      currentNode[currentKey].details[pauseId] += pauseQuota.total
    }
  }

  const initialiseTreeIfNecessary = (tree, key, initialValue) => {
    if (!tree[key]) {
      tree[key] = initialValue
    }
  }

  const modifyNodeByKeys = (tree, currentKey, nextKey, pauseQuota) => {
    if (!nextKey) {
      tree[currentKey].total += pauseQuota.total
      tree[currentKey].quotas.push(pauseQuota.id)

      return
    }

    tree[currentKey].total -= pauseQuota.total
  }

  const handleQuotaAdding = (sectorsIds, total, startDate, endDate) => {
    addQuotasFromArray(
      user,
      {
        sectors: sectorsIds,
        total,
        startDate: startDate.format(BACKEND_DATE_FORMAT),
        endDate: endDate.format(BACKEND_DATE_FORMAT)
      }).then(
      json => {
        if (json?.data) {
          dispatch({
            type: QUOTAS.SET_QUOTAS,
            payload: json.data
          })
        }
      })
  }

  const handleCellSelection = (sector, rowIndex, colIndex) => {
    setSelectedCell({
      sector,
      startDate: tableColumns[colIndex].startDate,
      endDate: tableColumns[colIndex].endDate,
      colIndex,
      rowIndex
    })
  }

  const handleCellDoubleClicked = (sector, rowIndex, colIndex) => {
    if (sectorHasData(sector.id, colIndex)) {
      setOpenQuotaManagingForm(true)
    }
  }

  const handleQuotaEdition = (sectorId, state, quota) => {
    const newQuota = {
      ...quota,
      sector: { id: sectorId },
      section: state === GLOBAL_PLACES_KEY ? null : { id: state }
    }

    updateQuota(newQuota, user)
  }

  const handleDisplayedSectionsChange = (sectionsIds) => {
    setSectionIdsToDisplay(sectionsIds)
  }

  const handleSectorsChange = sectors => {
    setSelectedSectors(sectors)
  }

  const handleSectorCheck = (sector, add) => {
    const newSectors = add ? [...selectedSectors, sector] : selectedSectors.filter(ss => ss.id !== sector.id)

    handleSectorsChange(newSectors.sort((a, b) => a.name.localeCompare(b.name)))
  }

  const handleAddFormClosing = () => {
    setOpenAddingForm(false)
    setOpenQuotaManagingForm(false)
    setSelectedCell({})
  }

  const openQuotaForm = () => {
    setOpenAddingForm(true)
  }

  const openManagingForm = () => {
    setOpenQuotaManagingForm(true)
  }

  const getSectorData = () => {
    if (selectedCell.colIndex && placesPerColumn[selectedCell.colIndex]) {
      return placesPerColumn[selectedCell.colIndex][selectedCell.sector.id]
    }

    return null
  }

  const sectorHasData = (sectorId, colIndex) => {
    if (!placesPerColumn[colIndex] || !placesPerColumn[colIndex][sectorId]) {
      return false
    }

    return true
  }

  const getPauseQuotas = () => {
    if (selectedCell.colIndex && pausePlacesPerColumn[selectedCell.colIndex]) {
      return pausePlacesPerColumn[selectedCell.colIndex][selectedCell.sector.id]
    }

    return null
  }

  const refreshQuotas = useCallback(() => {
    Object.keys(selectedSectors).forEach(key => {
      fetchSectorQuotas(selectedSectors[key].id, user)
    })
  }, [user, selectedSectors])

  return (
    <div className='flex-row quota'>
      <div className='definition-settings-div'>
        <Settings
          selectorData={pauseActivated ? pausesAsAntdTree : sectionsAsAntdTree}
          selectedSectors={selectedSectors}
          onSectorChecked={handleSectorCheck}
          onAllSectorsChecked={handleSectorsChange}
          onCheckChanged={handleDisplayedSectionsChange}
          onTreeChanged={setSectionIdsToDisplay}
        />
      </div>
      <div className='flex-column options-and-table-div'>
        <div style={MENU_DIV_STYLE}>
          <TableMenu
            addButtonText={DEFINITION_ADDING_BUTTON_TEXT}
            date={date}
            defaultPeriod={TIME_PERIODS_TYPE[1]}
            disabledModification={!getSectorData()}
            periodType={periodType}
            modificationButtonText={DEFINITION_MANAGE_BUTTON_TEXT}
            onAddClick={openQuotaForm}
            onDateChange={setDate}
            onModificationClick={openManagingForm}
            onPeriodChange={setPeriodType}
            onRefresh={refreshQuotas}
          />
        </div>
        <PlacesDisplayTable
          currentDate={getDate()}
          sectors={selectedSectors}
          data={placesPerColumn}
          pauses={pausePlacesPerColumn}
          columns={tableColumns}
          sectionIdsToDisplay={sectionIdsToDisplay}
          selectedCell={selectedCell}
          timePeriod={period}
          onCellSelected={handleCellSelection}
          onCellDoubleClicked={handleCellDoubleClicked}
        />
      </div>
      <PlacesFormDrawer
        visible={openAddingForm}
        sectors={sectors}
        period={period}
        date={getDate()}
        onClose={handleAddFormClosing}
        onQuotaAdding={handleQuotaAdding}
      />
      <PlacesManagingDrawer
        visible={openQuotaManagingForm}
        data={getSectorData()}
        pauses={getPauseQuotas()}
        sector={selectedCell?.sector ?? null}
        onClose={handleAddFormClosing}
        onDataSaved={handleQuotaEdition}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacesDefiner)
