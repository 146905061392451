import React from 'react'

import { Col, InputNumber, Row } from 'antd'
import { connect, mapStateToProps, mapDispatchToProps } from '../../../../../reducers/Dispatchers'

const PLACES_TITLE = 'Allocated places'

const QuotaAllocationForm = props => {
  const handlePlaceChange = places => {
    if (Number.isInteger(places)) {
      props.onPlacesChanged(places)
    }

    if (places === '') {
      props.onPlacesChanged(0)
    }
  }

  return (
    <div className='allocate-quota-form'>
      <Row>
        <Col span={12}>
          <h4>
            <b>
              {props.t(PLACES_TITLE)}
            </b>
          </h4>
        </Col>
        <Col span={12}>
          <InputNumber
            value={props.places ?? 0}
            onChange={handlePlaceChange}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotaAllocationForm)
