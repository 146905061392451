import React from 'react'
import { bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, Button, notification, Checkbox } from 'antd'
import Cookie from 'js-cookie'
import remove from 'lodash/remove'
import { request, validateFormInput, generalErrorHandler, getCurrentUrl } from '../../utils'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'

const FormItem = Form.Item

const GENERAL_TERLS_OF_USAGE_LINK_FR = 'https://opalsolutions.be/wp-content/uploads/Privacy-policy_Opal-Solutions_052018_v2.pdf'
const GENERAL_TERLS_OF_USAGE_LINK_EN = 'https://opalsolutions.be/wp-content/uploads/EN_Privacy-policy_Opal-Solutions_082022.pdf'
const GENERAL_TERLS_OF_USAGE_LINK_NL = 'https://opalsolutions.be/wp-content/uploads/NL_Privacy-policy_Opal-Solutions_082022.pdf'
const GENERAL_TERLS_OF_USAGE_LINK = {
  fr_be: GENERAL_TERLS_OF_USAGE_LINK_FR,
  fr_fr: GENERAL_TERLS_OF_USAGE_LINK_FR,
  en: GENERAL_TERLS_OF_USAGE_LINK_EN,
  nl: GENERAL_TERLS_OF_USAGE_LINK_NL
}

const defaultOptins = [
  {
    id: 1,
    content: 'I have read and accepted the general terms of usage. In particular, I agree to the processing of my personal data by Interneo.',
    required: true,
    link: {
      href: GENERAL_TERLS_OF_USAGE_LINK,
      text: 'General terms of usage'
    }
  },
  {
    id: 2,
    required: false,
    content: 'I agree that my personal data can be used for recruitment purposes by the institutions using Interneo.',
    link: null
  }
]
class NormalPasswordForceUpdateForm extends React.Component {
  static propTypes = {
    showOptinsSelection: bool,
    showPasswordChangeForm: bool,
    /**
     * userIsNoStudent -> is true if the current user is not linked to a student. In this case we don't show the second optin
     */
    userIsNoStudent: bool
  }

  static defaultProps = {
    showOptinsSelection: false,
    showPasswordChangeForm: true,
    userIsNoStudent: false
  }

  constructor (props) {
    super(props)

    if (props.userIsNoStudent) {
      // remove from defaultOptions the scond item
      remove(defaultOptins, item => item.id === 2)
    }

    this.state = {
      confirmDirty: false,
      loading: false,
      optins: defaultOptins.map(o => {
        return {
          ...o,
          content: this.props.t(o.content),
          link: o.link === null ? null : {
            href: o.link.href[this.props.getActiveLanguage],
            text: this.props.t(o.link.text)
          }
        }
      })
    }
  }

  componentDidMount () {
    Cookie.remove('token')
  }

  componentDidUpdate (prevProps) {
    if (prevProps.t !== this.props.t) {
      this.updateOptins()
    }
  }

  updateOptins = () => {
    const translatedOptins = defaultOptins.map(o => {
      return {
        ...o,
        content: this.props.t(o.content),
        link: o.link === null ? null : {
          href: o.link.href[this.props.getActiveLanguage],
          text: this.props.t(o.link.text)
        }
      }
    })

    this.setState({ optins: translatedOptins })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })

        if (this.props.showPasswordChangeForm) {
          const body = {
            current_password: values.oldPassword,
            password: values.newPassword,
            password_confirm: values.newPasswordConfirm
          }

          request('/user/change-password', 'POST', body, this.props.getUser)
            .then(json => {
              if (typeof json.error !== 'undefined') {
                this.setState({ loading: false })
                notification.error({
                  message: this.props.t('Wrong old password.'),
                  placement: 'bottomRight'
                })
              } else if (!this.props.showOptinsSelection) {
                this.setState({ loading: false })
                notification.success({
                  message: this.props.t('Password update successful.'),
                  placement: 'bottomRight'
                })
                this.props.onPasswordChanged(json.token)
              } else {
                // now that the user is saved, also save the optins
                this.submitOptins(values)
              }
            })
            .catch(error => { generalErrorHandler(error) })
        } else if (this.props.showOptinsSelection) {
          this.submitOptins(values)
        }
      }
    })
  };

  submitOptins = (formValues) => {
    const optins = []
    for (let i = 0; i < this.state.optins.length; i++) {
      let text = this.state.optins[i].content
      if (this.state.optins[i].link !== null) {
        text += '<br/><br/><a href="' + this.state.optins[i].link.href + '" rel="noopener noreferrer" target="_blank">' + this.state.optins[i].link.text + '</a>'
      }
      const currentOptin = {
        text: text,
        accepted: formValues[`check_${i + 1}`] || false,
        from: getCurrentUrl()
      }
      optins.push(currentOptin)
    }
    const body = { optins }

    // send the request to backend
    request('/user/save-optins', 'POST', body, this.props.getUser)
      .then((json) => {
        this.setState({ loading: false })
        if (json.error === undefined) {
          notification.success({
            message: this.props.showPasswordChangeForm ? this.props.t('Password update successful.') : this.props.t('Your options have been saved, thank you.'),
            placement: 'bottomRight'
          })
          this.props.onPasswordChanged(json.token)
        }
      })
      .catch((error) => {
        generalErrorHandler(error)
        this.setState({ loading: false })
      })
  }

  checkFormFields = (rule, value, callback) => {
    switch (rule.field) {
      case 'oldPassword':
        if (!validateFormInput('password', value, true)) {
          callback(
            this.props.t(
              'The password must contain between 8 and 255 characters !'
            )
          )
        }
        callback()
        break
      case 'newPassword':
        if (value && this.state.confirmDirty) {
          this.props.form.validateFields(['newPasswordConfirm'], {
            force: true
          })
        }
        if (!validateFormInput('password', value, true)) {
          callback(
            this.props.t(
              'The password must contain between 8 and 255 characters !'
            )
          )
        }
        callback()
        break
      case 'newPasswordConfirm':
        if (value !== this.props.form.getFieldValue('newPassword')) { callback(this.props.t('The passwords do not match !')) }
        callback()
        break
      case 'check_1':
        if (!value) {
          callback(this.props.t('You need to accept this in order to use Interneo !'))
        }
        callback()
        break
      default:
        callback()
        break
    }
  };

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { xs: { span: 24 } },
      wrapperCol: { xs: { span: 24 } }
    }

    const { optins } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.showPasswordChangeForm &&
          <>
            {this.props.t('Please update your password :')}
            <div className='v-spacing' />
            <FormItem {...formItemLayout}>
              {getFieldDecorator('oldPassword', {
                rules: [{ validator: this.checkFormFields }]
              })(
                <Input
                  prefix={<FontAwesomeIcon icon='lock' />}
                  type='password'
                  placeholder={this.props.t('Old password')}
                />
              )}
            </FormItem>
            <FormItem {...formItemLayout}>
              {getFieldDecorator('newPassword', {
                rules: [{ validator: this.checkFormFields }]
              })(
                <Input
                  prefix={<FontAwesomeIcon icon='lock' />}
                  type='password'
                  placeholder={this.props.t('New password')}
                />
              )}
            </FormItem>
            <FormItem {...formItemLayout}>
              {getFieldDecorator('newPasswordConfirm', {
                rules: [{ validator: this.checkFormFields }]
              })(
                <Input
                  prefix={<FontAwesomeIcon icon='lock' />}
                  type='password'
                  placeholder={this.props.t('Confirm the new password')}
                />
              )}
            </FormItem>
          </>}

        {this.props.showOptinsSelection &&
          <>
            {!this.props.showPasswordChangeForm &&
              <h3 style={{ marginBottom: '20px' }}>
                {this.props.t('You have to agree with our terms and conditions before you can use Interneo!')}
              </h3>}
            {
              optins.map(o => {
                return (
                  <FormItem key={o.id}>
                    {getFieldDecorator('check_' + o.id, { rules: [{ validator: this.checkFormFields }] })(
                      <Checkbox style={{ lineHeight: 'initial' }} disabled={this.state.loading}>
                        {o.content}
                        <br />
                        <br />
                        {o.link === null ? null : <a href={o.link.href} rel='noopener noreferrer' target='_blank'>{o.link.text}</a>}
                      </Checkbox>
                    )}
                  </FormItem>
                )
              })
            }
          </>}
        <FormItem {...formItemLayout}>
          <Button
            type='primary'
            htmlType='submit'
            className='full-width'
            loading={this.state.loading}
          >
            <FontAwesomeIcon icon='check' />
            &nbsp;{this.props.t('Confirm')}
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const PasswordForceUpdateForm = Form.create()(NormalPasswordForceUpdateForm)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordForceUpdateForm)
