const help = [
  {
    key: 'SCHOOL_INTERNSHIPS',
    content: [
      {
        titleKey: 'Opmaken van een stageaanvraag',
        text: "Het opmaken van een aanvraag voor een medische, paramedische of verpleegkundige stage op Interneo. Deze video illustreert verschillende acties bij het opmaken van stageperiodes: stages toevoegen, verwijderen en annuleren, stagedetails bekijken, enz.",
        youtubeEmbed: 'iJCQmaXCej0'
      },
      {
        titleKey: 'Afspraken en stagegebonden documenten',
        text: "Het beheer van attesten en documenten met betrekking tot een medische, paramedische of verpleegkundige stage op Interneo. Het platform stelt gebruikers in staat om bestanden en documenten toe te voegen aan geaccepteerde stages, stageplaatsen, etc. Bijvoorbeeld: stageovereenkomsten, dienstentabellen, interne afspraken, regels en voorwaarden van stageplaatsen, intern reglement van zorgeenheden of de instelling, enz. Deze video illustreert verschillende acties voor het behandelen van bestanden: uploaden, downloaden, raadpleging van gegevens, zoeken naar de stageovereenkomst, enz.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Chatfunctie Interneo',
        text: "Interneo heeft een interne chatfunctie, waardoor iedereen centraal kan communiceren over de stageperiode. Deze module is bedoeld voor alle betrokkenen bij de stage: docent, coördinator, begeleider, student-stagiair, diensthoofden, verpleegkundigen, promotor, ICANE, etc. Het platform maakt het dus mogelijk om tientallen e-mails, e-mails, telefoontjes te vermijden. Alle berichten worden in de geschiedenis bewaard en gedeeld tussen elke persoon met toegangsrechten tot deze informatie. Informatie en de communicatie ervan wordt gecentraliseerd, gedeeld en opgeslagen. Deze video illustreert hoe de stagegerelateerde chatfunctie werkt.",
        youtubeEmbed: 'Q1TSs2RAI5A'
      },
      {
        titleKey: 'Opmaak en beheer van uurroosters',
        text: 'Opmaak en beheer van voorlopige uurroosters per dag: begintijd, eindtijd, pauzetijden, manipulatie van kalenders, raadplegen van de uurroosters die voor elke student of elke stage zijn gemaakt.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Hulp en updates',
        text: "Raadpleging van de hulpvideo's en de changelog: alle gebruikersvideo's zijn gegroepeerd in de helpfunctie rechts bovenaan. De changelog bevat de nieuwe functies/updates die aan elke versie zijn toegevoegd en is toegankelijk links in de menubalk.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Inloggen bij Interneo',
        text: 'Hoe inloggen? Met behulp van uw gebruikersnaam en wachtwoord. Je wachtwoord vergeten ? Hier leest u hoe u het kunt resetten.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'INSTITUTION_INTERNSHIPS',
    content: [
      {
        titleKey: 'Opmaken van een stageaanvraag',
        text: "Het opmaken van een aanvraag voor een medische, paramedische of verpleegkundige stage op Interneo. Deze video illustreert verschillende acties bij het opmaken van stageperiodes: stages toevoegen, verwijderen en annuleren, stagedetails bekijken, enz.",
        youtubeEmbed: 'iJCQmaXCej0'
      },
      {
        titleKey: 'Afspraken en stagegebonden documenten',
        text: "Het beheer van attesten en documenten met betrekking tot een medische, paramedische of verpleegkundige stage op Interneo. Het platform stelt gebruikers in staat om bestanden en documenten toe te voegen aan geaccepteerde stages, stageplaatsen, etc. Bijvoorbeeld: stageovereenkomsten, dienstentabellen, interne afspraken, regels en voorwaarden van stageplaatsen, intern reglement van zorgeenheden of de instelling, enz. Deze video illustreert verschillende acties voor het behandelen van bestanden: uploaden, downloaden, raadpleging van gegevens, zoeken naar de stageovereenkomst, enz.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Chatfunctie Interneo',
        text: "Interneo heeft een interne chatfunctie, waardoor iedereen centraal kan communiceren over de stageperiode. Deze module is bedoeld voor alle betrokkenen bij de stage: docent, coördinator, begeleider, student-stagiair, diensthoofden, verpleegkundigen, promotor, ICANE, etc. Het platform maakt het dus mogelijk om tientallen e-mails, e-mails, telefoontjes te vermijden. Alle berichten worden in de geschiedenis bewaard en gedeeld tussen elke persoon met toegangsrechten tot deze informatie. Informatie en de communicatie ervan wordt gecentraliseerd, gedeeld en opgeslagen. Deze video illustreert hoe de stagegerelateerde chatfunctie werkt.",
        youtubeEmbed: 'Q1TSs2RAI5A'
      },
      {
        titleKey: 'Opmaak en beheer van uurroosters',
        text: 'Opmaak en beheer van voorlopige uurroosters per dag: begintijd, eindtijd, pauzetijden, manipulatie van kalenders, raadplegen van de uurroosters die voor elke student of elke stage zijn gemaakt.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Hulp en updates',
        text: "Raadpleging van de hulpvideo's en de changelog: alle gebruikersvideo's zijn gegroepeerd in de helpfunctie rechts bovenaan. De changelog bevat de nieuwe functies/updates die aan elke versie zijn toegevoegd en is toegankelijk links in de menubalk.",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Inloggen bij Interneo',
        text: 'Hoe inloggen? Met behulp van uw gebruikersnaam en wachtwoord. Je wachtwoord vergeten ? Hier leest u hoe u het kunt resetten.',
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'INSTITUTION_STUDENTS',
    content: [
      {
        titleKey: 'Overeenkomsten, contracten en dossiers met betrekking tot stages',
        text: "Beheer certificaten en documenten met betrekking tot medische, paramedische of verpleegkundige stages op Interneo. Het platform stelt gebruikers in staat om bestanden en documenten te koppelen aan afgeronde stages, stageplaatsen, etc. Bijvoorbeeld: stagecontracten, prestatietabellen, interne beschrijvingen, reglementen en voorwaarden voor stageplaatsen, interne reglementen voor zorgeenheden of verpleeghuizen, etc. Deze video illustreert verschillende handelingen voor het verwerken van bestanden: uploaden, downloaden/downloaden, details bekijken, zoeken naar de plaatsingsovereenkomst, enz.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Uurroosters opmaken',
        text: 'Opmaak en beheer van voorlopige uurroosters per dag: begintijd, eindtijd, pauzetijden, manipulatie van kalenders, raadplegen van de uurroosters die voor elke student of elke stage zijn gemaakt.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Hulp en updates',
        text: "De hulpvideo\'s en de changelog bekijken: alle gebruikersvideo\'s zijn gegroepeerd in de hulpsectie rechtsboven. De changelog een lijst van de nieuwe functies bij elke update (versie), kan onderaan in het menu worden geopend.\n",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Verbinding maken met Interneo',
        text: "Hoe log ik in? Met uw gebruikersnaam en wachtwoord. Bent u uw wachtwoord vergeten? Hier leest u hoe u het opnieuw kunt instellen.",
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  },
  {
    key: 'SCHOOL_STUDENTS',
    content: [
      {
        titleKey: 'Overeenkomsten, contracten en dossiers met betrekking tot stages',
        text: "Beheer certificaten en documenten met betrekking tot medische, paramedische of verpleegkundige stages op Interneo. Het platform stelt gebruikers in staat om bestanden en documenten te koppelen aan afgeronde stages, stageplaatsen, etc. Bijvoorbeeld: stagecontracten, prestatietabellen, interne beschrijvingen, reglementen en voorwaarden voor stageplaatsen, interne reglementen voor zorgeenheden of verpleeghuizen, etc. Deze video illustreert verschillende handelingen voor het verwerken van bestanden: uploaden, downloaden/downloaden, details bekijken, zoeken naar de plaatsingsovereenkomst, enz.",
        youtubeEmbed: 'qugsLaNFNEM'
      },
      {
        titleKey: 'Uurroosters opmaken',
        text: 'Opmaak en beheer van voorlopige uurroosters per dag: begintijd, eindtijd, pauzetijden, manipulatie van kalenders, raadplegen van de uurroosters die voor elke student of elke stage zijn gemaakt.',
        youtubeEmbed: 'OWp2pZQUxAo'
      },
      {
        titleKey: 'Hulp en updates',
        text: "De hulpvideo\'s en de changelog bekijken: alle gebruikersvideo\'s zijn gegroepeerd in de hulpsectie rechtsboven. De changelog een lijst van de nieuwe functies bij elke update (versie), kan onderaan in het menu worden geopend.\n",
        youtubeEmbed: 'MMcmbtezUcs'
      },
      {
        titleKey: 'Verbinding maken met Interneo',
        text: "Hoe log ik in? Met uw gebruikersnaam en wachtwoord. Bent u uw wachtwoord vergeten? Hier leest u hoe u het opnieuw kunt instellen.",
        youtubeEmbed: 'MMcmbtezUcs'
      }
    ]
  }
]

export default help
