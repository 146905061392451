import appConfig from '../../config'
import { canonizeString, generalErrorHandler, request } from '../../utils'
import { STUDENTS } from './actionsType'

const {
  SET_STUDENTS, ADD_STUDENT, UPDATE_STUDENT,
  REMOVE_STUDENT
} = STUDENTS

export const getStudents = state => {
  return state.students
}

export const setStudents = students => {
  return (dispatch, getState) => {
    const studentsWithSections = setStudentsSections(students, getState())
    dispatch({
      type: SET_STUDENTS,
      students: studentsWithSections
    })
  }
}

export const fetchStudents = (currentUser) => {
  return async (dispatch, getState) => {
    try {
      const studentsList = []
      const { data, nextPage } = await request(`/students/0/${appConfig.apiPageSize}`, 'GET', null, currentUser)
      let nextPageInfo = nextPage
      studentsList.push(...data)
      while (nextPageInfo) {
        const newPageResults = await request(nextPageInfo, 'GET', null, currentUser)
        if (newPageResults.data) {
          studentsList.push(...newPageResults.data)
        }
        nextPageInfo = newPageResults.nextPage
      }

      if (studentsList.length) {
        const studentsWithSections = setStudentsSections(studentsList, getState())
        dispatch({
          type: SET_STUDENTS,
          students: studentsWithSections
        })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }
}

const setStudentsSections = (students, globalState) => {
  const sectionsFlatList = []
  if (globalState.getSchools.list.length) {
    globalState.getSchools.list.forEach(school => {
      if (school.sections && school.sections.length > 0) {
        sectionsFlatList.push(...school.sections)
      }
      if (school.parentSections && school.parentSections.length > 0) {
        sectionsFlatList.push(...school.parentSections)
      }
    })
  }

  let studentsWithSections = []
  const studentsIds = []
  students.forEach(student => {
    if (studentsIds.includes(student.id)) {
      const existingStudent = studentsWithSections.find(item => item.id === student.id)
      if (existingStudent) {
        existingStudent.sections.push(...generateStudentSectionDetails(student, sectionsFlatList, globalState))
      }
    } else {
      if (student.sections && student.sections.length > 0) {
        student.sections = generateStudentSectionDetails(student, sectionsFlatList, globalState)
      }
      // remove unused properties
      const { studentAlreadyExists, updateResultNotification, ...studentProps } = student
      studentsIds.push(student.id)
      studentsWithSections.push(studentProps)
    }
  })

  studentsWithSections = studentsWithSections.sort((a, b) =>
    canonizeString(a.lastname + a.firstname).localeCompare(
      canonizeString(b.lastname + b.firstname)
    )
  )
  return studentsWithSections
}

const generateStudentSectionDetails = (student, sectionsFlatList, globalState) => {
  if (student.sections && student.sections.length > 0) {
    const sectionsDetails = []
    student.sections.forEach(sectionId => {
      const foundSection = sectionsFlatList.find(item => item.id === sectionId)
      if (foundSection) {
        sectionsDetails.push(foundSection)
      }
    })
    if (globalState.getUser.context === 'SCHOOL') {
      // in this case, for each student we keep only one section found in the user's school sections
      const schoolSections = globalState.getSchools.list.length > 0 ? [...globalState.getSchools.list[0].sections, globalState.getSchools.list[0].rootSection] : []
      const studentSectionForSchoolUser = sectionsDetails.find(item => {
        return schoolSections.find(schoolSection => schoolSection.id === item.id)
      })
      if (studentSectionForSchoolUser) {
        return [studentSectionForSchoolUser]
      } else {
        return []
      }
    } else {
      return sectionsDetails
    }
  }

  return []
}

export const saveStudent = (studentData, isEdit = false, newSectionContext = false) => {
  return (dispatch, getState) => {
    const user = getState().getUser
    const student = { ...studentData }

    if (newSectionContext) {
      const schoolSections = getState().schoolSections[user.school.id]

      student.sections = student.sections
        ? student.sections.filter(section => {
          return schoolSections.find(schoolSection => schoolSection.id === section.id)
        })
        : []

      if (student.sections.length === 0) {
        student.sections = [getState().getSchools.list[0].rootSection]
      }

      return dispatch(addOrUpdateStudent(student, isEdit))
    }

    // if the user context is school, make sure we keep only the section of the current user

    if (['ROLE_ENCODER', 'ROLE_COORDINATOR'].includes(user.roles[0]) && student.sections && student.sections.length) {
      const schoolSections = getState().getSchools.list[0].sections
      const finalStudentSections = student.sections.filter(item => schoolSections.find(schoolSection => schoolSection.id === item.id))
      student.sections = finalStudentSections
    }

    // remove unused properties from state
    const { studentAlreadyExists, updateResultNotification, ...studentProps } = student

    return dispatch({
      type: isEdit ? UPDATE_STUDENT : ADD_STUDENT,
      student: studentProps
    })
  }
}

const addOrUpdateStudent = (student, isEdit) => {
  return {
    type: isEdit ? UPDATE_STUDENT : ADD_STUDENT,
    student: student
  }
}

export const removeStudent = ({ studentId, currentUser }) => {
  request(`/api/Student/${studentId}`, 'DELETE', null, currentUser)
  return {
    type: REMOVE_STUDENT,
    payload: { studentId }
  }
}
