import React, { useCallback, useState } from 'react'

import StudentPathwayBulkForm from './Forms/StudentPathwayBulkForm'
import { Divider } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import StudentPathwaysList from './Lists/StudentPathwaysList'
import { getFullAbbreviation } from '../../utils/entities/sectionToYear'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const StudentPathwaysBuffer = ({ defaultValues = [], onChange, t }) => {
  const [studentPathways, setStudentPathways] = useState(defaultValues)

  const updateStudentPathways = useCallback(newStudentPathways => {
    setStudentPathways(newStudentPathways)
    onChange(newStudentPathways)
  })

  const addStudentPathways = useCallback(newStudentPathways => {
    const spToBeAdded = newStudentPathways.filter(nsp => !studentPathways.find(sp => sp.sectionToYear.id === nsp.sectionToYear.id))

    updateStudentPathways([...studentPathways, ...spToBeAdded])
  }, [studentPathways, updateStudentPathways])

  const deleteStudentPathways = useCallback(pathwaysToBeRemoved => {
    const newStudentPathways = studentPathways.filter(sp => !pathwaysToBeRemoved.find(p => getFullAbbreviation(p.sectionToYear) === getFullAbbreviation(sp.sectionToYear)))

    updateStudentPathways(newStudentPathways)
  }, [studentPathways, updateStudentPathways])

  return (
    <div className='flex-col'>
      <b className='ft-s-20'> {t('student_pathways_buffer.list_title')} </b>
      <Divider style={{ width: 'auto', margin: '0px', marginBottom: '8px' }} type='horizontal' />
      <div className='flex-row'>
        <div className='w-50'>
          <StudentPathwayBulkForm saveText={t('student_pathways_buffer.form_button')} onSubmit={addStudentPathways} />
        </div>
        <Divider style={{ height: 'auto' }} type='vertical' />
        <div className='w-50'>
          <StudentPathwaysList bordered={false} data={studentPathways} maxHeight='200' onDelete={deleteStudentPathways} />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(StudentPathwaysBuffer)
