import { isArray, isBoolean } from 'lodash'

export const buildFilters = filters => {
  const validFilters = []

  filters.forEach(f => {
    let valid = true

    Object.keys(f.props).forEach(key => {
      const value = f.props[key]

      if ((isArray(value) && value.length === 0) || (value !== 0 && value !== null && !isBoolean(value) && !value)) {
        valid = false
      }
    })

    if (valid) {
      validFilters.push(f)
    }
  })

  return validFilters
}

export class Filter {
  constructor ({ key, label, component, props = {}, disabled = false}) {
    this.key = key
    this.label = label
    this.component = component
    this.props = props
    this.disabled = disabled
  }
}
