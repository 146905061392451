const SET_APP_BANNER = 'SET_APP_BANNER'

export const getAppBanner = (state) => {
  return state
}

export const setAppBanner = (appBanner) => {
  return {
    type: SET_APP_BANNER,
    appBanner: appBanner
  }
}

export default (state = null, action) => {
  switch (action.type) {
    case SET_APP_BANNER:
      return action.appBanner
    default:
      return state
  }
}
