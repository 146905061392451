import React from 'react'

const ActionsRow = ({ actions }) => {
  return (
    <div className='actions-row float-btm-ctr flex-row jtf-ctt-ctr bdr-2'>
      {actions.map((action, index) => action.render(index))}
    </div>
  )
}

export default ActionsRow
