const SET_DATA_READY = 'SET_DATA_READY'

export const getDataReady = state => {
  return state
}

export const setDataReady = dataReady => {
  return {
    type: SET_DATA_READY,
    dataReady: dataReady
  }
}

export default (state = [], action) => {
  switch (action.type) {
    case SET_DATA_READY:
      return action.dataReady
    default:
      return state
  }
}
