import React from 'react'

const CheckboxUserSectionTable = ({ setUserContactPerson, sectionId, isChecked, handleChangeChecked, indexSection }) => {
  const checkEntity = () => {
    if (typeof setUserContactPerson === 'function') {
      setUserContactPerson(sectionId, isChecked)
    }

    handleChangeChecked(indexSection)
  }

  return (
    <div className='div-checkbox'>
      <input checked={isChecked} onChange={checkEntity} className='checkbox' type='checkbox' />
    </div>
  )
}

export default (CheckboxUserSectionTable)
