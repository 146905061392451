import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { getUser } from '../../../reducers/UserReducer'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { deleteUser, getPaginateUsers } from '../../../utils/api/user'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { connect } from 'react-redux'
import UserFormModal from '../../../HOC/Forms/UserFormModal'
import UsersTable from './UsersTable'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { onError, onSuccess } from '../../../utils/apiHelper'
import { getTranslate } from 'react-localize-redux'
import UserManagedSectors from '../../../Components/institution/UserManagedSectors'
import AdminResetPassword from '../../../Components/shared/AdminResetPassword'
import { isArray } from 'lodash'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import { ORDER_BY } from '../../../utils/constants'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const UsersView = ({ user, t, setSelectedUser }) => {
  const { institutions } = useContext(GlobalContext)
  const { retrieveInstitutionSectors } = useContext(InstitutionGroupContext)
  const { selectedInstitution } = useContext(GlobalFiltersContext)

  const [users, setUsers] = useState({ data: [], meta: new Metadata({ pageSize: 20 }) })
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.EMAIL, pageSize: 20, filters: { institution: selectedInstitution } }))
  const [selectedUserSectors, setSelectedUserSectors] = useState(null)
  const [selectedUserPassword, setSelectedUserPassword] = useState(null)
  const [loading, setLoading] = useState(false)

  const selectedSectors = useMemo(() => {
    return retrieveInstitutionSectors(isArray(selectedUserSectors?.institutions) ? selectedUserSectors.institutions[0] : null)
  }, [retrieveInstitutionSectors, selectedUserSectors])

  const timer = useRef(null)

  useEffect(() => {
    if (user) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => fetchUsers(user, tableParameters), 300)
    }
  }, [user.institutionGroup, tableParameters, timer])

  const fetchUsers = (user, filters) => {
    setLoading(true)

    getPaginateUsers(user, filters).then(json => {
      if (json?.data || json?.meta) {
        const users = {}

        users.data = json?.data ?? []
        users.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

        setUsers(users)
      }

      setLoading(false)
    })
  }

  const handleDeleteUser = useCallback(userToDelete => {
    deleteUser(user, userToDelete).then(json => {
      if (json?.data) {
        onSuccess(t('user_view.actions.delete-user.success'))
        fetchUsers(user, tableParameters)
      } else {
        onError(t('user_view.actions.delete-user.error'))
      }
    })
  }, [user, tableParameters, t])

  return (
    <div className='users-view-container'>
      <UserFormModal onSave={() => fetchUsers(user, tableParameters)} institutions={institutions}>
        <UsersTable
          parameters={tableParameters}
          loading={loading}
          users={users}
          onDeleteUser={handleDeleteUser}
          onParametersChange={setTableParameters}
          onSelectUserPassword={setSelectedUserPassword}
          onSelectUserSectors={setSelectedUserSectors}
        />
      </UserFormModal>
      <UserManagedSectors
        selectedUser={selectedUserSectors}
        institutionSectors={selectedSectors}
        onClose={() => setSelectedUserSectors(null)}
      />
      <AdminResetPassword
        userToResetPassword={selectedUserPassword}
        onClose={() => setSelectedUserPassword(null)}
      />
    </div>
  )
}

export default connect(mapStateToProps)(UsersView)
