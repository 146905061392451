import { connect, mapDispatchToProps, mapStateToProps } from '../../../../../reducers/Dispatchers'
import { Button, Form, InputNumber, Modal } from 'antd'
import React, { useState } from 'react'

const UnitActsFormModal = (props) => {
  const [values, setValues] = useState({ unit: 0 })

  const okButtonProps = { htmlType: 'submit', form: 'actEditionForm' }
  const footer =
    (
      <div>
        <Button key='back' onClick={() => props.onClose(false)}>
          {props.t('Cancel')}
        </Button>
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            props.onClose(true, values)
            setValues({})
          }}
        >
          {props.t('Submit')}
        </Button>
      </div>
    )

  return (
    <Modal
      title={props.title}
      visible
      onCancel={props.onCancel}
      okButtonProps={okButtonProps}
      footer={footer}
    >
      <Form
        id='actEditionForm'
        layout='vertical'
        initialvalues={values}
      >
        <Form.Item>
          <InputNumber
            size='large'
            min={0}
            defaultValue={values.unit}
            placeholder={values.unit}
            onChange={(value) => {
              setValues({ ...values, unit: value })
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitActsFormModal)
